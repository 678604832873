import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {LadDate} from 'ladrov-commons';
import {QtOrderModel} from '../../models/qt-order.model';
import {LadRadio} from '../../../../app/dynamic-form/components/lad-radio/lad-radio.component';
import {LadInput} from '../../../../app/dynamic-form/components/lad-input/lad-input.component';
import {LadCheckbox} from '../../../../app/dynamic-form/components/lad-checkbox/lad-checkbox.component';
import {DynamicFormComponent} from '../../../../app/dynamic-form/form/dynamic-form.component';
import {debounceTime} from 'rxjs/operators';
import {ChartOptions, themeColors} from '../../../../app/charts/apex/apex.component';

const formatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
  useGrouping: true,
});

export enum OrderSummaryDateFilterValues {
  MONTH,
  WEEK,
  TODAY,
  CUSTOM
}

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit {

  @ViewChild('dynamicFormElement') dynamicFormElement: DynamicFormComponent;

  showLoadingMsg = false;
  summary: any;
  dynamicFields: any;
  lineChartOptions: Partial<ChartOptions>;
  resultViewType: 'chart' | 'list' = 'chart';
  currentListPage = 1;
  ordersArray: QtOrderModel[] = [];

  constructor(public cd: ChangeDetectorRef) {
    this.dynamicFields = {
      dateFilter: new LadRadio({
        row: 'row1',
        label: 'Date Filter:',
        columnClass: 'col-md-4',
        value: OrderSummaryDateFilterValues.TODAY,
        items: [
          {
            label: 'Today', value: OrderSummaryDateFilterValues.TODAY
          },
          {
            label: 'This Week', value: OrderSummaryDateFilterValues.WEEK
          },
          {
            label: 'This Month', value: OrderSummaryDateFilterValues.MONTH
          },
          {
            label: 'Custom', value: OrderSummaryDateFilterValues.CUSTOM
          },
        ]
      }),
      resultType: new LadRadio({
        row: 'row2',
        label: 'Result Type:',
        columnClass: 'col-md-4',
        value: 'chart',
        items: [
          {
            label: 'Chart', value: 'chart'
          },
          {
            label: 'List', value: 'list'
          }
        ]
      }),
      startDate: new LadInput({
        row: 'row2',
        label: 'Start Date',
        value: new LadDate(),
        required: true,
        inputType: 'date',
        visible: false
      }),
      endDate: new LadInput({
        row: 'row2',
        label: 'End Date',
        value: new LadDate(),
        required: true,
        inputType: 'date',
        visible: false
      }),
      // searchKey: new LadInput({
      //   row: 'row2',
      //   label: 'Order No.',
      //   required: false,
      //   columnClass: 'col-md-4',
      //   visible: false
      // }),
      cancelledOnly: new LadCheckbox({label: 'Cancelled Only', value: false}),
    };
  }

  randomNo() {
    const min = 5000;
    const max = 200000;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  getDatesToCurrentFromMonthStart() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const dates: Date[] = [];
    for (let day = 1; day <= currentDate.getDate(); day++) {
      const date = new Date(year, month, day);
      dates.push(date);
    }
    return dates;
  }

  getDatesToCurrentFromWeekStart() {
    const currentDate = new Date();
    const currentDayOfWeek = currentDate.getDay(); // 0 (Sunday) to 6 (Saturday)
    // Calculate the start of the week (Sunday)
    const startOfWeek = new Date(currentDate);
    startOfWeek.setDate(currentDate.getDate() - currentDayOfWeek);
    const dates: Date[] = [];
    for (let i = 0; i <= currentDayOfWeek; i++) {
      const date = new Date(startOfWeek);
      date.setDate(startOfWeek.getDate() + i);
      dates.push(date);
    }
    return dates;
  }

  ngOnInit(): void {
    const categories = [];
    const data = [];
    for (let i = 1; i <= 31; i++) {
      categories.push(`${i}`);
      data.push(this.randomNo());
    }
    this.lineChartOptions = {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      colors: themeColors,
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      series: [{
        name: 'Total Order Amount',
        data,
      }],
      title: {
        text: '',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#F5F5F5', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories,
        labels: {
          style: {
            fontSize: '11px'
          }
        }
      },
      yaxis: {
        tickAmount: 8,
        labels: {
          style: {
            fontSize: '11px'
          },
          formatter(val: number, opts?: any): string {
            return formatter.format(val);
          }
        }
      }
    };
  }

  // async searchOrders(doc) {
  //   const searchArgs = this.dynamicFormElement.form.getRawValue();
  //   debugger;
  //   const result = await QtOrderModel.search(searchArgs);
  //   this.orders = result;
  // }

  onFormInitDone() {
    this.dynamicFields.dateFilter.fieldValueChanges.subscribe(value => {
      const showDates = value === OrderSummaryDateFilterValues.CUSTOM;
      this.dynamicFields.startDate.visible = showDates;
      this.dynamicFields.endDate.visible = showDates;
      this.cd.markForCheck();
    });
    this.dynamicFields.resultType.fieldValueChanges.subscribe(value => {
      this.resultViewType = value;
      this.cd.markForCheck();
    });
    // butangan ug debounce kay para dli dayon maevaluate taga input ug character. maka tiwas pag input ang user
    this.dynamicFormElement.form.valueChanges.pipe(debounceTime(500)).subscribe((formValue) => {
      this.doSearchOrders();
    });

    this.doSearchOrders();
  }

  getReportStartEndDates(formValue) {
    let startDate: Date;
    let endDate: Date;
    const currentDate = new Date();
    switch (formValue.dateFilter) {
      case OrderSummaryDateFilterValues.MONTH:
        currentDate.setDate(1);
        startDate = new Date(currentDate.toLocaleDateString());
        currentDate.setMonth(currentDate.getMonth() + 1);
        currentDate.setDate(0);
        endDate = new Date(currentDate.toLocaleDateString());
        break;
      case OrderSummaryDateFilterValues.WEEK:
        // Calculate the current day of the week (0-6, where 0 is Sunday)
        const currentDay: number = currentDate.getDay();
        // Calculate the difference between the current day and Monday
        const diff: number = currentDay === 0 ? 6 : currentDay - 1;
        // Calculate the start date of the current week (Monday)
        startDate = new Date(currentDate.setDate(currentDate.getDate() - diff));
        // Calculate the end date of the current week (Sunday)
        endDate = new Date(currentDate.setDate(currentDate.getDate() + 6));
        break;
      case OrderSummaryDateFilterValues.CUSTOM:
        startDate = new Date(formValue.startDate.date);
        endDate = new Date(formValue.endDate.date);
        debugger;
      default: // today
        startDate = new Date(currentDate);
        endDate = new Date(startDate);
        break;
    }
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    return {startDate, endDate};
  }

  async doSearchOrders() {
    const formValue = this.dynamicFormElement.form.getRawValue();
    const {searchKey} = formValue;
    const {startDate, endDate} = this.getReportStartEndDates(formValue);
    // TODO: fetch only current store
    this.ordersArray = await QtOrderModel.search({startDate, endDate, searchKey});
    this.ordersArray.forEach(e => {
      for (const i of e.items) {

      }
    });
    debugger;
    this.cd.markForCheck();
  }

  cancelOrder(item: QtOrderModel) {

  }
}
