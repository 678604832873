import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {LadField} from '../../models/lad-field';

@Component({
  selector: 'lad-section-title',
  templateUrl: './lad-section-title.component.html',
  styleUrls: ['./lad-section-title.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LadSectionTitleComponent implements OnInit {

  @Input()
  formField: LadSectionTitle;

  constructor() {
  }

  ngOnInit(): void {
  }

}

export class LadSectionTitle extends LadField<string> {
  icon: string;
  title: string;
  subTitle: string;

  constructor(options) {
    super(options);
    this.__componentType = LadSectionTitleComponent;
    this.columnClass = 'col-md-12';
    this.excludeAtSave = true;
  }
}
