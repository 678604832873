import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LadComponentBase} from '../../models/lad-component-base';
import {LadField, LadFieldOptions} from '../../models/lad-field';

import Map from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import {fromLonLat, transform} from 'ol/proj';
import {Fill, Stroke, Style, Icon} from 'ol/style';
import {Draw} from 'ol/interaction';
import {OSM, Vector as VectorSource, XYZ} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {Point} from 'ol/geom';
import {GeoJSON} from 'ol/format';
import {Attribution, FullScreen, defaults as defaultControls} from 'ol/control';
import {ToastrService} from 'ngx-toastr';
// import {transform} from 'ol/proj';
import {defaults as defaultInteractions} from 'ol/interaction';
import {Subscription} from 'rxjs';
import {LadGeoJSONObject} from 'ladrov-commons';

@Component({
  selector: 'app-lad-single-location',
  templateUrl: './lad-single-location.component.html',
  styleUrls: ['./lad-single-location.component.scss']
})
export class LadSingleLocationComponent extends LadComponentBase implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  formField: LadSingleLocation;

  @Input()
  editable?: boolean;

  private DEFAULT_CENTER = [125.607900, 7.064612];
  private mapControls: any;
  private pointInteraction: Draw;
  private map: Map;
  private allInteractions: any[];
  private allControls: any[];
  private DEFAULT_ZOOM = 12;
  private DEFAULT_ZOOM_WITH_VALUE = 15;

  geoJSON = new GeoJSON(); // formatter
  vectorSource: VectorSource;

  mapFrozen = false;
  private $fieldValueChanges: Subscription;

  @Input()
  set value(value: LadGeoJSONObject) {
    if (!value) {
      return;
    }
    this.formField.value = value;
  }

  constructor(
    public toastr: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.$fieldValueChanges = this.formField.fieldValueChanges.subscribe((nextValue: LadGeoJSONObject) => {
      this.setValue(nextValue);
      // must be responsible for setting controlRef value (patchValue)
      this.formField.formControl.patchValue(nextValue,{onlySelf: true});
    });
  }

  ngOnDestroy(): void {
    if (this.$fieldValueChanges) {
      this.$fieldValueChanges.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    // initialise map here:
    const attribution = new Attribution({
      collapsible: true,
      collapsed: true,
      tipLabel: 'OSM'
    });
    const osmSrc = new OSM({
      // url : 'https://tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=148d9e850a014139a6e02dc703659cd3'
    });
    const rasterLayer = new TileLayer({
      source: osmSrc
    });

    // from retina (high resolution maps) example
    const highLayer = new TileLayer({
      source: new XYZ({
        url:
          'https://api.maptiler.com/maps/streets/256/{z}/{x}/{y}@2x.png?key=Jwm8B5N9cVVMHoTf5Opi',
        tilePixelRatio: 2, // THIS IS IMPORTANT
      })
    });

    this.vectorSource = new VectorSource({
      features: []
    });
    const vectorLayer = new VectorLayer({
      source: this.vectorSource,
      style: new Style({
        fill: new Fill({
          color: 'rgba(222, 106, 81, 0.2)',
        }),
        stroke: new Stroke({
          color: '#DE6A51',
          width: 2,
        }),
        image: new Icon(({
          src: '/assets/map/placeholder2.svg',
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          anchor: [0.5, 64],
          anchorOrigin: 'top-left',
          scale: 0.5,
        }))
      }),
    });

    this.map = new Map({
      controls: defaultControls({attribution: false, rotate: false}).extend([attribution, new FullScreen()]),
      // projection: 'EPSG:3857',
      interactions: defaultInteractions({ // see https://openlayers.org/en/latest/apidoc/module-ol_interaction.html
        altShiftDragRotate: false,
        doubleClickZoom: true,
        // dragAndDrop: false,
        // keyboardPan: true,
        // keyboardZoom: true,
        mouseWheelZoom: true,
        // pointer: true,
        // select: false, // no box select
        pinchZoom: true,
        pinchRotate: false,
        shiftDragZoom: true
      }),
      layers: [
        rasterLayer,
        highLayer,
        vectorLayer
      ],
      target: this.formField.key,
      view: new View({
        center: fromLonLat(this.DEFAULT_CENTER),
        zoom: this.formField.value ? this.DEFAULT_ZOOM_WITH_VALUE : this.DEFAULT_ZOOM,
        minZoom: 10,
        maxZoom: 18.5
      }),
    });

    this.mapControls = this.map.getControls();

    this.pointInteraction = new Draw({
      source: this.vectorSource,
      type: 'Point'
    });

    this.pointInteraction.on('drawstart', (e) => {
      if (this.map.getView().getZoom() < 15) {
        this.toastr.clear();
        this.toastr.warning('Please ZOOM IN further to point a location.');
        return e.target.abortDrawing();
      } else {
        this.vectorSource.clear();
      }
    });

    this.pointInteraction.on('drawend', (e) => {
      // @ts-ignore
      const coords = e.feature.getGeometry().getCoordinates();
      // this will trigger controlRef.valueChangesListener

      const coordinates = transform(coords, 'EPSG:3857', 'EPSG:4326');
      console.log(`https://api.maptiler.com/geocoding/${coordinates[0]},${coordinates[1]}.json?key=Jwm8B5N9cVVMHoTf5Opi`);

      this.formField.formControl.patchValue({
        type: 'Point',
        coordinates
      },{onlySelf: true});
      this.setCenter(e.feature);
    });

    this.map.addInteraction(this.pointInteraction);

    this.setValue(this.formField.value);

  }

  private setValue(newValue: LadGeoJSONObject) {
    let feature = null;
    if (newValue && newValue.coordinates) {
      const point = new Point(transform(newValue.coordinates, 'EPSG:4326', 'EPSG:3857'));
      feature = new Feature({
        geometry: point,
        // name: 'Home location',
      });
      this.vectorSource.clear();
      this.vectorSource.addFeatures([feature]);
    }
    this.setCenter(feature);
  }

  private setCenter(feature: Feature) {
    setTimeout(() => {
      // allow edit if no value or undefined
      if (!feature && this.formField.editable !== false) {
        this.unfreezeMap();
        this.map.updateSize();
        return;
      }
      // this.vectorSource.clear();
      let centerCoordinates = fromLonLat(this.DEFAULT_CENTER);
      if (feature) {
        // @ts-ignore
        centerCoordinates = feature.getGeometry().getCoordinates();
      }
      // console.log(centerCoordinates);
      this.map.getView().setCenter(centerCoordinates);
      this.map.getView().setZoom(this.DEFAULT_ZOOM_WITH_VALUE);
      // disable edit if value is set
      this.freezeMap();
      this.map.updateSize();
    });
  }

  private freezeMap() {
    this.mapFrozen = true;
    setTimeout(() => {
      if (!this.allInteractions) {
        this.allInteractions = [];
        // just put it in another array because removing from here will modify `this.map.getInteractions()` and mess up the loop
        this.map.getInteractions().forEach(each => {
          this.allInteractions.push(each);
        });
      }
      // we can now safely remove here because we aren't modifying the original `this.map.getInteractions()`
      for (const i of this.allInteractions) {
        this.map.removeInteraction(i);
      }
      if (!this.allControls) {
        this.allControls = [];
        this.map.getControls().forEach(each => {
          this.allControls.push(each);
        });
      }
      for (const c of this.allControls) {
        this.map.removeControl(c);
      }
      this.map.updateSize();
    });
  }

  private unfreezeMap() {
    if (this.editable) {
      return;
    }
    this.mapFrozen = false;
    setTimeout(() => {
      if (this.allInteractions) {
        for (const i of this.allInteractions) {
          this.map.addInteraction(i);
        }
      }
      if (this.allControls) {
        for (const c of this.allControls) {
          this.map.addControl(c);
        }
      }
      this.map.updateSize();
    });
  }

}

export class LadSingleLocation extends LadField<LadGeoJSONObject> {

  constructor(options: LadFieldOptions<LadGeoJSONObject>) {
    super(options);
    this.__componentType = LadSingleLocationComponent;
    this.columnClass = 'col-md-12';
  }
}
