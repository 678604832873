import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-invoice-template',
  templateUrl: './invoice-template.component.html',
  styleUrls: ['./invoice-template.component.scss']
})
export class InvoiceTemplateComponent implements OnInit {
  billTo = 'gpaytechnologies.com';
  dateStr = 'August 1 - 31, 2023';
  invoiceNo = '20230827';
  deadLineStr = 'Aug 31, 2023';

  constructor() {
  }

  ngOnInit(): void {
  }

}
