
<h2 class="card-title">
  <i class="ft-grid"></i>
  &nbsp;Winning Numbers
</h2>


<div class="card-content">
  <div class="px-3" >

    <lad-dynamic-form #dynamicFormElement
                      (onInitDone)="onDFormInitDone()"
                      [dynamicFields]="dynamicFields"
                      [customSaveFn]="onSave"
                      [submitType]="'global'">
    </lad-dynamic-form>

  </div>
</div>
<a (click)="addNewRow()" class="mb-1 col-md-6 text-warning text-bold-500 float-right"
   style="text-transform: uppercase; font-size: 0.85rem; font-weight: 500;">
  <i class="ft-plus-square mr-1"></i>New
</a>
<br/>
<div [id]="hbTableId" class="mt-1 col-md-6 font-weight-light" style="overflow-x: auto;">
  <p class="font-weight-light font-small-2">Loading...</p>
</div>
