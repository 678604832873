import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ImageCroppedEvent} from 'ngx-image-cropper';

declare var window: any;

@Component({
  selector: 'app-image-uploader',
  templateUrl: './lad-image-uploader.component.html',
  styleUrls: ['./lad-image-uploader.component.scss']
})

export class LadImageUploaderComponent {
  @Input() name;
  @Input() title;
  @Input() aspectRatio;
  @Input() resizeToWidth;
  @Input() format;
  @Input() imageQuality;

  imageChangedEvent: any = '';
  cropped: any = '';
  original: File;

  reader = new FileReader();

  status = {
    processing: false,
    icon: 'icon-login',
    error: false,
    message: ''
  };
  canvasRotation = 0;
  isImageLoaded = false;

  constructor(
    public activeModal: NgbActiveModal,
    private cd: ChangeDetectorRef
  ) {
  }

  rotateCanvas() {
    this.canvasRotation++;
    this.cd.markForCheck();
  }

  continueImage() {
    const croppedImageFile = this.dataURLtoFile(this.cropped, this.original.name);
    this.activeModal.close({original: this.original, cropped: croppedImageFile});
  }

  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type: mime});
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.original = this.imageChangedEvent.target.files[0];
    this.reader.readAsDataURL(this.original);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.cropped = event.base64;
  }

  imageLoaded() {
    this.isImageLoaded = true;
    this.status.processing = false;
    this.status.error = false;

    // Check for the various File API support.
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      // this.original = this.reader.result;
      // write your code here!
    } else {
      alert(`Your browser is too old to support HTML5 File's API.`);
    }
  }

  cropperReady() {
    this.status.processing = false;
    this.status.error = false;
  }

  loadImageFailed() {
    this.status.error = true;
  }
}
