import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'boldFirstTwo'
})
export class BoldFirstTwoPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): any {
    if (!value) {
      return value;
    }

    const transformedValue = value.replace(/\b(\w{1,2})(\w*)/g, (match, firstTwo, rest) => {
      return `<span style="font-weight: bold">${firstTwo}</span>${rest}`;
    });

    return this.sanitizer.bypassSecurityTrustHtml(transformedValue);
  }
}
