import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BlackCoordinator} from '../../models/black-coordinator';
import {BlackStatus} from '../../models/utils/black-utils';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {CoordinatorEditorComponent} from "../coordinator-editor/coordinator-editor.component";
import {APIService} from "../../../../app/shared/backend/api.service";

@Component({
  selector: 'app-coordinator-masterlist',
  templateUrl: './coordinator-masterlist.component.html',
  styleUrls: ['./coordinator-masterlist.component.scss']
})
export class CoordinatorMasterlistComponent implements OnInit {

  searchKey: string = '';
  coordinatorsArr: BlackCoordinator[]  = [];

  constructor(
    private cd: ChangeDetectorRef,
    public modalService: NgbModal,
    private api: APIService
  ) {
  }

  ngOnInit(): void {
    this.doSearch();
  }

  async doSearch() {
    this.coordinatorsArr = await BlackCoordinator.search({searchKey: this.searchKey});
    this.cd.detectChanges();
  }

  delete(i: any) {
  }

  async coordinatorEditorDialog(a?: BlackCoordinator, addNew?: boolean){
    let isNew: boolean = addNew? addNew : false;
    let doc = addNew? new BlackCoordinator() : a;
    const modalRef = this.modalService.open(CoordinatorEditorComponent, {size: 'lg'});
    const instance: CoordinatorEditorComponent = modalRef.componentInstance;
    instance.isNew = isNew;
    instance.targetDocument = doc;
    try {
      const result = await modalRef.result;
      if (result === 'delete' && isNew === false) {
        await this.api.deleteDocument(doc.documentId, BlackCoordinator.MODEL_NAME).toPromise();
        await this.doSearch();
        return;
      }
      if(result === 'cancel'){
        return;
      }
      await this.api.saveDocument(doc).toPromise();
    } catch (e) {
      console.error(e);
    }
  }

    protected readonly BlackStatus = BlackStatus;
}

