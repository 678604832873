import {Injectable} from '@angular/core';
import {LadField} from './models/lad-field';
import swal from 'sweetalert2';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common';

@Injectable()
export class DynamicFormService {

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
  ) {
  }

  toFormArray(fields: any) {

    return scan(fields, []);

    function scan(questions: any, parent: any[]) {
      const keys = Object.keys(questions);
      const rows = {};
      for (const k of keys) {
        const question: LadField<any> = questions[k];
        if (!question) { // skip
          continue;
        }
        if (question.row) {
          if (!rows[question.row]) {
            const rowArray: LadField<any>[] = [];
            rows[question.row] = rowArray;
            parent.push(rowArray);
          }
          rows[question.row].push(question);
        } else {
          parent.push([question]);
        }
      }
      return parent;
    }

  }

  registerKeys(fields: any, targetDocument: any) {
    scan(fields);
    function scan(formFields: any) {
      const keys = Object.keys(formFields);
      for (const k of keys) {
        const formField = formFields[k];
        if (!formField) {
          continue;
        }
        if (formField instanceof LadField) {
          formField.key = k;
          if (formField.value === undefined && targetDocument && targetDocument[k]) {
            formField.value = targetDocument ? targetDocument[k] : null;
          }
        } else {
          scan(formField);
        }
      }
    }
  }

  fireSwalConfirm(msg, icon?, title?, confirmButtonText='Confirm', cancelButtonText = 'Cancel') {
    return swal.fire({
      title,
      text: msg,
      icon: icon ? icon : 'question',
      showCancelButton: true,
      cancelButtonText,
      confirmButtonColor: '#2F8BE6',
      cancelButtonColor: '#F55252',
      confirmButtonText,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      },
      buttonsStyling: false
    });
  }

  oops(text?: string, footer?: string) {
    swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: text ? text : 'Something went wrong!',
      // footer: '<a href = "javascript:void(0);">Why do I have this issue?</a>',
      footer: footer ? footer : '',
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      buttonsStyling: false
    });
  }

  success(){
    return swal.fire({
      title: 'Success!',
      icon: 'success',
      showCloseButton: true,
      showConfirmButton: false
    });
  }

  getToaster() {
    return this.toastr;
  }

  toCurrency(number: Number) {
    return number.toLocaleString('en-US', {
      style: 'currency',
      currency: 'PHP',
    });
  }

  toProperCase(str: string) {
    const newStr = str.split(' ')
      .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
      .join(' ');
    return newStr;
  }

  navigateToSamePageWithQuery(queryParams) {
    this.router.navigate([], {
      replaceUrl: true,
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge' // preserve the current query params
    });
  }

  goBack() {
    this.location.back();
  }

}
