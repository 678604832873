import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {LadComponentBase} from '../../models/lad-component-base';
import {APIService} from '../../../shared/backend/api.service';
import {FileItem, FileUploader, ParsedResponseHeaders} from 'ng2-file-upload';
import {ToastrService} from 'ngx-toastr';
import {LadField, LadFieldOptions} from '../../models/lad-field';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LadImageUploaderComponent} from '../lad-image-uploader/lad-image-uploader.component';
import {LadUploadedAsset} from 'ladrov-commons';

@Component({
  selector: 'app-lad-portrait',
  templateUrl: './lad-portrait.component.html',
  styleUrls: ['./lad-portrait.component.scss']
})
export class LadPortraitComponent extends LadComponentBase implements OnInit {

  @Input()
  public formField: LadPortrait;

  portraitUploader: FileUploader = new FileUploader({
    url: this.api.getUploadUrl(),
    isHTML5: true,
    autoUpload: true
  });
  message = 'JPG or PNG. Max 800kB';

  constructor(
    private api: APIService,
    private cd: ChangeDetectorRef,
    public toastr: ToastrService,
    public ngbModal: NgbModal,
  ) {
    super();
  }

  get portraitSource() {
    const control = this.formField.formControl;
    if (control.value && control.value.fileName) {
      return this.api.getFileUrl(control.value.fileName);
    }
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.portraitUploader.onBeforeUploadItem = (item: FileItem) => {
      this.message = 'Uploading...';
    };

    this.portraitUploader.onCompleteItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      if (status === 200) {
        const rArray: LadUploadedAsset = JSON.parse(response);
        this.formField.formControl.patchValue(rArray[0],{onlySelf: true});
        this.cd.markForCheck();
        this.message = 'Done!';
      } else {
        this.toastr.error(`An error occurred when uploading portrait: ${this.formField.key}.`, 'Upload Error');
      }
    };

    this.formField.formControl.registerOnChange((newVal) => {
      if (!newVal) {
        this.message = 'Invalid file';
      }
    })
  }

  // TODO: do not use ng2-file-upload. Use angular/commons for uploading: https://www.npmjs.com/package/angular-file
  async selectPortraitFile() {
    try {
      const modalRef = this.ngbModal.open(LadImageUploaderComponent);
      // name, tile, format, aspectRatio, resizeToWidth
      modalRef.componentInstance.name = 'lad-portrait-' + this.formField.key;
      modalRef.componentInstance.title = this.formField.label;
      modalRef.componentInstance.format = 'png';
      modalRef.componentInstance.aspectRatio = 4 / 4;
      modalRef.componentInstance.resizeToWidth = 160;

      const result = await modalRef.result;
      this.portraitUploader.addToQueue([result.cropped]);
    } catch (err) {
      console.error(err);
    }
  }

}

export class LadPortrait extends LadField<LadUploadedAsset> {

  constructor(options: LadFieldOptions<LadUploadedAsset>) {
    super(options);
    this.__componentType = LadPortraitComponent;
  }
}

