<lad-dynamic-form #dynamicFormElement (onInitDone)="onFormInitDone()"
                  [dynamicFields]="dynamicFields"
                  [noConfirmAndFormCheck]=true
                  submitIcon="ft-search"
                  submitLabel="Search"
                  submitType="none">
</lad-dynamic-form>

<div [hidden]="reportType === 'summary'" class="col-12">
  <div [(ngModel)]="gameSheetChartInfo.currentGameSheet" (ngModelChange)="renderReport()" class="btn-group btn-group-toggle d-flex justify-content-center" ngbRadioGroup>
    <label class="btn-outline-primary btn-sm font-small-2" ngbButtonLabel>
      <input ngbButton type="radio" value="default">ALL BETS
    </label>
    <label *ngFor="let g of gameSheetChartInfo.gameSheets" class="btn-outline-primary btn-sm  font-small-2" ngbButtonLabel>
      <input ngbButton type="radio" value="{{g.documentId}}">{{g.name | uppercase}}
    </label>
  </div>
</div>

<div *ngIf="reportType !== 'summary'" class="mt-3 hide-on-print">
  <app-game-sheet-pdf-template></app-game-sheet-pdf-template>
</div>

<div class="card" *ngIf="reportType === 'gameSheetTable' || reportType === 'gameSheet'">
  <div class="card-content">
    <div class="card-body">
      <div class="media">

        <div class="media-body text-left">
          <h3 class="mb-1 danger">{{selectedRaffle | uppercase}} - {{gameSheetInfo.gross | number:'1.2-2'}}</h3>
          <span>No. of combinations: {{gameSheetInfo.items}}</span>
        </div>

        <div class="media-right align-self-center">
          <div class="dropdown btn-group float-right mr-1 mb-1 hide-on-print" ngbDropdown>
            <button type="button" class="btn btn-outline-info dropdown-toggle" ngbDropdownToggle>
              <i class="ft-settings font-medium-1 spinner d-md-none"></i> <!-- Hidden on medium and larger screens -->
              <span class="d-none d-md-inline">Options</span> <!-- Visible on medium and larger screens -->
              <span class="caret"></span>
            </button>
            <div ngbDropdownMenu class="dropdown-menu" role="menu">
              <a class="dropdown-item">Owner Restrictions</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item">Coordinator Restrictions</a>
              <a class="dropdown-item">Area Restrictions</a>
              <a class="dropdown-item">Agent Restrictions</a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <app-dotted-table *ngIf="reportType === 'gameSheetTable' && gameSheetTableSettings"
                            #dottedTable
                            [settings]="gameSheetTableSettings">
          </app-dotted-table>

          <apx-chart *ngIf="reportType === 'gameSheet' && gameSheetChartInfo.lineChartOptions"
                     [hidden]="reportType !== 'gameSheet'"
                     #apxChart1
                     [chart]="gameSheetChartInfo.lineChartOptions.chart"
                     [colors]="gameSheetChartInfo.lineChartOptions.colors"
                     [dataLabels]="gameSheetChartInfo.lineChartOptions.dataLabels"
                     [fill]="gameSheetChartInfo.lineChartOptions.fill"
                     [grid]="gameSheetChartInfo.lineChartOptions.grid"
                     [labels]="gameSheetChartInfo.lineChartOptions.labels"
                     [legend]="gameSheetChartInfo.lineChartOptions.legend"
                     [markers]="gameSheetChartInfo.lineChartOptions.markers"
                     [plotOptions]="gameSheetChartInfo.lineChartOptions.plotOptions"
                     [responsive]="gameSheetChartInfo.lineChartOptions.responsive"
                     [series]="gameSheetChartInfo.lineChartOptions.series"
                     [stroke]="gameSheetChartInfo.lineChartOptions.stroke"
                     [theme]="gameSheetChartInfo.lineChartOptions.theme"
                     [title]="gameSheetChartInfo.lineChartOptions.title"
                     [tooltip]="gameSheetChartInfo.lineChartOptions.tooltip"
                     [xaxis]="gameSheetChartInfo.lineChartOptions.xaxis"
                     [yaxis]="gameSheetChartInfo.lineChartOptions.yaxis"
          ></apx-chart>
        </div>
      </div>
    </div>
  </div>
</div>

<div [hidden]="reportType !== 'summary'">
  <div>
    <app-game-summary-pdf-template></app-game-summary-pdf-template>
    <span class="badge badge-info mb-1 mr-2">Agents: {{summaryTableInfo.agentCount}}</span>
    <span class="badge badge-primary mb-1 mr-2">Gross: {{summaryTableInfo.gross | number:'1.2-2'}}</span>
    <span class="badge badge-success mb-1 mr-2">Winnings: {{summaryTableInfo.wins | number:'1.2-2'}}</span>
    <span class="badge badge-secondary mb-1 mr-2">Comm: {{summaryTableInfo.commissions | number:'1.2-2'}}</span>
    <span class="badge badge-danger mb-1 mr-2">Net: {{summaryTableInfo.net | number:'1.2-2'}}</span>
  </div>
  <div [id]="summaryTableInfo.htableContainerId" class="mt-1 font-weight-light" style="overflow-x: auto;">
    <p class="font-weight-light font-small-2 hide-on-print">Loading...</p>
  </div>
</div>

