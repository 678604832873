import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LadRadio} from '../../../../app/dynamic-form/components/lad-radio/lad-radio.component';
import {DynamicFormComponent} from '../../../../app/dynamic-form/form/dynamic-form.component';
import {LadInput} from '../../../../app/dynamic-form/components/lad-input/lad-input.component';
import {BlackTransactionItem} from '../../models/black-transaction-item';
import {Draw, Raffle, RaffleLength} from '../../models/utils/number-helper';
import {BlackTransaction} from '../../models/black-transaction';
import {DynamicFormService} from '../../../../app/dynamic-form/dynamic-form.service';
import {Subscription} from 'rxjs';
import {BlackAgent} from '../../models/black-agent';
import {BlackPrinterHelper} from '../../models/utils/printer-helper';
import {GPayAndroidService} from '../../../../app/android/gpay.android';

@Component({
  selector: 'app-game-client',
  templateUrl: './game-client.component.html',
  styleUrls: ['./game-client.component.scss']
})
export class GameClientComponent implements OnInit, OnDestroy {

  @ViewChild('dynamicFormElement') dynamicFormElement: DynamicFormComponent;
  dynamicFields: any;
  bets: any[] = [];
  raffleItems = [
    {label: 'l2', value: Raffle.l2},
    {label: 's3', value: Raffle.s3},
    {label: 's3r', value: Raffle.s3r}
  ];
  drawItems: any[] = [
    {label: '2pm', value: Draw.d1},
    {label: '5pm', value: Draw.d2},
    {label: '9pm', value: Draw.d3}
  ];
  isCloseDraws: boolean = false;

  subs = new Subscription();
  private currentAgent: BlackAgent;

  currentTransaction = new BlackTransaction();


  constructor(
    private cd: ChangeDetectorRef,
    private dfs: DynamicFormService
  ) {
    this.dynamicFields = {
      draw: new LadRadio({
        row: 'row1',
        label: 'Select Time:',
        columnClass: 'col-md-6',
        value: Draw.d1,
        items: this.drawItems
      }),
      raffle: new LadRadio({
        row: 'row1',
        label: 'Select Raffle:',
        columnClass: 'col-md-6',
        value: Raffle.l2,
        items: this.raffleItems
      }),
      combination: new LadInput({
        row: 'row3',
        label: 'Combination',
        columnClass: 'col-md-6',
        inputType: 'number',
      }),
      amount: new LadInput({
        row: 'row3',
        label: 'Amount',
        placeholder: 'pesos',
        columnClass: 'col-md-6',
        inputType: 'number',
      })
    };
  }

  raffleRadio(){
    if(this.dynamicFields.draw.value === Draw.d3){
      this.dynamicFields.raffle.items = [
        ...this.raffleItems,
        { label: '4D', value: Raffle.d4 },
        { label: 'P3', value: Raffle.p3 },
      ];
    } else {
      this.dynamicFields.raffle.items = this.raffleItems;
      if(this.dynamicFields.raffle.value === Raffle.d4 || this.dynamicFields.raffle.value === Raffle.p3){
        this.dynamicFields.raffle.value = Raffle.l2;
      }
    }
  }

  combinationField(){
    const bet: any = this.dynamicFormElement.form.getRawValue() as BlackTransactionItem;
    if(bet.combination?.length >= Number(RaffleLength[bet.raffle])) {
      let combinationInput: any = document.getElementById(this.dynamicFields.combination.key);
      combinationInput.value = combinationInput.value.slice(0, RaffleLength[bet.raffle]);
    }
    this.cd.detectChanges();
  }

  inputField(event: any){
    if(document.activeElement.getAttribute('id') === this.dynamicFields.combination.key) {
      const amount = document.getElementById(this.dynamicFields.amount.key);
      if(event.key === 'Backspace' || event.key === 'Delete') {
        return;
      }
      if (event.keyCode === 13) { // Enter (keyCode 13)
        amount.focus();
      }
      if(event.target.value.length >= RaffleLength[this.dynamicFields.raffle.value]) {
        this.combinationField();
        event.preventDefault();
        amount.focus();
      }
    }
    if(document.activeElement.getAttribute('id') === this.dynamicFields.amount.key) {
      if (event.keyCode === 13) {
        this.addBetOnClick();
      }
    }
  }

  async addBetOnClick(){
    let {draw, raffle, combination, amount} = this.dynamicFormElement.form.getRawValue();

    let combinationInput: any = document.getElementById(this.dynamicFields.combination.key);
    let amountInput: any = document.getElementById(this.dynamicFields.amount.key);
    if (combinationInput.value.trim() === '') {
      return combinationInput.focus();
    } else if (amountInput.value.trim() === '') {
      return amountInput.focus();
    }

    combination = combinationInput.value;
    combination = combination.toString().padStart(Number(RaffleLength[this.dynamicFields.raffle.value]), '0');
    if(raffle === Raffle.p3){
      combination = combination.replace(/\B(?=(\d{2})+(?!\d))/g, '-');
    }
    // const sameRaffle = this.bets.find(x => x.raffle === raffle);
    // const sameBet = this.bets.find(x => x.combination === combination);
    if(this.dynamicFields.raffle.value === Raffle.s3r && (amount % 6 !== 0)){
      this.dfs.oops(`Invalid bet amount ${amount} for S3R-${combination}. Amount must be divisible by 6`);
      return;
    }
    const raffleValues = [Raffle.l2, Raffle.s3, Raffle.s3r];
    const colorValues = ['btn-primary', 'btn-warning', 'btn-info'];
    // if(sameRaffle && sameBet){
    //   await this.dfs.oops(`Bet already added: ${raffle === Raffle.p3 ? combination.replace(/\B(?=(\d{2})+(?!\d))/g, '-') : combination} ${raffle.toUpperCase()}-${draw}`);
    //   return;
    // } else {
      const newItem: any = new BlackTransactionItem(raffle, combination, Number(amount));
      newItem.color = colorValues[raffleValues.indexOf(raffle)];
      this.bets = [...this.bets, newItem];
      this.dynamicFormElement.form.patchValue({
        combination: '',
        amount: ''
      });
      document.getElementById(this.dynamicFields.combination.key).focus();
    // }
    this.cd.detectChanges();
  }

  totalAmount(){
    let amount: number = 0;
    this.bets.forEach( (item) => {
      amount += Number(item.amount);
    });
    return amount;
  }

  async removeBetOnClick(index, raffle, combination){
    const print = await this.dfs.fireSwalConfirm( `Are you sure you want to remove bet?`, 'warning', `Remove #${index + 1} ${raffle.toUpperCase()}.${combination.toUpperCase()}`);
    if (!print.isConfirmed) {
      return;
    }
    this.bets.splice(index, 1);
  }

  async resetOnClick(){
    const confirm = await this.dfs.fireSwalConfirm('Confirm reset bets?', null, 'Confirm');
    if (!confirm.isConfirmed) {
      return;
    } else {
      this.bets = [];
      this.cd.detectChanges();
    }
  }

  async submitOnClick(){
    if (!this.currentAgent) {
      this.dfs.oops('Could not determine if you\'re registered as an agent.')
      return;
    }
    const confirm = await this.dfs.fireSwalConfirm('Confirm submit bets?');
    if (!confirm.isConfirmed) {
      return;
    }
    if(this.bets.length === 0){
      this.dfs.oops('Please add bets.');
      return;
    }
    const {draw} = this.dynamicFormElement.form.getRawValue();
    this.bets = this.bets.map(obj => {
      const { color, ...others } = obj;
      return others;
    });
    try {
      this.currentTransaction.draw = draw;
      this.currentTransaction.bets = this.bets
      this.currentTransaction.updateTotal();
      const transaction = await BlackTransaction.save(this.currentTransaction);

      this.bets = [];
      this.currentTransaction = new BlackTransaction();

      let printed = false;
      while(true) {
        const print = await this.dfs.fireSwalConfirm( `Bets submitted. Ref: ${transaction.transactionId}`, 'success', null, `${printed ? 'Reprint' : 'Print'}`, 'Close' );
        if (!print.isConfirmed) {
          return;
        }
        const data = BlackPrinterHelper.generateSTLPrinterData(transaction, this.currentAgent);
        GPayAndroidService.sendToBluetoothPrinter(data);
        // GPayAndroidService.testPrint();
        printed = true;
        await this.availableTime();
      }

    } catch (e) {
      let message = 'An error occurred. Please try again.';
      if (e.status === 404) {
        message = 'The requested resource could not be found on the server. Please contact our support team for assistance.'
      } else if (e.status === 504) {
        message = 'Please ensure that your internet connection is stable.'
      } else {
        message = e.error ? e.error : message;
      }
      this.dfs.oops(message);
    }
    console.log(this.bets);
    this.cd.detectChanges();
  }

  async availableTime(){
    await BlackTransaction.availableDraws().then((result) => {
      // exampleResult = ['d1, 'd2', 'd3']
      const items = []
      for (const i of this.drawItems) {
        const drawAvailable = result.indexOf(i.value) > -1;
        if (drawAvailable) {
          items.push(i);
        }
      }
      this.dynamicFields.draw.items = [...items];
      this.cd.detectChanges();
    });
  }

  async ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async onFormInitDone() {
    await this.availableTime();
    const closeDraws = await this.drawItems.every((x:any)=> x?.disabled === true);
    if(closeDraws === true){
      this.isCloseDraws = true;
      this.dfs.oops('All draws are closed.');
    } else {
      this.subs.add(this.dynamicFields.draw.fieldValueChanges.subscribe(value => {
        this.raffleRadio();
      }));
      this.subs.add(this.dynamicFields.raffle.fieldValueChanges.subscribe(value => {
        this.combinationField();
      }));
      BlackAgent.getCurrentAgent().then(agent => {
        if (!agent) {
          this.dfs.oops('You are not registered as an agent. Submit will fail.');
          return;
        }
        this.currentAgent = agent;
      });
    }
    this.cd.markForCheck();
  }

}
