import {API, APIFunctionTypes, LadAPIUtils, LadDate, LadDocumentService, SystemDocument} from 'ladrov-commons';
import {JPEModelNames} from './jpe-model.names';
import {v4 as uuid} from 'uuid';
import {JPEAccount} from './jpe-account.model';
import {JPEPropertyContract} from './jpe-property-contract.model';
import {JPEInvoice} from './jpe-invoice.model';

export class JPEProjectInventorySummary {
  available: number;
  availablePercent: number;
  sold: number;
  soldPercent: number;
  soldLast1Month: number;
  soldLast3Month: number;
  soldLast6Month: number;
  fullyPaid: number;
  fullyPaidPercent: number;
}

export class JPEProjectInventoryItem {
  // statuses. supplied by API
  status: 'Available' | 'Active' | 'Fully Paid' | '';
  clientName: string;
  clientId: string;
  agentName: string;
  agentId: string;
  terms: number = 0;
  balance: number = 0;
  percentComplete: string = '0';
  missedPayments: number = 0;

  contractRef: JPEPropertyContract;
  lotRef: JPEProjectLot;
  sdName: string;
  sdId: string;
}

export class JPEProjectBranch {
  documentId = uuid();
  name: string;
  areas?: JPEProjectBranchArea[];
}

export class JPEProjectBranchArea {
  branch: string;
  documentId = uuid();
  name: string;
}

export class JPEProjectLot {

  public documentId: any;
  clientName: string;
  balance: number;
  terms: number;
  contractDate: string;
  clientAddress: string;
  clientContact: string;
  agentName: string;
  salesDirectorName: string;
  dos: string;
  titling: string;
  remarks: string;
  monthlyAmortization: number;
  tcp: number;

  constructor(
    public blockNo?: string,
    public lotNo?: string,
    public priceSqm?: number,
    public lotArea?: number
  ) {
      this.documentId = uuid();
  }
}

export class JPEProject extends SystemDocument {
  name: string;
  branch: JPEProjectBranch;
  area: JPEProjectBranchArea;
  location: string; // barangay
  lots: JPEProjectLot[] = [
    new JPEProjectLot()
    // new JPEProjectLot('1', '1', 2500, 80),
    // new JPEProjectLot('1', '2', 2500, 80),
    // new JPEProjectLot('1', '3', 2500, 80),
    // new JPEProjectLot('1', '4', 2500, 80),
    // new JPEProjectLot('1', '5', 2500, 80),
    // new JPEProjectLot('2', '1', 2500, 80),
    // new JPEProjectLot('2', '2', 2500, 80),
    // new JPEProjectLot('2', '3', 2500, 80),
  ];
  inventorySummary?: JPEProjectInventorySummary;

  @API(APIFunctionTypes.PRIVATE)
  public static async getLotReference(args: {lotDocumentId: string}, utils: LadAPIUtils) {
    const docService: LadDocumentService = utils.documentService
    const {lotDocumentId} = args;
    if (!lotDocumentId) {
      throw new Error('Invalid lotId at getLotReference()');
    }
    const project: JPEProject = await docService.findOne(JPEModelNames.PROJECT, {'lots.documentId': lotDocumentId});
    if (!project) {
      return null;
    }
    const lot = project.lots.find(l => l.documentId === lotDocumentId);
    delete project.lots;
    return {
      project,
      lot
    };
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async search(args, utils?: LadAPIUtils): Promise<JPEProject[]> {
    const {branchId, areaId, searchKey} = args;
    const docService: LadDocumentService = utils.documentService;
    const keyRegex = new RegExp(searchKey?.trim().toLowerCase(), 'i');
    let filter: any = {};
    if (branchId || areaId) {
      filter = {
        $or: [
          {'branch.documentId': {$eq: branchId, $exists: true}},
          {'area.documentId': {$eq: areaId, $exists: true}}
        ]
      };
    }
    if(searchKey){
      filter.$and = [
        {
          $or: [
            {'name': keyRegex},
            {'location': keyRegex}
          ]
        }
      ];
    }
    return await docService.find(JPEModelNames.PROJECT, filter);
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getAllBranch(args, utils: LadAPIUtils): Promise<JPEProjectBranch[]> {
    const docService: LadDocumentService = utils.documentService;
    const projects: JPEProject[] = await docService.find(JPEModelNames.PROJECT, {});
    const result = [];
    const index: { [key: string]: { branch: JPEProjectBranch, areaIndex } } = {};
    for (const p of projects) {
      if (p.branch) {
        if (!index[p.branch.documentId]) {
          index[p.branch.documentId] = {
            branch: p.branch,
            areaIndex: {}
          };
        }
        if (p.area) {
          const indexItem = index[p.branch.documentId];
          if (!indexItem.areaIndex[p.area.documentId]) {
            indexItem.areaIndex[p.area.documentId] = p.area;
          }
        }
      }
    }
    // sort areas alphabetically
    for (const i of Object.values(index)) {
      const areas: JPEProjectBranchArea[] = Object.values(i.areaIndex);
      areas.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
      i.branch.areas = areas;
      result.push(i.branch);
    }
    // sort branches
    return result.sort((a, b) => a.name.localeCompare(b.name));
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getProjectInventory(args, utils: LadAPIUtils): Promise<JPEProjectInventoryItem[]> {
    let {projectId, searchKey} = args;
    const docService: LadDocumentService = utils.documentService;
    const filter = {documentId: projectId};
    const project: JPEProject = await docService.findOne(JPEModelNames.PROJECT, filter);
    const results: JPEProjectInventoryItem[] = [];
    // no searchKey so return all
    if (!searchKey || !searchKey.trim()) {
      for (const lot of project.lots) {
        results.push(await JPEProject.toInventoryItem(lot, utils));
      }
      return results;
    }
    // with search key
    searchKey = searchKey.trim().toLowerCase();
    let searchBlockLot = searchKey.indexOf(',') > -1;
    let blockN = '';
    let lotN = ''
    if (searchBlockLot) { // look for block, lot or lastname, firstname
      const split = searchKey.split(',');
      blockN = split[0].trim();
      lotN = split[1].trim();
    }
    const accountsWithName: JPEAccount[] = await JPEAccount.search({ searchKey }, utils);
    const accountIds = accountsWithName.map(e => e.documentId);
    // do filtering
    for (const lot of project.lots) {
      if (await match(lot)) {
        results.push(await JPEProject.toInventoryItem(lot, utils));
      }
    }
    return results;

    async function match(lot: JPEProjectLot) {
      if (searchBlockLot) {
        const matched = lot.blockNo.trim() === blockN && lot.lotNo.trim() === lotN;
        if (matched) {
          return true;
        }
      }
      // if (lot.documentId.trim().indexOf(searchKey)) {
      //   return true;
      // }
      const contract = await JPEPropertyContract.getContractByLotId({lotId: lot.documentId}, utils);
      if (contract) {
        for (const id of accountIds) {
          const match = contract.client?.relatedDocumentId === id
            || contract.agent?.relatedDocumentId === id
            || contract.salesDirector?.relatedDocumentId === id
            || contract.documentId.toLowerCase().indexOf(searchKey) > -1
          if (match) {
            return true;
          }
        }
      }
    }

  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getProjectInventorySummary(args, utils: LadAPIUtils): Promise<JPEProjectInventorySummary>  {
    let { projectId } = args;
    const docService: LadDocumentService = utils.documentService;
    const filter = { documentId: projectId };
    const project: JPEProject = await docService.findOne(JPEModelNames.PROJECT, filter);
    // do filtering
    const promises = [];
    for (const lot of project.lots) {
      promises.push(JPEProject.toInventoryItem(lot, utils));
    }
    const results: JPEProjectInventoryItem[] = await Promise.all(promises);
    const summ: JPEProjectInventorySummary = {
      available: 0,
      availablePercent: 0,
      fullyPaid: 0,
      fullyPaidPercent: 0,
      sold: 0,
      soldPercent: 0,
      soldLast1Month: 0,
      soldLast3Month: 0,
      soldLast6Month: 0,
    };
    const now = new Date();
    for (const i of results) {
      const isAvailable = i.status === 'Available';
      if (isAvailable){
        summ.available++;
      }
      const isActive = i.status === 'Active';
      const isFullPaid = i.status === 'Fully Paid';
      if (isActive || isFullPaid) {
        summ.sold++;
      }
      if (isFullPaid){
        summ.fullyPaid++;
      }
      const startD = i.contractRef?.startDate?.date;
      if (startD) {
        const diff = monthDiff(new Date(startD), now);
        if (diff <= 1) {
          summ.soldLast1Month++;
        }
        else if (diff <= 3) {
          summ.soldLast3Month++;
        }
        else if (diff <= 6) {
          summ.soldLast6Month++;
        }
      }
    }
    const numOfLots = project.lots.length;
    summ.availablePercent = toPercent(summ.available, numOfLots);
    summ.fullyPaidPercent = toPercent(summ.fullyPaid, summ.sold);
    summ.soldPercent = toPercent(summ.sold, numOfLots);
    return summ;

    function toPercent (val, length) {
      if (!val) {
        return 0;
      }
      return Number ( ((val / length) * 100).toFixed(2) );
    }
    function monthDiff(dateFrom, dateTo) {
      return dateTo.getMonth() - dateFrom.getMonth() +
        (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
    }
  }

  // helper
  private static async toInventoryItem(lot: JPEProjectLot, utils: LadAPIUtils) {
    const findOptions = {
      toFetchRelatedDocuments: ["agent", "salesDirector", "client"]
    };
    const filter = {lotId: lot.documentId, cancelled: {$ne: true}};
    const findContractFn = utils.documentService.findOne(JPEModelNames.PROPERTY_CONTRACT, filter, findOptions);
    const contract = (await findContractFn) as JPEPropertyContract;
    const item: JPEProjectInventoryItem = {
      agentId: null,
      agentName: '',
      clientId: null,
      clientName: '',
      contractRef: contract,
      status: 'Available',
      terms: 0,
      balance: 0,
      missedPayments: 0,
      percentComplete: '',
      lotRef: lot,
      sdName: '',
      sdId: null
    }
    if (contract) {
      item.balance = contract.balance;
      item.percentComplete = contract.percentComplete;
      item.terms = contract.termsInMonths;
      if (contract.agent?.relatedDocumentId) {
        const agent = contract.agent?.objectRef;
        if (agent) {
          item.agentName = JPEAccount.getName(agent);
          item.agentId = agent.documentId;
        }
      }
      if (contract.salesDirector?.relatedDocumentId) {
        const sd = contract.salesDirector?.objectRef;
        if (sd) {
          item.sdName = JPEAccount.getName(sd);
          item.sdId = sd.documentId;
        }
      }
      if (contract.client?.relatedDocumentId) {
        const client = contract.client?.objectRef;
        if (client) {
          item.clientName = JPEAccount.getName(client);
          item.clientId = client.documentId;
        }
      }
      item.status = contract.percentComplete === '100.00' ? 'Fully Paid' : 'Active'
    }
    return item;
  }


  @API(APIFunctionTypes.PRE_SAVE)
  public static async presave(toSave: JPEProject, utils: LadAPIUtils, operationInfo) {
    // clear branch.areas
    if (toSave.branch) {
      delete toSave.branch.areas;
    }
    // on insert only
    if (!operationInfo.isInsert) {
      return;
    }
    for (const lot of toSave.lots) {
      if (isNaN(Number(lot.terms)) && `${lot.terms}` !== '') {
        throw new Error(`Block ${lot.blockNo}, Lot ${lot.lotNo} - terms should be a number.`)
      }
      if (lot.contractDate?.length > 0 && isNaN(new Date(lot.contractDate).getTime())) {
        throw new Error(`Block ${lot.blockNo}, Lot ${lot.lotNo} - start/contract date should be a valid date format.`);
      }
    }
  }

  @API(APIFunctionTypes.PRE_SAVE)
  public static async checkContracts(savedProject: JPEProject, utils: LadAPIUtils) {
    for (const lot of savedProject.lots) {
      if (!lot.clientName?.trim()) {
        continue;
      }
      let contract = await utils.documentService.findOne(JPEModelNames.PROPERTY_CONTRACT, {'lotId': lot.documentId});
      if (!contract) {
        continue;
      }
      const clientParams = { name: lot.clientName, address: lot.clientAddress, contact: lot.clientContact }
      const client: JPEAccount = await JPEAccount.registerAndFetchByName(clientParams, utils);
      // create contract
      if (contract.client.relatedDocumentId !== client.documentId) {
        throw new Error(`Block: ${lot.blockNo}, Lot: ${lot.lotNo} already taken. Please revoke current contract before transferring to a new client.`);
      }
    }
  }


  @API(APIFunctionTypes.POST_SAVE)
  public static async createContractDocs(savedProject: JPEProject, utils: LadAPIUtils) {
    for (const lot of savedProject.lots) {
      if (!lot.clientName?.trim()) {
        continue;
      }
      // // register clients, agents, sales director
      // // create contract
      const clientParams = { name: lot.clientName, address: lot.clientAddress, contact: lot.clientContact }
      const client: JPEAccount = await JPEAccount.registerAndFetchByName(clientParams, utils);
      if (!client) {
        return;
      }
      const agent: JPEAccount = await JPEAccount.registerAndFetchByName({ name: lot.agentName }, utils);
      const sd: JPEAccount = await JPEAccount.registerAndFetchByName({ name: lot.agentName }, utils);

      // create contract
      let contract: JPEPropertyContract = await utils.documentService.findOne(JPEModelNames.PROPERTY_CONTRACT, {'lotId': lot.documentId});
      if (!contract) {
        contract = new JPEPropertyContract(savedProject.documentId, lot.documentId);
      }

      let balance = stn(lot.balance);
      contract.totalContractAmount = stn(lot.lotArea) * stn(lot.priceSqm);
      contract.balance = balance;
      contract.percentComplete = (100 - ((balance / contract.totalContractAmount) * 100)).toFixed(2);

      contract.client = {
        relatedDocumentType: JPEModelNames.ACCOUNT,
        relatedDocumentId: client.documentId
      }
      if (agent) {
        contract.agent = {
          relatedDocumentType: JPEModelNames.ACCOUNT,
          relatedDocumentId: agent.documentId
        }
      }
      if (sd) {
        contract.salesDirector = {
          relatedDocumentType: JPEModelNames.ACCOUNT,
          relatedDocumentId: sd.documentId
        }
      }

      contract.termsInMonths = stn(lot.terms);
      contract.monthlyAmortization = stn(lot.monthlyAmortization);
      contract.startDate = new LadDate(new Date(lot.contractDate));
      contract.dos = lot.dos?.toLowerCase().trim() === 'paid';
      contract.titling = lot.titling?.toLowerCase().trim() === 'paid';
      contract.remarks = lot.remarks;
      await utils.documentService.upsert(contract, utils.currentUserId, {noProcessors: true});

      const invoices: JPEInvoice[] = await utils.documentService.find(JPEModelNames.INVOICE, {'propertyContract.relatedDocumentId': contract.documentId});
      let invoicesAmount = 0;
      for (const i of invoices) {
        invoicesAmount += i.subTotal; // 100
      }

      // create invoices
      let amountPaid = contract.totalContractAmount - balance; // 70
      amountPaid = amountPaid - invoicesAmount;
      if (amountPaid === 0) {
        continue;
      }

      const invoice = new JPEInvoice();
      invoice.paymentDate = contract.startDate;
      invoice.invoiceDate = invoice.paymentDate;
      invoice.dueDate = invoice.paymentDate;
      invoice.paymentMethod = 'CASH';
      invoice.amountDue = amountPaid;
      invoice.propertyContract.relatedDocumentId = contract.documentId;
      invoice.billTo.relatedDocumentId = client.documentId;
      if (contract.termsInMonths > 1) {
        invoice.amortizationInfo = {
          from: invoice.paymentDate,
          to: new LadDate()
        }
      }
      let detailsStr;
      if (balance === 0) {
        detailsStr = `FULL PAYMENT`;
      } else {
        detailsStr = `PARTIAL PAYMENT (${Number(contract.percentComplete)}%)`;
      }
      invoice.items = [
        {
          details: `GDRIVE-IMPORT - ${detailsStr} > PROJECT: ${savedProject.name} BLOCK: ${lot.blockNo}, LOT: ${lot.lotNo}`,
          amount: invoice.amountDue
        }
      ];
      invoice.tax = 0;
      invoice.subTotal = amountPaid;
      invoice.receiptNo = 'GDRIVE-IMPORT';
      await utils.documentService.upsert(invoice, utils.currentUserId);
    }
  }

  @API(APIFunctionTypes.POST_DELETE)
  public static async postProjectDelete(deleted: JPEProject, utils: LadAPIUtils) {
    // search for all contracts and mark 'projectDeleted'
    const docServ = utils.documentService;
    const contractsQuery = {
      projectId: deleted.documentId
    };
    const contracts: JPEPropertyContract[] = await docServ.find(JPEModelNames.PROPERTY_CONTRACT, contractsQuery);
    // for every contract search for invoice 'projectDeleted'
    const contractUpdates = [];
    let totalInvoiceUpdates = 0;
    for (const c of contracts) {
      contractUpdates.push({
        documentId: c.documentId,
        projectDeleted: true,
        systemHeader: {
          type: JPEModelNames.PROPERTY_CONTRACT
        }
      });
      const invoiceQuery = {
        'propertyContract.relatedDocumentId': c.documentId
      }
      docServ.find(JPEModelNames.INVOICE, invoiceQuery).then(async (invoices: JPEInvoice[]) => {
        const invoiceUpdates = invoices.map(e => {
          return {
            documentId: e.documentId,
            projectDeleted: true,
            systemHeader: {
              type: JPEModelNames.INVOICE
            }
          }
        });
        await docServ.upsert(invoiceUpdates, utils.currentUserId);
        totalInvoiceUpdates += invoiceUpdates.length;
      });
    }
    await docServ.upsert(contractUpdates, utils.currentUserId);
    return {
      contractsUpdated: contractUpdates.length,
      invoiceUpdates: totalInvoiceUpdates
    }
  }

  constructor() {
    super(JPEModelNames.PROJECT);
  }
}

export function stn(val: any) { // string to number
  if (!val) {
    return 0;
  }
  const str = `${val}`
    .trim()
    .replace(new RegExp(',', 'g'), '')
    .replace(new RegExp(' ', 'g'), '');
  let number = Number(str);
  number = isNaN(number) ? 0 : number;
  return number;
}

export function getSampleData(): JPEProject {
  const p = new JPEProject();
  p.lots = [
    new JPEProjectLot('1', '1', 2500, 80),
    new JPEProjectLot('1', '2', 2500, 80),
    new JPEProjectLot('1', '3', 2500, 80),
    new JPEProjectLot('1', '4', 2500, 80),
    new JPEProjectLot('1', '5', 2500, 80),
    new JPEProjectLot('2', '1', 2500, 80),
    new JPEProjectLot('2', '2', 2500, 80),
    new JPEProjectLot('2', '3', 2500, 80),
  ]
  return p;
}



