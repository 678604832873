<lad-dynamic-form #dynamicFormElement (onInitDone)="onFormInitDone()"
                  [dynamicFields]="dynamicFields"
                  [noConfirmAndFormCheck]=true
                  submitIcon="ft-search"
                  submitLabel="Search"
                  submitType="none">
</lad-dynamic-form>

<div *ngIf="lineChartOptions && resultViewType === 'chart'" class="row">
  <!-- Line Chart starts -->
  <div class="col-xl-6 col-12">
    <div class="card">
      <div class="card-header">
        <h5>₱5,000.0 (100 Orders) </h5>
      </div>
      <div class="card-content">
        <div class="card-body" style="padding: 0rem">
          <apx-chart
            [chart]="lineChartOptions.chart"
            [colors]="lineChartOptions.colors"
            [dataLabels]="lineChartOptions.dataLabels"
            [fill]="lineChartOptions.fill"
            [grid]="lineChartOptions.grid"
            [labels]="lineChartOptions.labels"
            [legend]="lineChartOptions.legend"
            [markers]="lineChartOptions.markers"
            [plotOptions]="lineChartOptions.plotOptions"
            [responsive]="lineChartOptions.responsive"
            [series]="lineChartOptions.series"
            [stroke]="lineChartOptions.stroke"
            [theme]="lineChartOptions.theme"
            [title]="lineChartOptions.title"
            [tooltip]="lineChartOptions.tooltip"
            [xaxis]="lineChartOptions.xaxis"
            [yaxis]="lineChartOptions.yaxis"
          ></apx-chart>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="resultViewType==='list'" class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h5>₱5,000.0 (100 Orders) </h5>
      </div>
      <div class="card-content">
        <div class="card-body">
          <div class="mt-4 table-responsive-sm">
            <table class="align-items-start table summaryTable text-center m-0">
              <tbody>
              <tr *ngFor="let item of ordersArray; let i = index" class="menuItems">
                <td style="text-align: start">
                  <div style="display: flex;">
                    <!--                    <div style="flex: 1;">-->
                    <!--                      <img alt="Image" class="item-image"-->
                    <!--                           src="{{item.image?.fileName || '../../../../assets/img/photos/01.jpg'}}" style="width:90%;height: 95%;">-->
                    <!--                    </div>-->
                    <div style="flex: 1;">
                      <span class="item-name" style="font-weight: bold;">{{item.items.length}} items ({{item.gross}}
                        )<br/></span>
                      <span class="item-info" style="font-style: italic;">{{item.attendant.objectRef?.terminalName}}
                        / {{item.attendant.objectRef?.account.objectRef?.name}}</span><br/>
                      <span class="item-price"
                            style="font-weight: bold;font-style: italic;">{{item.systemHeader.createdOnDate | date:'yyyy-MM-dd HH:mm:ss' }}</span>
                    </div>
                  </div>
                </td>
                <!-- <td style="text-align: start">
                  {{item.unitPrice | currency:'':'' }} / {{item.unit}}
                </td>  -->
                <td style="text-align: start">
                  <a class="info button" href="/pages/pos/product-masterlist/edit/{{item.documentId}}">
                    <i class="ft-edit font-medium-3" tooltip-placement="top" uib-tooltip="Update">
                    </i>
                  </a><br/><br/>
                  <a class="danger button">
                    <i (click)="cancelOrder(item)" class="ft-trash font-medium-3" tooltip-placement="top"
                       uib-tooltip="Delete">
                    </i>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <ngb-pagination [(page)]="currentListPage" [boundaryLinks]="true" [collectionSize]="120" [maxSize]="5"
                        [pageSize]="100"
                        [rotate]="true"></ngb-pagination>
      </div>
    </div>
  </div>
</div>
