import {API, APIFunctionTypes, LadAccountModel, LadAPIUtils, LadDate, LadRelatedDocument, LadTime, SystemDocument} from 'ladrov-commons';
import {BlackCoordinator} from './black-coordinator';
import {initMaxBetObj, Raffle, RaffleSettings} from './utils/number-helper';
import {BLACK_SYSTEM_NAME, BlackStatus} from './utils/black-utils';
import {BlackUserBase} from './black-user-base';
import {BlackArea} from './black-area';

export class BlackAgent extends SystemDocument implements BlackUserBase{
  public static MODEL_NAME = 'BlackAgent';
  name: string;

  accessKey = this.documentId.split('-')[0];
  mobileNumber: string;

  status: BlackStatus = BlackStatus.enabled
  lastActive: LadDate;

  printName: string;
  printUnitPrize: RaffleSettings[] = [];

  draws = [];
  raffles = [];

  enablePrizes: boolean;
  unitPrizes: RaffleSettings[] = [];

  enableCommissions: boolean;
  commissions: RaffleSettings[] = [];

  enableCutOffTime: boolean;
  cutoffTime: { [p: string]: LadTime } = {};

  enableMaxBet: boolean;
  maxBet: { [key: string]: { [key: string]: RaffleSettings[]; }; } = initMaxBetObj();

  public coordinatorRel: LadRelatedDocument<BlackCoordinator>
  public areaRel: LadRelatedDocument<BlackArea>
  public accountRel: LadRelatedDocument<LadAccountModel>

  constructor(coordinatorId: string, areaId: string) {
    super(BlackAgent.MODEL_NAME, BLACK_SYSTEM_NAME);
    this.coordinatorRel = {
      relatedDocumentType: BlackCoordinator.MODEL_NAME,
      relatedDocumentId: coordinatorId
    }
    this.areaRel = {
      relatedDocumentType: BlackArea.MODEL_NAME,
      relatedDocumentId: areaId
    }
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async search(args, util?: LadAPIUtils) {
    const {searchKey, coordinatorId} = args;
    let filter: any = {};
    if (searchKey != null) {
      const keyRegex = new RegExp(searchKey?.trim().toLowerCase(), 'i');
      filter.$or = [
        { name: keyRegex },
        { status: keyRegex },
        { mobileNumber: keyRegex }
      ];
    }
    if (coordinatorId) {
      filter = {
        ...filter,
        'coordinatorRel.relatedDocumentId': coordinatorId
      };
    }
    console.log(filter);
    return await util.documentService.find(BlackAgent.MODEL_NAME, filter, {sort: {name: 1}});
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getCurrentAgent(args?, utils?: LadAPIUtils): Promise<BlackAgent> {
    const filter = {
      'accountRel.relatedDocumentId': utils.currentUserId
    }
    return await utils.documentService.findOne(BlackAgent.MODEL_NAME, filter);
  }

  @API(APIFunctionTypes.PRE_SAVE)
  public static async preSave(agent: BlackAgent, util: LadAPIUtils, opt) {
    const isUpdate = !opt?.isInsert;
    if (isUpdate) {
      return agent;
    }
    // ang username sa agent kay access key lang kay para dali lang ang mga demo agent isetup
    const account = await util.doSignup({username: agent.accessKey, password: agent.accessKey} );
    agent.accountRel = {
      relatedDocumentId: account.documentId,
      relatedDocumentType: `LadAccountModel`
    }
    return agent;
  }

  @API(APIFunctionTypes.POST_SAVE)
  public static async postSave(agent: BlackAgent, util: LadAPIUtils) {
    BlackCoordinator.updateCoordinatorAgentCount(agent.coordinatorRel.relatedDocumentId, util);
    // if ma usab ang agent access key kay dapat ma usab pod ang username / password sa iyang accounti
    const current: LadAccountModel = await util.documentService.findOne('LadAccountModel', {documentId: agent.accountRel.relatedDocumentId});
    if (current && current.username !== agent.accessKey) {
      // access key updated:
      const updates = {
        documentId: current.documentId,
        username: agent.accessKey,
        systemHeader: {
          type: "LadAccountModel"
        }
      }
      await util.documentService.upsert(updates, util.currentUserId, {noProcessors: true})
      await util.doUpdatePassword(current.documentId, agent.accessKey);
    }

  }

  @API(APIFunctionTypes.POST_DELETE)
  public static async postDelete(agent: BlackAgent, util: LadAPIUtils, opt) {
    await BlackCoordinator.updateCoordinatorAgentCount(agent.coordinatorRel.relatedDocumentId, util);
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getAgentById(args: {id: string}, util?: LadAPIUtils): Promise<BlackAgent> {
    let agentId = args.id;
    return await util.documentService.findOne(BlackAgent.MODEL_NAME, {documentId: agentId});
  }

  public static getCommission(raffle: Raffle, raffleAmount: number, commissions: RaffleSettings[]) {
    let commissionSetup: RaffleSettings;
    for (const item of commissions) {
      let raffleMatch = item.raffleId === raffle;
      let isRaffleALL = item.raffleId === Raffle.ALL;
      if (isRaffleALL || raffleMatch) {
        // prioritise non 'ALL' raffle:
        if (isRaffleALL && commissionSetup && commissionSetup.raffleId !== Raffle.ALL) {
          continue;
        }
        item.value = Number(item.value);
        if (!isNaN(item.value)) {
          commissionSetup = item;
        }
      }
    }

    if (commissionSetup) {
      return (Math.abs(commissionSetup.value) / 100) * raffleAmount;
    }

    return 0;
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getAgentByAccountId(userId, util?: LadAPIUtils): Promise<BlackAgent> {
    const filter = {};
    filter['accountRel.relatedDocumentId'] = userId;
    return await util.documentService.findOne(BlackAgent.MODEL_NAME, filter);
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getAgentByCoorId(coordinatorId, util?: LadAPIUtils) {
    const filter = {};
    filter['coordinatorRel.relatedDocumentId'] = coordinatorId;
    return await util.documentService.find(BlackAgent.MODEL_NAME, filter);
  }

  public static async updateLastActive(agentId, util: LadAPIUtils){
    const updates = {
      documentId: agentId,
      lastActive: new LadDate(),
      systemHeader: {
        type: BlackAgent.MODEL_NAME
      }
    };
    await util.documentService.upsert(updates, util.currentUserId);
  }

}
