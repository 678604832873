

<label *ngIf="formField.label" [class]="formField.labelClass">
  {{formField.label}}
  <ng-container *ngIf="afterViewInit && !formField?.formControl?.valid" class="text-warning">
    <a class="ft-info font-small-3 blinking"></a>
    {{getErrorMessages(formField.formControl.errors)}}
  </ng-container>
</label>
<a (click)="addNewRow()" class="mb-1 text-warning text-bold-500 float-right"
   style="text-transform: uppercase; font-size: 0.85rem; font-weight: 500;">
  <i class="ft-plus-square mr-1"></i>New
</a>

<div [id]="htableContainerId" class="mt-1 font-weight-light" style="overflow-x: auto;">
  <p class="font-weight-light font-small-2">Loading...</p>
</div>
