import {
  API,
  APIFunctionTypes,
  LadAccountModel,
  LadAPIUtils,
  LadDate,
  LadNumberIncrement,
  LadRelatedDocument,
  SystemDocument
} from 'ladrov-commons';
import {QtModelNames} from './qt-model.names';
import {QtProductModel} from './qt-product.model';
import {QtUserModel} from './qt-user.model';
import {v4 as uuid} from 'uuid';
import {QtBusinessModel} from './qt-business.model';

export class QtOrderItem {
  product: LadRelatedDocument<QtProductModel>;
  name: string;
  sku: string;
  quantity = 1;
  unitPrice: number;
  total: number;

  constructor(product: QtProductModel) {
     this.product = {
       relatedDocumentId: product.documentId,
       relatedDocumentType: QtProductModel.MODEL_NAME
     };
     this.name = product.name;
     this.sku = product.code;
     this.unitPrice = product.unitPrice;
  }
}

export class QtOrderCancelDetails {
  cancelledBy: QtUserModel;
  cancelledOn = new LadDate();
}

export interface QtOrderModelSearchArgs {
  startDate?: Date,
  endDate?: Date,
  searchKey?: string, // TODO: can be documentId, on notes, or amount, or attendant name (later)
  cancelledOnly?: boolean
}

export class QtOrderModel extends SystemDocument {
  static MODEL_NAME = 'QtOrderModel'

  notes: string;
  items: QtOrderItem[];
  gross: number;
  paid: boolean;
  cancelled: QtOrderCancelDetails;

  business: LadRelatedDocument<QtBusinessModel>;
  attendant: LadRelatedDocument<LadAccountModel>;

  orderNumber: LadNumberIncrement = {
    ladNumberIncrementId: 'qt-order-model-order-number',
    startValue: 1000,
    value: null
  };

  constructor(items: QtOrderItem[]) {
    super(QtOrderModel.MODEL_NAME);
    this.items = items;
    this.gross = 0;
    for (let i of items) {
      i.total = (i.quantity * i.unitPrice);
      this.gross += i.total;
    }
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async saveOrder(order: QtOrderModel, utils?: LadAPIUtils): Promise<QtOrderModel> {
    return await utils.documentService.upsert(order, utils.currentUserId);
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async updateOrder(args, utils?: LadAPIUtils): Promise<QtOrderModel> {
    let {items, documentId, cancelled} = args;
    const docService = utils.documentService;
    for(const i of items){
      const order: QtProductModel = await docService.findOne(QtModelNames.PRODUCT, {'documentId': i.product.relatedDocumentId});
      i.name = order.name;
      // i.sku = order.sku;
      i.unitPrice = order.unitPrice;
    }
    let updates;
    if(!documentId){
      updates = {
        documentId: uuid(),
        items,
        systemHeader: {
          type: QtModelNames.ORDER
        }
      } as QtOrderModel;
    } else {
      if(cancelled){
        // updates = await utils.documentService.findOne(QtModelNames.ORDER, {documentId: documentId});
        // if(!updates){
        //   return;
        // }
        updates = {
          documentId: documentId,
          cancelled: {
            cancelledBy: {
              account: {
                documentId: utils.currentUserId
              }
            },
            cancelledOn: new LadDate()
          },
          systemHeader: {
            type: QtModelNames.ORDER
          }
        };
      }
    }
    await utils.documentService.upsert(updates, utils.currentUserId);
    return {documentId: updates.documentId.substring(0, 7)} as QtOrderModel;
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async search(args: QtOrderModelSearchArgs, utils?: LadAPIUtils): Promise<QtOrderModel[]> {
    let filter = {}
    const result: QtOrderModel[] = await utils.documentService.find(QtModelNames.ORDER, filter)
    return result;
  }

  @API(APIFunctionTypes.PRE_SAVE)
  public static async presave(order: QtOrderModel, utils: LadAPIUtils) {
    const currentUser = await utils.getCurrentAccount();
    order.attendant = {
      relatedDocumentId: currentUser.documentId,
      relatedDocumentType: 'LadAccountModel'
    };

    const currentBusiness = await QtBusinessModel.getCurrentUserBusiness();
    order.business = {
      relatedDocumentId: currentBusiness.documentId,
      relatedDocumentType: QtBusinessModel.MODEL_NAME
    }
    return order;
  }

}
