import {SystemDocument} from 'ladrov-commons';
import {v4 as uuid} from 'uuid';

export enum Draw {
  ALL = 'ALL',
  d1 = 'd1',
  d2 = 'd2',
  d3 = 'd3'
}

export enum DrawStr {
  ALL = 'ALL',
  d1 = '2pm',
  d2 = '5pm',
  d3 = '9pm'
}

export enum Raffle {
  ALL = 'ALL',
  l2 = 'l2',
  l3 = 'l3',
  s3 = 's3',
  s3r = 's3r',
  s2 = 's2',
  d4 = 'd4',
  p3 = 'p3'
}

export enum RaffleLength {
  s3 = 3,
  s3r = 3,
  s2 = 2,
  l2 = 2,
  l3 = 3,
  d4 = 4,
  p3 = 6
}

export function initMaxBetObj():  { [key: string]: { [key: string]: RaffleSettings[]; }; } {
  const result = {};
  for (const d of Object.values(Draw)) {
    result[d] = {};
    for (const r of Object.values(Raffle)) {
      result[d][r] = [
        {
          raffleId: r,
          combination: '',
          value: 0,
          draw: d
        }
      ];
    }
  }
  return result;
}

export class RaffleSettings {
  from?;
  to?;
  scope?: 'owner' | 'coordinator' | 'area' | 'agent' | 'creator';

  id = uuid();
  draw: Draw;
  raffleId: Raffle;
  combination?: string;
  combinationError?: string;
  value: number;

  public static validateCombination(settings: RaffleSettings) {

    let combo = settings.combination;
    if (!combo) {
      throw new Error(`Please provide a number, range or pattern.`)
    }
    // replace all white space
    combo = combo.replace(/\s/g, "");
    // range check
    if (combo.indexOf('-') > -1) {
      const raw = combo.split('-');
      if (raw.length !== 2) {
        throw new Error('Invalid range value.');
      }
      if (!raw[0] || isNaN(Number(raw[0])) || !raw[1] || isNaN(Number(raw[1]))) {
        throw new Error('Invalid range: start and end must be numbers')
      }
      return;
    }

    // pattern and single number check
    combo = combo.replace(/\*/g, "");
    if (isNaN(Number(combo))) {
      throw new Error('Invalid combination: should be a number');
    }
  }

}

export function paddy(num, padLength?, padchar?) {
  try{
    const pad_char = typeof padchar !== 'undefined' ? padchar : '0';
    const pad = new Array(1 + padLength).join(pad_char);
    return (pad + num).slice(-pad.length); // returns a string
  }catch(err){
    return null;
  }
}

export function paddyTable(combination, raffle: Raffle, padLengthRaffle?){
  let padLength = RaffleLength[raffle];
  if(raffle === Raffle.p3) {
    padLength = padLengthRaffle ? padLengthRaffle : RaffleLength.p3;
    const combi = paddy(combination, padLength);
    return combi.match(/.{1,2}/g).join("-").toString();
  }
  return paddy(combination, padLength);
}

export function rambolitoStr(number: Number) {
  const c: string = paddy(number, 3); // combination
  if (c.length !== 3) {
    throw new Error('Rambolito does not apply with combination: ' + c);
  }
  const permutation: Set<string> = new Set([
    '' + c[0] + c[1] + c[2],
    '' + c[0] + c[2] + c[1],
    '' + c[1] + c[0] + c[2],
    '' + c[1] + c[2] + c[0],
    '' + c[2] + c[0] + c[1],
    '' + c[2] + c[1] + c[0]
  ]);
  return permutation;
}

export function rambolitoInt(combination: Number){
  return (Array.from(rambolitoStr(combination)).map(val => {return parseInt(val, 10)}));
}

export function numberThousand(number){
  if(typeof number !== 'number'){
    number = parseFloat(number);
  }
  return number.toLocaleString();
}

export function combinationRaffleFormat(combination, raffle){
  return `${paddyTable(combination,raffle)} ${raffle}`
}

export function combinationFormat(combination,raffle){
  return `${paddyTable(combination, raffle)}  `
}

// final amount to calculate prize
export function getWeight(raffle, combination, amount) {
  const s3r = raffle === Raffle.s3r;
  let numberOfCombinations = 1;
  let weight = amount;
  if (s3r) {
    numberOfCombinations = Array.from(rambolitoStr(combination)).length;
    weight = ( amount / numberOfCombinations );
  }
  return {weight, numberOfCombinations};
}

export function num(n) {
  const r = Number(n);
  return isNaN(r) ? 0 : r;
}
