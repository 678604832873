<div [formGroup]="formGroup" class="form-group">

  <div class="checkbox">
    <input
      [formControlName]="formField.key"
      [id]="formField.key"
      [name]="formField.key"
      (change)="onChange($event)"
      class="custom-control-input"
      type="checkbox">
    <label *ngIf="formField.label" [attr.for]="formField.key" [class]="formField.labelClass">
      {{formField.label}}
      <a *ngIf="formField.required && !isValid" class="ft-info font-small-3 blinking"></a>
    </label>
  </div>

  <code *ngIf="formField.required && !isValid" class="font-small-1 mr-1">*Invalid!</code>
</div>



