import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {DynamicFormService} from '../dynamic-form.service';
import {LadField} from '../models/lad-field';
import {DynamicFormContext, DynamicFormSaveService, PostSaveFnParam} from '../dynamic-form-save.service';
import {SystemDocument} from 'ladrov-commons';
import {APIService} from '../../shared/backend/api.service';

@Component({
  selector: 'lad-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [DynamicFormService],
  encapsulation: ViewEncapsulation.None
})
export class DynamicFormComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input()
  dynamicFields: {[key: string]: LadField<any>};
  @Input()
  showSubmitButton?: boolean; // deprecate in favor of submitType
  @Output()
  onInitDone = new EventEmitter<FormGroup>();
  @Input()
  public form?: FormGroup;
  formFieldsArray: Array<Array<LadField<any>>> = [];
  @Input()
  submitLabel?: string;
  @Input()
  submitIcon?: string;

  @Input()
  submitType?: 'global' | 'button' | 'none' = 'none';
  @Input()
  targetDocument?: SystemDocument | any = {};
  @Input()
  preSaveFn?: (updatedDoc: SystemDocument | any) => Promise<any>;
  @Input()
  customSaveFn?: (doc: SystemDocument | any) => Promise<any>;
  @Input()
  postSaveFn?: (result: PostSaveFnParam | any) => Promise<any> | any;
  @Input()
  postDeleteFn?: (result: PostSaveFnParam | any) => Promise<any> | any = async  (result: PostSaveFnParam | any) => {
    // default delete action is to go back to the last page
    this.dynamicFormService.goBack();
  };
  @Input()
  saveUrl?: string;
  @Input()
  noConfirmAndFormCheck?: boolean;
  @Input()
  show?: boolean;
  private context: DynamicFormContext;
  @Input()
  disableForm? = false;

  @Input()
  allowDelete = true;

  constructor(
    private dynamicFormService: DynamicFormService,
    private cd: ChangeDetectorRef,
    private dfSave: DynamicFormSaveService,
  ) {
  }

  ngOnInit() {
    this.dynamicFormService.registerKeys(this.dynamicFields, this.targetDocument);
    if (!this.form) {
      this.form = new FormGroup({});
    }
    this.formFieldsArray = this.dynamicFormService.toFormArray(this.dynamicFields);
    const showDelete = !this.targetDocument?.systemHeader?.isNew;
    this.context = new DynamicFormContext(
      this.targetDocument,
      this.form,
      async (updatedDoc: SystemDocument | any) => {

        // don't support nested objects
        // 'object.key' will not create object = {key} but 'object.key' = {}
        for (const key in this.dynamicFields) {
          const dField = this.dynamicFields[key];
          if (dField.excludeAtSave === true) {
            delete updatedDoc[key];
          }
          // implement mapping functions
          // mapping function should assign the value to the appropriate field
          if (dField.mappingFunction) {
            const value =  updatedDoc[key];
            delete updatedDoc[key];
            await dField.mappingFunction(updatedDoc, value);
          }
        }

        if (this.preSaveFn) {
          await this.preSaveFn (updatedDoc);
        }

      },
      this.postSaveFn,
      this.saveUrl,
      this.customSaveFn,
      this.noConfirmAndFormCheck,
      this.postDeleteFn,
      this.allowDelete,
    );
    if (this.submitType === 'global') {
      this.showSubmitButton = false;
      // if global saving then install context to dynamic form service as main context
      this.dfSave.setCurrentContext(this.context);
    }
    this.cd.detectChanges();
  }

  public async submit() {
    await this.dfSave.triggerSave(this.context);
    this.form.markAsUntouched();
    this.cd.markForCheck();
  }

  public async delete() {
    await this.dfSave.triggerDelete(this.context);
    this.form.disable();
    this.cd.markForCheck();
  }

  onReset() {
    this.form.reset();
  }

  ngAfterViewInit(): void {
    console.log(this.form);
    this.onInitDone.emit(this.form);
  }

  ngOnDestroy(): void {
    if (this.dfSave.currentContext.getValue() === this.context) {
      this.dfSave.setCurrentContext(null);
      this.cd.markForCheck();
    }
  }

}
