import {Component, Input, OnInit, Pipe, PipeTransform, ViewEncapsulation} from '@angular/core';
import {LadField, LadFieldOptions} from '../../models/lad-field';
import {LadComponentBase} from '../../models/lad-component-base';

@Component({
  selector: 'app-lad-select',
  templateUrl: './lad-select.component.html',
  styleUrls: ['./lad-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LadSelectComponent extends LadComponentBase implements OnInit {

  @Input()
  formField: LadSelect;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}

export type LadSelectAddTagFn = (term: string) => void;
export type LadSelectNgModelChange = (selectedValue: any) => void;
export type LadSelectCompareFn = (item, selected) => boolean; // item is {value, label} while selected is the actual value

export interface LadSelectItem {
  value: any,
  label: string,
  customItemDisplayHtmlFn?: (item) => string,
  customItemDisplayHtml?: string
}

export interface LadSelectOptions<T> extends LadFieldOptions<T> {
  // component specific options
  addTag?: LadSelectAddTagFn,
  items?: LadSelectItem[],
  placeholder?: string,
  multiple?: boolean,
  closeOnSelect?: boolean,
  virtualScroll?: boolean,
  compareFn?: LadSelectCompareFn;
  customLabelHtmlFn?: (any) => string;
  ngModelChange?: LadSelectNgModelChange;
}

export class LadSelect extends LadField<any> {

  addTag: LadSelectAddTagFn;
  items: LadSelectItem[];
  placeholder: string;
  multiple: boolean;
  closeOnSelect = true;
  virtualScroll = true;
  customLabelHtmlFn?: (any) => string;
  ngModelChange: LadSelectNgModelChange;

  constructor(options: LadSelectOptions<any>) {
    super(options);
    this.__componentType = LadSelectComponent;
    const compareFnOverride = options.compareFn;
    if (compareFnOverride) {
      this.compareFn = (item, selected) => {
        try {
          return compareFnOverride(item, selected);
        } catch (e) {
          return false;
        }
      }
    }
  }

  compareFn: LadSelectCompareFn = (item, selected) => item.value === selected;

  public setItems(items: any[]) {
    this.items = [...items];
  }

  public addItem(item) {
    this.items.push(item);
    this.setItems(this.items);
  }

  ngModelChangeHandler(selectedValue: any) {
    if (this.ngModelChange) {
      this.ngModelChange(selectedValue);
    }
  }
}
