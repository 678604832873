import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {WinnersListDialogComponent} from "./winners-list-dialog/winners-list-dialog.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BlackAgentSummary} from "../../models/black-agent-summary";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  gross: number;
  commission: number;
  wins: number = 0;
  raffle: number;
  winners: number;
  summary: any[] = [];

  constructor(public modalService: NgbModal) { }

  ngOnInit(): void {
    this.summary = [this.sampleData];
    this.summary.forEach(val => {
      this.gross = val.gross? val?.gross : 0;
      this.commission = val.commission? val?.commission : 0;
      this.raffle = val.raffle.length? val?.raffle.length : 0;
    });
    this.sampleWinners.forEach(val => {
      this.wins += val.bets.length;
    });
    this.winners = this.sampleWinners.length ? this.sampleWinners.length : 0;
  }

  getCardValue(prop){
    const list = this.summary.find(val => val.hasOwnProperty(prop));
    console.log(list[prop]);
    console.log(this.summary[prop]);
    return list[prop] ? list[prop] : null;
  }

  async winnersDialog(){
    const modalRef = this.modalService.open(WinnersListDialogComponent, {size: 'lg'});
    modalRef.componentInstance.target = this.sampleWinners;
  }

  sampleData = {
    "draw" : "d1",
    "winDetails" : {"totalWin" : 3},
    "agentRel" : {
      "relatedDocumentType" : "BlackAgent",
      "relatedDocumentId" : "f16a6f48-2788-45b5-8120-49569bbe1270"
    },
    "coordinatorRel" : {
      "relatedDocumentType" : "BlackCoordinator",
      "relatedDocumentId" : "584b4ec6-0377-4747-b62b-88b7687c7a28"
    },
    "commission" : 73,
    "gross" : 365,
    "raffle" : [
      "l2",
      "s3",
      "s3r",
      "d4"
    ]
  };

  sampleWinners = [
    {
      "raffle" : "l2",
      "bets" : [
        {
          "combination" : "15",
          "amount" : 10,
          "draw" : "d1"
        },
        {
          "combination" : "12",
          "amount" : 20,
          "draw" : "d2"
        }
      ],
      "transactionId" : "43382aad",
      "total" : 30,
      "agentRel" : {
        "relatedDocumentType" : "BlackAgent",
        "relatedDocumentId" : "f16a6f48-2788-45b5-8120-49569bbe1270"
      },
      "coordinatorRel" : {
        "relatedDocumentType" : "BlackCoordinator",
        "relatedDocumentId" : "584b4ec6-0377-4747-b62b-88b7687c7a28"
      }
    },
    {
      "raffle" : "l2",
      "bets" : [
        {
          "combination" : "15",
          "amount" : 10,
          "draw" : "d1"
        }
      ],
      "transactionId" : "12345aad",
      "total" : 10
    }
  ];

}
