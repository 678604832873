import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {LadInput} from '../../../../app/dynamic-form/components/lad-input/lad-input.component';
import {GridSettings} from 'handsontable/settings';
import {Draw, DrawStr, Raffle} from '../../models/utils/number-helper';
import {LadRadio} from '../../../../app/dynamic-form/components/lad-radio/lad-radio.component';
import {LadDate} from 'ladrov-commons';
import {BlackWinningNumber} from '../../models/black-winning-number';
import Handsontable from 'handsontable';
import {DynamicFormComponent} from '../../../../app/dynamic-form/form/dynamic-form.component';

@Component({
  selector: 'app-input-winning-numbers',
  templateUrl: './input-winning-numbers.component.html',
  styleUrls: ['./input-winning-numbers.component.scss']
})
export class InputWinningNumbersComponent implements OnInit {

  @ViewChild('dynamicFormElement') dynamicFormElement: DynamicFormComponent;
  dynamicFields;
  hbTableId = 'table1';

  data = [
    new BlackWinningNumber(new LadDate(), Draw.d1, Raffle.p3, 100)
  ];

  changeBuffer: BlackWinningNumber[] = [];

  constructor(private cd: ChangeDetectorRef) {
  }

  async ngOnInit() {
    this.dynamicFields = {
      selectedDate: new LadInput({
        row: 'row1',
        label: 'Selected Date',
        value: new LadDate(),
        required: true,
        inputType: 'date',
      }),
      selectedDraw: new LadRadio({
        row: 'row2',
        label: 'Select Draw:',
        columnClass: 'col-md-4',
        value: Draw.d1,
        items: [
          {
            label: DrawStr[Draw.d1], value: Draw.d1
          },
          {
            label: DrawStr[Draw.d2], value: Draw.d2
          },
          {
            label: DrawStr[Draw.d3], value: Draw.d3
          },
        ]
      })
    };
    this.cd.markForCheck();
  }

  onDFormInitDone() {
    this.dynamicFormElement.form.markAsDirty();
    this.dynamicFormElement.form.valueChanges.subscribe(next => {
      this.renderTable();
      debugger;
    });
    this.renderTable();
  }

  private createHandsonTable(data: BlackWinningNumber[]) {
    // if (!data || data.length === 0) {
    //   data = [{raffleId: 'n/a', from: 0, to: 0, value: 0}]
    // }
    let columnDef =  [
      {
        width: 50,
        title: 'Draw',
        data: 'draw',
        type: 'dropdown',
        source: [Draw.d1, Draw.d2, Draw.d3]
      },
      {
        width: 50,
        title: 'Raffle',
        data: 'raffle',
        type: 'dropdown',
        source: [Raffle.l2, Raffle.s3, Raffle.s3r, Raffle.d4, Raffle.p3]
      },
      {
        width: 50,
        title: `Winning Combination`,
        data: 'combination'
      }
    ];
    const gridSettings: GridSettings = {
      readOnlyCellClassName: 'text-muted',
      rowHeights: 45,
      columnHeaderHeight: 45,
      rowHeaders: false,
      licenseKey: 'non-commercial-and-evaluation',
      data,
      columns : columnDef,
      dataSchema : () => {
        const {selectedDate, selectedDraw} = this.dynamicFormElement.form.getRawValue();
        return new BlackWinningNumber(selectedDate, selectedDraw !== Draw.ALL ? selectedDraw : Draw.d1, Raffle.l2, 0);
      },
      contextMenu: ['remove_row', '---------', 'row_below', 'row_above', '---------',  'copy', 'cut', '---------', 'undo', 'redo'],
      allowRemoveRow: true,
      fixedColumnsLeft: 0,
      stretchH: 'all',
      colWidths: columnDef.map((each: any) => each.width ? each.width : 100),
      height: '50vh',
      beforePaste : (data, coords) => {
        for (let rindex = 0 ; rindex < data.length; rindex++ ) {
          for (let colIndex = 0; colIndex < data[rindex].length; colIndex++ ) {
            const val = data[rindex][colIndex];
            if (typeof val === 'string') {
              data[rindex][colIndex] = val.trim();
            }
          }
        }
      },
      afterChange: (changes, source) => {
        if (source !== 'loadData') {
          changes.forEach(([row, prop, oldValue, newValue]) => {
            this.changeBuffer.push(this.data[row]);
            this.dynamicFormElement.form.markAsDirty();
          });
        }
      },
      afterRemoveRow: function(index, amount) {
        debugger;
        console.log(`Removed ${amount} row(s) starting at index ${index}`);
      }
    };
    return gridSettings;
  }

  onSave = async (doc: any) => {
    const changes = this.changeBuffer;
    await BlackWinningNumber.saveWinningNumbers({changes});
    this.changeBuffer = [];
  }

  handsontable: Handsontable;

  async renderTable(){
    const {selectedDate, selectedDraw} = this.dynamicFormElement.form.getRawValue();
    const result: BlackWinningNumber[] = await BlackWinningNumber.getWinningNumbers({date: selectedDate, draw: selectedDraw});
    this.data = [...result];
    this.changeBuffer = [];
    setTimeout(() => {
      if (!this.handsontable) {
        const settings =  this.createHandsonTable(this.data);
        this.handsontable = new Handsontable(document.getElementById(this.hbTableId), settings);
        this.handsontable.render();
        return;
      }
      this.handsontable.loadData(this.data);
      this.cd.markForCheck();
    });
  }

  addNewRow() {
    const col = this.handsontable.countRows();
    this.handsontable.alter('insert_row', col, 1);
  }
}
