import {Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AutoUnsub} from '../../../utils/AutoUnsub';
import {LadField} from './lad-field';

@AutoUnsub()
export class LadComponentBase implements OnDestroy, OnInit {

  @Input()
  public formField: LadField<any>;

  @Input()
  public formGroup?: FormGroup;

  constructor() {
    this.formField = new LadField<any>({});
    this.formGroup = new FormGroup({});
  }

  public get isValid() {
    // legacy formField.controlRef

    if (!this.formField.formControl) { // default valid if no control entry.
      return true;
    }

    if (this.formField.required) {
      return !!this.formField.formControl.value; // convert '' as false
    }

    return this.formField.formControl.valid;
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    const validators = [];
    if (this.formField.required) {
      validators.push(Validators.required)
    }
    const state = {
      value: this.formField.value ? this.formField.value : null,
      disabled: !!this.formField.disabled
    };
    this.formField.formControl = new FormControl(state, validators);
    if (this.formGroup) {
      this.formGroup.addControl(this.formField.key, this.formField.formControl);
    }
    this.formField.formControl.registerOnChange(() => {
      this.formField.value = this.formField.formControl.value;
    });
  }

  public getErrorMessages(errors: any): string {
    const errorMessages = [];

    if (errors) {
      for (const errorKey in errors) {
        if (errors.hasOwnProperty(errorKey)) {
          let errorMessage = '';

          switch (errorKey) {
            case 'required':
              errorMessage = 'Required';
              break;
            case 'minlength':
              errorMessage = `Min length should be ${errors[errorKey].requiredLength}.`;
              break;
            case 'maxlength':
              errorMessage = `Max length allowed is ${errors[errorKey].requiredLength}.`;
              break;
            // Add more cases for other error keys as needed
            default:
              errorMessage = 'Invalid value.';
              break;
          }

          errorMessages.push(errorMessage);
        }
      }
    }

    return errorMessages.join(' ');
  }


}
