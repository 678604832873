import {API, APIFunctionTypes, LadAPIUtils, LadDate, LadDocumentService, LadRelatedDocument, SystemDocument} from 'ladrov-commons';
import {JPEModelNames} from './jpe-model.names';
import {JPEAccount} from './jpe-account.model';
import {JPEInvoice} from './jpe-invoice.model';
import {JPEProject} from './jpe-project.model';

export interface ContractSummary {
  completionPercent: number;
  missedPayments: number;
  status: 'ACTIVE' | 'INACTIVE' | 'FULLY PAID' | 'N/A',
  monthsLeft: number;
  dueInvoices: number;
  totalAmountDue: number;
  paidInvoices: number;
  //FOR NOW
  totalAmountPaid: number;
  totalBalance1?: any;
  totalBalance2?: any;
}

export class JPEPropertyContract extends SystemDocument {

  client: LadRelatedDocument<JPEAccount> = null;
  agent: LadRelatedDocument<JPEAccount> = null;
  salesDirector: LadRelatedDocument<JPEAccount> = null;
  salesManager: LadRelatedDocument<JPEAccount> = null;

  downPaymentAmount = 0;
  downPaymentTerms = 1;
  totalContractAmount = 0;
  termsInMonths = 0;
  monthlyAmortization = 0;
  startDate = new LadDate();
  // dayOfMonthPaymentDeadline = 1;
  dos = false;
  titling = false;
  remarks: string;
  balance = 0;
  percentComplete = '0';
  cancelled = false; // contract cancelled
  projectDeleted?: boolean;

  // FOR NOW - todo remove unnecessary
  transactionDate: LadDate;
  maturityDate: LadDate;
  downpayment: any;
  sd: any;
  installmentBalance: any;
  accountingStaff: any;
  ceo: any;

  @API(APIFunctionTypes.PRIVATE)
  public static async search(args, utils: LadAPIUtils): Promise<JPEPropertyContract[]> {
    const {projectIds} = args;
    const docService: LadDocumentService = utils.documentService;
    let filter: any = {};
    if(projectIds.length){
      filter = {
        'projectId': {$exists: true, $in: projectIds}
      };
    }
    return docService.find(JPEModelNames.PROPERTY_CONTRACT, filter);
  }

  // @API(APIFunctionTypes.PRIVATE)
  // public static async generateAmortizationInvoices(args: { contractId: string }, utils: LadAPIUtils): Promise<JPEPropertyContract[]> {
  //   const { contractId } = args;
  //   const contract: JPEPropertyContract = await utils.documentService.findOne(JPEModelNames.PROPERTY_CONTRACT, { documentId: contractId });
  //   if (!contract) {
  //     throw new Error('Did not find contract with id: ' + contractId)
  //   }
  //   const {lot, project} = await JPEProject.getLotReference({lotDocumentId: contract.lotId}, utils);
  //   let currentYear = contract.startDate.year;
  //   let currentMonth = contract.startDate.month;
  //   const invoices = [];
  //   const currentDate = new LadDate();
  //   for (let i = 0; i < contract.termsInMonths; i++) {
  //     const inv = new JPEInvoice();
  //     inv.billTo = contract.client;
  //     inv.invoiceDate = currentDate;
  //     inv.propertyContract.relatedDocumentId = contractId;
  //     // amortization info
  //     if (currentMonth === 13) {
  //       currentYear++;
  //       currentMonth = 1;
  //     }
  //     inv.amortizationInfo =  {
  //       year: currentYear,
  //       month: currentMonth,
  //       monthOnTerm: i + 1, // the nth month based on the set terms
  //     }
  //     inv.dueDate = new LadDate();
  //     inv.dueDate.year = currentYear;
  //     inv.dueDate.month = currentMonth;
  //     inv.dueDate.day = contract.dayOfMonthPaymentDeadline;
  //     // details
  //     const detail = `MORTGAGE PAYMENT NO. ${inv.amortizationInfo.monthOnTerm} of ${contract.termsInMonths} `
  //       + `CONTRACT: ${contract.documentId.substring(0, 7)} ${project.name}, Block ${lot.blockNo}, Lot ${lot.lotNo}`;
  //     inv.items = [
  //       new InvoiceItem(detail, contract.monthlyAmortization)
  //     ]
  //     inv.subTotal = contract.monthlyAmortization;
  //     inv.amountDue = contract.monthlyAmortization;
  //     // inc
  //     invoices.push(inv)
  //     currentMonth++
  //   }
  //   const currentAccount = await utils.getCurrentAccount();
  //   await utils.documentService.upsert(invoices, currentAccount.documentId)
  //   return invoices;
  // }

  @API(APIFunctionTypes.PRIVATE)
  public static async getContractSummary(args: { contractId }, utils: LadAPIUtils) {
    const contractFilter = { documentId: args.contractId };
    const contract: JPEPropertyContract = await utils.documentService.findOne(JPEModelNames.PROPERTY_CONTRACT, contractFilter);
    if (!contract) {
      throw new Error(`Did not find contract with id ${args.contractId}`);
    }
    const summary: ContractSummary = {
      completionPercent: 0,
      dueInvoices: 0,
      missedPayments: 0,
      monthsLeft: 0,
      status: 'N/A',
      totalAmountDue: 0,
      paidInvoices: 0,
      totalAmountPaid: 0
    }
    // search amort invoices
    const filter = {
      'propertyContract.relatedDocumentId': args.contractId,
      'amortizationInfo.monthOnTerm' : { $gt: 0 }
    }
    const invoices: JPEInvoice[] = await utils.documentService.find(JPEModelNames.INVOICE, filter);

    let completedCount = 0;
    let missedPayments = 0;
    const now = new LadDate();
    for (const i of invoices) {
      if (i.paymentMethod !== 'UNPAID') {
        completedCount++;
      } else {
        if (now.after(i.dueDate)) {
          missedPayments++;
        }
      }
    }
    summary.monthsLeft = contract.termsInMonths - completedCount;
    summary.completionPercent = Number(((completedCount / contract.termsInMonths) * 100).toFixed(2));
    summary.status = missedPayments >= 3 ? 'INACTIVE' : completedCount === invoices.length ? 'FULLY PAID' : 'INACTIVE';
    summary.missedPayments = missedPayments;

    const allInvoicesQuery = {
      'propertyContract.relatedDocumentId': args.contractId,
      // 'paymentMethod': 'UNPAID',
      'dueDate.year': {$lte: now.year},
      'dueDate.month': {$lte: now.month},
      'dueDate.day': {$lte: now.day},
    }
    const allInv: JPEInvoice[] = await utils.documentService.find(JPEModelNames.INVOICE, allInvoicesQuery);

    for (const d of allInv) {
      if (d.paymentMethod === 'UNPAID') {
        summary.dueInvoices++;
        summary.totalAmountDue += d.amountDue;
      } else {
        summary.paidInvoices++;
        summary.totalAmountPaid += d.amountDue;
      }
    }

    return summary;
  }

  @API(APIFunctionTypes.PRE_SAVE)
  public static async checkIfPropertyTaken(toSave: JPEPropertyContract, utils: LadAPIUtils, opInfo) {
    // check if lot taken
    if (toSave.lotId) {
      const findCurrentContractFilter = {
        lotId: toSave.lotId,
        cancelled: {$ne: true}
      };
      let currentContract: JPEPropertyContract = await utils.documentService.findOne(JPEModelNames.PROPERTY_CONTRACT, findCurrentContractFilter);
      if (opInfo.isInsert && currentContract && currentContract.documentId !== toSave.documentId) {
        throw new Error('Property is already sold.')
      }
    }
  }

  @API(APIFunctionTypes.POST_SAVE)
  public static async postSave(contract: JPEPropertyContract, utils: LadAPIUtils) {
    // const {lot, project} = await JPEProject.getLotReference({ lotDocumentId: contract.lotId }, utils);
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getRelatedContract(args, utils: LadAPIUtils) {
    let {accountId, searchKey} = args;
    const docService = utils.documentService;
    let filter: any = {
      projectDeleted: {$ne: true},
      $or: [
        {'client.relatedDocumentId': accountId},
        {'agent.relatedDocumentId': accountId},
        {'salesDirector.relatedDocumentId': accountId},
        {'salesManager.relatedDocumentId': accountId},
      ]
    };
    const options = {
      toFetchRelatedDocuments: ["client", "agent", "salesDirector", "salesManager"]
    }
    const contracts: JPEPropertyContract[] = await docService.find(JPEModelNames.PROPERTY_CONTRACT, filter, options);
    const results: any[] = [];

    for (const c of contracts) {
      results.push(await getDetails(c));
    }

    if (searchKey) {
      searchKey = searchKey?.trim().toLowerCase();

      return results.filter( (r: any) =>
        (r.clientName + r.agentName + r.salesDirectorName + r.name + r.location + r.blockNo + r.lotNo).toLowerCase().indexOf(searchKey) > -1
      );
    }

    return results;

    async function getDetails(ref) {
      const result: any = {};
      result.contactId = ref.documentId;
      result.lotId = ref.lotId;
      const client = ref.client?.objectRef;
      if (client) {
        result.clientName = JPEAccount.getName(client);
      }
      const agent = ref.agent?.objectRef;
      if (agent) {
        result.agentName = JPEAccount.getName(agent);
      }
      const sd = ref.salesDirector?.objectRef;
      if (sd) {
        result.salesDirectorName = JPEAccount.getName(sd);
      }
      const sm = ref.salesManager?.objectRef;
      if (sm) {
        result.salesManagerName = JPEAccount.getName(sm);
      }

      filter = { 'lots.documentId' : ref.lotId };
      const findProject: JPEProject = await docService.findOne(JPEModelNames.PROJECT, filter);

      if(findProject?.name){
        result.name = findProject?.name;
      }
      if(findProject?.location){
        result.location = findProject?.location;
      }

      if(findProject?.lots){
        for (const lot of findProject.lots){
          if(lot.documentId === ref.lotId){
            result.blockNo = lot.blockNo;
            result.lotNo = lot.lotNo;
          }
        }
      }

      return result;
    }
  }

  constructor(public projectId: string, public lotId: string) {
    super(JPEModelNames.PROPERTY_CONTRACT);
  }

  static async getContractByLotId(param: { lotId: any }, utils: LadAPIUtils): Promise<JPEPropertyContract> {
    return await utils.documentService.findOne(JPEModelNames.PROPERTY_CONTRACT, { lotId: param.lotId, inactive: {$ne : true}, cancelled: {$ne: true} });
  }
}
