import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {LadField, LadFieldOptions} from '../../models/lad-field';
import {LadComponentBase} from '../../models/lad-component-base';
import {Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {APIService} from '../../../shared/backend/api.service';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../../shared/auth/auth.service';
import {LadDate, SystemDocument} from 'ladrov-commons';
import {FormControl} from '@angular/forms';
import {NgbTimepicker} from '@ng-bootstrap/ng-bootstrap';

// import {BehaviorSubject, Subscription} from 'rxjs';

@Component({
  selector: 'app-lad-text-box',
  templateUrl: './lad-input.component.html',
  styleUrls: ['./lad-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default
})
export class LadInputComponent extends LadComponentBase implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  public formField: LadInput;

  @ViewChild('timepicker')
  timepicker: NgbTimepicker

  timeValue: any;

  _value: any;
  _valueDate: Date;
  _valueNum: number;
  _typeAheadProcessor;

  // subject = new BehaviorSubject(null);
  // $sub: Subscription;
  private dateSub: Subscription;

  constructor(
    private api: APIService,
    private toastr: ToastrService,
    private auth: AuthService,
    private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    // this.$sub = this.subject.subscribe((val) => {
    //   console.log(val);
    // });

    if (this.formField.typeAheadItems) {
      let debounce = this.formField.typeAheadDebounceTime;
      debounce = debounce ? debounce : 700;
      this._typeAheadProcessor = (text$: Observable<string>) =>
        text$.pipe(
          debounceTime(debounce),
          distinctUntilChanged(),
          map(term => term === '' ? []
            : this.formField.typeAheadItems.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
        );
    }

    try {
      switch (this.formField.inputType) {
        case 'date' :
          this._valueDate = new Date(this.formField.value);
          break;
        case 'number':
          this._valueNum = Number(this.formField.value);
          break;
        default:
          this._value = this.formField.value ? this.formField.value : '';
      }
    } catch (e) {
      this._value = ''
    }

    if (this.formField.inputType === 'date') {
      this.dateSub = this.formField.formControl.valueChanges.subscribe((newVal) => {
        const final = new LadDate(new Date(`${newVal.year}-${newVal.month}-${newVal.day}`));
        this.formField.formControl.patchValue(final, {onlySelf: true, emitModelToViewChange: false, emitViewToModelChange: false, emitEvent: false})
      });
    }

    this.formField.fieldValueChanges.subscribe((newVal) => {
      const parsedVal = this.parseValue(newVal);
      if (parsedVal === this.getValue()) {
        return;
      }
      this.setValue(parsedVal);
    });
  }

  _valueChanged($event) {
    let value = $event.target.value;
    value = this.parseValue(value);
    this.formField.formControl.setValue(value,{onlySelf: true});
  }

  getValue() {
    switch (this.formField.inputType) {
      case 'date' :
        return this._valueDate;
      case 'number':
        return this._valueNum;
    }
    return this._value;
  }

  setValue(parsedValue) {
    switch (this.formField.inputType) {
      case 'date' :
        this._valueDate = parsedValue;
        return;
      case 'number':
        this._valueNum = parsedValue;
        return;
    }
    this._value = parsedValue;
    this.cdr.markForCheck();
  }

  parseValue(value) {
    switch (this.formField.inputType) {
      case 'date' :
        value = new Date(value).getTime();
        break;
      case 'number':
        value = Number(value);
        break;
    }
    return value;
  }

  async onOTPRequest() {
    if (!this.formField.otp) {
      return;
    }
    try {
      let sendTo = this.auth.userAccountSubject.getValue().mobileNo;
      if (this.formField.otp !== true) {
        const fnResult = await this.formField.otp();
        sendTo = fnResult ? fnResult : sendTo;
      }
      await this.api.requestOTP(sendTo).toPromise();
      this.toastr.success('OTP Request sent.');
    } catch (e) {
      const m = e.error ? e.error : e.message ? e.message : 'An error occurred during OTP Request. Please try again later.';
      this.toastr.error(m);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.dateSub) {
      this.dateSub.unsubscribe();
    }
  }

  getError(formControl: FormControl) {
    return JSON.stringify(formControl.errors)
  }

  regenerateUUID() {
    const uuid = new SystemDocument('dummy').documentId.split('-')[0];
    this.formField.formControl.setValue(uuid, {emitViewToModelChange: true, })
  }

  ngAfterViewInit(): void {
  }
}

export class LadInputOptions<T> extends LadFieldOptions<T> {
  // component specific options
  inputType?: 'number' | 'date' | 'time' | 'password';
  inputClass?: string;
  typeAheadItems?: string[];
  typeAheadDebounceTime?: number;
  placeholder?: string;
  withTimeSpinners?: boolean;
  minLength?: number;
  maxLength?: number;
  // the promise should return a mobile no to where the otp is going to be sent to
  otp?: boolean | (() => Promise<string>);
  uuid?: boolean | (() => Promise<string>);
}

export class LadInput extends LadField<any> {

  inputType: string;
  inputClass: string;
  typeAheadItems?: string[];
  typeAheadDebounceTime?: number;
  placeholder?: string;
  withTimeSpinners?: boolean;
  minLength?: number;
  maxLength?: number;
  otp?: boolean | (() => Promise<string>);
  uuid?: boolean | (() => Promise<string>);

  constructor(options: LadInputOptions<any>) {
    if (!options.value) {
      options.value = null;
    }
    super(options);
    this.__componentType = LadInputComponent;
    // defaults here to avoid overrides.
    this.inputType = this.inputType ? this.inputType : 'text';
    this.placeholder = this.placeholder ? this.placeholder : '';
  }
}

