import {LadTime, SystemDocument} from 'ladrov-commons';
import {BlackStatus, BLACK_SYSTEM_NAME} from './utils/black-utils';
import {Draw, Raffle, RaffleSettings} from './utils/number-helper';

export interface BlackUserBase {

  draws: Draw[];
  raffles: Raffle[];

  enableCutOffTime?: boolean
  cutoffTime: {[key: string]: LadTime};

  enablePrizes?: boolean;
  unitPrizes: RaffleSettings[];

  enableCommissions?: boolean;
  commissions: RaffleSettings[];

  enableMaxBet?: boolean;
  // example: {d1: { all: [] }}
  maxBet: {[key: string]: {[key: string]: RaffleSettings[]}};

}

export function getMaxBet(draw: Draw, raffle: Raffle, combo: number, src: BlackUserBase): RaffleSettings {
  // look for all first
  if (!src.maxBet) {
    return null;
  }

  // query for specific draw
  let settings = src.maxBet[draw][raffle];
  let maxBetSettings: RaffleSettings = getMaxBet(settings);
  if (maxBetSettings) {
    return maxBetSettings;
  }

  settings = src.maxBet[Draw.ALL][raffle];
  return getMaxBet(settings);

  function getMaxBet(maxBetArray: RaffleSettings[]) {
    if (!maxBetArray) {
      return null;
    }

    let found = null;
    for (const m of maxBetArray) {
      // remove all whitespace
      if (!m.combination) {
        m.combination = '';
      }
      m.combination = m.combination.replace(/\s/g, '');

      // specific number check
      const limitComboAsNumber = Number(m.combination);
      if (!isNaN(limitComboAsNumber) && limitComboAsNumber === combo){
        found = m;
        continue;
      }

      // range check
      if (m.combination?.indexOf('-')) {
        const split = m.combination.split('-');
        const from = Number(split[0]);
        const to = Number(split[1]);
        if (isNaN(from) || isNaN(to)) {
          console.error('Invalid range. From and to should be numbers: ' + JSON.stringify(m));
        }
        const finalFrom = Math.min(from, to);
        const finalTo = Math.max(from, to);
        if (combo >= finalFrom && combo <= finalTo) {
          found = m;
          continue;
        }
      }

      // wildcard check
      const startsWithWildCard = m.combination.startsWith('*');
      const endsWithWildCard = m.combination.endsWith('*');
      let limitCombo = m.combination;
      if (startsWithWildCard || endsWithWildCard) {
        limitCombo = limitCombo.replace(/\*/g, '');
      }
      if (isNaN(Number(limitCombo))) {
        console.log('Invalid wildcard. No number: ' + JSON.stringify(m));
        continue;
      }
      let comboStr = `${combo}`
      if (startsWithWildCard && comboStr.startsWith(limitCombo)) {
        found = m;
        continue;
      }
      if (endsWithWildCard && comboStr.endsWith(limitCombo)) {
        found = m;
        continue;
      }
    }
    return found;
  }

}
