

<ng-template let-c="close" let-d="dismiss">

</ng-template>

<div class="modal-header">
  <h4 class="modal-title">Edit #{{itemIndex}} {{toEdit.draw}}-{{toEdit.raffleId}}</h4>
  <button type="button" class="close" (click)="activeModal.dismiss()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">

    <div class="col-md-12" *ngIf="mode === MODE_RAFFLE_EDIT">
      <fieldset class="form-group">
        <label for="raffleInput">Raffle</label>
        <div class="form-group">
          <ng-select id="raffleInput" [items]="raffles" bindValue="value" placeholder="Select draw" [(ngModel)]="toEdit.raffleId">
          </ng-select>
        </div>
      </fieldset>
      <fieldset class="form-group">
        <label for="valueInput1">{{valueLabel}}</label>
        <input type="number" class="form-control" id="valueInput1"  placeholder="Enter amount" [(ngModel)]="toEdit.value">
      </fieldset>
    </div>

    <div class="col-md-12" *ngIf="mode === MODE_COMBINATION_EDIT">
      <fieldset class="form-group" style="margin-bottom: 8px;">
        <label for="patternInput" style="margin-bottom: 0px;">Combination</label>
        <br/>
        <span class="font-small-1 text-muted">xx, xx-xx, *xx, xx*, *xx*</span>
        <span class="font-small-1 text-muted">xx, xx-xx, *xx, xx*, *xx*</span>
        <input type="text" class="form-control" id="patternInput"  placeholder="Number/Range/Pattern" [(ngModel)]="toEdit.combination" (ngModelChange)="onCombinationUpdate()">
        <small *ngIf="!toEdit.combinationError" class="text-muted font-small-1">*999 = All numbers ending 999</small>
        <p *ngIf="toEdit.combinationError" class="text-warning font-small-2 mt-1" style="line-height: 1">{{toEdit.combinationError}}</p>
      </fieldset>
      <fieldset class="form-group">
        <label for="amountInput">{{valueLabel}}</label>
        <input type="number" class="form-control" id="amountInput"  placeholder="Enter amount" [(ngModel)]="toEdit.value">
      </fieldset>
    </div>

  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary btn-warning mr-auto" (click)="activeModal.close('delete')">Delete</button>
  <button type="button" class="btn btn-primary ml-auto" (click)="activeModal.close('ok')">OK</button>
</div>
