<div class="form-group">
  <code *ngIf="!isValid" class="font-small-1">*</code>
  <label *ngIf="formField.label" [attr.for]="formField.key" [class]="formField.labelClass">
    {{formField.label}}
    <a *ngIf="!isValid" class="ft-info font-small-3 blinking"></a>
  </label>
  <div id="activityMap"></div>
  <div class="d-flex">
    <div *ngIf="formField.editable !== false && mapFrozen" class="col-md-6" style="padding-left: 0px;">
      <label (click)="unfreezeMap()" *ngIf="isValid" style="cursor: pointer;">
        <i class="font-medium-1 ft-edit text-primary cursor-pointer mt-1"
           ngbTooltip="Edit location" placement="right"></i>
      </label>

    </div>
    <!--    <div class="col-md-6 d-flex justify-content-end align-items-end">-->
    <!--      <i (click)="removeLocation()" class="ft-thumbs-up text-success cursor-pointer mt-1"-->
    <!--         ngbTooltip="Remove current location." placement="left"></i>-->
    <!--      <i (click)="removeLocation()" class="ft-trash-2 text-danger cursor-pointer mt-1"-->
    <!--         ngbTooltip="Remove current location." placement="left"></i>-->
    <!--    </div>-->
  </div>

</div>
