import {API, APIFunctionTypes, LadAPIUtils, LadUploadedAsset, SystemDocument} from 'ladrov-commons';
import {QtModelNames} from './qt-model.names';

export class QtProductModel extends SystemDocument {
  public static MODEL_NAME = 'QtProductModel';

  // quantity: number;//
  // category: string;//
  // unit: string;//
  unitPrice: number;//
  code: string; // require only 5 letters//
  name: string;//
  // business: QtBusinessModel;
  // location: number; // product location on screen//
  color: string;//
  shortDocumentId: string;
  image: LadUploadedAsset;


  constructor() {
    super(QtProductModel.MODEL_NAME);
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async search(args: { searchKey: string }, utils?: LadAPIUtils): Promise<QtProductModel[]> {
    let filter = args.searchKey ? {
      code: RegExp(args.searchKey, 'i'),
      name: RegExp(args.searchKey, 'i')
    } : {};
    const result = await utils.documentService.find(QtModelNames.PRODUCT, filter);
    return result;
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async updateProduct(args, utils?: LadAPIUtils): Promise<QtProductModel[]>{
    await utils.documentService.upsert(args, utils.currentUserId);
    return;
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async deleteProduct(args, utils?: LadAPIUtils): Promise<QtProductModel[]> {
    const id = args
    await utils.documentService.deleteDocument(QtProductModel.MODEL_NAME, id, utils.currentUserId);
    return;
  }

  @API(APIFunctionTypes.PRE_SAVE)
  public static async presave(product: QtProductModel, utils: LadAPIUtils): Promise<QtProductModel> {
    const results = await utils.documentService.find(QtModelNames.PRODUCT, {code: product.code});
    if (results[0]) {
      const existing = results[0] as QtProductModel;
      if (existing.documentId !== product.documentId) {
        throw new Error(`Product with code ${product.code.toUpperCase()} is already taken.`);
      }
    }
    return product;
  }

}
