import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import Handsontable from 'handsontable';
import {getSampleData, JPEProjectLot} from '../../../../projects/jpe-app/models/jpe-project.model';
import {GridSettings} from 'handsontable/settings';
import {LadField, LadFieldOptions} from '../../models/lad-field';
import {LadUploadedAsset} from 'ladrov-commons';
import {LadComponentBase} from '../../models/lad-component-base';
import {LadUploads} from '../lad-uploads/lad-uploads.component';
import {DynamicFormService} from '../../dynamic-form.service';

export interface BbHandsontableOptions extends LadFieldOptions<any> {
  gridSettings: GridSettings | (() => Promise<GridSettings>);
  enableRowMove?: boolean;
  enableRowRemove?: boolean;
}

export class BbHandsontable extends LadField<any > {
  gridSettings: GridSettings | (() => Promise<GridSettings>);
  getHandsOnTableRef: () => Handsontable;
  enableRowMove?: boolean;
  enableRowRemove?: boolean;

  constructor(options: BbHandsontableOptions) {
    super(options);
    this.__componentType = BbHandsontableComponent;
  }
}

@Component({
  selector: 'app-bb-handsontable',
  templateUrl: './bb-handsontable.component.html',
  styleUrls: ['./bb-handsontable.component.scss']
})
export class BbHandsontableComponent extends LadComponentBase implements OnInit, AfterViewInit {

  @Input()
  formField: BbHandsontable;
  hot: Handsontable = null;
  htableContainerId: string;
  private afterViewInit: boolean;

  constructor(
    private cd: ChangeDetectorRef,
    private dfs: DynamicFormService
  ) {
    super();
  }

  private array_move = (arr: any, old_index, new_index) => {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

    this.hot.render();

    return arr; // for testing
  };

  private moveCols = [
    {

      readOnly: true,
      renderer: (instance, td, row, col, prop, value, cellProperties) => {
        if (td.childElementCount > 0 || row === 0) {
          return td;
        }
        const anchor = document.createElement('a');
        anchor.className = 'info';
        anchor.title = 'Move Up';
        anchor.onclick = () => {
          if (row === 0) {
            return;
          }
          const newRow = row - 1
          this.array_move(this.hot.getSettings().data, row, newRow);
          this.hot.selectRows(newRow);
          this.dfs.getToaster().warning('Row moved up.', null, {closeButton: true, positionClass: 'toast-top-right'});
        };
        anchor.innerHTML = `<i class="ft-arrow-up"></i> <span class="text-white">MOVE UPWARD XXXXXX</span>`;
        td.appendChild(anchor);
        return td;
      },
    },
    {
      title: '',
      readOnly: true,
      renderer: (instance, td, row, col, prop, value, cellProperties) => {
        const rows = instance.countRows();
        if (td.childElementCount > 0 || row === rows - 1) {
          return td;
        }

        const anchor = document.createElement('a');
        anchor.className = 'info';
        anchor.title = 'Move Down';
        anchor.onclick = () => {
          if (row === rows - 1) {
            return;
          }
          const newRow = row + 1
          this.array_move(this.hot.getSettings().data, row, newRow);
          this.hot.selectRows(newRow);
          this.dfs.getToaster().warning('Row moved down.', null, {closeButton: true, positionClass: 'toast-top-right'});
        };
        anchor.innerHTML = `<i class="ft-arrow-down"></i> <span class="text-white">MOVE DOWN XXXXXX</span>`;
        td.appendChild(anchor);
        return td;
      },
    },
  ]

  private removeCol =     {

    title: '',
    data: 'documentId',
    readOnly: true,
    renderer: (instance, td, row, col, prop, value, cellProperties) => {
      if (td.childElementCount > 0) {
        return td;
      }
      const anchor = document.createElement('a');
      anchor.className = 'danger';
      anchor.title = 'Remove';
      anchor.onclick = () => {
        instance.alter('remove_row', row);
        this.dfs.getToaster().warning('Table row removed', null, {closeButton: true, positionClass: 'toast-top-right'});
      };
      anchor.innerHTML = `<i class="ft-trash-2"></i><span class="text-white">REMOVE</span>`;
      td.appendChild(anchor);
      return td;
    },
  };

  ngOnInit(): void {
    super.ngOnInit();
    this.htableContainerId = `htableContainer-${this.formField.key}`;
    this.updateSettings();
  }

  addNewRow() {
    const col = this.hot.countRows();
    this.hot.alter('insert_row', col, 1);
  }

  updateSettings() {
    setTimeout(async () => {
      const gridSettings = await this.formField.gridSettings;
      const settings = typeof gridSettings === 'function' ? await gridSettings() : gridSettings;

      const mov = this.formField.enableRowMove ? this.moveCols : [];
      const rem = this.formField.enableRowRemove ? [this.removeCol] : [];
      // @ts-ignore
      settings.columns = [...settings.columns, ...mov, ...rem];


      const override: any = settings.afterChange;
      settings.afterChange = (changes, src) => {
        if (override) {
          override(changes, src);
        }
        this.formGroup.markAsDirty();
      }
      if (!this.hot) {
        this.hot = new Handsontable(document.getElementById(this.htableContainerId), settings);
      } else {
        this.hot.updateSettings(settings);
      }
      this.hot.render();
    });
  }

  ngAfterViewInit(): void {
    this.afterViewInit = true;
    this.formField.getHandsOnTableRef = () => { return this.hot };
  }
}
