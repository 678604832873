import {Component, OnInit} from '@angular/core';
import {MONTHS} from "./CustomDateFormatterService";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  selectedDate: string;
  selectedTime = '2pm';
  activeLink: string = 'homeLink';
  // avatar = "/assets/black/3d_lotto.png";
  avatar;
  name: string = 'analiza';
  avatar_letter = this.name.split(" ");
  summary = [
    {name: '2PM', total: 2000},
    {name: '5PM', total: 0.456},
    {name: '9PM', total: 0},
    {name: 'Total Sales', total: 2000},
    {name: 'Hit', total: 0},
    {name: 'Commission', total: 0},
    {name: 'Net Income', total: 2000}
  ];
  radioTime = {
    columnClass: 'col-md-4',
    value: this.selectedTime,
    items: [
      {
        label: '2PM', value: '2pm'
      },
      {
        label: '5PM', value: '5pm'
      },
      {
        label: '9PM', value: '9pm'
      },
      {
        label: 'All', value: 'All'
      },
    ]
  };

  constructor(){
    const currentDate = new Date(Date.now());
    this.selectedDate =  `${MONTHS[currentDate.getMonth()]} ${currentDate.getDate()}, ${currentDate.getFullYear()}`;
  }

  ngOnInit(): void {
  }

  setActiveLink(link: string) {
    this.activeLink = link;
  }

  setLinkStyle(link: string, text?: boolean) {
    let color: any = { color: '#156cf4', backgroundColor: 'rgba(137, 62, 191, .2)' };
    if(text){
      color = { color: '#156cf4' };
    }
    return this.activeLink === link ? color : {color: 'rgba(169, 169, 169, 0.6)'};
  }

}
