import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {DynamicFormService} from '../../../../../app/dynamic-form/dynamic-form.service';
import {GameSheetItem} from '../../../models/black-number-summary';
import {DottedTableComponent, DottedTableSettings} from '../dotted-table/dotted-table.component';

@Component({
  selector: 'app-game-sheet-item-details-dialog-component',
  templateUrl: './game-sheet-item-details-dialog.component.html',
  styleUrls: ['./game-sheet-item-details-dialog.component.scss']
})
export class GameSheetItemDetailsDialogComponent implements OnInit, AfterViewInit {

  @ViewChild('dottedTable') dottedTable: DottedTableComponent;

  @Input()
  item: GameSheetItem;

  tableSettings: DottedTableSettings = {
    cols : [
      {fieldName: 'agentName', style: {'min-width': '85px', 'max-width': '85px'}},
      {fieldName: 'total', isNumber: true}
    ],
    rows: 25,
    items: []
  };

  constructor(
    public activeModal: NgbActiveModal,
    public dynamicFormService: DynamicFormService
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.dottedTable.setItems(this.item.details)
  }

}
