import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {QtProductModel} from '../../models/qt-product.model';
import {ActivatedRoute, Router} from '@angular/router';
import {LadInput} from '../../../../app/dynamic-form/components/lad-input/lad-input.component';
import {LadSelect} from '../../../../app/dynamic-form/components/lad-select/lad-select.component';
import {QtModelNames} from '../../models/qt-model.names';
import {APIService} from '../../../../app/shared/backend/api.service';
import {Subscription} from 'rxjs';
import {LadPortrait} from '../../../../app/dynamic-form/components/lad-portrait/lad-portrait.component';

@Component({
  selector: 'app-product-mastlist-add',
  templateUrl: './product-mastlist-add.component.html',
  styleUrls: ['./product-mastlist-add.component.scss']
})
export class ProductMastlistAddComponent implements OnInit {
  targetDoc: QtProductModel = new QtProductModel();
  dynamicFields: any;
  targetContract: QtProductModel;
  id: string;
  preSaveFn: any;
  postSaveFn: any;
  subs = new Subscription();

  constructor(
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private api: APIService,
    ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    console.log(this.id);
    this.fetchProductInfo(this.id)
    this.dynamicFields = {
      code: new LadInput({
        row: 'row1',
        label: 'Code/SKU',
        required: true,
        columnClass: 'col-md-4', // default is col-md-3
        inputClass: 'text-uppercase',
        minLength: 5,
        maxLength: 5
      }),
      name: new LadInput({
        row: 'row1',
        label: 'Name',
        required: true,
        columnClass: 'col-md-4', // default is col-md-3
        inputClass: 'text-uppercase',
      }),
      unitPrice: new LadInput({
        row: 'row2',
        label: 'Unit Price',
        required: true,
        columnClass: 'col-md-6', // default is col-md-3
      }),
      color: new LadSelect({
        row: 'row2',
        label: 'Color',
        placeholder: '',
        items: [
          {
            label: 'RED', // make sure we have this content so that the value will show up
            value: 'red',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#f44336!important">RED</div>'
          },
          {
            label: 'PINK',
            value: 'pink',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#e91e63!important">PINK</div>'
          },
          {
            label: 'PURPLE',
            value: 'purple',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#9c27b0!important">PURPLE</div>'
          },
          {
            label: 'DEEP PURPLE',
            value: 'deepPurple',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#673ab7!important">PURPLE</div>'
          },
          {
            label: 'INDIGO',
            value: 'indigo',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#3f51b5!important">INDIGO</div>'
          },
          {
            label: 'BLUE',
            value: 'blue',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#2196F3!important">BLUE</div>'
          },
          {
            label: 'LIGHT BLUE',
            value: 'lightBlue',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#87CEEB!important">LIGHT BLUE</div>'
          },
          {
            label: 'CYAN',
            value: 'cyan',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#00bcd4!important">CYAN</div>'
          },
          {
            label: 'TEAL',
            value: 'teal',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#059862 !important">TEAL</div>'
          },
          {
            label: 'GREEN',
            value: 'green',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#4CAF50!important">GREEN</div>'
          },
          {
            label: 'LIGHT GREEN',
            value: 'lightGreen',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#8bc34a!important">LIGHT GREEN</div>'
          },
          {
            label: 'LIME',
            value: 'lime',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#cddc39!important">LIME</div>'
          },
          {
            label: 'KHAKI',
            value: 'khaki',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#f0e68c!important">KHAKI</div>'
          },
          {
            label: 'YELLOW',
            value: 'yellow',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#ffeb3b!important">YELLOW</div>'
          },
          {
            label: 'AMBER',
            value: 'amber',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#ffc107!important">AMBER</div>'
          },
          {
            label: 'ORANGE',
            value: 'orange',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#ff9800!important">ORANGE</div>'
          },
          {
            label: 'DEEP ORANGE',
            value: 'deepOrange',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#ff5722!important">DEEP ORANGE</div>'
          },
          {
            label: 'BLUE GREY',
            value: 'blueGrey',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#607d8b!important">BLUE GREY</div>'
          },
          {
            label: 'BROWN',
            value: 'brown',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#795548!important">BROWN</div>'
          },
          {
            label: 'GREY',
            value: 'grey',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#9e9e9e!important">GREY</div>'
          },
          {
            label: 'DARK GREY',
            value: 'darkGrey',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#616161!important">DARK GREY</div>'
          },
          {
            label: 'BLACK',
            value: 'black',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#000!important">BLACK</div>'
          }
        ],
        multiple: false,
        columnClass: 'col-md-3',
        visible: true,
        closeOnSelect: true,
        virtualScroll: true,
      }),
      portrait: new LadPortrait({label: 'Image', columnClass: 'col-md-8'}),
      documentId: new LadInput({
        row: 'row2',
        label: 'Unit Price',  
        value:null,
        required: true,
        columnClass: 'col-md-6', // default is col-md-3
        visible:false
      })
    }; 
    this.cd.detectChanges();
  }


  onFormInitDone() {

  }

  addNewLot() {

  }

  async fetchProductInfo(id){
    if(id){
      const fetchProd = (await this.api.getDocument(id, QtModelNames.PRODUCT).toPromise()) as QtProductModel;
      this.targetDoc = fetchProd; 
      this.dynamicFields.code.value = fetchProd.code;
      this.dynamicFields.name.value = fetchProd.name;
      this.dynamicFields.color.value = fetchProd.color;
      // this.dynamicFields.category.value = fetchProd.category;
      this.dynamicFields.unitPrice.value = fetchProd.unitPrice;
      // this.dynamicFields.unit.value = fetchProd.unit;
      // this.dynamicFields.quantity.value = fetchProd.quantity;
      // this.dynamicFields.location.value = fetchProd.location; 
      this.dynamicFields.documentId.value = fetchProd.documentId; //cant set by target doc so we need to create an invisible element to hold the id
      // this.targetDoc.documentId = fetchProd.documentId;
      this.targetDoc.systemHeader.type = QtModelNames.PRODUCT;
      this.cd.markForCheck();
    }
  }

}
