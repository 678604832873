import {API, APIFunctionTypes, LadAPIUtils, SystemDocument} from 'ladrov-commons';
import {QtModelNames} from './qt-model.names';
import {v4 as uuid} from 'uuid';
export class QtContactPerson {
  public documentId: any;

  constructor(
    public name?: string,
    public position?: string,
    public address?: string,
    public email?: string,
    public number?: string,
  ){
    this.documentId = uuid();
}
}

export function getStoreData() : QtBusinessModel{
  const p = new QtBusinessModel();
  p.contacts = [
  ]
  return p;
}

export class QtBusinessModel extends SystemDocument {
  static MODEL_NAME = 'QtBusinessModel'

  name = 'unknown';
  address = 'n/a';
  orderSlipHeader: "[C]CELY'S CAFE\n[C]Seaoil Sinawilan, Digos City";
  orderSlipFooter = '' +
    '[C]THIS IS NOT AN OFFICIAL RECEIPT.\n' +
    '[C]PLEASE ASK FOR AN OFFICIAL RECEIPT.\n' +
    '[C]THANK YOU!';

  contacts: QtContactPerson[] = []; // use bbhandsontable

  constructor() {
    super(QtModelNames.BUSINESS);
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async search(args: { searchKey: string }, utils?: LadAPIUtils): Promise<QtBusinessModel[]> {
    let filter = args.searchKey ? {
      name: RegExp(args.searchKey, 'i'),
      address: RegExp(args.searchKey, 'i')
    } : {};
    const result = await utils.documentService.find(QtModelNames.BUSINESS, filter);
    return result;
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async update(args, utils?: LadAPIUtils): Promise<QtBusinessModel[]> {
    await utils.documentService.upsert(args, utils.currentUserId);
    return;
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async delete(args, utils?: LadAPIUtils): Promise<QtBusinessModel[]> {
    const id = args
    await utils.documentService.deleteDocument(QtBusinessModel.MODEL_NAME, id, utils.currentUserId);
    return;
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getCurrentUserBusiness(args?, utils?: LadAPIUtils){
    return new QtBusinessModel();
  }

}
