import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LadComponentBase} from '../../models/lad-component-base';
import {LadField, LadFieldOptions} from '../../models/lad-field';

import Map from 'ol/Map';
import View from 'ol/View';
import Feature from 'ol/Feature';
import {fromLonLat} from 'ol/proj';
import {Fill, Icon, Stroke, Style} from 'ol/style';
// import {transform} from 'ol/proj';
import {defaults as defaultInteractions, Draw} from 'ol/interaction';
import {OSM, Vector as VectorSource, XYZ} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {Geometry, Point} from 'ol/geom';
import {GeoJSON} from 'ol/format';
import {Attribution, defaults as defaultControls} from 'ol/control';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {LadGeoJSONObject} from 'ladrov-commons';

@Component({
  selector: 'app-lad-multiple-location',
  templateUrl: './lad-multiple-location.component.html',
  styleUrls: ['./lad-multiple-location.component.scss']
})
export class LadMultipleLocationComponent extends LadComponentBase implements OnInit, OnDestroy {

  @Input()
  formField: LadMultipleLocation;

  private DEFAULT_CENTER = [125.35562772785079, 6.74254707723081];
  private mapControls: any;
  private pointInteraction: Draw;
  private map: Map;
  private allInteractions: any[];
  private allControls: any[] = [];
  private DEFAULT_ZOOM = 12;
  private DEFAULT_ZOOM_WITH_VALUE = 15;
  private geoJSONObj: any[];

  geoJSON = new GeoJSON(); // formatter
  vectorSource: VectorSource<Geometry>;

  mapFrozen = false;
  private $fieldValueChanges: Subscription;

  constructor(
    public toastr: ToastrService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.geoJSONObj = [];

    this.$fieldValueChanges = this.formField.fieldValueChanges.subscribe((nextValue: LadGeoJSONObject) => {
      this.setValue(nextValue);
      // must be responsible for setting controlRef value (patchValue)
      this.formField.formControl.patchValue(nextValue,{onlySelf: true});
    });

    const attribution = new Attribution({
      collapsible: true,
      collapsed: true,
      tipLabel: 'OSM'
    });
    const osmSrc = new OSM({
      url : 'https://tile.thunderforest.com/cycle/{z}/{x}/{y}.png?apikey=148d9e850a014139a6e02dc703659cd3'
    });
    const rasterLayer = new TileLayer({
      source: osmSrc
    });

    // from retina (high resolution maps) example
    const highLayer = new TileLayer({
      source: new XYZ({
        url:
          'https://api.maptiler.com/maps/outdoor/256/{z}/{x}/{y}@2x.png?key=Jwm8B5N9cVVMHoTf5Opi',
        tilePixelRatio: 2, // THIS IS IMPORTANT
      })
    });

    this.vectorSource = new VectorSource({
      features: []
    });
    const vectorLayer = new VectorLayer({
      source: this.vectorSource,
      style: new Style({
        fill: new Fill({
          color: 'rgba(222, 106, 81, 0.2)',
        }),
        stroke: new Stroke({
          color: '#DE6A51',
          width: 2,
        }),
        image: new Icon(({
          src: '/assets/map/home-pin.png',
          // imgSize: [64, 64],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          anchor: [0.5, 35],
          anchorOrigin: 'top-left'
        }))
      }),
    });

    this.map = new Map({
      controls: defaultControls({attribution: false, rotate: false}).extend([attribution]),
      // projection: 'EPSG:3857',
      interactions: defaultInteractions({ // see https://openlayers.org/en/latest/apidoc/module-ol_interaction.html
        altShiftDragRotate: false,
        doubleClickZoom: true,
        // dragAndDrop: false,
        // keyboardPan: true,
        // keyboardZoom: true,
        mouseWheelZoom: true,
        // pointer: true,
        // select: false, // no box select
        pinchZoom: true,
        pinchRotate: false,
        shiftDragZoom: true
      }),
      layers: [
        rasterLayer,
        highLayer,
        vectorLayer
      ],
      target: 'activityMap',
      view: new View({
        center: fromLonLat(this.DEFAULT_CENTER),
        zoom: this.formField.value ? this.DEFAULT_ZOOM_WITH_VALUE : this.DEFAULT_ZOOM,
        minZoom: 10,
        maxZoom: 17
      }),
    });

    this.mapControls = this.map.getControls();

    this.pointInteraction = new Draw({
      source: this.vectorSource,
      type: 'Point'
    });

    this.pointInteraction.on('drawstart', (e) => {
      if (this.map.getView().getZoom() < 15) {
        this.toastr.clear();
        this.toastr.warning('Please ZOOM IN further to point a location.');
        return e.target.abortDrawing();
      } else {
        this.vectorSource.clear();
        const newValue = this.geoJSON.writeFeatures([e.feature]);
        const val = JSON.parse(newValue).features[0].geometry;
        this.geoJSONObj.push(val);
        this.formField.formControl.patchValue(this.geoJSONObj,{onlySelf: true});
        this.setValue(this.geoJSONObj);
      }
    });

    this.map.addInteraction(this.pointInteraction);
    this.setValue(this.formField.value);

  }

  ngOnDestroy(): void {
    if (this.$fieldValueChanges) {
      this.$fieldValueChanges.unsubscribe();
    }
  }

  private setValue(newValue: any) {
    this.vectorSource.clear();
    if (!newValue) {
      return;
    }
    let feature;
    newValue.forEach(element => {
      feature = new Feature({
        geometry: new Point(element.coordinates),
        // name: 'Home location',
      });
      this.vectorSource.addFeatures([feature]);
      if (this.formField.value) {
        this.setCenter(feature);
      }
    });
  }

  private setCenter(feature: Feature) {
    // allow edit if no value or undefined
    if (!feature && this.formField.editable !== false) {
      this.unfreezeMap();
      this.map.updateSize();
      return;
    }
    // this.vectorSource.clear();
    let centerCoordinates = fromLonLat(this.DEFAULT_CENTER);
    if (feature) {
      // @ts-ignore
      centerCoordinates = feature.getGeometry().getCoordinates();
    }
    this.map.getView().setCenter(centerCoordinates);
    this.map.getView().setZoom(this.DEFAULT_ZOOM_WITH_VALUE);
    // disable edit if value is set
    this.freezeMap();
    this.map.updateSize();
  }

  private freezeMap() {
    this.mapFrozen = true;
    setTimeout(() => {
      if (!this.allInteractions) {
        this.allInteractions = [];
        // just put it in another array because removing from here will modify `this.map.getInteractions()` and mess up the loop
        this.map.getInteractions().forEach(each => {
          this.allInteractions.push(each);
        });
      }
      // we can now safely remove here because we aren't modifying the original `this.map.getInteractions()`
      for (const i of this.allInteractions) {
        this.map.removeInteraction(i);
      }
      if (!this.allControls) {
        this.allControls = [];
        this.map.getControls().forEach(each => {
          this.allControls.push(each);
        });
      }
      for (const c of this.allControls) {
        this.map.removeControl(c);
      }
      this.map.updateSize();
    });
  }

  private unfreezeMap() {
    this.mapFrozen = false;
    setTimeout(() => {
      if (this.allInteractions) {
        for (const i of this.allInteractions) {
          this.map.addInteraction(i);
        }
      }
      if (this.allControls) {
        for (const c of this.allControls) {
          this.map.addControl(c);
        }
      }
      this.map.updateSize();
    });
  }

}

export class LadMultipleLocation extends LadField<LadGeoJSONObject[]> {

  constructor(options: LadFieldOptions<LadGeoJSONObject[]>) {
    super(options);
    this.__componentType = LadMultipleLocationComponent;
    this.columnClass = 'col-md-12';
  }
}
