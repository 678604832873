
export const LISTITEMS: any[] = [];
export const LISTITEMSx: any[] = [
        {'url': '/dashboard/dashboard1', 'name': 'Dashboard 1', 'icon': 'ft-home'},
        {'url': '/dashboard/dashboard2', 'name': 'Dashboard 2', 'icon': 'ft-home'},
        {'url': '/uikit/colorpalettes', 'name': 'Color Palette', 'icon': 'ft-droplet'},
        {'url': '/inbox', 'name': 'Email', 'icon': 'ft-mail'},
        {'url': '/chat', 'name': 'Chat', 'icon': 'ft-message-square'},
        {'url': '/taskboard', 'name': 'Task Board', 'icon': 'ft-file-text'},
        {'url': '/calendar', 'name': 'Calendar', 'icon': 'ft-calendar'},
        {'url': '/uikit/grids', 'name': 'Grid', 'icon': 'ft-grid'},
        {'url': '/uikit/typography', 'name': 'Typography', 'icon': 'ft-type'},
        {'url': '/uikit/syntaxhighlighter', 'name': 'Syntax Highlighter', 'icon': 'ft-edit-1'},
        {'url': '/uikit/helperclasses', 'name': 'Helper Classes', 'icon': 'ft-help-circle'},
        {'url': '/uikit/textutilities', 'name': 'Text Utilities', 'icon': 'ft-underline'},
        {'url': '/uikit/feather', 'name': 'Feather Icon', 'icon': 'ft-feather'},
        {'url': '/uikit/font-awesome', 'name': 'Font Awesome Icon', 'icon': 'ft-flag'},
        {'url': '/uikit/simple-line', 'name': 'Simple Line Icon', 'icon': 'ft-navigation'},
        {'url': '/components/buttons', 'name': 'Buttons', 'icon': 'ft-plus-circle'},
        {'url': '/components/alerts', 'name': 'Alerts', 'icon': 'ft-alert-triangle'},
        {'url': '/components/badges', 'name': 'Badges', 'icon': 'ft-square'},
        {'url': '/components/dropdowns', 'name': 'Dropdowns', 'icon': 'ft-pocket'},
        {'url': '/components/media', 'name': 'Media Objects', 'icon': 'ft-image'},
        {'url': '/components/pagination', 'name': 'Pagination', 'icon': 'ft-chevrons-right'},
        {'url': '/components/progress', 'name': 'Progress Bars', 'icon': 'ft-sliders'},
        {'url': '/components/models', 'name': 'Modals', 'icon': 'ft-layout'},
        {'url': '/components/collapse', 'name': 'Collapse', 'icon': 'ft-minimize'},
        {'url': '/components/lists', 'name': 'List Group', 'icon': 'ft-list'},
        {'url': '/components/accordion', 'name': 'Accordion', 'icon': 'ft-map'},
        {'url': '/components/carousel', 'name': 'Carousel', 'icon': 'ft-map'},
        {'url': '/forms/datepicker', 'name': 'Datepicker', 'icon': 'ft-calendar'},
        {'url': '/components/popover', 'name': 'Popover', 'icon': 'ft-message-square'},
        {'url': '/components/rating', 'name': 'Rating', 'icon': 'ft-award'},
        {'url': '/tables/tables', 'name': 'Tables', 'icon': 'ft-align-justify'},
        {'url': '/components/navs', 'name': 'Navs', 'icon': 'ft-server'},
        {'url': '/forms/timepicker', 'name': 'Timepicker', 'icon': 'ft-clock'},
        {'url': '/components/tooltip', 'name': 'Tooltip', 'icon': 'ft-message-square'},
        {'url': '/components/typeahead', 'name': 'Typeahead', 'icon': 'ft-type'},
        {'url': '/components/sweetalerts', 'name': 'Sweet Alert', 'icon': 'ft-alert-triangle'},
        {'url': '/components/toastr', 'name': 'Toastr', 'icon': 'ft-map'},
        {'url': '/forms/select', 'name': 'Select', 'icon': 'ft-chevrons-down'},
        {'url': '/components/nouislider', 'name': 'NoUI Slider', 'icon': 'ft-sliders'},
        {'url': '/forms/editor', 'name': 'Editor', 'icon': 'ft-edit'},
        {'url': '/components/upload', 'name': 'Upload', 'icon': 'ft-upload'},
        {'url': '/components/dragndrop', 'name': 'Drag and Drop', 'icon': 'ft-copy'},
        {'url': '/components/tour', 'name': 'Tour', 'icon': 'ft-send'},
        {'url': '/components/cropper', 'name': 'Image Cropper', 'icon': 'ft-crop'},
        {'url': '/forms/tags', 'name': 'Input Tags', 'icon': 'ft-tag'},
        {'url': '/forms/switch', 'name': 'Switch', 'icon': 'ft-toggle-left'},
        {'url': '/forms/inputs', 'name': 'Form Inputs', 'icon': 'ft-file-text'},
        {'url': '/forms/input-groups', 'name': 'Form Input Groups', 'icon': 'ft-book-open'},
        {'url': '/forms/radio', 'name': 'Radio', 'icon': 'ft-target'},
        {'url': '/forms/checkbox', 'name': 'Checkbox', 'icon': 'ft-check-square'},
        {'url': '/forms/layout', 'name': 'Form Layouts', 'icon': 'ft-layout'},
        {'url': '/forms/validation', 'name': 'Form Validation', 'icon': 'ft-check-circle'},
        {'url': '/forms/archwizard', 'name': 'Form Wizard', 'icon': 'ft-layers'},
        {'url': '/tables/basic', 'name': 'Basic Tables', 'icon': 'ft-sidebar'},
        {'url': '/tables/extended', 'name': 'Extended Tables', 'icon': 'ft-grid'},
        {'url': '/datatables', 'name': 'DataTables', 'icon': 'ft-layout'},
        {'url': '/cards/basic', 'name': 'Basic Cards', 'icon': 'ft-layers'},
        {'url': '/cards/advanced', 'name': 'Advanced Cards', 'icon': 'ft-grid'},
        {'url': '/maps/google', 'name': 'Maps', 'icon': 'ft-map'},
        {'url': '/charts/ngx', 'name': 'NGX Charts', 'icon': 'ft-bar-chart-2'},
        {'url': '/charts/chartjs', 'name': 'ChartJs', 'icon': 'ft-pie-chart'},
        {'url': '/charts/chartist', 'name': 'Chartist', 'icon': 'ft-bar-chart-line'},
        {'url': '/charts/apex', 'name': 'Apex', 'icon': 'ft-bar-chart-line'},
        {'url': '/pages/forgotpassword', 'name': 'Forgot Password', 'icon': 'ft-alert-octagon'},
        {'url': '/pages/login', 'name': 'Login', 'icon': 'ft-log-in'},
        {'url': '/pages/register', 'name': 'Register', 'icon': 'ft-user-plus'},
        {'url': '/pages/lockscreen', 'name': 'Lock Screen', 'icon': 'ft-lock'},
        {'url': '/pages/horizontaltimeline', 'name': 'Horizontal Timeline', 'icon': 'ft-credit-card'},
        {'url': '/pages/timeline-vertical-center', 'name': 'Vertical Timeline Center', 'icon': 'ft-sidebar'},
        {'url': '/pages/timeline-vertical-left', 'name': 'Vertical Timeline Left', 'icon': 'ft-sidebar'},
        {'url': '/pages/timeline-vertical-right', 'name': 'Vertical Timeline Right', 'icon': 'ft-sidebar'},
        {'url': '/pages/account-settings', 'name': 'Account Settings', 'icon': 'ft-settings'},
        {'url': '/pages/profile', 'name': 'User Profile', 'icon': 'ft-user'},
        {'url': '/pages/invoice', 'name': 'Invoice', 'icon': 'ft-file-text'},
        {'url': '/pages/error', 'name': 'Error', 'icon': 'ft-alert-triangle'},
        {'url': '/pages/comingsoon', 'name': 'Coming Soon', 'icon': 'ft-clock'},
        {'url': '/pages/maintenance', 'name': 'Maintenance', 'icon': 'ft-settings'},
        {'url': '/pages/gallery', 'name': 'Gallery', 'icon': 'ft-image'},
        {'url': '/pages/search', 'name': 'Search', 'icon': 'ft-search'},
        {'url': '/pages/faq', 'name': 'FAQ', 'icon': 'ft-help-circle'},
        {'url': '/pages/kb', 'name': 'Knowledge Base', 'icon': 'ft-info'},
        {'url': '/pages/users-list', 'name': 'Users List', 'icon': 'ft-search'},
        {'url': '/pages/users-view', 'name': 'Users View', 'icon': 'ft-search'},
        {'url': '/pages/users-edit', 'name': 'Users Edit', 'icon': 'ft-search'}
    ];

