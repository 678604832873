import {Component, Input, OnInit} from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {BlackNumberSummary} from '../../models/black-number-summary';
// @ts-ignore
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-game-sheet-pdf-template',
  templateUrl: './game-sheet-pdf-template.component.html',
  styleUrls: ['./game-sheet-pdf-template.component.scss']
})
export class GameSheetPdfTemplateComponent implements OnInit {

  // @Input() data[];
  @Input() blackNumberSummary: BlackNumberSummary[];

  // numberSummary: { combination: number, amount: number }[] = [];
  numberSummary: { combination: number, amount: number }[] = [];
  totalCombination = 100;
  columns = 4;
  rows = 25;
  data = [];
  total: number = 0;

  constructor() { }

  ngOnInit(): void {
    this.getBets();
    this.blackNumberSummary = this.sampleData;
    this.tableData();
  }

  generatePdf() {
    // @ts-ignore
    pdfMake.createPdf(this.getDocument()).print();
  }

  getDocument() {
    return {
      info: {
        title: 'Black Game Sheet'
      },
      pageSize: 'A4',
      content: [
        {
          table: {
            widths: ['*'],
            body: [
              [{
                text: 'GAME SHEET',
                bold: true,
                alignment: 'center',
                fontSize: 16
              }]
            ]
          }, layout: 'noBorders'
        },
        { text: ' ', style: { lineHeight: 2.5 } },
        {
          table: {
            widths: [40, 60, '*', 40, 60, '*', 40, 60, '*', 40, 60],
            body: [
              [
                { text: 'No.', style: 'tableFont',fillColor: 'lightgrey' },
                { text: 'Amount', style: ['tableFont', 'tblAlignR'],fillColor: 'lightgrey' },
                { text: '', border: [false, false, false, false] },
                { text: 'No.', style: 'tableFont',fillColor: 'lightgrey' },
                { text: 'Amount', style: ['tableFont', 'tblAlignR'],fillColor: 'lightgrey' },
                { text: '', border: [false, false, false, false] },
                { text: 'No.', style: 'tableFont',fillColor: 'lightgrey' },
                { text: 'Amount', style: ['tableFont', 'tblAlignR'],fillColor: 'lightgrey' },
                { text: '', border: [false, false, false, false] },
                { text: 'No.', style: 'tableFont',fillColor: 'lightgrey' },
                { text: 'Amount', style: ['tableFont', 'tblAlignR'],fillColor: 'lightgrey' }
              ],
              // this.tableData()
              ...this.colRow().map((val, i) => {
                let array = [];
                if(i<this.rows){
                  array = [
                    { text: this.data[0][i].combination, style: 'tableFont' },
                    { text: this.data[0][i].amount, style: ['sizeFont', 'tblAlignR'] },
                    { text: '', border: [false, false, false, false] },
                    { text: this.data[1][i].combination, style: 'tableFont' },
                    { text: this.data[1][i].amount, style: ['sizeFont', 'tblAlignR'] },
                    { text: '', border: [false, false, false, false] },
                    { text: this.data[2][i].combination, style: 'tableFont' },
                    { text: this.data[2][i].amount, style: ['sizeFont', 'tblAlignR'] },
                    { text: '', border: [false, false, false, false] },
                    { text: this.data[3][i].combination, style: 'tableFont' },
                    { text: this.data[3][i].amount, style: ['sizeFont', 'tblAlignR'] }
                  ]
                } else {
                  array = [
                    { text: this.data[0][i].combination, style: 'sizeFont',fillColor: 'lightgrey' },
                    { text: this.currency(this.data[0][i].amount), style: ['tableFont', 'tblAlignR'],fillColor: 'lightgrey' },
                    { text: '', border: [false, false, false, false] },
                    { text: this.data[1][i].combination, style: 'sizeFont',fillColor: 'lightgrey' },
                    { text: this.currency(this.data[1][i].amount), style: ['tableFont', 'tblAlignR'],fillColor: 'lightgrey' },
                    { text: '', border: [false, false, false, false] },
                    { text: this.data[2][i].combination, style: 'sizeFont',fillColor: 'lightgrey' },
                    { text: this.currency(this.data[2][i].amount), style: ['tableFont', 'tblAlignR'],fillColor: 'lightgrey' },
                    { text: '', border: [false, false, false, false] },
                    { text: this.data[3][i].combination, style: 'sizeFont',fillColor: 'lightgrey' },
                    { text: this.currency(this.data[3][i].amount), style: ['tableFont', 'tblAlignR'],fillColor: 'lightgrey' }
                  ]
                }
                return array;
              })
            ]
          }, layout: { vLineColor: function (i) {return 'lightgrey';}, vLineWidth: function (i, node) { return 1.5; }, hLineColor: function (i) {return 'lightgrey';}, hLineWidth: function (i, node) { return 1.5} }
        },
        { text: ' ', style: { lineHeight: 2 } },
        {
          table: {
            widths: ['*'],
            body: [
              [{
                text: 'Total: ' + this.currency(this.total, true)?.toLocaleString(),
                bold: true,
                alignment: 'right',
                fontSize: 14
              }]
            ]
          }, layout: 'noBorders'
        }
      ],
      styles: {
        tableFont: {
          fontSize: 11,
          bold: true
        },
        sizeFont: {
          fontSize: 11
        },
        tblAlignR: {
          alignment: 'right'
        }
      }
    }
  }

  colRow(){
    const array = [];
    for (let r=0; r<=this.rows; r++) {
      array.push(r);
    }
    return array;
  }

  tableData(){
    let summary = [];
    this.numberSummary.map(v => {
      let amount;
      let prop = this.blackNumberSummary.find(val => val.combination === v.combination);
      if(v.amount || v.amount === 0){
        amount =  prop?.total ? prop?.total : v?.amount;
      }
      summary.push({combination: v.combination, amount: amount});
    });
    function chunkArray(arr, chunkSize) {
      const chunks = [];
      for (let i=0; i<arr.length; i+=chunkSize) {
        chunks.push(arr.slice(i, i + chunkSize));
      }
      return chunks;
    }
    const chunks = chunkArray(summary, this.rows);
    const summaryColumns = [[], [], [], []];
    chunks.forEach((item, index) => {
      summaryColumns[index % this.columns].push(...item);
    });
    summaryColumns.map(cols => {
      let subTotal = 0;
      cols.forEach(bets => {
        subTotal += bets.amount;
        bets.combination = bets.combination < 10 ? '0' + bets.combination?.toLocaleString() : bets.combination?.toLocaleString();
        bets.amount = bets.amount?.toLocaleString();
      });
      this.total += subTotal;
      cols.push({combination: 'Total', amount: subTotal});
    });
    this.data = summaryColumns;
  }

  getBets(){
    for (let i = 0; i < this.totalCombination; i++) {
      this.numberSummary.push({combination: i, amount: 0});
    }
  }

  currency(amount:number, sign?){
    let addSign;
    if(sign){
      addSign = {
        style: 'currency',
        currency: 'PHP'
      };
    }
    return amount.toLocaleString('en-US', {
      minimumIntegerDigits: 1,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...addSign
    });
  }

  sampleData: any = [
    {
      "date" : {
        "year" : 2023,
        "month" : 8,
        "day" : 6
      },
      "draw" : "d1",
      "raffle" : "l2",
      "combination" : 0,
      "total" : 1000
    },//0-10
    {"combination" : 1, "total" : 60}, //1-60
    {"combination" : 2, "total" : 15},  //2-15
    {"combination" : 10, "total" : 20}, //10-20
    {"combination" : 25, "total" : 20}, //25-20
    {"combination" : 50, "total" : 5},  //50-5
    {"combination" : 60, "total" : 30}, //60-30
    {"combination" : 71, "total" : 80}, //71-80
    {"combination" : 75, "total" : 75}, //75-75
    {"combination" : 90, "total" : 100},//90-100
    {"combination" : 99, "total" : 200},//99-200
  ];

}
