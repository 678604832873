import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {GameSheetItem} from '../../../models/black-number-summary';
import {GameSheetItemDetailsDialogComponent} from '../game-sheet-item-details-dialog-component/game-sheet-item-details-dialog.component';

export interface DottedTableCols {
  isNumber?: boolean;
  fieldName: string;
  style?: { [key: string]: string };
}

export class DottedTableSettings {
  cols: DottedTableCols[] = [];
  rows? = 25;
  items: any[] = [];
  onItemClick?: (item: any) => Promise<void>
}

@Component({
  selector: 'app-dotted-table',
  templateUrl: './dotted-table.component.html',
  styleUrls: ['./dotted-table.component.scss']
})
export class DottedTableComponent implements OnInit {

  @Input()
  settings: DottedTableSettings;
  renderItems = [];

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.setItems(this.settings.items);
  }

  protected readonly JSON = JSON;

  async onItemClick(i: any) {
    if (this.settings?.onItemClick) {
      await this.settings.onItemClick(i);
    }
  }

  toRows(items, rowLength = 10) {
    const output = [];
    for (let i = 0; i < rowLength; i++) {
      for (let x = i; x < items.length; x = x + rowLength) {
        if (!output[i]) output[i] = [];
        output[i].push(items[x]);
      }
    }
    return output;
  }

  public setItems(items = []) {
    if (items) {
      this.settings.items = items;
    }
    this.renderItems = this.toRows(this.settings.items, this.settings.rows);
    this.cd.detectChanges();
  }

}
