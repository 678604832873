import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Draw, Raffle, RaffleSettings} from '../../models/utils/number-helper';
import {BlackStatus} from '../../models/utils/black-utils';
import {LadField, LadFieldOptions} from '../../../../app/dynamic-form/models/lad-field';
import {LadComponentBase} from '../../../../app/dynamic-form/models/lad-component-base';
import {DynamicFormService} from '../../../../app/dynamic-form/dynamic-form.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {
  MODE_COMBINATION_EDIT, MODE_RAFFLE_EDIT,
  RaffleSettingsEditorDialogComponent
} from './raffle-settings-editor-dialog/raffle-settings-editor-dialog.component';


export interface RaffleSettingsEditorOptions extends LadFieldOptions<any> {
  mode?: 'win' | 'commission' | 'maxbet';
}

export class RaffleSettingsEditor extends LadField<any > {
  excludeAtSave = true;
  mode?: 'win' | 'commission' | 'maxbet';
  constructor(options: RaffleSettingsEditorOptions) {
    super(options);
    if (!this.mode) {
      this.mode = 'maxbet';
    }
    this.__componentType = RaffleSettingsEditorComponent;
  }
}

@Component({
  selector: 'app-raffle-settings-editor',
  templateUrl: './raffle-settings-editor.component.html',
  styleUrls: ['./raffle-settings-editor.component.scss']
})
export class RaffleSettingsEditorComponent extends LadComponentBase implements OnInit {

  @Input()
  formField: RaffleSettingsEditor;

  drawValues = Object.values(Draw);
  selectedDraw: Draw = Draw.ALL;

  // no raffle all
  raffleValues = Object.values(Raffle).filter(e => e !== Raffle.ALL);
  selectedRaffle: Raffle = Raffle.l2;

  amountLabel = 'Amount';

  constructor(
    private cd: ChangeDetectorRef,
    private dfs: DynamicFormService,
    private modalService: NgbModal
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    switch (this.formField.mode) {
      case 'commission':
        this.amountLabel = 'Percent';
        break;
    }
  }

  protected readonly BlackStatus = BlackStatus;

  async addNewRow() {
    const row = new RaffleSettings();
    row.draw = this.selectedDraw;
    row.raffleId = this.selectedRaffle;
    row.value = 0;

    const v = this.formField.value;
    const arr = Array.isArray(v) ? v : v[this.selectedDraw][this.selectedRaffle];
    const r = await this._editRaffleSettingsItem(arr.length, row);
    if (r === 'ok') {
      arr.push(row);
      this.formGroup.markAsDirty();
      this.cd.markForCheck();
    }
  }

  async editRow(index, i: RaffleSettings) {
    const r = await this._editRaffleSettingsItem(index, i);
    if (r === 'delete') { // delete
      const arr = this.formField.value[this.selectedDraw][this.selectedRaffle];
      arr.splice(index, 1)
    }
    this.formGroup.markAsDirty();
    this.cd.markForCheck();
  }

  async _editRaffleSettingsItem(index, i: RaffleSettings) {
    const ref = this.modalService.open(RaffleSettingsEditorDialogComponent);
    ref.componentInstance.toEdit = i;
    ref.componentInstance.itemIndex = index + 1;
    ref.componentInstance.mode = this.formField.mode === 'maxbet' ? MODE_COMBINATION_EDIT : MODE_RAFFLE_EDIT;

    let label = 'Amount';
    switch (this.formField.mode) {
      case 'commission':
        label = 'Percent';
        break;
      case 'win':
        label = 'Prize';
    }
    ref.componentInstance.valueLabel = label;
    try {
      return await ref.result; // ok or delete
    } catch (e) {
    }
    return null;
  }

  onRaffleChange() {
  }

  onDrawChange() {

  }

  protected readonly RaffleSettings = RaffleSettings;
}
