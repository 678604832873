
import {Raffle} from './utils/number-helper';

// individual bet
export class BlackTransactionItem {

  public id: string; // the index of this bet when stored inside the transaction.bets array
  public index: number;

  public unitPrize: number;
  public prize: number;

  public printUnitPrize: number;
  public printPrize: number;

  public weight: number; //how much this is contributing to the limit; s3 = amount; s3r = amount/possible-combination;
  public numberOfCombinations: number;

  public cancelled?: boolean;

  constructor(
    public raffle: Raffle,
    public combination: number,
    public amount: number
  ) {
    this.weight = amount; // todo: calculate based on raffle
    this.unitPrize = 70;  // todo: fetch from agent/coordinator/global profile
    this.prize = this.unitPrize * this.weight;
    // display para printer
    this.printUnitPrize = 65;
    this.printPrize = this.printUnitPrize * this.weight;
  }

}


