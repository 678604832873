<div [formGroup]="formGroup" class="form-group">
  <label *ngIf="formField.label" [attr.for]="formField.key" [class]="formField.labelClass">
    {{formField.label}}
    <a *ngIf="!isValid" class="ft-info font-small-3 blinking"></a>
  </label>
  <ul class="list-unstyled mb-0">
    <li *ngFor="let item of formField.items; let i = index;" class="d-inline-block mr-2">
      <div class="radio">
        <input
               [formControlName]="formField.key"
               [id]="formField.key + '_' + i"
               [name]="formField.key"
               [value]="item.value"
               [checked]="item.value === formField.formControl.value"
               (change)="onValueChange($event)"
               type="radio">
        <label [for]="formField.key + '_' + i">{{item.label}}</label>
      </div>
    </li>
  </ul>

  <code *ngIf="!isValid" class="font-small-1 mr-1">*Invalid!</code>
</div>
