import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BbHandsontable} from '../../../../app/dynamic-form/components/bb-handsontable/bb-handsontable.component';
import {BlackGameSheet} from '../../models/black-game-sheet';
import {LadInput} from '../../../../app/dynamic-form/components/lad-input/lad-input.component';
import {ActivatedRoute} from '@angular/router';
import {DynamicFormService} from '../../../../app/dynamic-form/dynamic-form.service';
import {createRaffleSettingsTable} from '../../models/utils/raffle-settings-table-helper';

@Component({
  selector: 'app-game-sheet-editor',
  templateUrl: './game-sheet-editor.component.html',
  styleUrls: ['./game-sheet-editor.component.scss']
})
export class GameSheetEditorComponent implements OnInit {
  targetDocument;
  dynamicFields;
  showDelete = false;

  constructor(
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private df: DynamicFormService
  ) { }

  async ngOnInit() {
    const id = this.route.snapshot.queryParamMap.get('id');
    if (id) {
      this.targetDocument = await BlackGameSheet.getGameSheetById({id});
      this.showDelete = true;
    } else {
      this.targetDocument = new BlackGameSheet();
      this.targetDocument.systemHeader.isNew = true;
    }
    console.log(this.targetDocument);
    this.dynamicFields = {
      name: new LadInput({
        row: 'row0',
        label: 'Game Sheet Name',
        value: this.targetDocument.name,
        required: true,
        columnClass: 'col-md-4', // default is col-md-3
        inputClass: 'text-uppercase',
      }),
      limits: new BbHandsontable({
        row: 'row2',
        label: 'Cut-off Amount',
        columnClass: 'col-md-6',
        gridSettings: async () => {
          return createRaffleSettingsTable(this.targetDocument.limits, {valueTitle: 'Amount', noDrawCol: true});
        }
      }),
    };
    this.cd.markForCheck();
  }

  onDFormInitDone() {
  }

  onDelete() {
    this.df.fireSwalConfirm('Confirm delete gamesheet?', 'warning');
  }

  postSave = (result) => {
    this.df.navigateToSamePageWithQuery({id: result.response.documentId});
  }
}
