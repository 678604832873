import {Component, Input, OnInit} from '@angular/core';
import {LadField, LadFieldOptions} from '../../models/lad-field';
import {LadComponentBase} from '../../models/lad-component-base';

@Component({
  selector: 'app-lad-radio',
  templateUrl: './lad-radio.component.html',
  styleUrls: ['./lad-radio.component.scss']
})
export class LadRadioComponent extends LadComponentBase implements OnInit {

  @Input()
  public formField: LadRadio;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onValueChange($event: any) {
    this.formField.formControl.setValue(this.formField.formControl.value,{onlySelf: true}); // fire change seq
  }
}

export interface LadRadioItems {
  value: any,
  label: string,
  disabled?: boolean,
}

export interface LadRadioOptions extends LadFieldOptions<any> {
  items: LadRadioItems[];
}

export class LadRadio extends LadField<any> {

  items: LadRadioItems[];

  constructor(options: LadRadioOptions) {
    super(options);
    this.__componentType = LadRadioComponent;
    this.items = this.items ? this.items : [];
  }

}

