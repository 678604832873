<form (ngSubmit)="submit()" [class.disabled]="disableForm" [formGroup]="form" class="form">
  <div *ngFor="let field of formFieldsArray" class="row">
    <ng-container *ngFor="let subQuestion of field">
      <div [class.d-none]="subQuestion.visible === false"
           [formField]="subQuestion"
           [form]="form"
           [ngClass]="subQuestion.columnClass"
           lad-dynamic-field>
      </div>
    </ng-container>
  </div>
  <div *ngIf="submitType === 'button'" class="form-actions right">
    <!--          <button type="button" class="btn btn-raised btn-warning mr-1" (click)="onReset()">-->
    <!--            <i class="ft-x"></i> Reset-->
    <!--          </button>-->
    <!--          [disabled]="!form.valid"-->
    <button [disabled]="!form.valid" class="btn btn-raised btn-primary" type="submit">
      <i [ngClass]="submitIcon ? submitIcon : 'fa fa-check-square-o'"></i> {{submitLabel ? submitLabel : 'Save'}}
    </button>
  </div>
</form>
