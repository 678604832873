import {Router} from '@angular/router';
import {Injectable} from '@angular/core';

import {LadAccountModel} from 'ladrov-commons';
import {APIService} from '../backend/api.service';
import {BehaviorSubject} from 'rxjs';
import {Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public userAccountSubject = new BehaviorSubject<LadAccountModel>(null);
  public loggedUserMainPage = '/pages/login';

  constructor(
    public router: Router,
    private api: APIService,
    private titleService: Title
  ) {
    // dummy account before any initial
    // const dummy = new LadAccountModel();
    // dummy.documentId = 'dummy';
    this.userAccountSubject.next(null);
    this.setupLoggedUser();
    this.setupAppTitle();
  }

  async setupLoggedUser() {
    const logged = localStorage.getItem('loggedUser');
    if (logged) {
      this.loggedUserMainPage = localStorage.getItem('loggedUserMainPage');
      const storedAccount = (JSON.parse(logged) as LadAccountModel);
      // @ts-ignore
      storedAccount.systemHeader.__fromLocalStorage = true;
      this.userAccountSubject.next(storedAccount);
      try {
        const res: any = await this.api.loggedIn().toPromise();
        const loggedInAccount = (res.user as LadAccountModel);
        if (storedAccount.documentId !== loggedInAccount.documentId) {
          this.userAccountSubject.next(null);
          this.logout();
        }
        this.userAccountSubject.next(loggedInAccount);
      } catch (err) {
        this.userAccountSubject.next(null);
        console.log(err);
        this.logout();
      }
    }
  }

  async setupAppTitle() {
    const cSettings = await this.api.getClientSettings();
    this.titleService.setTitle(cSettings.appTitle ? cSettings.appTitle : 'BackBone.PH');
  }

  setLoggedUser(user: LadAccountModel) {
    this.userAccountSubject.next(user);
    localStorage.setItem('loggedUser', JSON.stringify(user));
  }

  async signinUser(email: string, password?: string) {
    const result: any = await this.api.login(email, password).toPromise();
    this.setLoggedUser(result.user);
    this.loggedUserMainPage = result.mainPage;
    localStorage.setItem('loggedUserMainPage', result.mainPage);
    return result.mainPage;
  }

  async signinFbUser(fbUser) {
    const result: any = await this.api.signin3rdPartyUser(fbUser).toPromise();
    this.setLoggedUser(result.user);
    this.loggedUserMainPage = result.mainPage;
    localStorage.setItem('loggedUserMainPage', result.mainPage);
    return result.mainPage;
  }

  async logout(noNavigate?: boolean) {
    await this.api.logout();
    this.userAccountSubject.next(null);
    localStorage.removeItem('loggedUser');
    localStorage.removeItem('loggedUserMainPage');
    if (!noNavigate) {
      this.router.navigate([''], {replaceUrl: true});
    }
  }

  isAuthenticated() {
    return this.userAccountSubject.getValue() != null;
  }

  async signup(username, mobileNo, pass, otp) {
    return Promise.all(
      [
        this.api.signup(username, mobileNo, pass, otp).toPromise()
      ]
    );
  }
}
