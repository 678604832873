<div [formGroup]="formGroup" class="form-group">
  <code *ngIf="!isValid" class="font-small-1 mr-1">*</code>
  <label *ngIf="formField.label" [attr.for]="formField.key" [class]="formField.labelClass">
    {{formField.label}}
    <a *ngIf="!isValid" class="ft-info font-small-3 blinking"></a>
  </label>
  <select (change)="onSelectedItemChanged($event)" [formControlName]="formField.key" [id]="formField.key"
          [name]="formField.key" class="form-control">
    <ng-container *ngFor="let item of formField.items">
      <option *ngIf="item.visible !== false" [value]="item.value">{{item.label}}</option>
    </ng-container>
  </select>
</div>
