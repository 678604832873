import { Component, OnInit } from '@angular/core';
import {RaffleSettings} from '../../../models/utils/number-helper';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {BLACK_DRAW_ARR, BLACK_RAFFLE_ARR} from '../../../models/utils/black-utils';

export const MODE_RAFFLE_EDIT = 2;
export const MODE_COMBINATION_EDIT = 1;

@Component({
  selector: 'app-raffle-settings-editor-dialog',
  templateUrl: './raffle-settings-editor-dialog.component.html',
  styleUrls: ['./raffle-settings-editor-dialog.component.scss']
})
export class RaffleSettingsEditorDialogComponent implements OnInit {

  toEdit: RaffleSettings;
  itemIndex: number;
  draws = BLACK_DRAW_ARR;
  raffles = BLACK_RAFFLE_ARR;

  mode = MODE_COMBINATION_EDIT;

  valueLabel = 'Value';
  MODE_RAFFLE_EDIT = MODE_RAFFLE_EDIT;
  MODE_COMBINATION_EDIT = MODE_COMBINATION_EDIT;


  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  onCombinationUpdate() {
    try {
      RaffleSettings.validateCombination(this.toEdit);
      this.toEdit.combinationError = null;
    } catch (e) {
      this.toEdit.combinationError = e.message;
    }
  }
}
