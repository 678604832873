<div class="modal-header">
  <h4 class="modal-title">{{targetDocument.name || 'New Area' | uppercase}}</h4>
  <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
    x
  </button>
</div>
<div class="modal-body">
  <div class="px-3" *ngIf="targetDocument">
    <lad-dynamic-form #dynamicForm
                      [dynamicFields]="dynamicFields"
                      (onInitDone)="dfInitDone()"
                      [submitType]="'none'"
                      [allowDelete]="false"
                      [targetDocument]="targetDocument">
    </lad-dynamic-form>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary btn-warning mr-auto" (click)="onCancelDelete()">{{isNew ? 'Cancel' : 'Delete'}}</button>
  <button type="button" class="btn btn-primary ml-auto" (click)="onClose()">OK</button>
</div>


