<section class="invoiceSection">
  <div [@animImageSlider]="currentTemplate" class="d-flex flex-grow-1 h-100 billingContainer position-relative">
    <ng-template [ngIf]="currentTemplate===0 && !confirmOrder">
      <div class="menuContainer d-flex flex-column m-0 w-100 bg-transparent shadow-none position-relative"
           style="min-height:inherit">
        <div class="d-flex p-0 mt-1 mb-2">
          <div class="d-flex pl-3 align-items-center">

            <div class="d-flex flex-wrap">
              <h5 class="m-0">{{selectedItems.length}} {{selectedItems.length > 1 ? 'items' : 'item'}}&nbsp;</h5>
              <h5 *ngIf="menus.length" class="m-0">({{totalAmount() | currency:'PHP':'symbol-narrow':'1.1-1'}})</h5>
            </div>
          </div>
          <div class="ml-auto mr-1">
            <div>
              <button (click)="currentTemplate=1" class="btn btn-outline-primary btn-sm-2 mr-2" type="button">
                Checkout
              </button>
            </div>
          </div>
        </div>

        <div class="menuItems d-flex align-content-start flex-wrap justify-content-center p-2 pt-3"
             id="menuItemsContainer">
          <ng-container *ngIf="!menus.length">
            (Loading...)
          </ng-container>
          <div *ngFor="let menu of menus; " class="mb-2">
            <div class="position-relative mr-2" id="m{{menu.shortDocumentId}}">
              <div
                (click)="addItem(menu); isItemClicked=true"
                [ngClass]="{'active-menu-item': selectedItem === menu.documentId}"
                [ngStyle]="{ backgroundColor: menu.color? menu.color : defaultColor, filter: checkSelectedItem(menu.documentId)? 'brightness(0.85)':''}"
                class="menuItem d-flex justify-content-center align-items-center"
                onclick="event.stopPropagation()"
                style="mix-blend-mode: difference;">
                <p [ngStyle]="{color: menu.color == 'black' ? 'white' : 'black'}" class="m-0 font-small-3 icon-text">
                  {{menu.code | uppercase}} <br/> {{menu.unitPrice | number:'1.1-1'}}
                </p>
              </div>
              <button (click)="menuQty(menu.documentId)" *ngIf="getQuantity(menu.documentId)>0"
                      class="font-small-1 itemQty p-2 border-0 badge badge-danger badge-pill position-absolute"
                      onclick="event.stopPropagation()" type="button">
                {{getQuantity(menu.documentId)}}
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="isItemClicked" class="card m-0 card-shadow w-100" style="bottom: 0">
          <div class="d-flex mx-2">
            <div (click)="isClickedCDP=true" class="m-1">
              <h6 class="mb-0">{{itemClickedDetail?.code | uppercase}}</h6>
              <p class="mb-0" style="font-size: 12px">
                {{itemClickedDetail?.name}}
              </p>
              <h6 class="mb-0">{{itemClickedDetail?.unitPrice | number:'1.1-1'}}</h6>
            </div>
            <div class="ml-auto mr-3 my-1 d-flex flex-column align-items-end">
              <div class="btn-group mx-auto">
                <a (click)="qtyChange(selectedItem, 'minus')" class="btn btn-primary font-medium-4"
                   style="padding: 2px 11px">-</a>
                <input (input)="decimalInputValue($event); customQtyChange($event)" (blur)="blurQtyChange()" [(ngModel)]="itemSelectedQty"
                       class="text-center" inputmode="decimal" min="1"
                       style="font-size: 1.8rem; padding: 2px;width: 4.5rem;border-color: transparent;" type="number">
                <a (click)="qtyChange(selectedItem, 'add')" class="btn btn-primary font-medium-4"
                   style="padding: 2px 11px">+</a>
              </div>
              <button (click)="cancelItem(selectedItem)" *ngIf="isClickedCDP"
                      class="btn btn-outline-secondary btn-sm mt-auto mx-auto" type="button">Remove Item
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-template [ngIf]="currentTemplate===1">
      <div class="menuContainer d-flex flex-grow-1 card m-0 p-3 w-100 h-100 position-absolute">
          <div class="d-flex mb-2">
            <h5>Order Details</h5>
            <h6 (click)="currentTemplate=0" *ngIf="!confirmOrder" class="text-primary ml-auto mt-1">Add Items</h6>
          </div>

          <div class="position-relative table-responsive">
            <table class="align-items-stretch table summaryTable text-center m-0">
              <thead>
              <tr>
                <th style="text-align: start">Product</th>
                <th style="text-align: start">Unit Price</th>
                <th class="text-center" style="text-align: start">Qty</th>
                <th *ngIf="!confirmOrder"></th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of selectedItems; let i = index" class="menuItems">
                <td style="text-align: start">
                  {{getDetails(item.product.relatedDocumentId, 'code') | uppercase}}<br/>
                  {{getDetails(item.product.relatedDocumentId, 'name')}}<br/>
                  {{getDetails(item.product.relatedDocumentId, 'category')}}<br/>
                </td>
                <td style="text-align: start">
                  {{getDetails(item.product.relatedDocumentId, 'unitPrice') | number:'1.1-1'}}
                </td>
                <td class="text-center" style="text-align: start">
                  <div *ngIf="!confirmOrder" class="btn-group">
                    <a (click)="qtyChange(item.product.relatedDocumentId, 'minus')" class="btn btn-primary px-1 py-1 font-medium-5">-</a>
                    <a class="btn btn-primary px-1 py-1">{{item.quantity}}</a>
                    <a (click)="qtyChange(item.product.relatedDocumentId, 'add')" class="btn btn-primary px-1 py-1 font-medium-5">+</a>
                  </div>
                  <span *ngIf="confirmOrder">{{item.quantity}}</span>
                </td>
                <td *ngIf="!confirmOrder">
                  <a (click)="selectedItem = item.product.relatedDocumentId; cancelItem(selectedItem)" class="danger">
                    <i class="ft-trash font-medium-3">
                    </i>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        <div class="mt-auto">
          <div *ngIf="selectedItems.length" class="d-flex my-2">
            <div class="d-flex flex-column">
              <h6 class="m-0 my-auto font-weight-bold">TOTAL ({{selectedItems.length}} {{selectedItems.length > 1 ? 'items' : 'item'}})</h6>
              <h6 class="mt-1" *ngIf="confirmOrder">
                Cash<br/>
                Change
              </h6>
            </div>
            <div class="ml-auto d-flex flex-column align-items-end">
              <h5 class="mb-0 font-weight-bold">{{totalAmount() | currency:'PHP':'symbol-narrow':'1.1-1'}}</h5>
              <h6 class="mt-1 text-right" *ngIf="confirmOrder">
                {{cashAmount | number:'1.1-1'}}<br/>
                {{changeAmount | number:'1.1-1'}}
              </h6>
            </div>
          </div>
          <div *ngIf="selectedItems.length && !confirmOrder" class="d-flex">
            <div class="d-flex flex-column">
              <h6 class="mt-2">
                Cash ->
                <br/>
                Change: {{changeAmount | number:'1.1-1'}}
              </h6>
            </div>
            <div class="ml-auto d-flex flex-column align-items-end mb-3">
              <div class="btn-group mx-auto ">
                <a (click)="cashChange('minus', cashInput.value)" [class.disabled]="cashInput.value < 1"
                   class="btn btn-primary font-medium-4 " style="padding: 2px 11px;">-</a>
                <input class="text-center" inputmode="decimal" style="font-size: 1.0rem; padding: 2px; width: 4.5rem; border-color: transparent;"
                       type="number" min="1" #cashInput [value]="cashAmount" (input)="decimalInputValue($event); cashInputValue($event)"
                       (blur)="blurCashInput($event)">
                <a (click)="cashChange('add', cashInput.value)" class="btn btn-primary font-medium-4 " style="padding: 2px 11px;">+</a>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center">
            <button (click)="confirmAndSaveOrder()" *ngIf="!confirmOrder"
                    [disabled]="selectedItems.length<1"
                    class="btn btn-primary col-12 font-weight-bold">
              Checkout
            </button>
            <button (click)="printOrder()" *ngIf="confirmOrder" class="btn btn-primary mr-1 col-9 font-weight-bold">
              Print Order
            </button>
            <button (click)="reset()" *ngIf="confirmOrder" class="btn btn-danger col-3 font-weight-bold">Exit
              </button>
            </div>
          </div>
      </div>
    </ng-template>
  </div>
</section>
