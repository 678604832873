import { Component, OnInit } from '@angular/core';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
// @ts-ignore
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-game-summary-pdf-template',
  templateUrl: './game-summary-pdf-template.component.html',
  styleUrls: ['./game-summary-pdf-template.component.scss']
})
export class GameSummaryPdfTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  generatePdf() {
    // @ts-ignore
    pdfMake.createPdf(this.getDocument()).print();
  }

  getDocument() {
    return {
      info: {
        title: 'Game Summary'
      },
      pageSize: 'A4',
      content: [
        { text: 'Game Summary', style: { lineHeight: 2.5 } },
      ]
    }
  }

}
