import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavbarSearchService {

  private navbarSearchResult = new BehaviorSubject<NavbarSearchResultItem[]>(null);

  private client: (searchKey: string) => Promise<NavbarSearchResultItem[]> = null;

  constructor() {
  }

  // clients should implement their own debounceTime set
  setSearchClient(client: (searchKey: string) => Promise<NavbarSearchResultItem[]>) {
    this.client = client;
    this.navbarSearchResult.next([]);
  }

  removeSearchClient(client: (searchKey: string) => Promise<NavbarSearchResultItem[]>) {
    if (this.client === client) {
      this.client = null;
      this.navbarSearchResult.next([]);
    }
    console.log('removeSearchClient called');
  }

  async doSearch(key: string) {
    console.log('doSearch called');
    if (!this.client) {
      return;
    }
    const result = await this.client(key);
    this.navbarSearchResult.next(result);
  }

  getNavbarSearchResultRef() {
    return this.navbarSearchResult;
  }
}

export interface NavbarSearchResultItem {
  name: string;
  icon: string;
  onClick: () => Promise<any>
}
