
<h5>{{formField.label}}</h5>
<div class="row" *ngIf="formField.mode === 'maxbet'">
  <div class="col-md-12">
    <label  class="primary text-bold-500">
      Draw:
    </label>
    <ul  class="list-unstyled mb-0">
      <li  class="d-inline-block mr-2" *ngFor="let draw of drawValues">
        <div  class="radio">
          <input type="radio" [name]="formField.key + '-draw'" [id]="formField.key + '-' + draw + '-draw'" [value]="draw" [(ngModel)]="selectedDraw" (ngModelChange)="onDrawChange()">
          <label [for]="formField.key + '-' + draw + '-draw'">{{ draw }}</label>
        </div>
      </li>
    </ul>
  </div>
  <div class="col-md-12">
    <label  class="primary text-bold-500">
      Raffle:
    </label>
    <ul class="list-unstyled mb-0">
      <li  class="d-inline-block mr-2" *ngFor="let raffle of raffleValues">
        <div class="radio">
          <input type="radio" [name]="formField.key + '-raffle'" [id]="formField.key + '-' + raffle + '-raff'" [value]="raffle" [(ngModel)]="selectedRaffle" (ngModelChange)="onRaffleChange()">
          <label [for]="formField.key + '-' + raffle + '-raff'">{{ raffle }}</label>
        </div>
      </li>
    </ul>
  </div>
</div>

<div class="row">
  <div class="table-responsive">
    <table class="table table-striped table-bordered">
      <thead>
        <tr>
          <th class="font-weight-light" scope="col">#</th>
          <th class="font-weight-light" *ngIf="formField.mode !== 'maxbet'" scope="col">Raffle</th>
          <th class="font-weight-light" *ngIf="formField.mode === 'maxbet'" scope="col">Draw-Raffle</th>
          <th class="font-weight-light" *ngIf="formField.mode === 'maxbet'" scope="col">Combination</th>
          <th class="font-weight-light" scope="col">{{amountLabel}}</th>
        </tr>
      </thead>
      <tbody class="table-bordered">
      <ng-container *ngIf="formField.mode === 'maxbet'">
        <tr class="cursor-pointer" *ngFor="let i of formField.value[selectedDraw][selectedRaffle]; let idx = index" (click)="editRow(idx, i)">
          <td>
            {{idx + 1}}
          </td>
          <td class="text-uppercase">
            {{i.draw}}-{{i.raffleId}}
          </td>
          <td>
            {{i.combinationError ? i.combinationError : i.combination}}
          </td>
          <td>
            ₱
            <span *ngIf="i.value">{{i.value | number: '1.1-2'}}</span>
            <span *ngIf="!i.value">CLOSED</span>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="formField.mode === 'win'">
        <tr class="cursor-pointer" *ngFor="let i of formField.value; let idx = index" (click)="editRow(idx, i)">
          <td>
            {{idx + 1}}
          </td>
          <td class="text-uppercase">
            {{i.raffleId}}
          </td>
          <td>
            ₱
            <span *ngIf="i.value">{{i.value | number: '1.1-2'}}</span>
          </td>
        </tr>
      </ng-container>
      <ng-container *ngIf="formField.mode === 'commission'">
        <tr class="cursor-pointer" *ngFor="let i of formField.value; let idx = index" (click)="editRow(idx, i)">
          <td>
            {{idx + 1}}
          </td>
          <td class="text-uppercase">
            {{i.raffleId}}
          </td>
          <td>
            <span *ngIf="i.value">{{i.value | number: '1.1-2'}}</span> %
          </td>
        </tr>
      </ng-container>
      <tr>
        <td colspan="5">
          <a (click)="addNewRow()" class="mb-1 text-warning text-bold-500"
             style="text-transform: uppercase; font-size: 0.85rem; font-weight: 500;">
            <i class="ft-plus-square mr-1"></i>New
          </a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>


