<table class="table table-responsive table-sm font-small-1 mt-1 table-borderless">
  <tbody>
  <tr *ngFor="let r of renderItems">
    <ng-container *ngFor="let i of r">
      <td *ngFor="let col of settings.cols" (click)="onItemClick(i)" class="dotted cursor-pointer text-uppercase" [ngStyle]="col.style">
        {{col.isNumber ? (i[col.fieldName] | number: '1.1-1') : i[col.fieldName]}}
      </td>
      <td></td>
    </ng-container>
  </tr>
  </tbody>
</table>
