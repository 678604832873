import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {BlackAppRoutingModule} from './black-app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDatepickerModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {GameCenterComponent} from './pages/game-center/game-center.component';
import {DynamicFormModule} from '../../app/dynamic-form/dynamic-form.module';
import {NgApexchartsModule} from 'ng-apexcharts';
import { GameClientComponent } from './pages/game-client/game-client.component';
import { CoordinatorMasterlistComponent } from './pages/coordinator-masterlist/coordinator-masterlist.component';
import { CoordinatorEditorComponent } from './pages/coordinator-editor/coordinator-editor.component';
import { AgentMasterlistComponent } from './pages/agent-masterlist/agent-masterlist.component';
import { AgentEditorComponent } from './pages/agent-editor/agent-editor.component';
import { OwnerSettingsComponent } from './pages/owner-settings/owner-settings.component';
import { InputWinningNumbersComponent } from './pages/input-winning-numbers/input-winning-numbers.component';
import { GameSheetMasterlistComponent } from './pages/game-sheet-masterlist/game-sheet-masterlist.component';
import { GameSheetEditorComponent } from './pages/game-sheet-editor/game-sheet-editor.component';
import { TransactionHistoryComponent } from './pages/transactions-history/transaction-history.component';
import { TransactionViewerComponent } from './pages/transaction-viewer/transaction-viewer.component';
import { GameSheetItemDetailsDialogComponent } from './pages/game-center/game-sheet-item-details-dialog-component/game-sheet-item-details-dialog.component';
import { DottedTableComponent } from './pages/game-center/dotted-table/dotted-table.component';
import {GameSheetPdfTemplateComponent} from './pdf-template/game-sheet-pdf-template/game-sheet-pdf-template.component';
import { AreaEditorComponent } from './pages/area-editor/area-editor.component';
import { RaffleSettingsEditorComponent } from './components/raffle-settings-editor/raffle-settings-editor.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { WinnersListDialogComponent } from './pages/dashboard/winners-list-dialog/winners-list-dialog.component';
import { RaffleSettingsEditorDialogComponent } from './components/raffle-settings-editor/raffle-settings-editor-dialog/raffle-settings-editor-dialog.component';
import {TagInputModule} from 'ngx-chips';
import { GameSummaryPdfTemplateComponent } from './pdf-template/game-summary-pdf-template/game-summary-pdf-template.component';
import { HomeComponent } from './pages/home/home.component';


@NgModule({
  declarations: [
    GameCenterComponent,
    GameClientComponent,
    CoordinatorMasterlistComponent,
    CoordinatorEditorComponent,
    AgentMasterlistComponent,
    AgentEditorComponent,
    OwnerSettingsComponent,
    InputWinningNumbersComponent,
    GameSheetMasterlistComponent,
    GameSheetEditorComponent,
    TransactionHistoryComponent,
    TransactionViewerComponent,
    GameSheetItemDetailsDialogComponent,
    DottedTableComponent,
    GameSheetPdfTemplateComponent,
    AreaEditorComponent,
    RaffleSettingsEditorComponent,
    AreaEditorComponent,
    DashboardComponent,
    WinnersListDialogComponent,
    RaffleSettingsEditorComponent,
    RaffleSettingsEditorDialogComponent,
    GameSummaryPdfTemplateComponent,
    HomeComponent
  ],
    imports: [
        CommonModule,
        BlackAppRoutingModule,
        FormsModule,
        NgbDatepickerModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgbModule,
        DynamicFormModule,
        NgApexchartsModule,
        TagInputModule,
    ],
})
export class BlackAppModule {
}
