<section class="">
  <div class="row d-flex flex-column position-relative h-100">

    <div class="col" style="overflow-y: auto; padding-bottom: 113px"><!--bottom height-->
      <div class="col-12">
        <div class="d-flex align-items-center my-2">
          <img *ngIf="avatar" class="avatar rounded-sm mr-2" src="{{avatar}}" alt="avatar" style="width:35px; height:35px" />
          <span *ngIf="!avatar"  class="avatar rounded-sm my-auto mr-2 align-items-center justify-content-center"
                style="background-color: #242424; width: 35px; height: 35px;">
            <h6 class="text-white m-0">{{avatar_letter[0].charAt(0).toUpperCase()}}{{avatar_letter[1] ? avatar_letter[1].charAt(0).toUpperCase() : ''}}</h6>
          </span>
          <h6 class="m-0">{{name}}</h6>
          <button class="btn rounded-lg ml-auto" style="background-color: #242424" type="button">
            <h6 class="text-white py-1 m-0">Print Report</h6>
          </button>
        </div>
      </div>
      <div class="col-12">
        <div class="card rounded-lg mb-0" style="background-color: #3b77d9">
          <div class="py-1 px-2">
            <ul *ngFor="let sum of summary" class="list-unstyled m-0">
              <li class="py-1 row align-items-center">
                <h6 class="text-white m-0 col">{{sum.name}}</h6>
                <div class="col d-flex">
                  <h4 class="text-white m-0 ml-auto">{{sum.total | currency:'PHP':'symbol-narrow':'1.0-2'}}</h4>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12" style="overflow: auto">
        <div class="card rounded-lg">
          <div class="p-2 mt-1">
            <ul class="d-flex flex-row list-unstyled m-0">
              <li class="btn text-center rounded-sm box-shadow-1 py-1 px-3 mr-2" type="button">
                <img src="/assets/black/3d_lotto.png" alt="3dLotto" style="width:25px; height:25px" />
                <p class="mb-0 mt-1">3D</p>
              </li>
              <li class="btn text-center rounded-sm box-shadow-1 py-1 px-3 mr-2" type="button">
                <img src="/assets/black/search.png" alt="3dLotto" style="width:25px; height:25px" />
                <p class="mb-0 mt-1">3D</p>
              </li>
              <li class="btn text-center rounded-sm box-shadow-1 py-1 px-3" type="button">
                <img src="/assets/black/arrow.png" alt="3dLotto" style="width:25px; height:25px; transform: rotateY(180deg);" />
                <p class="mb-0 mt-1">3D</p>
              </li>
            </ul>
          </div>
          <div class="p-2">
            <app-lad-radio [formField]="radioTime"></app-lad-radio>
            <input
              readonly
              class="form-control dateInput col-md-4"
              container="body"
              [placeholder]="selectedDate"
              ngbDatepicker
              [(ngModel)]="selectedDate"
              (click)="datePicker.toggle()"
              #datePicker="ngbDatepicker">
          </div>
        </div>
      </div>
    </div>

    <div class="col-12 bg-white position-absolute" style="bottom: 0">
        <ul class="nav nav-pills nav-fill my-1">
          <li class="nav-item mx-2 my-1">
            <!--          $event.preventDefault() prevent from changing page | for test -->
            <a class="nav-link py-1 px-0" href="#" id="homeLink"
               (click)="setActiveLink('homeLink'); $event.preventDefault()">
              <i class="ft-home py-1 px-3 nav-size rounded-pill" [ngStyle]="setLinkStyle('homeLink')"></i>
              <p class="mt-1" [ngStyle]="setLinkStyle('homeLink', true)">Home</p>
            </a>
          </li>
          <li class="nav-item mx-2 my-1">
            <a class="nav-link py-1 px-0" href="#" id="historyLink"
               (click)="setActiveLink('historyLink'); $event.preventDefault()">
              <i class="ft-clock py-1 px-3 nav-size rounded-pill" [ngStyle]="setLinkStyle('historyLink')"></i>
              <p class="mt-1" [ngStyle]="setLinkStyle('historyLink', true)">History</p>
            </a>
          </li>
          <li class="nav-item mx-2 my-1">
            <a class="nav-link py-1 px-0" href="#" id="transactionLink"
               (click)="setActiveLink('transactionLink'); $event.preventDefault()">
              <i class="ft-file py-1 px-3 nav-size rounded-pill" [ngStyle]="setLinkStyle('transactionLink')"></i>
              <p class="mt-1" [ngStyle]="setLinkStyle('transactionLink', true)">Transaction</p>
            </a>
          </li>
        </ul>
    </div>
  </div>
</section>
