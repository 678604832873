import {Component, Input, OnInit} from '@angular/core';
import {LadComponentBase} from '../../models/lad-component-base';
import {LadField, LadFieldOptions} from '../../models/lad-field';

@Component({
  selector: 'app-lad-textarea',
  templateUrl: './lad-text-area.component.html',
  styleUrls: ['./lad-text-area.component.scss']
})
export class LadTextAreaComponent extends LadComponentBase implements OnInit {

  @Input()
  formField: LadTextArea;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

}

export interface LadTextAreaOptions<T> extends LadFieldOptions<T> {
  // component specific options
  placeHolder?: string;
  textAreaClass?: string;
  rows?: number;
}

export class LadTextArea extends LadField<any> {

  placeHolder?: string;
  textAreaClass?: string;
  rows?: number;

  constructor(options: LadTextAreaOptions<string>) {
    super(options);
    this.__componentType = LadTextAreaComponent;
    // defaults here to avoid overrides.
    this.placeHolder = this.placeHolder ? this.placeHolder : '';
    this.textAreaClass = this.textAreaClass ? this.textAreaClass : '';
    this.rows = this.rows ? this.rows : 3;
  }

}
