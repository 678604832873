import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {BlackTransaction} from "../../../models/black-transaction";

@Component({
  selector: 'app-winners-list-dialog',
  templateUrl: './winners-list-dialog.component.html',
  styleUrls: ['./winners-list-dialog.component.scss']
})
export class WinnersListDialogComponent implements OnInit, OnDestroy {

  data: any;

  set target(value) {
    if(value){
      this.data = value;
      console.log(value);
    }
  }

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.activeModal.close();
  }

}
