<div class="menuContainer card m-0 p-3 d-flex h-100">
  <div class="gradient-background">
  <div class="d-flex mb-2">
    <h5>Establishment Masterlist</h5>
    <br>
  </div>
  <p>Add Establishments, and areas that you own or manage.</p>
  <div class="mt-3 d-flex justify-content-end"> 
    <!-- <a class=" ml-1 btn btn-primary" type="button" href="/pages/pos/myEstabliment/add">Add Establishment</a> -->
  </div></div><hr/>
  <div class="mt-4 table-responsive">
    <!-- <table class="align-items-start table summaryTable text-center m-0">
      <tbody>
        <tr>
          <th>#</th> 
          <th>Name</th>
          <th class="d-none d-sm-table-cell">Address</th>
          <th>Description</th>
          <th>Contact Information</th>
          <th>Terminals</th>
          <th>Actions</th>
        </tr>
      <tr *ngFor="let item of productsArr; let i = index" class="menuItems">        
        <td>          
          {{i + 1}}
        </td> 
        <td>          
          <span style="font-weight: bold;font-style: italic;" class="item-price">{{item.name}}</span>
        </td> 
        <td>          
          <span style="font-weight: bold;font-style: italic;" class="item-price">{{item.address}}</span>
        </td> 
        <td>          
          <span style="font-weight: bold;font-style: italic;" class="item-price">{{item.description}}</span>
        </td> 
        <td>          
          <span style="font-weight: bold;font-style: italic;" class="item-price">{{item.contactPerson}}&nbsp;-&nbsp;{{item.contactNumber}}</span>
        </td> 
        <td>          
          <span style="font-weight: bold;font-style: italic;" class="item-price"></span>
        </td>       
        <td>
          <a class="info button" href="/pages/pos/myEstabliment/edit/{{item.documentId}}">
            <i class="ft-edit font-medium-3" uib-tooltip="Update" tooltip-placement="top">
            </i>
          </a>
          <a class="danger button">
            <i class="ft-trash font-medium-3" (click)="delete(item)" uib-tooltip="Delete" tooltip-placement="top" >
            </i>
          </a>
        </td>
      </tr>

      </tbody>
    </table> -->
    <lad-dynamic-form #dynamicFormElement (onInitDone)="onFormInitDone()" [dynamicFields]="dynamicFields"
                        [postSaveFn]="postSaveFn"
                        [preSaveFn]="preSaveFn" submitType="global" [targetDocument]="newDoc">
      </lad-dynamic-form>
  </div>

</div>
