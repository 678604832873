
<h2 class="card-title">
  <i class="ft-grid"></i>
  &nbsp;Owner Settings
</h2>

<div class="card-content">
  <div class="px-3" *ngIf="targetDocument">
    <lad-dynamic-form #dynamicForm
                      [dynamicFields]="dynamicFields"
                      [submitType]="'global'"
                      [allowDelete]="false"
                      [targetDocument]="targetDocument">
    </lad-dynamic-form>
  </div>
</div>

