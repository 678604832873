<div class="modal-header">
  <div class="col">
    <h4 class="modal-title">
      <i class="ft-user mr-1"></i>
      {{isNew ? 'NEW COORDINATOR' : 'COORDINATOR DETAILS'}}
    </h4>
    <p class="text-bold-300 font-small-3">
      {{isNew ? 'Add' : 'Edit'}} coordinator information here
    </p>
  </div>
  <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
    x
  </button>
</div>
<div class="modal-body">
  <div class="px-3" *ngIf="targetDocument">
    <lad-dynamic-form #dynamicForm
                      [dynamicFields]="dynamicFields"
                      (onInitDone)="dfInitDone()"
                      [submitType]="'none'"
                      [allowDelete]="false"
                      [targetDocument]="targetDocument">
    </lad-dynamic-form>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary btn-warning mr-auto" (click)="onCancelDelete()">{{isNew ? 'Cancel' : 'Delete'}}</button>
  <button type="button" class="btn btn-primary ml-auto" (click)="onClose()">OK</button>
</div>
