
<div class="row">
  <div class="col-12">
    <h2 class="content-header">
      <i class="ft-grid"></i>
      {{showCustomersOnly ? 'Customer' : 'Agent'}} Master List
    </h2>
  </div>
</div>

<!-- Tagging start -->
<section class="agent-list">

  <div class="col-md-12" style="padding: 0 !important;">
    <fieldset class="col-md-4" style="padding: 0 !important; max-height: 30px;">
      <form (ngSubmit)="doSearch()">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Enter search key" [(ngModel)]="searchKey" [ngModelOptions]="{standalone: true}">
          <div class="input-group-append">
            <button class="btn btn-primary" type="submit">Search</button>
          </div>
        </div>
      </form>
    </fieldset>
  </div>

  <div class="mt-3 checkbox">
    <input type="checkbox" id="showCustomersOnly" [checked]="showCustomersOnly" [(ngModel)]="showCustomersOnly" (ngModelChange)="onShowCustomerOnlyChange()">
    <label for="showCustomersOnly">
      <span>Show Customers Only</span>
    </label>
  </div>


  <div *ngIf="coordinatorId && !coordinatorDisabled" class="mt-2">
    <button class="ml-1 btn btn-primary" type="button" [hidden]="coordinatorDisabled" (click)="newArea()">New Area</button>
  </div>

  <div *ngIf="coordinatorDisabled" class="text-warning mt-3">
    <a class="ft-info mr-1"></a>COORDINATOR DISABLED
  </div>

  <div class="row" *ngIf="areas.length > 0">
    <div class="col-12">
      <div class="card">
        <div class="card-content">
          <div class="row">
            <div class="col-12">
              <div class="card-body" *ngIf="showItems">
                <fieldset *ngFor="let a of areas">
                  <label style="cursor: pointer" (click)="onAreaSelect(a)">
                    <h3><i class="ft-edit-2 font-medium-3 mr-1"></i>{{a.name | uppercase}} </h3>
                  </label>
                  <div class="form-group">
                    <tag-input (onAdd)="onAgentAdd($event, a)"
                               secondaryPlaceholder="Enter New Agent"
                               placeholder="+Agent"
                               displayBy="name"
                               (onSelect)="onAgentSelect($event)"
                               [(ngModel)]="a.systemHeader.__.agentArr"
                               [removable]="false">
                    </tag-input>
                    <p class="text-muted small my-1">{{a.systemHeader.__.agentArr.length}} Agents</p>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row ml-2 mt-2" *ngIf="areas.length === 0">
    No results.
  </div>
</section>
