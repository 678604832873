<div class="menuContainer card m-0 p-3 d-flex h-100">
  <div class="gradient-background">
  <div class="d-flex mb-2">
    <h5>Product Masterlist</h5>
  </div>
  <div class="mt-3 d-flex justify-content-end">
    <a class=" ml-1 btn btn-primary" type="button" href="/pages/pos/product-masterlist/add">Add Product</a>
  </div></div><hr/>
  <div class="mt-4 table-responsive-sm">
    <table class="align-items-start table summaryTable text-center m-0">
      <tbody>
      <tr *ngFor="let item of productsArr; let i = index" class="menuItems">
        <td style="text-align: start">
          <div style="display: flex;">
            <div style="flex: 1;">
              <img alt="Image" class="item-image"
                   src="{{item.image?.fileName || '../../../../assets/img/photos/01.jpg'}}" style="width:90%;height: 95%;">
            </div>
            <div style="flex: 1;">
              <span class="item-name" style="font-weight: bold;">{{item.code | uppercase}}<br/></span>
              <span class="item-info" style="font-style: italic;">{{item.name}}</span><br/>
              <span class="item-price"
                    style="font-weight: bold;font-style: italic;">{{item.unitPrice | currency:'':'' }}</span>
            </div>
          </div>
        </td>
        <!-- <td style="text-align: start">
          {{item.unitPrice | currency:'':'' }} / {{item.unit}}
        </td>  -->
        <td style="text-align: start">
          <a class="info button" href="/pages/pos/product-masterlist/edit/{{item.documentId}}">
            <i class="ft-edit font-medium-3" uib-tooltip="Update" tooltip-placement="top">
            </i>
          </a><br/><br/>
          <a class="danger button">
            <i class="ft-trash font-medium-3" (click)="delete(item)" uib-tooltip="Delete" tooltip-placement="top" >
            </i>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

</div>
