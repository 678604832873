<div class="card">

  <div class="card-header pb-2">
    <h4><i class="ft-map mr-2"></i>Dynamic Form Sample</h4>
    <p class="mb-0 text-bold-300 font-small-3">View all dynamic components here and sample usages.</p>
  </div>

  <div class="card-content">
    <div class="px-3">

      <lad-dynamic-form #dynamicFormElement
                        (onInitDone)="onFormInitDone()"
                        [dynamicFields]="dynamicFields"
                        [postSaveFn]="postSaveFn"
                        [preSaveFn]="preSaveFn"
                        [submitType]="'none'"
                        [targetDocument]="newDoc">
      </lad-dynamic-form>

    </div>
  </div>

</div>
