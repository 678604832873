<section>
  <div class="row">
    <div class="col-xl-3 col-lg-6 col-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left">
                <h3 class="mb-1 danger">{{gross}}</h3>
                <span>Gross</span>
              </div>
              <div class="media-right align-self-center">
                <i class="ft-dollar-sign danger font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left">
                <h3 class="mb-1 success">{{commission}}</h3>
                <span>Gross</span>
              </div>
              <div class="media-right align-self-center">
                <i class="ft-pie-chart success font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left">
                <h3 class="mb-1 warning">{{wins}}</h3>
                <span>Wins</span>
              </div>
              <div class="media-right align-self-center">
                <i class="ft-file warning font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left">
                <h3 class="mb-1 danger">{{raffle}}</h3>
                <span>Raffle</span>
              </div>
              <div class="media-right align-self-center">
                <i class="ft-pie-chart danger font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-12">
      <div class="card btn p-0" (click)="winnersDialog()">
        <div class="card-content">
          <div class="card-body">
            <div class="media">
              <div class="media-body text-left">
                <h3 class="mb-1 warning">{{winners}}</h3>
                <span>Winners</span>
              </div>
              <div class="media-right align-self-center">
                <i class="ft-book-open warning font-large-2 float-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
