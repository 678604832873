import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GameCenterComponent} from './pages/game-center/game-center.component';
import {GameClientComponent} from './pages/game-client/game-client.component';
import {CoordinatorMasterlistComponent} from './pages/coordinator-masterlist/coordinator-masterlist.component';
import {AgentMasterlistComponent} from './pages/agent-masterlist/agent-masterlist.component';
import {OwnerSettingsComponent} from './pages/owner-settings/owner-settings.component';
import {InputWinningNumbersComponent} from './pages/input-winning-numbers/input-winning-numbers.component';
import {AgentEditorComponent} from './pages/agent-editor/agent-editor.component';
import {CoordinatorEditorComponent} from './pages/coordinator-editor/coordinator-editor.component';
import {GameSheetMasterlistComponent} from './pages/game-sheet-masterlist/game-sheet-masterlist.component';
import {GameSheetEditorComponent} from './pages/game-sheet-editor/game-sheet-editor.component';
import {TransactionHistoryComponent} from './pages/transactions-history/transaction-history.component';
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import {HomeComponent} from "./pages/home/home.component";


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'game-center',
        component: GameCenterComponent,
      },
      {
        path: 'game-client',
        component: GameClientComponent,
      },
      {
        path: 'coordinators',
        component: CoordinatorMasterlistComponent,
      },
      {
        path: 'coordinator-editor',
        component: CoordinatorEditorComponent,
      },
      {
        path: 'agents',
        component: AgentMasterlistComponent,
      },
      {
        path: 'agent-editor',
        component: AgentEditorComponent,
      },
      {
        path: 'owner',
        component: OwnerSettingsComponent,
      },
      {
        path: 'input-winning-numbers',
        component: InputWinningNumbersComponent,
      },
      {
        path: 'game-sheet-masterlist',
        component: GameSheetMasterlistComponent,
      },
      {
        path: 'game-sheet-editor',
        component: GameSheetEditorComponent,
      },
      {
        path: 'bet-history',
        component: TransactionHistoryComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'home',
        component: HomeComponent
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class BlackAppRoutingModule {
}
