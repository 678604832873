import {API, APIFunctionTypes, LadAPIUtils, LadDocumentService, LadUploadedAsset, SystemDocument} from 'ladrov-commons';
import {JPEModelNames} from './jpe-model.names';

export class JPEAccount extends SystemDocument {

  importName: string; // from import

  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  addressLine1: string;
  barangay: string;
  city: string;
  province: string;

  email: string;
  mobileNo: string;
  landLineNo: string;

  portrait: LadUploadedAsset;


  public static getClientAddress(a: JPEAccount) {
    if (!a) {
      return '';
    }
    const a1 = a.addressLine1 ? a.addressLine1 : '';
    const barangay = a.barangay ? a.barangay : '';
    const city = a.city ? a.city : '';
    const province = a.province ? a.province : '';

    return `${a1}${barangay ? ',' : ''} ${barangay}${city ? ',' : ''} ${city}${province ? ',' : ''} ${province}`;
  }

  public static getClientContactStr(a: JPEAccount) {
    if (!a) { return ''; }
    return `${a.mobileNo ? a.mobileNo : ''} ${a.email ? '/' : ''} ${a.email ? a.email : ''}`;
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async search(args: {searchKey: string}, utils: LadAPIUtils): Promise<JPEAccount[]> {
    let searchKey = args.searchKey ? args.searchKey : '';
    searchKey = searchKey.trim();
    const docService: LadDocumentService = utils.documentService;
    let filter;
    if (searchKey.indexOf(',') > -1){
      const sp = searchKey.split(',');
      filter = {};
      const lname = sp[0]?.trim();
      if (lname) {
        filter.lastName = new RegExp(lname, 'i');
      }
      const fname = sp[1]?.trim();
      if (fname) {
        filter.firstName = new RegExp(fname, 'i');
      }
    } else if (searchKey) {
      const keyRegex = new RegExp(searchKey.trim(), 'i');
      filter = {
        $or : [
          { lastName: keyRegex },
          { firstName: keyRegex },
          { email: keyRegex },
          { mobileNo: keyRegex },
          { addressLine1: keyRegex },
          { barangay: keyRegex },
          { city: keyRegex },
          { province: keyRegex },
        ]
      };
    } else {
      filter = {};
    }
    return docService.find(JPEModelNames.ACCOUNT, filter, {sort: {lastName: 1, firstName: 1, city: 1}});
  }

  public static async registerAndFetchByName(args: { name, address?, contact?}, utils: LadAPIUtils): Promise<JPEAccount> {
    const importName = args.name?.trim();
    if (!importName) {
      return;
    }
    let account: JPEAccount = await utils.documentService.findOne(JPEModelNames.ACCOUNT, { importName: new RegExp(importName, 'i') });
    if (!account) {
      const lsplit = importName.split(',');
      const noFname = lsplit.length < 2
      let lname = lsplit[0];
      let fname = noFname ? '' : lsplit[1];
      let mname = '';
      let suffix = '';

      let dot = fname.indexOf('.');
      if (dot > -1) {
        mname = fname.substring(dot + 1, dot - 1);
        suffix = fname.substring(dot + 1, fname.length);
        if ( mname.toLowerCase().indexOf(' jr') > -1 || mname.toLowerCase().indexOf(' sr') > -1) {
          const tmp = mname;
          mname = suffix;
          suffix = tmp;
        }
        fname = fname.substring(0, dot - 1);
      }

      lname = lname.trim();
      fname = fname.trim();
      mname = mname.trim();
      suffix = suffix.trim();

      account = new JPEAccount();
      account.importName = importName;
      account.firstName = fname;
      account.lastName = lname;
      account.middleName = mname;
      account.suffix = suffix;
      account.addressLine1 = args.address?.trim();
      if (args.contact?.indexOf('@') > -1) {
        account.email = args.contact?.trim();
      } else {
        account.mobileNo = args.contact?.trim();
      }
      const currentAccount = await utils.getCurrentAccount();
      account = await utils.documentService.upsert(account, currentAccount.documentId);
    }
    return account;
  }

  public static getName(a: JPEAccount) {
    if (!a) { return 'n/a'; }
    return `${a.lastName}, ${a.firstName} ${a.middleName ? a.middleName : ''} ${a.suffix ? a.suffix : ''}`;
  }

  constructor() {
    super(JPEModelNames.ACCOUNT);
  }
}
