import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {QtBusinessModel} from '../../models/qt-business.model';
import {LadInput} from '../../../../app/dynamic-form/components/lad-input/lad-input.component';
import {LadCombo} from '../../../../app/dynamic-form/components/lad-combo/lad-combo.component';
import {LadTextArea} from '../../../../app/dynamic-form/components/lad-textarea/lad-text-area.component';
import {LadSectionTitle} from '../../../../app/dynamic-form/components/lad-section-title/lad-section-title.component';
import {APIService} from '../../../../app/shared/backend/api.service';
import {QtModelNames} from '../../models/qt-model.names';

@Component({
  selector: 'app-store-editor',
  templateUrl: './store-editor.component.html',
  styleUrls: ['./store-editor.component.scss']
})
export class StoreEditorComponent implements OnInit {
  targetDoc: QtBusinessModel = new QtBusinessModel();
  preSaveFn: any;
  postSaveFn: any;
  dynamicFields: any;
  id: string | null;

  constructor(
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private api: APIService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    console.log(this.id);
    this.dynamicFields = {
      name: new LadInput({
        label: 'Name / Registered Name',
        value: '',
        row: 'nameRow',
        required: true,
        columnClass: 'col-md-12',
        inputClass: 'text-uppercase'
      }),
      type: new LadCombo({
        row: 'row1',
        label: 'Category',
        value: '',
        items: [
          {
            label: 'Private', value: 'Private'
          },
          {
            label: 'Public', value: 'Public'
          }
        ],
        required: true,
        columnClass: 'col-md-6' // default is col-md-3
      }),
      category: new LadCombo({
        row: 'row1',
        label: 'Type',
        value: '',
        items: [
          {
            label: 'Restaurants', value: 'Restaurants'
          },
          {
            label: 'Cafés', value: 'Cafés'
          },
          {
            label: 'Fast food chains Hall', value: 'Fast food chains'
          },
          {
            label: 'Bars', value: 'Bars'
          },
          {
            label: 'Bakeries', value: 'Bakeries'
          },
          {
            label: 'Diners', value: 'Diners'
          },
          {
            label: 'Bistros', value: 'Bistros'
          },
          {
            label: 'Coffee shops', value: 'Coffee shops'
          },
          {
            label: 'Convenience Store', value: 'Convenience Store'
          },
          {
            label: 'Ice cream parlors', value: 'Ice cream parlors'
          },
          {
            label: 'Gas Station', value: 'Gas Station'
          },
          {
            label: 'Seafood restaurants', value: 'Seafood restaurants'
          },
          {
            label: 'Food courts', value: 'Food courts'
          },
          {
            label: 'Pizza parlors', value: 'Pizza parlors'
          },
          {
            label: 'Caridenria', value: 'Caridenria'
          },
          {
            label: 'Food trucks', value: 'Food trucks'
          },
          {
            label: 'Market', value: 'Market'
          },
          {
            label: 'Pharmacy', value: 'Pharmacy'
          },
          {
            label: 'Store', value: 'Store'
          },
          {
            label: 'Supermarket', value: 'Supermarket'
          },
          {
            label: 'Others', value: 'Others'
          }

        ],
        required: true,
        columnClass: 'col-md-6'
      }),
      contactPerson: new LadInput({
        label: 'Contact Person',
        value: '',
        row: 'row2',
        required: true,
        columnClass: 'col-md-12',
        inputClass: 'text-uppercase'
      }),
      contactNumber: new LadInput({
        label: 'Contact Number',
        value: '',
        row: 'row2',
        required: true,
        columnClass: 'col-md-12',
        inputClass: 'text-uppercase'
      }),
      details: new LadTextArea({
        row: 'row2',
        label: 'Details:',
        value: '',
        placeHolder: 'TIN, License No., or any certified information that can identify this entity.',
        columnClass: 'col-md-12' // default is col-md-3
      }),

      __section2: new LadSectionTitle({value: 'Address Information', visible: true}),
      address: new LadTextArea({
        row: 'row2',
        label: 'Address:',
        value: '',
        columnClass: 'col-md-12' // default is col-md-3
      })
    };
    this.fetchProductInfo(this.id)
  }


  onFormInitDone() {

  }

  async fetchProductInfo(id) {
    if (id) {
      const fetchProd = (await this.api.getDocument(id, QtModelNames.BUSINESS).toPromise()) as QtBusinessModel;
      this.dynamicFields.name.value = fetchProd.name;
      this.dynamicFields.address.value = fetchProd.address;
      this.targetDoc.documentId = fetchProd.documentId;
      this.targetDoc.systemHeader.type = QtModelNames.BUSINESS;
      this.cd.markForCheck();
    }
  }
}
