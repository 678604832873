


interface JavaScriptInterface {
  download(data: string, fileName: string) : string;
  sendToBluetoothPrinter(data: string);
  sendToPrinterWithAddress(data: string, address: string);
  sendSms(message: string, number: string);
  getPrinters(): string;
  generate318Qr(qr: string, address?: string);
  generatePrint1Qr(qr: string, address?: string);
  getAPKVersion(): string;
  testPrint();
  generateTone(tone: number, duration: number);
}

export interface BasicPrinter {
  printerName: string;
  address: string
}


declare var GPayAndroid: JavaScriptInterface;

export module GPayAndroidService {

  export function download(content, fileName) {
    if (typeof GPayAndroid?.download === "function") {
      return GPayAndroid.download(content, fileName);
    }
  }

  export function sendSms(message, number) {
    if (typeof GPayAndroid?.sendSms === "function") {
      return GPayAndroid.sendSms(message, number);
    }
  }

  export function testPrint() {
    if (typeof GPayAndroid?.testPrint === "function") {
      return GPayAndroid.testPrint();
    }
  }

  export function sendToBluetoothPrinter(data) {
    if (GPayAndroid) {
      return GPayAndroid.sendToBluetoothPrinter(data);
    }
  }

  export function generateTone(tone: number, duration = 150) {
    if (typeof GPayAndroid?.generateTone === "function") {
      return GPayAndroid.generateTone(tone, duration);
    }
  }

  export const TONE_PROP_NACK = 26;
  export const TONE_PROP_ACK = 25;


  export function sendToPrinterWithAddress(data, address) {
    if (GPayAndroid) {
      return GPayAndroid.sendToPrinterWithAddress(data, address); // should return true if complete
    }
  }

  export function getPrinters(): BasicPrinter[] {
    if (GPayAndroid) {
      try {
        const r = GPayAndroid.getPrinters(); // should return true if complete
        return JSON.parse(r);
      } catch (e) {
        console.error(e);
        return [];
      }
    }
  }

  export function getAPKVersion(): string {
    if (GPayAndroid) {
      try {
        return GPayAndroid.getAPKVersion(); // should return true if complete
      } catch (e) {
        console.error(e);
        return 'APK version not available';
      }
    }
  }

}
