import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BlackGameSheet} from '../../models/black-game-sheet';
import {BlackStatus} from '../../models/utils/black-utils';

@Component({
  selector: 'app-game-sheet-masterlist',
  templateUrl: './game-sheet-masterlist.component.html',
  styleUrls: ['./game-sheet-masterlist.component.scss']
})
export class GameSheetMasterlistComponent implements OnInit {

  gameSheetArr: BlackGameSheet[] = [];

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    BlackGameSheet.getGameSheets({includeDisabled: true}).then(results => {
      this.gameSheetArr = [...results];
      this.cd.markForCheck();
    })
  }
}
