import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DynamicFormComponent} from './form/dynamic-form.component';
import {DynamicFieldComponent} from './field/dynamic-field.component';
import {LadSectionTitleComponent} from './components/lad-section-title/lad-section-title.component';
import {LadInputComponent} from './components/lad-input/lad-input.component';
import {LadSelectComponent} from './components/lad-select/lad-select.component';
import {DfSamplePageComponent} from './df-sample-page/df-sample-page.component';
import {LadRadioComponent} from './components/lad-radio/lad-radio.component';
import {LadPortraitComponent} from './components/lad-portrait/lad-portrait.component';
import {FileUploadModule} from 'ng2-file-upload';
import {LadTextAreaComponent} from './components/lad-textarea/lad-text-area.component';
import {LadComboComponent} from './components/lad-combo/lad-combo.component';
import {LadCheckboxComponent} from './components/lad-checkbox/lad-checkbox.component';
import {DynamicFormPendingChangesGuard} from './dynamic-form-pending-changes.guard';
import {LadUploadsComponent} from './components/lad-uploads/lad-uploads.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LightboxModule} from 'ngx-lightbox';
import {LadSingleLocationComponent} from './components/lad-single-location/lad-single-location.component';
import {LadImageUploaderComponent} from './components/lad-image-uploader/lad-image-uploader.component';
import {ImageCropperModule} from 'ngx-image-cropper';
// import {LadScannerModule} from './components/lad-scanner/lad-scanner.module';
import {LadExpressionChallengeModule} from './components/lad-expression-challenge/lad-expression-challenge.module';
import {DynamicFormService} from './dynamic-form.service';
import {LadMultipleLocationComponent} from './components/lad-multiple-location/lad-multiple-location.component';
import {BbHandsontableComponent} from './components/bb-handsontable/bb-handsontable.component';
import {HotTableModule} from '@handsontable/angular';
import {registerAllModules} from 'handsontable/registry';
import {Safe} from './pipes/safe-pipe';
import {BoldFirstTwoPipe} from './pipes/bold-first-two-pipe';

// register Handsontable's modules
registerAllModules();

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    FileUploadModule,
    NgbModule,
    LightboxModule,
    ImageCropperModule,
    LadExpressionChallengeModule,  // todo: do not include by default
    // LadScannerModule, // todo: do not include by default
    HotTableModule
  ],
  declarations: [
    DynamicFormComponent,
    DynamicFieldComponent,
    LadSectionTitleComponent,
    LadInputComponent,
    LadSelectComponent,
    DfSamplePageComponent,
    LadRadioComponent,
    LadPortraitComponent,
    LadTextAreaComponent,
    LadComboComponent,
    LadCheckboxComponent,
    LadUploadsComponent,
    LadSingleLocationComponent,
    LadMultipleLocationComponent,
    LadImageUploaderComponent,
    BbHandsontableComponent,
    Safe,
    BoldFirstTwoPipe
  ],
  providers: [
    // services here
    DynamicFormService,
    DynamicFormPendingChangesGuard
  ],
  exports: [
    DynamicFormComponent,
    LadSingleLocationComponent,
    LadRadioComponent,
    DynamicFieldComponent,
    BoldFirstTwoPipe
  ]
})
export class DynamicFormModule {
}
