import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {LadInput} from '../components/lad-input/lad-input.component';
import {LadSelect, LadSelectItem} from '../components/lad-select/lad-select.component';
import {LadSectionTitle} from '../components/lad-section-title/lad-section-title.component';
import {LadRadio} from '../components/lad-radio/lad-radio.component';
import {LadPortrait} from '../components/lad-portrait/lad-portrait.component';
import {LadTextArea} from '../components/lad-textarea/lad-text-area.component';
import {LadCombo} from '../components/lad-combo/lad-combo.component';
import {LadCheckbox} from '../components/lad-checkbox/lad-checkbox.component';
import {ComponentCanDeactivate} from '../dynamic-form-pending-changes.guard';
import {DynamicFormComponent} from '../form/dynamic-form.component';
import {LadUploads} from '../components/lad-uploads/lad-uploads.component';
import {LadSingleLocation} from '../components/lad-single-location/lad-single-location.component';
import {LadMultipleLocation} from '../components/lad-multiple-location/lad-multiple-location.component'
import {LadExpressionChallenge} from '../components/lad-expression-challenge/lad-expression-challenge/lad-expression-challenge.component';
// import {LadScanner} from '../components/lad-scanner/lad-scanner/lad-scanner.component';
import {DFSampleDocument} from './df-sample-document';
import {BbHandsontable} from '../components/bb-handsontable/bb-handsontable.component';
import {GridSettings} from 'handsontable/settings';
import {getSampleData, JPEProjectLot} from '../../../projects/jpe-app/models/jpe-project.model';


const COLORS = {
  red: 'color:#fff!important;background-color:#f44336!important',
  purple: 'color:#fff!important;background-color:#9c27b0!important'
}

@Component({
  selector: 'app-df-sample-page',
  templateUrl: './df-sample-page.component.html',
  styleUrls: ['./df-sample-page.component.scss']
})
export class DfSamplePageComponent implements OnInit, ComponentCanDeactivate {

  newDoc = new DFSampleDocument();

  @ViewChild('dynamicFormElement') dynamicFormElement: DynamicFormComponent;

  dynamicFields1 = {
    textarea1: new LadTextArea({
      row: 'textarearow',
      label: 'This is a sample textarea:',
      required: true,
      placeHolder: 'This is a placeholder.'
    }),
    uploads0: new LadUploads({
      label: 'Uploads component:', value: [
        {
          fileName: 'assets/img/photos/12.jpg',
          mimeType: 'jpg',
          originalName: 'Photos-12.jpg',
          size: 0,
          uploadedTimestamp: Date.now()
        },
        {
          fileName: 'assets/img/photos/13.jpg',
          mimeType: 'jpg',
          originalName: 'Photos-13.jpg',
          size: 0,
          uploadedTimestamp: Date.now()
        },
        {
          fileName: 'assets/img/photos/14.jpg',
          mimeType: 'jpg',
          originalName: 'Photos-14.jpg',
          size: 0,
          uploadedTimestamp: Date.now()
        },
      ], columnClass: 'col-md-6'
    }),
    uploads1: new LadUploads({
      columnClass: 'col-md-3',
      label: 'Uploads component slim:'
    }),
  };

  dynamicFields;

  constructor(cd: ChangeDetectorRef) {
    this.dynamicFields = {
      standardInput: new LadInput({
        row: 'row1',
        label: 'standardInput',
        value: 'a large text for standard input',
        required: true,
        columnClass: 'col-md-4', // default is col-md-3
        inputClass: 'text-uppercase',
      }),
      numberInputWithInputClass: new LadInput({
        row: 'row1',
        label: 'Number Input with inputClass',
        value: 1,
        required: true,
        columnClass: 'col-md-4',
        inputType: 'number',
        inputClass: 'text-center',
      }),
      inputDate: new LadInput({
        row: 'row1',
        label: 'Date Input',
        value: null,
        required: true,
        inputType: 'date',
      }),
      inputDate1: new LadInput({
        row: 'row1.1',
        label: 'Date',
        value: null,
        required: true,
        inputType: 'date',
      }),
      timeInput: new LadInput({
        row: 'row1.1',
        label: 'Time',
        value: null,
        required: true,
        columnClass: 'col-md-3',
        inputType: 'time'
      }),
      duration: new LadInput({
        row: 'row1.1',
        label: 'Duration',
        value: 1,
        required: true,
        columnClass: 'col-md-2',
        inputType: 'number',
        inputClass: 'text-center',
      }),
      admins: new LadSelect({
        label: 'Admins:',
        placeholder: 'System Administrators',
        items: [
          {
            label: 'John Koers', // make sure we have this content so that the value will show up
            value: 'john.koers@gmail.com'
          },
          {value: 'anjmao', label: 'Anjmao'},
          {value: 'varnas', label: 'Tadeus Varnas'}
        ],
        value: ['john.solano@gmail.com'],
        multiple: true,
        addTag: (term) => {
          return `ModifiedByTagFunction:${term}`;
        }, // label is needed for display
        columnClass: 'col-md-6',
        visible: true,
        closeOnSelect: true,
        virtualScroll: true
      }),
      row2Section: new LadSectionTitle({
        title: 'Section Name',
        icon: 'ft-users',
        visible: true,
        subTitle: 'View all dynamic components here and sample usages.'
      }),
      select1: new LadSelect({
        label: 'Select Sample:',
        placeholder: '',
        items: [
          {
            label: 'Male', // make sure we have this content so that the value will show up
            value: 'Male'
          },
          {
            label: 'Female', // make sure we have this content so that the value will show up
            value: 'Female'
          },
        ],
        value: 'Female',
        multiple: false,
        columnClass: 'col-md-3',
        visible: true,
        closeOnSelect: true,
        virtualScroll: true
      }),
      row3Section: new LadSectionTitle({value: 'Section No Icon'}),
      select2: new LadSelect({
        row: 'row2',
        label: 'Another Combo Sample',
        placeholder: '',
        items: [
          {
            label: 'RED', // make sure we have this content so that the value will show up
            value: 'red',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#f44336!important">RED</div>'
          },
          {
            label: 'PINK',
            value: 'pink',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#e91e63!important">PINK</div>'
          },
          {
            label: 'PURPLE',
            value: 'purple',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#9c27b0!important">PURPLE</div>'
          },
          {
            label: 'DEEP PURPLE',
            value: 'deepPurple',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#673ab7!important">PURPLE</div>'
          },
          {
            label: 'INDIGO',
            value: 'indigo',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#3f51b5!important">INDIGO</div>'
          },
          {
            label: 'BLUE',
            value: 'blue',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#2196F3!important">BLUE</div>'
          },
          {
            label: 'LIGHT BLUE',
            value: 'lightBlue',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#87CEEB!important">LIGHT BLUE</div>'
          },
          {
            label: 'CYAN',
            value: 'cyan',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#00bcd4!important">CYAN</div>'
          },
          {
            label: 'TEAL',
            value: 'teal',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#059862 !important">TEAL</div>'
          },
          {
            label: 'GREEN',
            value: 'green',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#4CAF50!important">GREEN</div>'
          },
          {
            label: 'LIGHT GREEN',
            value: 'lightGreen',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#8bc34a!important">LIGHT GREEN</div>'
          },
          {
            label: 'LIME',
            value: 'lime',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#cddc39!important">LIME</div>'
          },
          {
            label: 'KHAKI',
            value: 'khaki',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#f0e68c!important">KHAKI</div>'
          },
          {
            label: 'YELLOW',
            value: 'yellow',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#ffeb3b!important">YELLOW</div>'
          },
          {
            label: 'AMBER',
            value: 'amber',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#ffc107!important">AMBER</div>'
          },
          {
            label: 'ORANGE',
            value: 'orange',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#ff9800!important">ORANGE</div>'
          },
          {
            label: 'DEEP ORANGE',
            value: 'deepOrange',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#ff5722!important">DEEP ORANGE</div>'
          },
          {
            label: 'BLUE GREY',
            value: 'blueGrey',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#607d8b!important">BLUE GREY</div>'
          },
          {
            label: 'BROWN',
            value: 'brown',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#795548!important">BROWN</div>'
          },
          {
            label: 'GREY',
            value: 'grey',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#9e9e9e!important">GREY</div>'
          },
          {
            label: 'DARK GREY',
            value: 'darkGrey',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#616161!important">DARK GREY</div>'
          },
          {
            label: 'BLACK',
            value: 'black',
            customItemDisplayHtml: '<div class="text-center" style="color:#fff!important;background-color:#000!important">BLACK</div>'
          }
        ],
        multiple: false,
        columnClass: 'col-md-3',
        visible: true,
        closeOnSelect: true,
        virtualScroll: true,
      }),
      select3: new LadSelect({
        row: 'row2',
        label: 'Colors Combo',
        placeholder: '',
        customLabelHtmlFn: (item: LadSelectItem) => {
          if (!item || !item.value) {
            return '<div>N/A</div>';
          }
          return item.customItemDisplayHtml;
        },
        items: Object.keys(COLORS).map((value): LadSelectItem => {
          const label = value.toUpperCase();
          const style = COLORS[value];
          return {
            label,
            value,
            customItemDisplayHtml: `<div class="text-center font-medium-3" style="${style}">${label}</div>`
          }
        }),
        multiple: false,
        columnClass: 'col-md-3',
        visible: true,
        closeOnSelect: true,
        virtualScroll: true,
      }),
      radioSample: new LadRadio({
        row: 'row2',
        label: 'Selected Number:',
        columnClass: 'col-md-4',
        value: 4,
        items: [
          {
            label: 'One', value: 1
          },
          {
            label: 'Two', value: 2
          },
          {
            label: 'Three', value: 3, disabled: false
          },
          {
            label: 'Four', value: 4
          },
        ]
      }),
      // schoolLogo: new DFUploader({
      //   row: 'row1',
      //   label: 'SCHOOL LOGO',
      //   value: [],
      //   visible: false
      // }),
      portrait: new LadPortrait({required: true, label: 'Sample Portrait Field', columnClass: 'col-md-8'}),
      textarea1: new LadTextArea({
        row: 'textarearow',
        label: 'This is a sample textarea:',
        required: true,
        placeHolder: 'This is a placeholder.'
      }),
      textarea2: new LadTextArea({
        row: 'textarearow',
        label: 'This is a sample textarea:',
        required: true,
        rows: 5,
        placeHolder: 'This is a placeholder.',
        textAreaClass: 'text-uppercase text-bold-300'
      }),
      combo: new LadCombo({
        label: 'Sample combobox:',
        value: 4,
        items: [
          {
            label: 'One', value: 1
          },
          {
            label: 'Two', value: 2, visible: false
          },
          {
            label: 'Three', value: 3
          },
          {
            label: 'Four', value: 4
          }
        ],
      }),
      checkbox: new LadCheckbox({label: 'Will check?', value: true}),
      checkbox1: new LadCheckbox({label: 'Will check?', value: null, required: true}),
      uploads0: new LadUploads({
        label: 'Uploads component:', value: [
          {
            fileName: 'assets/img/photos/12.jpg',
            mimeType: 'jpg',
            originalName: 'Photos-12.jpg',
            size: 0,
            uploadedTimestamp: Date.now()
          },
          {
            fileName: 'assets/img/photos/13.jpg',
            mimeType: 'jpg',
            originalName: 'Photos-13.jpg',
            size: 0,
            uploadedTimestamp: Date.now()
          },
          {
            fileName: 'assets/img/photos/14.jpg',
            mimeType: 'jpg',
            originalName: 'Photos-14.jpg',
            size: 0,
            uploadedTimestamp: Date.now()
          },
        ], columnClass: 'col-md-6'
      }),
      geoLocation1: new LadMultipleLocation({
        columnClass: 'col-md-12',
        label: 'Physical location:',
        value: [{
          'type': 'Point',
          'coordinates': [13954825.617534386, 752680.0413191374],
        }, {
          'type': 'Point',
          'coordinates': [13954777.84439171, 752216.6418351586]
        }
        ]
      }),
      uploads1: new LadUploads({
        columnClass: 'col-md-3',
        label: 'Uploads component slim:'
      }),
      ladSingleLocation: new LadSingleLocation({
        label: 'LadSingleLocation Component:',
        required: true,
        value: null
        // value: {
        //   'type': 'Point',
        //   'coordinates': [
        //     13952304.86344633, 751602.5995050165
        //   ]
        // }
      }),
      // ladSingleLocation1: new LadSingleLocation({
      //   label: 'LadSingleLocation Component:',
      //   required: true
      // }),
      // see ngOnInit where a new typeAheadItem is set after 15s
      typeahead: new LadInput({
        label: 'Sample Typeahead:',
        columnClass: 'col-md-6',
        typeAheadItems: ['A whole', 'new world', 'I can', 'open your',
          'eyes', 'the quick', 'brown fox', 'jumps', 'over', 'the lazy', 'dog'],
        typeAheadDebounceTime: 300
      }),
      expressionChallenge: new LadExpressionChallenge({
        label: 'Expression Challenge',
        columnClass: 'col-md-6'
      }),
      handsontable1: new BbHandsontable({
        gridSettings: async () => {
          return this.getHansdontableSampleGridSettings();
        }
      }),
      // scanner: new LadScanner({
      //   label: 'Scan QR and Face',
      //   strictMode: true,
      //   onScannerClicked: () => {
      //     console.log('scanner clicked!');
      //     this.dynamicFields.scanner.formControl.setValue(null);
      //   }
      // }),
      handsontable: new BbHandsontable({
        gridSettings: async () => {
          return this.getHansdontableSampleGridSettings();
        }
      })
    };

    setTimeout(() => {
      console.log('Updating admins...');
      const newItems = [
        {
          label: 'Updated... will only show this item label.', // make sure we have this content so that the value will show up
          value: 'updatedValue'
        }
      ];
      this.dynamicFields.admins.setItems(newItems);
      cd.detectChanges();
    }, 10_000);

  }

  getHansdontableSampleGridSettings() {
    const p = getSampleData();
    const items = p.lots;
    const columnDef =  [
      {
        width: 50,
        title: '<b>Lot#</b>',
        data: 'name'
      },
      {
        width: 50,
        title: '<b>Lot Area</b>',
        data: 'lotArea',
      },
      {
        width: 50,
        title: '<b>Price/SQM</b>',
        data: 'priceSqm'
      },
      {
        width: 50,
        title: '<b>Total Contract Price</b>',
        data: 'priceSqm'
      }
    ];
    const gridSettings: GridSettings = {
      readOnlyCellClassName: 'text-muted',
      rowHeights: 45,
      columnHeaderHeight: 45,
      rowHeaders: false,
      licenseKey: 'non-commercial-and-evaluation',
      data : items,
      columns : columnDef,
      dataSchema : () => {
        return new JPEProjectLot();
      },
      contextMenu: ['remove_row', '---------', 'row_below', 'row_above', '---------',  'copy', 'cut', '---------', 'undo', 'redo'],
      allowRemoveRow: true,
      fixedColumnsLeft: 0,
      stretchH: 'all',
      colWidths: columnDef.map((each: any) => each.width ? each.width : 100),
      height: '60vh',
      beforePaste : (data, coords) => {
        for (let rindex = 0 ; rindex < data.length; rindex++ ) {
          for (let colIndex = 0; colIndex < data[rindex].length; colIndex++ ) {
            const val = data[rindex][colIndex];
            if (typeof val === 'string') {
              data[rindex][colIndex] = val.trim();
            }
          }
        }
      }
    };
    return gridSettings;
  }

  canDeactivate() {
    // will warn DynamicFormPendingChangesGuard to stop moving between pages if there are changes.
    return !this.dynamicFormElement.form.touched;
  };

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.dynamicFields.typeahead.typeAheadItems = ['thequickbrownfoxjumpsoverthelazydog'];
    //   alert('typeahead items set with a new value');
    // }, 15000);
  }

  onFormInitDone() {
    // this.dynamicFields1.scanner.formControl.registerOnChange((newVal) => {
    //   console.log(JSON.stringify(newVal, null, 4));
    // });
  }

  async preSaveFn(updatedDoc) {
    console.log(`presave`)
    updatedDoc.fromPresave = updatedDoc.fromPresave ? updatedDoc.fromPresave++ : 1;
    return updatedDoc;
  }

  async postSaveFn(finalDoc) {
    console.log(`postsave: \n${JSON.stringify(finalDoc, null, 4)}`);
  }
}
