import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BlackTransaction} from '../../models/black-transaction';
import {LadDate} from 'ladrov-commons';
import {numberThousand} from '../../models/utils/number-helper';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TransactionViewerComponent} from '../transaction-viewer/transaction-viewer.component';
import {timeAgo} from '../../../../app/android/date-helper';

@Component({
  selector: 'app-bet-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent implements OnInit {
  searchKey: any;
  transactionsArr: any;
  selectedDate = new LadDate();

  constructor(
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.doSearch();
  }

  async doSearch() {
    this.transactionsArr = await BlackTransaction.search({searchKey: this.searchKey, selectedDate: this.selectedDate}) as BlackTransaction[];
    let totalAmount = 0;
    for (const t of this.transactionsArr) {
      totalAmount += t.total;
      // t.timeAgo = timeAgo(t.date);
    }
    this.totalAmount = numberThousand(totalAmount);
    this.cd.markForCheck();
  }

  shortTime(date: LadDate) {
    const amPm = date.hour >= 12 ? 'PM' : 'AM';
    const hour = date.hour % 12 || 12;
    return `${hour}:${date.minute}:${date.seconds} ${amPm}`;
  }

  protected readonly numberThousand = numberThousand;
  totalAmount: string;

  openTransaction(i: BlackTransaction | any) {
    i.time = this.shortTime(i.date);
    const modalRef = this.modalService.open(TransactionViewerComponent);
    modalRef.componentInstance.target = i;
  }
}
