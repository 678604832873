<h2>
    <i class="ft-grid"></i>
    &nbsp;Edit Product
  </h2>
  <div class="card">
    <div class="card-content">
      <div class="px-3 mt-3" *ngIf="targetDoc && dynamicFields">
        <lad-dynamic-form #dynamicFormElement (onInitDone)="onFormInitDone()" [preSaveFn]="preSaveFn" [postSaveFn]="postSaveFn"
                          [dynamicFields]="dynamicFields" submitType="global" [targetDocument]="targetDoc">
        </lad-dynamic-form>
      </div>
    </div>
  </div>