import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {LadInput} from '../../../../app/dynamic-form/components/lad-input/lad-input.component';
import {BbHandsontable} from '../../../../app/dynamic-form/components/bb-handsontable/bb-handsontable.component';
import {GridSettings} from 'handsontable/settings';
import {Draw, DrawStr, initMaxBetObj, Raffle, RaffleSettings} from '../../models/utils/number-helper';
import {LadRadio} from '../../../../app/dynamic-form/components/lad-radio/lad-radio.component';
import {BlackStatus} from '../../models/utils/black-utils';
import {BlackCoordinator} from '../../models/black-coordinator';
import {DynamicFormService} from '../../../../app/dynamic-form/dynamic-form.service';
import {LadSectionTitle} from '../../../../app/dynamic-form/components/lad-section-title/lad-section-title.component';
import {distinctUntilChanged} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {BlackOwnerSettings} from '../../models/black-owner-settings';
import {LadSelect} from '../../../../app/dynamic-form/components/lad-select/lad-select.component';
import {createRaffleSettingsTable} from '../../models/utils/raffle-settings-table-helper';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {DynamicFormComponent} from "../../../../app/dynamic-form/form/dynamic-form.component";
import {LadCheckbox} from "../../../../app/dynamic-form/components/lad-checkbox/lad-checkbox.component";
import {RaffleSettingsEditor} from "../../components/raffle-settings-editor/raffle-settings-editor.component";
import {LadField} from "../../../../app/dynamic-form/models/lad-field";
// import {RaffleSettingsEditor} from "../../../../app/dynamic-form/components/raffle-settings-editor/raffle-settings-editor.component";

@Component({
  selector: 'app-coordinator-editor',
  templateUrl: './coordinator-editor.component.html',
  styleUrls: ['./coordinator-editor.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoordinatorEditorComponent implements OnInit, OnDestroy {

  subs = new Subscription();

  @Input()
  isNew?: boolean;

  @Input()
  targetDocument: BlackCoordinator;

  @Input()
  dynamicFields: {[key: string]: LadField<any>};

  @ViewChild('dynamicForm') dynamicForm: DynamicFormComponent;

  constructor(
    public activeModal: NgbActiveModal,
    private cd: ChangeDetectorRef,
    private dfs: DynamicFormService,
  ) {
  }

  async ngOnInit() {
    this.targetDocument.maxBet = this.targetDocument.maxBet || initMaxBetObj();
    this.dynamicFields = {
      fullName: new LadInput({
        row: 'row0',
        label: 'Coordinator Name',
        value: this.targetDocument.fullName,
        required: true,
        columnClass: 'col-md-3', // default is col-md-3
        inputClass: 'text-uppercase',
      }),
      status: new LadRadio({
        row: 'row0',
        label: 'Status',
        columnClass: 'col-md-4',
        value: this.targetDocument.status,
        items: [
          {
            label: 'Enabled', value: BlackStatus.enabled
          },
          {
            label: 'Disabled', value: BlackStatus.disabled
          }
        ]
      }),

      accessKey: new LadInput({
        row: 'row1.1',
        label: 'Access Key',
        value: this.targetDocument.accessKey,
        required: true,
        uuid: true,
        columnClass: 'col-md-3', // default is col-md-3
      }),
      mobileNumber: new LadInput({
        row: 'row1.1',
        label: 'Mobile No.',
        value: this.targetDocument.mobileNumber,
        required: false,
        columnClass: 'col-md-3', // default is col-md-3
        inputClass: 'text-uppercase',
      }),

      // SECTION
      unitPrizeSection: new LadSectionTitle({
        title: 'Unit Prize Settings',
        subTitle: 'Prize per piso taga raffle',
        visible: true,
      }),
      enablePrizes: new LadCheckbox({label: 'Enable custom prizes', value: this.targetDocument.enablePrizes}),
      unitPrizes: new RaffleSettingsEditor({
        row: 'unitPrize-row',
        value: this.targetDocument.unitPrizes,
        columnClass: 'col-md-6',
        mode: 'win',
        visible: !!this.targetDocument.enablePrizes
      }),
      // SECTION
      comissionsSection: new LadSectionTitle({
        title: 'Commissions Settings',
        subTitle: 'Percentage value must be a whole number.',
        visible: true,
      }),
      enableCommissions: new LadCheckbox({label: 'Enable custom commissions', value: this.targetDocument.enableCommissions}),
      commissions: new RaffleSettingsEditor({
        row: 'commission-row',
        value: this.targetDocument.commissions,
        columnClass: 'col-md-6',
        mode: 'commission',
        visible: !!this.targetDocument.enableCommissions
      }),
      // SECTION
      maxBetSection: new LadSectionTitle({
        title: 'Max Bet Settings',
        subTitle: 'Ang pinaka last na item ang masunod pag naay confict.',
        visible: true,
      }),
      enableMaxBet: new LadCheckbox({label: 'Enable custom max bet', value: this.targetDocument.enableMaxBet}),
      maxBet: new RaffleSettingsEditor({
        row: 'maxbet-row',
        value: this.targetDocument.maxBet,
        columnClass: 'col-lg-8',
        visible: !!this.targetDocument.enableMaxBet
      })
    };
    this.cd.markForCheck();
  }

  dfInitDone(): void {
    const sub2 =this.dynamicFields.enableMaxBet.formControl.valueChanges.subscribe(val => {
      this.dynamicFields.maxBet.visible = val;
      this.cd.markForCheck();
    });
    this.subs.add(sub2);

    const sub3 =this.dynamicFields.enableCommissions.formControl.registerOnChange(val => {
      console.log(val);
      this.dynamicFields.commissions.visible = val;
    });
    this.subs.add(sub3);

    const sub4 =this.dynamicFields.enablePrizes.formControl.registerOnChange(val => {
      this.dynamicFields.unitPrizes.visible = val;
    });
    this.subs.add(sub4);
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  async onCancelDelete() {
    let action = 'delete';
    if(this.isNew){
      action = 'cancel';
    } else {
      const confirm = await this.dfs.fireSwalConfirm(`You're about to delete coordinator ${this.targetDocument.fullName.toUpperCase()}.`, 'warning', 'Confirm Delete?');
      if (!confirm.isConfirmed) {
        return;
      }
    }
    this.activeModal.close(action);
  }

  onClose() {
    Object.assign(this.targetDocument, this.dynamicForm.form.getRawValue());
    this.activeModal.close(this.targetDocument);
  }

}
