
<h2 class="card-title">
  <i class="ft-grid"></i>
  &nbsp;Coordinators
</h2>

<section id="responsive-table">

  <div class="col-md-12" style="padding: 0px !important;">
    <fieldset class="col-md-4" style="padding: 0px !important; max-height: 30px;">
      <form (ngSubmit)="doSearch()">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Enter search key" [(ngModel)]="searchKey" [ngModelOptions]="{standalone: true}">
          <div class="input-group-append">
            <button class="btn btn-primary" type="submit">Search</button>
          </div>
        </div>
      </form>
    </fieldset>
  </div>

  <div class="row">
    <div class="col-12 col-xl-7">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="d-sm-flex mb-3 justify-content-between table-striped">
              <div>
                <a class="ml-1 btn btn-primary" type="button" (click)="coordinatorEditorDialog(null, true)">New</a>
              </div>
            </div>
            <div class="table-responsive mb-3">
              <table class="table table-striped mb-0">
                <thead class="table-bordered">
                  <tr>
                    <th style="width: 150px">AGENTS</th>
                    <th scope="col">FULL NAME</th>
                    <th scope="col" style="width: 100px">STATUS</th>
                    <th scope="col" >Areas</th>
                  </tr>
                </thead>
                <tbody class="table-bordered">
                <tr *ngFor="let i of coordinatorsArr; let idx = index">
                  <td class="text-truncate" >
                    <a class="info p-0 " data-placement="top" data-toggle="tooltip"
                       [queryParams]="{id: i.documentId}"
                       routerLink="/pages/black/agents" title="Show agents">
                      <i class="ml-1 ft-edit font-medium-2 mr-1"></i>
                      {{i.agentCount ? i.agentCount : '0'}}
                    </a>
                  </td>
                  <td >
                    <a class="info p-0 " data-placement="top" data-toggle="tooltip"
                       (click)="coordinatorEditorDialog(i)" title="Edit Coordinator">
                      <i class="ml-1 ft-edit mr-1"></i>
                      <span [innerHTML]="i.fullName?.toUpperCase()"></span>
                    </a>
                  </td>
                  <td>
                    <div class="badge badge-pill ng-star-inserted" [ngClass]="{'bg-light-info': i.status === BlackStatus.enabled, 'bg-light-warning':  i.status !== BlackStatus.enabled }"> {{i.status?.toUpperCase()}} </div>
                  </td>
                  <td>
                    {{i.areas?.join(', ') | uppercase}}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
