import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LadExpressionChallengeComponent } from './lad-expression-challenge/lad-expression-challenge.component';
import {NgbModule, NgbProgressbarModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  declarations: [LadExpressionChallengeComponent],
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    CommonModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    NgbModule
  ]
})
export class LadExpressionChallengeModule { }
