import {Subscription} from 'rxjs';
import {FormControl} from '@angular/forms';
import {EventEmitter} from '@angular/core';
import {v4 as uuid} from 'uuid';

export type LadFieldValueChangesListener = (newValue: any) => void;

export class LadFieldOptions<T> {
  value?: T;
  visible?: boolean;
  disabled?: boolean;
  row?: string;
  columnClass?: string;
  label?: string;
  labelClass?: string;
  required?: boolean;
  excludeAtSave?: boolean;
  // component implementation must check if a FormControl is supplied and create it if not present.
  formControl?: FormControl;
  mappingFunction?: (targetDocument: any, value: any) => Promise<any>
}

export class LadField<T> implements LadFieldOptions<T> {
  formControl?: FormControl;
  formValidators?: [];
  key: string; // given key id
  visible = true;
  row?: string;
  columnClass: string;
  label: string;
  labelClass: string;
  __componentType: any;

  // todo: deprecate
  fieldValueChanges = new EventEmitter<T>();
  required: boolean;
  disabled: boolean;
  editable?: boolean;

  private __value: T; // todo: yes deprecate!

  constructor(options: LadFieldOptions<T>) {
    Object.assign(this, options);
    // should set the defaults here or else they'll be overridden with null values
    this.labelClass = this.labelClass ? this.labelClass : 'text-bold-500 primary';
    this.columnClass = this.columnClass ? this.columnClass : 'col-md-3';
    this.key = this.key ? this.key : uuid();
  }

  get value(): T {
    return this.__value;
  }

  set value(newVal: T) {
    if (this.__value === newVal) {
      return;
    }
    this.__value = newVal;
    this.fieldValueChanges.emit(this.__value);
    if (this.formControl && this.formControl.value !== newVal) {
      this.formControl.patchValue(newVal, {emitModelToViewChange: true});
    }
  }

  excludeAtSave?: boolean;

  mappingFunction = null;

}
