import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export const MONTHS = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

@Injectable()
export class CustomDateFormatterService extends NgbDateParserFormatter {

  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split(' ');
      const day = parseInt(dateParts[1].replace(',', ''), 10);
      const month = MONTHS.indexOf(dateParts[0]) + 1;
      const year = parseInt(dateParts[2], 10);
      return { day, month, year };
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    if (!date) {
      return '';
    }
    const monthName = MONTHS[date.month - 1];
    return `${monthName} ${date.day}, ${date.year}`;
  }
}

