<div class="modal-header">
  <h4 class="modal-title">
    <i class="ft-grid mr-1"></i>
    View Transaction</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="d-flex justify-content-between mx-3">
    <div class="text-uppercase">{{transaction.draw}}</div>
    <div>{{transaction.transactionId}} = {{transaction.total | number: '1.2-2'}}</div>
    <div>{{transaction.time}}</div>
  </div>
  <table class="table table-responsive-sm mt-2 col-md-12" >
    <thead class="">
    <tr>
      <th class="text-left" scope="col">Bet</th>
      <th class="text-right" scope="col">Amount</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let i of transaction?.bets; let idx = index">
      <td class="text-left" style="min-width: 150px">
        #{{idx + 1}} {{i.raffle}}-{{i.combination}}
      </td>
      <td class="text-right" style="text-align: right">
        {{i.amount | number: '1.2-2'}}
      </td>
    </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('Close click')">Close</button>
</div>
