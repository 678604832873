import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {QtBusinessModel,QtContactPerson, getStoreData} from '../../models/qt-business.model';
import {DynamicFormService} from '../../../../app/dynamic-form/dynamic-form.service';
import {APIService} from '../../../../app/shared/backend/api.service';
import { QtModelNames } from '../../models/qt-model.names';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BbHandsontable} from '../../../../app/dynamic-form/components/bb-handsontable/bb-handsontable.component';
import {GridSettings} from 'handsontable/settings';
import {getSampleData, JPEProjectLot} from '../../../../projects/jpe-app/models/jpe-project.model';
import {QtProductModel} from '../../models/qt-product.model';
@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss']
})
export class StoreComponent implements OnInit {
  contactsArr: QtContactPerson[];  
  dynamicFields: any;
  newDoc = new QtBusinessModel();

  constructor(
    private cdr: ChangeDetectorRef,
    private dfs: DynamicFormService,
    private api: APIService,
    public modalService: NgbModal
    ) {   
      // 
      

  } 

  ngOnInit(): void {
    QtBusinessModel.search({searchKey: null}).then(e =>{
      if(e.length === 0){
        QtBusinessModel.update({'name':'test'});
      } 
    });
    this.dynamicFields = {
      handsontable1: new BbHandsontable({
        gridSettings: async () => {
          return this.getHansdontableSampleGridSettings();
        }        
      }),
    }
  }

  getHansdontableSampleGridSettings() {
    const p = getStoreData();
    const items = this.newDoc.contacts;
    const columnDef =  [
      {
        width: 50,
        title: '<b>Name</b>',
        data: 'name'
      },
      {
        width: 50,
        title: '<b>Address</b>',
        data: 'address',
      },
      {
        width: 50,
        title: '<b>Position/SQM</b>',
        data: 'position'
      },
      {
        width: 50,
        title: '<b>Email</b>',
        data: 'email'
      },
      {
        width: 50,
        title: '<b>Contact Number</b>',
        data: 'number'
      }
    ];
    const gridSettings: GridSettings = {
      readOnlyCellClassName: 'text-muted',
      rowHeights: 45,
      
      columnHeaderHeight: 45,
      rowHeaders: false,
      licenseKey: 'non-commercial-and-evaluation',
      data : items,
      columns : columnDef,
      dataSchema : () => {
        return new QtBusinessModel();
      },
      contextMenu: ['remove_row', '---------', 'row_below', 'row_above', '---------',  'copy', 'cut', '---------', 'undo', 'redo'],
      allowRemoveRow: true,
      fixedColumnsLeft: 0,
      stretchH: 'all',
      colWidths: columnDef.map((each: any) => each.width ? each.width : 100),
      height: '60vh',
      beforePaste : (data, coords) => {
        for (let rindex = 0 ; rindex < data.length; rindex++ ) {
          for (let colIndex = 0; colIndex < data[rindex].length; colIndex++ ) {
            const val = data[rindex][colIndex];
            if (typeof val === 'string') {
              data[rindex][colIndex] = val.trim();
            }
          }
        }
      }
    };
    return gridSettings;
  }

  // refresh(){
    // QtContactPerson.search({searchKey: null}).then(e => {
    //   this.productsArr = e;
    //   this.cdr.detectChanges();
    // });
  // }

  // async delete(productId: QtContactPerson){ 
    // const response = await this.dfs.fireSwalConfirm('Confirm Establishment deletion? This will no longer be available list', 'warning', 'Delete');
    // if(!response.isConfirmed){
    //     return
    // }
    // await this.api.deleteDocument(productId.documentId,QtModelNames.BUSINESS).toPromise();
    // this.cdr.markForCheck(); 
    // this.refresh();
  // }

  onFormInitDone() {
    let search = QtBusinessModel.search({searchKey: null})
    if(!search){
      console.log('test')
    }
  }

  async preSaveFn(updatedDoc) {
    updatedDoc.fromPresave = updatedDoc.fromPresave ? updatedDoc.fromPresave++ : 1;
    return updatedDoc;
  }

  async postSaveFn(finalDoc) {
    console.log(`postsave: \n${JSON.stringify(finalDoc, null, 4)}`);
  }

  addNewLot() {
    this.newDoc.contacts.push(new QtContactPerson());
  }
}
