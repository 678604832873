export function AutoUnsub() {
  return function (constructor) {
    const orig = constructor.prototype.ngOnDestroy;
    constructor.prototype.ngOnDestroy = function () {
      for (const prop of Object.keys(this)) {
        const property = this[prop];
        if (!property) {
          continue;
        }
        if (typeof property.subscribe === 'function') {
          property.unsubscribe();
          console.log(`unsubscribed: ${prop}`);
        }
      }
      orig.apply()
    }
  }
}
