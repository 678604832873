<div class="modal-header">
  <h4 class="modal-title">{{item.raffle | uppercase}} - {{item.label}}</h4>
  <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
    x
  </button>
</div>
<div class="modal-body">
  <app-dotted-table #dottedTable [settings]="tableSettings"></app-dotted-table>
</div>

