<div [formGroup]="formGroup" class="form-group">
  <label *ngIf="formField.label" [attr.for]="formField.key" [class]="formField.labelClass">
    {{formField.label}}
    <a *ngIf="!isValid" class="ft-info font-small-3 blinking"></a>
  </label>
  <div class="custom-file">
    <input [id]="formField.key" ng2FileSelect [uploader]="uploader" class="custom-file-input" multiple="true" type="file">
    <label [for]="formField.key" class="custom-file-label">{{message}}</label>
  </div>

  <ngb-carousel *ngIf="_album.length > 0" #carousel [pauseOnHover]="true" ngbTooltip="Click to open in full screen.">
    <ng-template *ngFor="let item of _album; let i = index;" class="ft-align-center" ngbSlide>
      <img (click)="open(i)" [alt]="item.caption" [src]="item.src"
           style="cursor: pointer; object-fit: scale-down; width: 100%; max-height: 50vh; min-height: 50vh; position: relative;">
      <div class="carousel-caption">
        <!--        <h3 class="ff-serif text-uppercase">First slide label</h3>-->
        <p class="mb-0" style="color: #9E9E9E;">{{item.caption}}</p>
      </div>
    </ng-template>
  </ngb-carousel>
  <div *ngIf="_album.length > 0" class="d-flex justify-content-start align-items-start">
    <i (click)="removeItem()" (mouseover)="carousel.pause()"
       class="font-medium-1 ft-trash-2 text-danger cursor-pointer mt-1"
       ngbTooltip="Remove current item." placement="left"></i>
  </div>

  <code *ngIf="!isValid" class="font-small-1 mr-1">*Invalid!</code>
</div>


