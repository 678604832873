<div class="modal-header">

  <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
    x
  </button>
</div>
<div class="modal-body">
  <div class="px-md-3">
    <h3 class="text-center mb-3">Winners</h3>
    <div class="table-responsive mb-3">
      <table class="table table-striped mb-0">
        <thead class="table-bordered">
        <tr>
          <th style="width: 150px">Ref#</th>
          <th scope="col">RAFFLE</th>
          <th scope="col">AMOUNT</th>
          <th scope="col">WINS</th>
        </tr>
        </thead>
        <tbody class="table-bordered">
        <tr *ngFor="let d of data; let idx = index">
          <td class="text-truncate">
            {{d?.transactionId.toUpperCase()}} &nbsp; &nbsp;
          </td>
          <td>
            {{d?.raffle.toUpperCase()}}
          </td>
          <td>
            {{d?.total}}
          </td>
          <td>
            {{d?.bets.length}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
