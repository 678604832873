<label *ngIf="formField.label" [attr.for]="formField.key" [class]="formField.labelClass">
  {{formField.label}}
  <a *ngIf="!isValid" class="ft-info font-small-3 blinking"></a>
</label>
<br *ngIf="!isValid"/>
<code *ngIf="!isValid" class="font-small-1 mr-1">No photo added.</code>

<div class="media" style="padding-top: 5px !important;">
  <img [src]="portraitSource"
       alt="<<Portrait>>"
       class="rounded mr-3"
       height="64"
       onerror="this.onerror=null;this.src='assets/img/portrait/blur-girl.jpg';" style="object-fit: cover;"
       width="64">
  <div class="media-body">
    <div class="col-12 d-flex flex-sm-row flex-column justify-content-start px-0 mb-sm-2">
      <button (click)="selectPortraitFile()" class="btn btn-sm bg-light-primary" type="button">UPLOAD PHOTO</button>
    </div>
    <p class="text-muted mb-0 mt-1 mt-sm-0 font-small-2">
      {{message}}
    </p>
  </div>
  <br/>
</div>

