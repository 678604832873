import {ChangeDetectorRef, Injectable} from '@angular/core';
import {BehaviorSubject, fromEvent} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {SystemDocument} from 'ladrov-commons';
import {APIService} from '../shared/backend/api.service';
import {ToastrService} from 'ngx-toastr';
import swal from 'sweetalert2';
import {DynamicFormService} from './dynamic-form.service';

export interface PostSaveFnParam {
  finalDoc: SystemDocument,
  response: any
}

export interface DynamicFormContextInterface {
  targetDocument: SystemDocument;
  form: FormGroup;
  preSaveFn: (doc: SystemDocument | any) => Promise<SystemDocument | any>,
  postSaveFn: (doc: PostSaveFnParam | any) => Promise<SystemDocument | any>,
  saveUrl: string,
  customSaveFn?: (doc: SystemDocument | any) => Promise<SystemDocument | any>,
  noConfirmAndFormCheck: boolean;
  allowDelete?: boolean;

}

export class DynamicFormContext implements DynamicFormContextInterface {

  constructor(
    public targetDocument: SystemDocument,
    public form: FormGroup,
    public preSaveFn: (doc: SystemDocument | any) => Promise<SystemDocument | any>,
    public postSaveFn: (doc: PostSaveFnParam | any) => Promise<SystemDocument | any>,
    public saveUrl = '/api/document',
    public customSaveFn?: (doc: SystemDocument | any) => Promise<SystemDocument | any>,
    public noConfirmAndFormCheck = false,
    public postDeleteFn?: (doc: SystemDocument | any) => Promise<SystemDocument | any>,
    public allowDelete?: boolean
  ) {
  }

}

@Injectable()
export class DynamicFormSaveService { // handles the global save button and keyboard shortcut for saving

  private initDone = false;
  public currentContext: BehaviorSubject<DynamicFormContext> = new BehaviorSubject(null);

  constructor(
    private toastr: ToastrService,
    private api: APIService,
    private dfs: DynamicFormService,
  ) {
  }

  private initialise() {
    if (this.initDone) {
      return;
    }
    this.initDone = true;
    // create observable that emits click events
    const source = fromEvent<KeyboardEvent>(window, 'keydown');
    source.subscribe(event => {
      if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 's') {
        // console.log(`${event.metaKey || event.ctrlKey} -> ${event.key.toLowerCase()}`);
        const context = this.currentContext.getValue();
        if (context) {
          this.triggerSave(context);
        }
        event.preventDefault();
      }
    });
  }

  public setCurrentContext(context: DynamicFormContext) {
    this.initialise();
    this.currentContext.next(context);
  }

  public async triggerSave(context?: DynamicFormContext, options: {noConfirmAndFormCheck?: boolean, isDelete?: boolean} = {}) {
    console.log(`Dynamic Form Save Service: Save triggered.`);
    const currentContext = this.currentContext.getValue();
    context = !context && currentContext != null ? currentContext : context;
    // param is priority
    options.noConfirmAndFormCheck = options.noConfirmAndFormCheck ? true : !!context.noConfirmAndFormCheck;

    const confirm = !options.noConfirmAndFormCheck &&  !options.isDelete;
    if (confirm) {
      let msg = !context ? 'No active form' : null;
      if (context?.form?.pristine) {
        msg = 'Form is untouched.';
      } else if (!context?.form?.valid) {
        msg = 'Please fill up the form with the required values.';
      }
      if (msg) {
        this.dfs.getToaster().warning(msg, 'Save aborted', {positionClass: 'toast-top-center'});
        return;
      }
      const confirm = await this.dfs.fireSwalConfirm('Please ensure all changes are correct.', 'info', 'Confirm Save');
      if (!confirm.isConfirmed) {
        return;
      }
    }

    const {targetDocument, form, postSaveFn, preSaveFn, saveUrl, customSaveFn, postDeleteFn} = context;
    let finalDoc = Object.assign(targetDocument, form.getRawValue());
    if (preSaveFn) {
      try {
        const presaveResult = await preSaveFn(finalDoc);
        if (presaveResult) {
          finalDoc = presaveResult;
        }
      } catch (e) {
        console.error(e);
        this.toastr.error(e.message ? e.message : `Error on pre-save.`, 'Aborted: Pre-save failed.', {positionClass: 'toast-top-center'});
        return;
      }
    }

    let response;
    try {
      if (customSaveFn) {
        response = await customSaveFn(finalDoc);
      } else if (options.isDelete) {
        if (!finalDoc.systemHeader?.type || !finalDoc.documentId) {
          throw new Error('Target document is not a system document.');
        }
        response = await this.api.deleteDocument(finalDoc.documentId, finalDoc.systemHeader.type).toPromise();
      } else {
        response = await this.api.saveDocument(finalDoc, saveUrl).toPromise();
      }
      form.markAsUntouched();
      form.markAsPristine();
    } catch (e) {
      console.error(e);
      swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: e.error ? e.error : e.message ? e.message : 'Something went wrong during save! Please try again.',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      });
      return;
    }

    if (!options.noConfirmAndFormCheck) {
      const msg = options.isDelete ? `Your data was deleted.` : `Your changes were saved.`;
      this.toastr.success(msg, undefined, {positionClass: 'toast-top-center'});
    }

    try {
      if (options.isDelete) {
        await postDeleteFn({response, finalDoc});
      } else if (postSaveFn) {
        await postSaveFn({response, finalDoc});
      }
    } catch (e) {
      console.error(e);
      this.toastr.error(e.message ? e.message : `Error on post-save.`, 'Post-save Sequence Failed');
    }

  }

  public async triggerDelete(context?: DynamicFormContext) {
    const input = await swal.fire({
      icon: 'warning',
      // title: 'Delete',
      text: 'Please enter the word "delete" to continue:',
      input: 'text',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
    });

    if (input.isConfirmed && input.value === 'delete') {
      await this.triggerSave(context, {isDelete: true});

    }
  }
}
