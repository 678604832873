import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {LadDate} from 'ladrov-commons';
import {debounceTime} from 'rxjs/operators';
import {ChartComponent} from 'ng-apexcharts';
import {DynamicFormComponent} from '../../../../app/dynamic-form/form/dynamic-form.component';
import {LadRadio} from '../../../../app/dynamic-form/components/lad-radio/lad-radio.component';
import {LadInput} from '../../../../app/dynamic-form/components/lad-input/lad-input.component';
import {LadSelect} from '../../../../app/dynamic-form/components/lad-select/lad-select.component';
import {GridSettings} from 'handsontable/settings';
import {Draw, num, numberThousand, Raffle} from '../../models/utils/number-helper';
import Handsontable from 'handsontable';
import {BlackAgentSummary} from '../../models/black-agent-summary';
import {BlackCoordinator} from '../../models/black-coordinator';
import {BlackAgent} from '../../models/black-agent';
import {Subject} from 'rxjs';
import {BlackNumberSummary, GameSheetItem, GameSheetItemDetail} from '../../models/black-number-summary';
import {BlackGameSheet} from '../../models/black-game-sheet';
import {BlackTransaction} from '../../models/black-transaction';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {GameSheetItemDetailsDialogComponent} from './game-sheet-item-details-dialog-component/game-sheet-item-details-dialog.component';
import {DottedTableComponent, DottedTableSettings} from './dotted-table/dotted-table.component';
import {BLACK_DRAW_ARR, BLACK_RAFFLE_ARR, DATE_FILTER_ARR, DateFilterValues} from '../../models/utils/black-utils';

const formatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 0,
  useGrouping: true,
});

export class AgentSummaryItem {
  agentName = 'n/a' + Math.random();
  areas = '';
  gross = 0;
  commissions = 0;
  wins = 0;
  net = 0;
}

interface GCDynafield {
  dateFilter;
  startDate;
  endDate;
  draw;
  reportType;
  raffle,
  coordinator;
  agent;
}

@Component({
  selector: 'app-game-center',
  templateUrl: './game-center.component.html',
  styleUrls: ['./game-center.component.scss']
})
export class GameCenterComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('dynamicFormElement') dynamicFormElement: DynamicFormComponent;
  @ViewChild('apxChart1') apxChart1: ChartComponent;
  @ViewChild('dottedTable') dottedTable: DottedTableComponent;

  private renderReportSubject = new Subject<void>();
  gameSheetItemDetails: GameSheetItemDetail[] = null;

  pollerHandler;
  pollerProcessing = false;

  summary: any;
  dynamicFields = {
    dateFilter: new LadRadio({
      row: 'row1',
      label: 'Date Filter:',
      columnClass: 'col-md-12',
      value: DateFilterValues.TODAY,
      items: DATE_FILTER_ARR
    }),
    startDate: new LadInput({
      row: 'row2',
      label: 'Start Date',
      value: new LadDate(),
      required: true,
      inputType: 'date',
      visible: false
    }),
    endDate: new LadInput({
      row: 'row2',
      label: 'End Date',
      value: new LadDate(),
      required: true,
      inputType: 'date',
      visible: false
    }),
    draw: new LadRadio({
      row: 'row3',
      label: 'Select Draw:',
      columnClass: 'col-md-3',
      value: Draw.ALL,
      items: BLACK_DRAW_ARR
    }),
    reportType: new LadRadio({
      row: 'row3',
      label: 'Report Type:',
      columnClass: 'col-md-3',
      value: 'gameSheet',
      items: [
        {
          label: 'GSheet Chart', value: 'gameSheet'
        },
        {
          label: 'GSheet Table', value: 'gameSheetTable'
        },
        {
          label: 'Summary', value: 'summary'
        }
      ]
    }),
    raffle: new LadRadio({
      row: 'row3',
      label: 'Select Raffle:',
      columnClass: 'col-md-4',
      value: Raffle.l2,
      items: BLACK_RAFFLE_ARR.filter(each => each.value !== Raffle.ALL)
    }),
    coordinator: new LadSelect({
      row: 'row5',
      label: 'Coordinators:',
      placeholder: 'Select Coordinators',
      items: [],
      multiple: false,
      columnClass: 'col-sm-6 col-md-3 col-lg-3',
      visible: true,
      closeOnSelect: true,
      virtualScroll: true
    }),
    agent: new LadSelect({
      row: 'row5',
      label: 'Agents:',
      placeholder: 'Select Agents',
      items: [],
      multiple: false,
      columnClass: 'col-sm-6 col-md-3 col-lg-3',
      visible: false,
      closeOnSelect: true,
      virtualScroll: true,
    }),
    gameSheetOrderBy: new LadRadio({
      row: 'row6',
      label: 'Order By:',
      columnClass: 'col-md-4',
      value: 'amount',
      items: [
        {
          label: 'Amount', value: 'amount'
        },
        {
          label: 'Number', value: 'number'
        },

      ]
    }),
  };

  // game sheet info
  gameSheetChartInfo = {
    renderChartDone: false,
    lineChartOptions: null,
    gameSheets: [],
    currentGameSheet: 'default'
  };

  // agent summary info
  summaryTableInfo = {
    agentCount: 0,
    gross: 0,
    wins: 0,
    commissions: 0,
    net: 0,
    handsontable: null,
    htableContainerId: 'table1'
  };
  private lastPolledTransactionId: string;
  public reportType: 'gameSheet' | 'summary' | 'gameSheetTable' = 'gameSheet';
  public gameSheetItems: GameSheetItem[] = [];

  public gameSheetTableSettings: DottedTableSettings = {
    cols : [{fieldName: 'label', style: {'min-width': '85px'}}, {fieldName: 'value', isNumber: true}],
    rows: 25,
    items: [],
    onItemClick: async (item: GameSheetItem) => {
      const modalRef = this.modalService.open(GameSheetItemDetailsDialogComponent, {size: 'lg'});
      const instance: GameSheetItemDetailsDialogComponent = modalRef.componentInstance;
      instance.item = item;
    }
  };

  private selectedDraw: Draw;
  private selectedRaffle: Raffle;

  gameSheetInfo = {
    items: 0,
    gross: 0
  };

  constructor(
    private modalService: NgbModal,
    public cd: ChangeDetectorRef
  ) {
    this.renderReportSubject.pipe(debounceTime(256)).subscribe(() => this.debouncedRenderReport());
  }

  ngOnDestroy(): void {
    this.renderReportSubject.unsubscribe();
    if (this.pollerHandler) {
      clearInterval(this.pollerHandler);
    }
  }

  ngAfterViewInit(): void {
    // throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    BlackGameSheet.getGameSheets().then(sheets => {
      this.gameSheetChartInfo.gameSheets = [...sheets];
      this.cd.markForCheck();
    });
  }

  onFormInitDone() {
    // set all coors
    BlackCoordinator.search({}).then(results => {
      const coors = [];
      for (const r of results) {
        coors.push({label: r.fullName?.toUpperCase(), value: r.documentId});
      }
      this.dynamicFields.coordinator.setItems(coors);
    });
    // hide agents if no coordinator selected
    this.dynamicFields.coordinator.ngModelChange = (coordinatorId => {
      this.dynamicFields.agent.visible = !!coordinatorId;
      this.dynamicFields.agent.formControl.setValue(null, {emitModelToViewChange: true})
      if (coordinatorId) {
        BlackAgent.getAgentByCoorId(coordinatorId).then( (agents: BlackAgent[])=> {
          const r = [];
          for (const a of agents) {
            r.push({label: a.name?.toUpperCase(), value: a.documentId});
          }
          this.dynamicFields.agent.setItems(r);
          this.cd.markForCheck();
        })
      } else {
        this.dynamicFields.agent.setItems([])
      }
      this.renderReport();
    });
    // on agent change render report
    this.dynamicFields.agent.ngModelChange = () => {
      this.renderReport();
    };
    // on changes
    this.dynamicFormElement.form.valueChanges.subscribe(value => {
      const showDate  = value.dateFilter === DateFilterValues.CUSTOM_DATE;
      const showRange = value.dateFilter === DateFilterValues.CUSTOM_RANGE;

      const startDateComponent = this.dynamicFields.startDate;
      startDateComponent.visible = showDate || showRange;
      startDateComponent.label = showRange ? 'Start Date' : 'Date';
      this.dynamicFields.endDate.visible = showRange;

      const isGameSheet = value.reportType !== 'summary';
      this.dynamicFields.raffle.visible = isGameSheet;
      this.dynamicFields.gameSheetOrderBy.visible = isGameSheet;

      this.renderReport();
    });
    // load game sheet initially
    this.renderReport();

    this.pollerHandler = setInterval(async () => {
      if (this.pollerProcessing) {
        return;
      }
      const details = await BlackTransaction.getLatestBlackTransactionDetails({});
      if (details.transactionId !== this.lastPolledTransactionId) {
        this.lastPolledTransactionId = details.transactionId;
        this.renderReport();
      }

    }, 2000)
  }

  private getReportStartEndDates(formValue) {
    let startDate: Date;
    let endDate: Date;
    const currentDate = new Date();
    switch (formValue.dateFilter) {
      case DateFilterValues.MONTH:
        currentDate.setDate(1);
        startDate = new Date(currentDate.toLocaleDateString());
        currentDate.setMonth(currentDate.getMonth() + 1);
        currentDate.setDate(0);
        endDate = new Date(currentDate.toLocaleDateString());
        break;
      case DateFilterValues.WEEK:
        // Calculate the current day of the week (0-6, where 0 is Sunday)
        const currentDay: number = currentDate.getDay();
        // Calculate the difference between the current day and Monday
        const diff: number = currentDay === 0 ? 6 : currentDay - 1;
        // Calculate the start date of the current week (Monday)
        startDate = new Date(currentDate.setDate(currentDate.getDate() - diff));
        // Calculate the end date of the current week (Sunday)
        endDate = new Date(currentDate.setDate(currentDate.getDate() + 6));
        break;
      case DateFilterValues.CUSTOM_DATE:
        startDate = new Date(formValue.startDate.date);
        endDate = new Date(formValue.endDate.date);
      default: // today
        startDate = new Date(currentDate);
        endDate = new Date(startDate);
        break;
    }
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    return {startDate, endDate};
  }

  private applyCap(items: GameSheetItem[], raffle: Raffle) {
    const cur = this.gameSheetChartInfo.currentGameSheet;
    if (cur === 'default') {
      return;
    }
    const sheets: BlackGameSheet[] = this.gameSheetChartInfo.gameSheets;
    let gameSheet: BlackGameSheet;
    for (const c of sheets) {
      if (c.documentId === cur) {
        gameSheet = c;
      }
    }

    let capLimit = 0;
    for (const l of gameSheet.limits) {
      if (l.raffleId === raffle) {
        capLimit = num(l.value);
      }
    }
    const finalItems = [];
    for (const i of items) {
      i.value = num(i.value);
      if (i.value > capLimit) {
        i.value = i.value - capLimit;
        finalItems.push(i);
      }
    }
    return finalItems;
  }

  private async getGameSheetItems(startDate: Date, endDate: Date, draw: any, raffle: any, coordinator: any, agent: any) {
      let summaries = await BlackNumberSummary.getGameSheetItems({startDate, endDate, draw, raffle, coordinator, agent});
      if (summaries.length === 0) {
        this.gameSheetInfo.gross = 0;
        this.gameSheetInfo.items = 0;
        return [];
      }
      const capResult = this.applyCap(summaries, raffle);
      if (capResult) {
        summaries = capResult;
      }
      // order
      const orderBy = this.dynamicFields.gameSheetOrderBy.formControl.value;
      switch (orderBy) {
        case 'number':
          summaries.sort((a, b) => a.combination - b.combination);
          break;
        case 'amount':
        default:
          summaries.sort((a, b) => b.value - a.value);
      }

      this.gameSheetInfo.items = summaries.length;
      this.gameSheetInfo.gross = 0;
      for (const i of summaries) {
        this.gameSheetInfo.gross += i.value;
      }

      return summaries;
  }

  private async generateChart(startDate: Date, endDate: Date, draw: any, raffle: any, coordinator: any, agent: any) {
    let summaries = await this.getGameSheetItems(startDate, endDate, draw, raffle, coordinator, agent);
    // do generate apex chart
    let categories = [];
    const s1 = {
      name: 'Total Bet Amount',
      data: []
    };
    const series = [s1];
    let total = 0;
    for (const summ of summaries) {
      categories.push(summ.label);
      s1.data.push(summ.value);
      total += summ.value;
    }
    const height = Math.max(40 * (summaries.length ? summaries.length : 10), 150);
    const titleText = `${summaries.length} Numbers = ${numberThousand(total)}`;
    return {
      series,
      chart: {
        type: 'bar',
        height,
        stacked: true,
        events: {
          xAxisLabelClick: function(event, chartContext, config) {
            console.log(event, chartContext, config);
            debugger;
          },
          dataPointSelection: function(event, chartContext, config) {
            console.log(event, chartContext, config);
            debugger;
          }
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            total: {
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '13px',
                fontWeight: 900
              }
            }
          }
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      xaxis: {
        categories,
        labels: {
          formatter: function (val) {
            return val
          }
        }
      },
      yaxis: {
        title: {
          text: undefined
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val
          }
        }
      },
      fill: {
        opacity: 1
      },
      // title: {
      //   text: titleText,
      //   align: 'left',
      //   offsetX: 10,
      //   offsetY: 10,
      // }
    };
  }

  public renderReport() {
    const formValue = this.dynamicFormElement.form.getRawValue() as GCDynafield;
    const {reportType} = formValue;
    this.reportType = reportType;
    this.gameSheetItemDetails = null;
    this.renderReportSubject.next();
    this.cd.detectChanges();
  }

  private async generateSummaryTable(startDate, endDate, draw, raffle, coordinator, agent) {
    const args = {startDate, endDate, draw, raffle, coordinator, agent};
    const data = await BlackAgentSummary.getBlackAgentSummaries(args);
    // calc headers
    this.summaryTableInfo.agentCount = 0;
    this.summaryTableInfo.gross = 0;
    this.summaryTableInfo.commissions = 0;
    this.summaryTableInfo.wins = 0;
    this.summaryTableInfo.net = 0;
    for (const d of data) {
      this.summaryTableInfo.agentCount++;
      this.summaryTableInfo.gross += num(d.gross);
      this.summaryTableInfo.commissions += num(d.commissions);
      this.summaryTableInfo.wins += num(d.wins);
    }
    const {gross, commissions, wins} = this.summaryTableInfo;
    this.summaryTableInfo.net = gross - commissions - wins;
    this.cd.detectChanges();
    // calc headers
    const settings = createSummaryHandsOn(data);
    if (!this.summaryTableInfo.handsontable) {
      this.summaryTableInfo.handsontable = new Handsontable(
        document.getElementById(this.summaryTableInfo.htableContainerId),
        settings
      );
      this.summaryTableInfo.handsontable.render();
      return;
    }
    this.summaryTableInfo.handsontable.loadData(data);
    this.cd.markForCheck();
    // helper
    function createSummaryHandsOn(data) {
      let columnDef =  [
        {
          width: 50,
          title: 'AGENT',
          data: 'agentName'
        },
        {
          width: 30,
          title: 'ACTIVE',
          data: 'agentLastActive'
        },
        {
          width: 30,
          title: 'AREA',
          data: 'areas',
          renderer: function(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            td.textContent = value ? value.toUpperCase() : '';
          }
        },
        {
          width: 50,
          title: 'GROSS',
          data: 'gross'
        },
        {
          width: 50,
          title: 'COMMISSIONS',
          data: 'commissions'
        },
        {
          width: 50,
          title: 'WINS',
          data: 'wins'
        },
        {
          width: 50,
          title: `NET`,
          data: 'net'
        }
      ];
      const gridSettings: GridSettings = {
        columnSorting: true,
        readOnly: true,
        readOnlyCellClassName: 'table-txt',
        rowHeights: 40,
        columnHeaderHeight: 45,
        rowHeaders: true,
        licenseKey: 'non-commercial-and-evaluation',
        data,
        columns : columnDef,
        dataSchema : () => {
          return new AgentSummaryItem();
        },
        contextMenu: ['remove_row', '---------', 'row_below', 'row_above', '---------',  'copy', 'cut', '---------', 'undo', 'redo'],
        allowRemoveRow: false,
        fixedColumnsLeft: 0,
        stretchH: 'all',
        colWidths: columnDef.map((each: any) => each.width ? each.width : 100),
        height: '50vh',
        beforePaste : (data, coords) => {
          for (let rindex = 0 ; rindex < data.length; rindex++ ) {
            for (let colIndex = 0; colIndex < data[rindex].length; colIndex++ ) {
              const val = data[rindex][colIndex];
              if (typeof val === 'string') {
                data[rindex][colIndex] = val.trim();
              }
            }
          }
        }
      };
      return gridSettings;
    }
  }

  private async generateGameSheetChart(startDate: Date, endDate: Date, draw: any, raffle: any, coordinator: any, agent: any) {
    const newOptions= await this.generateChart(startDate, endDate, draw, raffle, coordinator, agent);
    if (newOptions) {
      if (!this.gameSheetChartInfo.lineChartOptions) {
        this.gameSheetChartInfo.lineChartOptions = newOptions;
      } else {
        this.apxChart1?.updateOptions(newOptions);
        this.gameSheetChartInfo.lineChartOptions = newOptions;
      }
    }
    this.cd.detectChanges();
  }

  private async debouncedRenderReport() {
    this.pollerProcessing = true;

    const formValue = this.dynamicFormElement.form.getRawValue() as GCDynafield;
    // do actual reporting
    const {startDate, endDate} = this.getReportStartEndDates(formValue);
    const {draw, raffle, coordinator, agent} = formValue;

    this.selectedRaffle = raffle;
    this.selectedDraw = draw;

    switch (this.reportType) {
      case 'gameSheet':
        await this.generateGameSheetChart(startDate, endDate, draw, raffle, coordinator, agent);
        break;
      case 'summary':
        await this.generateSummaryTable(startDate, endDate, draw, raffle, coordinator, agent);
        break;
      case 'gameSheetTable':
        const newItems = await this.getGameSheetItems(startDate, endDate, draw, raffle, coordinator, agent);
        this.gameSheetItems = [...newItems];
        this.dottedTable.setItems(this.gameSheetItems);
    }

    this.cd.markForCheck();

    // pooler continue
    this.pollerProcessing = false;
  }


  protected readonly JSON = JSON;
}
