import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LadInput} from '../../../../app/dynamic-form/components/lad-input/lad-input.component';
import {Draw} from '../../models/utils/number-helper';
import {LadRadio} from '../../../../app/dynamic-form/components/lad-radio/lad-radio.component';
import {BlackStatus} from '../../models/utils/black-utils';
import {LadCheckbox} from '../../../../app/dynamic-form/components/lad-checkbox/lad-checkbox.component';
import {BlackAgent} from '../../models/black-agent';
import {LadSectionTitle} from '../../../../app/dynamic-form/components/lad-section-title/lad-section-title.component';
import {Subscription} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LadField} from '../../../../app/dynamic-form/models/lad-field';
import {DynamicFormComponent} from '../../../../app/dynamic-form/form/dynamic-form.component';
import {BlackUserBase} from '../../models/black-user-base';
import {LadTime} from 'ladrov-commons';
import {RaffleSettingsEditor} from '../../components/raffle-settings-editor/raffle-settings-editor.component';
import {DynamicFormService} from "../../../../app/dynamic-form/dynamic-form.service";

@Component({
  selector: 'app-agent-editor',
  templateUrl: './agent-editor.component.html',
  styleUrls: ['./agent-editor.component.scss']
})
export class AgentEditorComponent implements OnInit, OnDestroy {

  sub = new Subscription();

  isNew?: boolean;
  targetDocument: BlackAgent;
  dynamicFields: {[key: string]: LadField<any>};

  @ViewChild('dynamicForm')
  dynamicForm: DynamicFormComponent;

  constructor(
    public activeModal: NgbActiveModal,
    private cd: ChangeDetectorRef,
    private dfs: DynamicFormService
  ) {
  }

  async ngOnInit() {
    if (!this.targetDocument.cutoffTime) {
      this.targetDocument.cutoffTime = {};
    }
    this.dynamicFields = {
      name: new LadInput({
        row: 'row0',
        label: 'Agent Name',
        value: this.targetDocument.name,
        required: true,
        columnClass: 'col-md-6', // default is col-md-3
        inputClass: 'text-uppercase',
      }),
      status: new LadRadio({
        row: 'row0.1',
        label: 'Status',
        columnClass: 'col-md-6',
        value: this.targetDocument.status,
        items: [
          {
            label: 'Enabled', value: BlackStatus.enabled
          },
          {
            label: 'Disabled', value: BlackStatus.disabled
          }
        ]
      }),
      accessKey: new LadInput({
        row: 'row1.1',
        label: 'Access Key',
        value: this.targetDocument.accessKey,
        required: true,
        uuid: true,
        columnClass: 'col-md-3', // default is col-md-3
      }),
      mobileNumber: new LadInput({
        row: 'row1.1',
        label: 'Mobile No.',
        value: this.targetDocument.mobileNumber,
        required: false,
        columnClass: 'col-md-3', // default is col-md-3
        inputClass: 'text-uppercase',
      }),

      enableCutOffTime: new LadCheckbox({label: 'Enable custom cut-off time', value: this.targetDocument.enableCutOffTime, columnClass: 'col-md-12'}),
      d1CutOffTime: new LadInput({
        row: 'row1',
        label: 'Draw1 Cutoff',
        value: this.targetDocument.cutoffTime[Draw.d1],
        required: true,
        columnClass: 'col-md-12 col-sm-12 col-lg-4',
        inputType: 'time',
        mappingFunction: async (target: BlackUserBase, val: LadTime) => {
          target.cutoffTime[Draw.d1] = val;
        },
        visible: !!this.targetDocument.enableCutOffTime
      }),
      d2CutOffTime: new LadInput({
        row: 'row1',
        label: 'Draw2 Cutoff',
        value: this.targetDocument.cutoffTime[Draw.d2],
        required: true,
        columnClass: 'col-md-12 col-sm-12 col-lg-4',
        inputType: 'time',
        mappingFunction: async (target: BlackUserBase, val: LadTime) => {
          target.cutoffTime[Draw.d2] = val;
        },
        visible: !!this.targetDocument.enableCutOffTime
      }),
      d3CutOffTime: new LadInput({
        row: 'row1',
        label: 'Draw3 Cutoff',
        value: this.targetDocument.cutoffTime[Draw.d3],
        required: true,
        columnClass: 'col-md-12 col-sm-12 col-lg-4',
        inputType: 'time',
        mappingFunction: async (target: BlackUserBase, val: LadTime) => {
          target.cutoffTime[Draw.d3] = val;
        },
        visible: !!this.targetDocument.enableCutOffTime
      }),

      maxBetSection: new LadSectionTitle({
        title: 'Max Bet Settings',
        subTitle: 'Ang pinaka last na item ang masunod pag naay confict.',
        visible: true,
      }),
      enableMaxBet: new LadCheckbox({label: 'Enable custom max bet', value: this.targetDocument.enableMaxBet}),
      maxBet: new RaffleSettingsEditor({
        row: 'maxbet-row',
        value: this.targetDocument.maxBet,
        columnClass: 'col-lg-8',
        visible: !!this.targetDocument.enableMaxBet
      }),

      commissionsSection: new LadSectionTitle({
        title: 'Commissions Settings',
        subTitle: 'Percentage value must be a whole number.',
        visible: true,
      }),
      enableCommissions: new LadCheckbox({label: 'Enable custom commissions', value: this.targetDocument.enableCommissions}),
      commissions: new RaffleSettingsEditor({
        row: 'commission-row',
        value: this.targetDocument.commissions,
        columnClass: 'col-md-6',
        mode: 'commission',
        visible: !!this.targetDocument.enableCommissions
      }),

      unitPrizeSection: new LadSectionTitle({
        title: 'Unit Prize Settings',
        subTitle: 'Prize per piso taga raffle',
        visible: true,
      }),
      enablePrizes: new LadCheckbox({label: 'Enable custom prizes', value: this.targetDocument.enablePrizes}),
      unitPrizes: new RaffleSettingsEditor({
        row: 'unitPrize-row',
        value: this.targetDocument.unitPrizes,
        columnClass: 'col-md-6',
        mode: 'win',
        visible: !!this.targetDocument.enablePrizes
      }),
    };
    this.cd.markForCheck();
  }

  dfInitDone() {
    const sub1 = this.dynamicFields.enableCutOffTime.formControl.valueChanges.subscribe(val => {
      const {d2CutOffTime, d1CutOffTime, d3CutOffTime} = this.dynamicFields;
      d1CutOffTime.visible = val;
      d2CutOffTime.visible = val;
      d3CutOffTime.visible = val;
    });
    this.sub.add(sub1);

    const sub2 = this.dynamicFields.enableMaxBet.formControl.valueChanges.subscribe(val => {
      this.dynamicFields.maxBet.visible = val;
      this.cd.markForCheck();
    });
    this.sub.add(sub2);

    const sub3 = this.dynamicFields.enableCommissions.formControl.valueChanges.subscribe(val => {
      this.dynamicFields.commissions.visible = val;
    });
    this.sub.add(sub3);

    const sub4 = this.dynamicFields.enablePrizes.formControl.valueChanges.subscribe(val => {
      this.dynamicFields.unitPrizes.visible = val;
    });
    this.sub.add(sub4);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async onCancelDelete() {
    let action = 'delete';
    if(this.isNew){
      action = 'cancel';
    } else {
      const confirm = await this.dfs.fireSwalConfirm(`You're about to delete agent ${this.targetDocument.name.toUpperCase()}.`, 'warning', 'Confirm Delete?');
      if (!confirm.isConfirmed) {
        return;
      }
    }
    this.activeModal.close(action);
  }

  onClose() {
    Object.assign(this.targetDocument, this.dynamicForm.form.getRawValue());
    this.activeModal.close(this.targetDocument);
  }
}
