<div class="container mt-5">
  <div class="row">
    <div class="col-md-6">
      <h3>Invoice</h3>
      <hr>
      <p><strong>Invoice Number:</strong> {{invoiceNo}}</p>
      <p><strong>Period:</strong> {{dateStr}}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <hr>
      <h5>Bill To:</h5>
      <p>{{billTo}}</p>
      <p>PHILIPPINES</p>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-md-12">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th>Item/Service Description</th>
          <th>Amount (PHP)</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            Operations monitoring and maintenance for:
            <br/>
            [1] GPAY SERVER - Server Application;
            <br/>
            [2] GPAY WEB Client - www.gpaytechnologies.com
            <br/>
            [3] GPAY RELAY - Android SMS relay software;
            <br/>
            [4] GPAY OFFLINE - Android-based Offline Client
            <br/>
            [5] AWS Dedicated Servers
          </td>
          <td>60,000</td>
        </tr>
        <tr>
          <td>
            Project Development after initial paid version:
            <br/>
            [6] Bug-support,
            <br/>
            [7] Feature addition,
            <br/>
            [8] Feature modification and feature improvements
            For a detailed list of items see: https://trello.com/b/bxHSGIuQ/g-pay
          </td>
          <td>---</td>
        </tr>
        <tr>
          <td>
            Domain Registration
            <br/>
            gpaytechnologies.com, offline.gpaytechnologies.com
            <br/>
            Premium Domain Privacy Protection (disables public information about the domain owner in the public whois
            service. http://whois.icann.org/en/lookup?name=gpaytechnologies.com)
          </td>
          <td>---</td>
        </tr>
        <tr>
          <td>Amazon Web Services Dedicated Server (8-core/12GB)</td>
          <td>25,000 (waived)</td>
        </tr>
        <tr>
          <td>Amazon Web Services Cloud Backup Server</td>
          <td>15,000 (waived)</td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <th>Total</th>
          <th>60,000</th>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-md-12">
      <p><strong>Payment Terms:</strong> Please make the payment by
        {{deadLineStr}}.</p>
      <p><strong>Payment Method:</strong> Bank Transfer</p>
      <p>Thank you for choosing our services. If you have any questions or concerns, please don't hesitate to contact
        us. We appreciate your prompt attention to this matter.</p>
      <br>
      <p>Sincerely,</p>
      <p>J. Solano</p>
      <p>Backbone Services</p>
      <p></p>
    </div>
  </div>
</div>
