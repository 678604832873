import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BlackOwnerSettings} from '../../models/black-owner-settings';
import {LadInput} from '../../../../app/dynamic-form/components/lad-input/lad-input.component';
import {BbHandsontable} from '../../../../app/dynamic-form/components/bb-handsontable/bb-handsontable.component';
import {Draw, initMaxBetObj, Raffle} from '../../models/utils/number-helper';
import {LadRadio} from '../../../../app/dynamic-form/components/lad-radio/lad-radio.component';
import {LadSectionTitle} from '../../../../app/dynamic-form/components/lad-section-title/lad-section-title.component';
import {Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {BLACK_DRAW_ARR, BLACK_RAFFLE_ARR} from '../../models/utils/black-utils';
import {LadCheckbox} from '../../../../app/dynamic-form/components/lad-checkbox/lad-checkbox.component';
import {DynamicFormComponent} from '../../../../app/dynamic-form/form/dynamic-form.component';
import {BlackUserBase} from '../../models/black-user-base';
import {LadField} from '../../../../app/dynamic-form/models/lad-field';
import {LadTime} from 'ladrov-commons';
import {createRaffleSettingsTable} from '../../models/utils/raffle-settings-table-helper';
import {RaffleSettingsEditor} from '../../components/raffle-settings-editor/raffle-settings-editor.component';

@Component({
  selector: 'app-owner-settings',
  templateUrl: './owner-settings.component.html',
  styleUrls: ['./owner-settings.component.scss']
})
export class OwnerSettingsComponent implements OnInit {

  targetDocument:  BlackOwnerSettings;
  dynamicFields: {[key: string]: LadField<any>};

  @ViewChild('dynamicForm')
  dynamicForm: DynamicFormComponent;

  constructor(
    private cd: ChangeDetectorRef
  ) {
  }

  async ngOnInit() {
    this.targetDocument = await BlackOwnerSettings.getOwnerSettings();
    if (!this.targetDocument.cutoffTime) {
      this.targetDocument.cutoffTime = {};
    }
    if (!this.targetDocument.maxBet) {
      this.targetDocument.maxBet = initMaxBetObj();
    }
    this.dynamicFields = {
      d1CutOffTime: new LadInput({
        row: 'row1',
        label: 'Draw1 Cutoff',
        value: this.targetDocument.cutoffTime[Draw.d1],
        required: true,
        columnClass: 'col-md-4 col-sm-12 col-lg-4',
        inputType: 'time',
        mappingFunction: async (target: BlackUserBase, val: LadTime) => {
          target.cutoffTime[Draw.d1] = val;
        }
      }),
      d2CutOffTime: new LadInput({
        row: 'row1',
        label: 'Draw2 Cutoff',
        value: this.targetDocument.cutoffTime[Draw.d2],
        required: true,
        columnClass: 'col-md-6 col-sm-12 col-lg-4',
        inputType: 'time',
        mappingFunction: async (target: BlackUserBase, val: LadTime) => {
          target.cutoffTime[Draw.d2] = val;
        }
      }),
      d3CutOffTime: new LadInput({
        row: 'row1',
        label: 'Draw3 Cutoff',
        value: this.targetDocument.cutoffTime[Draw.d3],
        required: true,
        columnClass: 'col-md-4 col-sm-12 col-lg-4',
        inputType: 'time',
        mappingFunction: async (target: BlackUserBase, val: LadTime) => {
          target.cutoffTime[Draw.d3] = val;
        }
      }),

      maxBetSection: new LadSectionTitle({
        title: 'Max Bet Settings',
        subTitle: 'Ang pinaka last na item ang masunod pag naay confict.',
        visible: true,
      }),
      maxBetInterface: new RaffleSettingsEditor({
        row: 'maxbet-row',
        value: this.targetDocument.maxBet,
        columnClass: 'col-md- col-lg-6',
        // label: 'This is a sample'
      }),

      comissionsSection: new LadSectionTitle({
        title: 'Commissions Settings',
        subTitle: 'Percentage value must be a whole number.',
        visible: true,
      }),
      comissionsInterface: new RaffleSettingsEditor({
        row: 'commission-row',
        value: this.targetDocument.commissions,
        columnClass: 'col-md-6',
        mode: 'commission'
      }),

      unitPrizeSection: new LadSectionTitle({
        title: 'Unit Prize Settings',
        subTitle: 'Prize per piso taga raffle',
        visible: true,
      }),
      unitPrizeInterface: new RaffleSettingsEditor({
        row: 'unitPrize-row',
        value: this.targetDocument.unitPrizes,
        columnClass: 'col-md-6',
        mode: 'win'
      }),

    };
    this.cd.markForCheck();
  }

}
