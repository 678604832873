<div [formGroup]="formGroup" class="form-group">
  <label *ngIf="formField.label" [attr.for]="formField.key" [class]="formField.labelClass">
    {{formField.label}}
    <a *ngIf="!isValid" class="ft-info font-small-3 blinking"></a>
  </label>
  <br *ngIf="!challengeComplete"/>
  <span *ngIf="!challengeComplete" class="font-small-2 mb-sm-0">Camera required. For IOS, use safari.</span>
  <br/>
  <video #inputVideo (loadedmetadata)="onPlay()" *ngIf="!challengeComplete" autoplay id="inputVideo" muted
         playsinline></video>
  <ngb-progressbar *ngIf="!challengeComplete" [animated]="true" [striped]="true" [value]="progressValue"
                   class="progress-bar-sm" height=".3rem"
                   max="500"
                   type="warning">
  </ngb-progressbar>

  {{challengeComplete ? 'Complete!' : 'Make the following expressions:'}}
  &nbsp;
  <img *ngFor="let c of expressionChallenge; let i = index;"
       [ngClass]="this.currentChallengeIndex === i ? 'activeChallenge' : ''"
       [ngbTooltip]="c.toolTip"
       [src]="c.imgSrc ? c.imgSrc : c.defaultImage" class="expressionIcon">

  <br/>
  <button (click)="reset()" class="btn btn-primary mr-1 mt-sm-1" type="button">Reset</button>
  <span class="font-small-2 mb-sm-0">{{this.uploadMessage}}</span>
</div>
