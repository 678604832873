<div [formGroup]="formGroup" class="form-group">
  <label *ngIf="formField.label" [attr.for]="formField.key" [class]="formField.labelClass">
    {{formField.label}}
    <a *ngIf="!isValid" class="ft-info font-small-3 blinking"></a>
  </label>
  <textarea [formControlName]="formField.key" [id]="formField.key"
            [ngClass]="formField.textAreaClass"
            [placeholder]="formField.placeHolder"
            [rows]="formField.rows"
            class="form-control">
  </textarea>

  <code *ngIf="!isValid" class="font-small-1 mr-1">*Invalid!</code>
</div>
