import {Component, OnInit} from '@angular/core';
import {BlackTransaction} from '../../models/black-transaction';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-transaction-viewer',
  templateUrl: './transaction-viewer.component.html',
  styleUrls: ['./transaction-viewer.component.scss']
})
export class TransactionViewerComponent implements OnInit {


  transaction: BlackTransaction | any;

  set target(value: any) {
    if(value){
      this.transaction = value;
    }
  }

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

}
