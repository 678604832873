
import {Draw, DrawStr, Raffle} from './number-helper';

export const BLACK_SYSTEM_NAME = 'black';

export enum BlackStatus {
  enabled = 'enabled',
  disabled = 'disabled'
}

export const BLACK_DRAW_ARR = Object.values(Draw).map(e => { return {value: e, label: e.toUpperCase()} });

export const BLACK_RAFFLE_ARR = Object.values(Raffle).map(e => { return {value: e, label: e.toUpperCase()} });

export enum DateFilterValues {
  MONTH,
  WEEK,
  TODAY,
  CUSTOM_DATE,
  CUSTOM_RANGE
}

export const DATE_FILTER_ARR = [
  {
    label: 'Today', value: DateFilterValues.TODAY
  },
  {
    label: 'This Week', value: DateFilterValues.WEEK
  },
  {
    label: 'This Month', value: DateFilterValues.MONTH
  },
  {
    label: 'Custom Date', value: DateFilterValues.CUSTOM_DATE
  },
  {
    label: 'Custom Range', value: DateFilterValues.CUSTOM_RANGE
  },
];

