
<div class="card-content">
  <div class="px-3" *ngIf="targetDocument">
    <lad-dynamic-form #dynamicFormElement
                      (onInitDone)="onDFormInitDone()"
                      [dynamicFields]="dynamicFields"
                      [submitType]="'global'"
                      [postSaveFn]="postSave"
                      [targetDocument]="targetDocument">
    </lad-dynamic-form>

  </div>
</div>

