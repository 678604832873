import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild, ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {FormGroup} from '@angular/forms';

import {LadField} from '../models/lad-field';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[lad-dynamic-field]',
  templateUrl: './dynamic-field.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DynamicFieldComponent implements AfterViewInit {

  @Input() formField: LadField<string>;
  @Input() form: FormGroup;

  @ViewChild('fieldContainer', {read: ViewContainerRef}) fieldContainer;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
  }

  ngAfterViewInit(): void {
    if (!this.formField.__componentType) {
      return;
    }
    const fieldContainerRef = this.fieldContainer;
    const factory = this.componentFactoryResolver.resolveComponentFactory(this.formField.__componentType);
    const component = fieldContainerRef.createComponent(factory);
    component.instance.formField = this.formField;
    component.instance.formGroup = this.form;
  }
}
