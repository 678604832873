<div class="modal-header">
  <h4 class="modal-title">{{title}} </h4>
  <button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="m-0">
    <input (change)="fileChangeEvent($event)" capture type="file">
  </div>
  <div *ngIf="!status.processing || !status.error">
    <image-cropper
      (cropperReady)="cropperReady()"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded()"
      (loadImageFailed)="loadImageFailed()"
      [aspectRatio]="aspectRatio"
      [format]="format"
      [imageChangedEvent]="imageChangedEvent"
      [imageQuality]="imageQuality"
      [maintainAspectRatio]="true"
      [resizeToWidth]="resizeToWidth"
      [canvasRotation]="canvasRotation"
    ></image-cropper>

    <span (click)="rotateCanvas()" *ngIf="isImageLoaded" class="font-medium-1 cursor-pointer">
      <i class="ft-rotate-cw"></i>
      Rotate
    </span>

  </div>
  <div *ngIf="status.processing">
    <i class="ft-loader spinner"></i>
  </div>
  <div *ngIf="status.error">
    <p><span><i class="ft-alert-triangle spinner text-danger"></i></span> There was an error uploading your image.
      Please Try again</p>
  </div>
</div>
<div class="modal-footer">

  <button (click)="activeModal.dismiss()" class="btn btn-secondary btn-raised" type="button">Cancel</button>
  <button (click)="continueImage()" [disabled]="status.error"
          [ngClass]="status.error === false ? 'btn btn-primary btn-raised' : 'btn btn-warning btn-raised' "
          type="button"><span>{{status.error === false ? 'Continue' : 'Failed'}}</span></button>
</div>
