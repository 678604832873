import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import swal from 'sweetalert2';
import {FileItem, FileUploader, ParsedResponseHeaders} from 'ng2-file-upload';
import {LadUploadedAsset} from 'ladrov-commons';
import {ToastrService} from 'ngx-toastr';
import {LadComponentBase} from '../../../models/lad-component-base';
import {APIService} from '../../../../shared/backend/api.service';
import {LadField, LadFieldOptions} from '../../../models/lad-field';

declare var faceapi;

const EXPRESSION_HOLD_DURATION = 1000;

interface ExpressionDuration {
  name: string;
  start: number;
  lastUpdate: number;
}

class ExpressionChallenge {
  name: string;
  toolTip: string;
  completed: boolean;
  imgSrc?: string;
  uploadedImage?: LadUploadedAsset;
  defaultImage?: string;
}

@Component({
  selector: 'app-lad-expression-challenge',
  templateUrl: './lad-expression-challenge.component.html',
  styleUrls: ['./lad-expression-challenge.component.scss']
})
export class LadExpressionChallengeComponent extends LadComponentBase implements OnInit, OnDestroy  {

  @Input()
  formField: LadExpressionChallenge;

  @ViewChild('inputVideo')
  inputVideo: ElementRef;
  @ViewChild('overlay')
  overlay: ElementRef;

  deployedExpression: string;
  progressValue = 0;

  expressionUploader: FileUploader = new FileUploader({
    url: this.api.getUploadUrl(),
    isHTML5: true,
    autoUpload: true
  });

  // flag for indicating that library is ready.
  __faceApiReady = false;
  // current expression buffer for calculating held duration
  __currentExpression: ExpressionDuration;
  // for dampening glitches; if the currentExpression changes, it is checked against this object
  __previousExpression: ExpressionDuration;

  __lastFaceScan = Date.now();

  currentChallengeIndex = 0;
  expressionChallenge: ExpressionChallenge[] = [
    {name: 'happy', toolTip: 'Happy', defaultImage: '/assets/img/expressions/happy.svg', completed: false},
    {name: 'neutral', toolTip: 'Neutral', defaultImage: '/assets/img/expressions/neutral.svg', completed: false},
    {name: 'surprised', toolTip: 'Surprised', defaultImage: '/assets/img/expressions/surprised.svg', completed: false},
    {name: 'neutral', toolTip: 'Neutral', defaultImage: '/assets/img/expressions/neutral.svg', completed: false},
    // {name: 'disgusted', toolTip: 'Disgusted', imgSrc: '/assets/img/expressions/disgusted.svg', completed: false},
    // {name: 'angry', toolTip: 'Angry', imgSrc: '/assets/img/expressions/angry.svg', completed: false},
  ];
  challengeComplete = false;
  uploadMessage: string;

  result: LadUploadedAsset[] = [];

  constructor(
    private cd: ChangeDetectorRef,
    private api: APIService,
    public toastr: ToastrService,
  ) {
    super();
    this.initFaceAPI();
  }

  stopCamera() {
    if (this.inputVideo && this.inputVideo.nativeElement) {
      const element = this.inputVideo.nativeElement;
      element.srcObject = undefined;
      element.load();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.stopCamera();
  }

  ngOnInit() {
    super.ngOnInit();
    this.videoInputInit();
    this.expressionUploader.onBeforeUploadItem = (item: FileItem) => {
      this.uploadMessage = 'Uploading...';
    };
    this.expressionUploader.onCompleteItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      if (status === 200) {
        const rArray: LadUploadedAsset = JSON.parse(response);
        const uploaded = rArray[0];
        this.result.push(uploaded);
        this.uploadMessage = `Uploaded: ${this.result.length}/${this.expressionChallenge.length}`;
        if (this.result.length === this.expressionChallenge.length) {
          this.formField.formControl.patchValue(this.result,{onlySelf: true});
        }
        this.cd.markForCheck();
      } else {
        this.toastr.error(`An error occurred when uploading captured expression: ${this.formField.key}.`, 'Upload Error');
      }
    };
  }

  async onPlay(): Promise<any> {
    if (!this.inputVideo) {
      return;
    }
    if (this.inputVideo.nativeElement.paused
      || this.inputVideo.nativeElement.ended
      || !this.isFaceDetectionModelLoaded()
      || !this.__faceApiReady
    ) {
      setTimeout(() => this.onPlay());
      return;
    }
    const challengeIncomplete = this.currentChallengeIndex < this.expressionChallenge.length;
    const scan = (Date.now() - this.__lastFaceScan ) > 250; // 4hz
    if (challengeIncomplete && scan) {
      const options = this.getFaceDetectorOptions();
      const videoEl = this.inputVideo.nativeElement;
      const detections = await faceapi.detectSingleFace(videoEl, options).withFaceExpressions();
      this.__lastFaceScan = Date.now();
      this.processResult(detections);
    }

    if (!challengeIncomplete) {
      this.stopCamera();
    }

    setTimeout(() => this.onPlay())
  }

  async initFaceAPI() {
    try {
      await Promise.all([
        faceapi.nets.tinyFaceDetector.load('/weights'),
        faceapi.loadFaceExpressionModel('/weights'),
      ]);
      this.__faceApiReady = true;
    } catch (e) {
      swal.fire({
        title: 'Error',
        text: 'Failed to load face detection engine.',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#2F8BE6',
        cancelButtonColor: '#F55252',
        confirmButtonText: 'Ok',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1'
        },
        buttonsStyling: false,
      });
    }

  }

  async extractFace(video, challengeIndex) {
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const canvasContext = canvas.getContext('2d');
    canvasContext.drawImage(video, 0, 0);

    // load into face images
    const imgInput = document.createElement('img');
    imgInput.src = canvas.toDataURL();
    imgInput.addEventListener('load', async () => {
      const options = this.getFaceDetectorOptions();
      const detections = await faceapi.detectAllFaces(imgInput, options);

      // const faceImages = await faceapi.extractFaces(imgInput, detections);
      const faceImages = await faceapi.extractFacesLarge(imgInput, detections);
      const challenge = this.expressionChallenge[challengeIndex];
      if (!faceImages || faceImages.length === 0) {
        return;
      }
      const faceImgData = faceImages[0].toDataURL('img/png');
      challenge.imgSrc = faceImgData;

      // initiate face upload
      this.uploadExtractedFace(faceImgData, `${challengeIndex}.${challenge.name}`);

      this.cd.markForCheck();
    });
  }

  async uploadExtractedFace(dataurl, filename) {
    const file = this.dataURLtoFile(dataurl, filename);
    this.expressionUploader.addToQueue([file]);
  }

  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const blob = new Blob([u8arr], {type: mime});
    return new File([blob], filename, {type: 'image/png'})
  }

  private shuffle(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  private round(num: number, prec: number = 2) {
    const f = Math.pow(10, prec);
    return Math.floor(num * f) / f
  }

  private isFaceDetectionModelLoaded() {
    return !!faceapi.nets.tinyFaceDetector.params
  }

  async videoInputInit() {
    const stream = await navigator.mediaDevices.getUserMedia({video: {}});
    this.inputVideo.nativeElement.srcObject = stream;
    this.inputVideo.nativeElement.onloadedmetadata = this.onPlay();
  }

  private getFaceDetectorOptions() {
    // TinyFaceDetectorOptions
    const inputSize = 256;
    const scoreThreshold = 0.5;
    return new faceapi.TinyFaceDetectorOptions({inputSize, scoreThreshold});
  }

  reset() {
    this.challengeComplete = false;
    this.currentChallengeIndex = 0;
    this.__currentExpression = null;
    this.__previousExpression = null;
    this.__lastFaceScan = Date.now();
    this.deployedExpression = null;
    this.progressValue = 0;
    for (const c of this.expressionChallenge) {
      c.completed = false;
      c.imgSrc = null;
      c.uploadedImage = null;
    }
    this.expressionUploader.cancelAll();
    this.expressionUploader.clearQueue();
    this.result = [];
    this.videoInputInit();
    this.cd.markForCheck();
  }

  private processResult(detections: any) {
    const expressions = detections ? detections.expressions : [];
    const minConfidence = 0.35;
    let frameExpression = null;
    let frameExpressionProbability = 0;
    for (const ex in expressions) {
      if (expressions[ex] > minConfidence) {
        const probability = this.round(expressions[ex]);
        if (!frameExpression || frameExpressionProbability <= probability) {
          frameExpression = ex;
          frameExpressionProbability = probability;
        }
      }
    }

    const now = Date.now();
    if (!this.__currentExpression) {
      const start = now;
      this.__currentExpression = {
        name: frameExpression,
        start,
        lastUpdate: start
      };
      return;
    }

    if (this.__currentExpression.name === frameExpression) {
      this.__currentExpression.lastUpdate = now;
      this.checkHoldDuration();
      return;
    }

    // expression changed:

    // check if glitch
    if (this.__previousExpression
      && this.__previousExpression.name === frameExpression
      && this.__previousExpression.name !== this.__currentExpression.name) {
      const duration = now - this.__currentExpression.start;
      // should be a glitch if it held for only 100ms and back to the previous expression
      if (duration < 100) {
        // restore previous as current
        this.__currentExpression = this.__previousExpression;
        this.__currentExpression.lastUpdate = now;
        this.checkHoldDuration();
        return;
      }
    }

    // expression really changed
    this.__previousExpression = this.__currentExpression;
    this.__currentExpression = {
      name: frameExpression,
      start: now,
      lastUpdate: now
    };
  }

  private async onExpressionHeld(name: string) {
    if (this.deployedExpression === name) {
      return;
    }
    this.deployedExpression = name;

    const challenge = this.expressionChallenge[this.currentChallengeIndex];
    // current challenge completed:
    if (challenge.name === this.deployedExpression) {
      await this.extractFace(this.inputVideo.nativeElement, this.currentChallengeIndex);
      // console.log(img.toDataURL('image/png'));
      challenge.completed = true;
      this.currentChallengeIndex++;
      this.progressValue = this.currentChallengeIndex * 100 + 100;

      // all challenges completed:
      if (this.currentChallengeIndex === this.expressionChallenge.length) {
        await this.onChallengesCompleted();
      }
      this.cd.markForCheck();
    }
  }

  private async onChallengesCompleted() {
    this.challengeComplete = true;
    this.stopCamera();
  }

  private checkHoldDuration() {
    const holdDuration = this.__currentExpression.lastUpdate - this.__currentExpression.start;
    setTimeout(() => {
      if (this.challengeComplete) {
        return;
      }
      if (this.__currentExpression.name === this.expressionChallenge[this.currentChallengeIndex].name) {
        this.progressValue = this.currentChallengeIndex * 100 + Math.ceil((holdDuration / EXPRESSION_HOLD_DURATION) * 100);
      } else {
        this.progressValue = this.currentChallengeIndex * 100;
      }
      this.cd.markForCheck();
    });

    if (holdDuration > EXPRESSION_HOLD_DURATION) {
      setTimeout(() => {
        this.onExpressionHeld(this.__currentExpression.name);
      });
    }
  }

}

export class LadExpressionChallengeOptions<T> extends LadFieldOptions<T> {
  // component specific options
}

export class LadExpressionChallenge extends LadField<any> {

  constructor(options: LadExpressionChallengeOptions<string>) {
    super(options);
    this.__componentType = LadExpressionChallengeComponent;
  }

}
