import {Component, Input, OnInit} from '@angular/core';
import {LadComponentBase} from '../../models/lad-component-base';
import {LadField, LadFieldOptions} from '../../models/lad-field';

@Component({
  selector: 'app-lad-checkbox',
  templateUrl: './lad-checkbox.component.html',
  styleUrls: ['./lad-checkbox.component.scss']
})
export class LadCheckboxComponent extends LadComponentBase implements OnInit {

  @Input()
  formField: LadCheckbox;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onChange($event: Event) {
    const target: any = $event.target;
    this.formField.formControl.patchValue(target.checked,{onlySelf: true});
  }
}


export class LadCheckbox extends LadField<boolean> {

  constructor(options: LadFieldOptions<boolean>) {
    super(options);
    this.__componentType = LadCheckboxComponent;
  }
}

