import {API, APIFunctionTypes, LadAPIUtils, SystemDocument} from 'ladrov-commons';
import {numberThousand, Raffle, RaffleSettings} from './utils/number-helper';
import {BLACK_SYSTEM_NAME, BlackStatus} from './utils/black-utils';

export class BlackGameSheet extends SystemDocument{

  public static MODEL_NAME = 'BlackGameSheet';

  name: string;
  limits: RaffleSettings[] = [];
  limitsStr: string;

  constructor() {
    super(BlackGameSheet.MODEL_NAME, BLACK_SYSTEM_NAME);
  }

  @API(APIFunctionTypes.PRE_SAVE)
  public static async preSave(toSave: BlackGameSheet) {
    toSave.limitsStr = toSave.limits.map(item => `${item.raffleId}=${numberThousand(item.value)}`).join(', ');
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getGameSheets(args?, util?: LadAPIUtils): Promise<BlackGameSheet[]> {
    const filter: any = {
      'systemHeader.createdBy' : util.currentUserId,
    }
    const result = await util.documentService.find(BlackGameSheet.MODEL_NAME, filter);
    return result;
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getGameSheetById(param: { id: string }, util?: LadAPIUtils) {
    return await util.documentService.findOne(BlackGameSheet.MODEL_NAME, {documentId: param.id});
  }
}
