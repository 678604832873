import {Injectable, NgModule} from '@angular/core';
import {RouterModule, Routes, Router, CanActivate} from '@angular/router';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { Full_ROUTES } from './shared/routes/full-layout.routes';
import { CONTENT_ROUTES } from './shared/routes/content-layout.routes';
import { AuthGuard } from './shared/auth/auth-guard.service';
import {AuthService} from './shared/auth/auth.service';

@Injectable()
export class RootAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  async canActivate() {
    if (this.authService.isAuthenticated()) {
      return this.router.parseUrl(this.authService.loggedUserMainPage);
    }
    return this.router.parseUrl('/pages/login');
  }

}

const appRoutes: Routes = [
  {
    path: '',
    canActivate: [RootAuthGuard],
    pathMatch: 'full',
    component: ContentLayoutComponent
  },
  { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES },
  {
    path: '**',
    redirectTo: 'pages/error'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
  providers: [RootAuthGuard],
})
export class AppRoutingModule {
}


