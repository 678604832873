import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PosMenuComponent} from './pages/pos-menu/pos-menu.component';
import {ProductMasterlistComponent} from './pages/product-masterlist/product-masterlist.component';
import {OrderSummaryComponent} from './pages/order-summary/order-summary.component';
import {InvoiceTemplateComponent} from './pages/invoice-template/invoice-template.component';
import {ProductMastlistAddComponent} from './pages/product-mastlist-add/product-mastlist-add.component';
import { StoreComponent } from './pages/store/store.component';
import { StoreEditorComponent } from './pages/store-editor/store-editor.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'menu',
        component: PosMenuComponent
      },
      {
        path: 'menu/:id',
        component: PosMenuComponent
      },
      {
        path: 'product-masterlist',
        component: ProductMasterlistComponent
      },
      {
        path: 'order-summary',
        component: OrderSummaryComponent
      },
      {
        path: 'product-masterlist/add',
        component: ProductMastlistAddComponent
      },
      {
        path: 'product-masterlist/edit/:id',
        component: ProductMastlistAddComponent
      },
      {
        path: 'invoice-template',
        component: InvoiceTemplateComponent
      },
      {
        path: 'myEstabliment',
        component: StoreComponent
      },
      {
        path: 'myEstabliment/add',
        component: StoreEditorComponent
      },
      {
        path: 'myEstabliment/edit/:id',
        component: StoreEditorComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PosRoutingModule { }
