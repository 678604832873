import {Draw, Raffle, RaffleSettings} from './number-helper';
import {GridSettings} from 'handsontable/settings';
import {BlackUserBase} from '../black-user-base';
import {Subscription} from 'rxjs';
import {LadRadio} from '../../../../app/dynamic-form/components/lad-radio/lad-radio.component';
import {BbHandsontable} from '../../../../app/dynamic-form/components/bb-handsontable/bb-handsontable.component';
import {LadSectionTitle} from '../../../../app/dynamic-form/components/lad-section-title/lad-section-title.component';
import {LadCheckbox} from '../../../../app/dynamic-form/components/lad-checkbox/lad-checkbox.component';
import {BLACK_DRAW_ARR, BLACK_RAFFLE_ARR} from './black-utils';

export function createRaffleSettingsTable(
  data,
  options?: {valueTitle?: string, isRange?: boolean, noDrawCol?: boolean, fixedRaffleValue?: Raffle},
) {
  const {valueTitle, isRange, noDrawCol, fixedRaffleValue} = options;

  let columnDef: any =  [
    {
      title: 'Draw',
      data: 'drawId',
      type: 'dropdown',
      source: Object.values(Draw)
    },
    {
      title: 'Raffle ID',
      data: 'raffleId',
      type: 'dropdown',
      source: Object.values(Raffle)
    },
    {
      title: 'From',
      data: 'from',
    },
    {
      title: 'To',
      data: 'to'
    },
    {
      title: `${valueTitle}`,
      data: 'value'
    }
  ];

  if (!isRange) {
    columnDef.splice(2, 2);
  }

  if (fixedRaffleValue) {
    columnDef.splice(1, 1);
  }

  if (noDrawCol) {
    columnDef.shift();
  }

  const gridSettings: GridSettings = {
    manualRowMove: true,
    rowHeaders: true,
    readOnlyCellClassName: 'text-muted',
    rowHeights: 45,
    columnHeaderHeight: 45,
    licenseKey: 'non-commercial-and-evaluation',
    data,
    columns : columnDef,
    dataSchema : () => {
      const raffle = new RaffleSettings();
      if (fixedRaffleValue) {
        raffle.raffleId = fixedRaffleValue;
      }
      return raffle;
    },
    contextMenu: ['remove_row', '---------', 'row_below', 'row_above', '---------',  'copy', 'cut', '---------', 'undo', 'redo'],
    allowRemoveRow: true,
    fixedColumnsLeft: 0,
    stretchH: 'all',
    colWidths: columnDef.map((each: any) => {
      if (each === columnDef[columnDef.length - 1]) {
        return 40;
      }
      return each.width ? each.width : null
    }),
    height: '50vh',
    beforePaste : (data, coords) => {
      for (let rindex = 0 ; rindex < data.length; rindex++ ) {
        for (let colIndex = 0; colIndex < data[rindex].length; colIndex++ ) {
          const val = data[rindex][colIndex];
          if (typeof val === 'string') {
            data[rindex][colIndex] = val.trim();
          }
        }
      }
    },
    // modifyColWidth: function(width, col){
    //   if (col == columnDef.length - 1) {
    //     return 50;
    //   }
    // }
  };
  return gridSettings;
}

export function getMaxBetSectionComponents(targetDocument: BlackUserBase, subs: Subscription) {

  const selectedDrawForMaxBet =new LadRadio({
    row: 'row3',
    label: 'Draw:',
    columnClass: 'col-md-3',
    value: Draw.ALL,
    items: BLACK_DRAW_ARR,
    excludeAtSave: true,
  });
  const selectedRaffleForMaxBet = new LadRadio({
    row: 'row3',
    label: 'Raffle:',
    columnClass: 'col-md-3',
    value: Raffle.ALL,
    items: BLACK_RAFFLE_ARR
  });

  const maxBetTables = {};
  for (const d of Object.values(Draw)) {
    for (const r of Object.values(Raffle)) {
      const key = `${d}${r}MaxBet`;
      if (!maxBetTables[key]) {
        maxBetTables[key] = [];
      }
      maxBetTables[key] = new BbHandsontable({
        visible: false,
        row: 'row2',
        label: 'All Draws Max Bet',
        columnClass: 'col-md-6',
        enableRowMove: true,
        enableRowRemove: true,
        excludeAtSave: true,
        gridSettings: async () => {
          if (!targetDocument.maxBet[d]) {
            targetDocument.maxBet[d] = {};
          }
          if (targetDocument.maxBet[d][r]) {
            targetDocument.maxBet[d][r] = []; // array of raffle settings
          }
          return createRaffleSettingsTable(targetDocument.maxBet[d][r], {valueTitle: 'Amount', isRange: true, noDrawCol: true, fixedRaffleValue: r});
        }
      });
    }
  }

  return {
    maxBetSection: new LadSectionTitle({
      title: 'Max Bet Settings',
      visible: true,
      subTitle: 'Wildcard Examples: 1. FROM *99 (Ending 99) 2. FROM 99* (Starting 99) 3.FROM *99* (Containing 99);'
    }),
    enableMaxBet: new LadCheckbox({label: 'Enable custom restrictions', value: true}),
    selectedDrawForMaxBet,
    selectedRaffleForMaxBet,
    ...maxBetTables
  };
}
