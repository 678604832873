<div class="col-md-8">
  <lad-dynamic-form #dynamicFormElement
                    (onInitDone)="onFormInitDone()"
                    [dynamicFields]="dynamicFields"
                    (keyup)="inputField($event)"
                    [noConfirmAndFormCheck]=true
                    submitIcon="ft-search"
                    submitLabel="Search"
                    submitType="none">
  </lad-dynamic-form>

  <div class="d-flex">
    <button class="btn btn-secondary" type="button" (click)="addBetOnClick()" [disabled]="isCloseDraws">Add Bet</button>
    <div class="d-flex flex-column flex-sm-row flex-grow-1 justify-content-sm-end">
      <button class="btn btn-outline-primary mr-sm-1" type="button" (click)="resetOnClick()">Reset</button>
      <button class="btn btn-primary" type="submit" (click)="submitOnClick()">Submit</button>
    </div>
  </div>

  <p class="m-0 mb-3">Total: <span class="text-danger">{{totalAmount() | number:'1.2-2'}}</span></p>

  <div class="table-responsive-sm">
    <table class="table table-sm table-striped table-bordered">
      <thead class="">
      <tr>
        <th class="text-uppercase" scope="col">#</th>
        <th class="text-uppercase" scope="col" colspan="3">Bet</th>
        <th class="text-center text-uppercase" scope="col">Unit Prize</th>
<!--        <th class="text-center text-uppercase" scope="col">Prize</th>-->
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let i of bets; let idx = index" style="text-transform: uppercase">
        <td>
          {{idx+1}}
        </td>
        <td colspan="3" class="cursor-pointer" (click)="removeBetOnClick(idx, i.raffle, i.combination)">
          <span>
            <i class="ft-trash mr-1 text-danger"></i>
            <label class="mr-1">{{i.raffle}}.{{i.combination}}</label>
            <label class="fonticon-unit">P{{i.amount | number: '1.2-2'}}</label>
          </span>

        </td>
        <td class="text-center">{{i.unitPrize}}</td>
<!--        <td class="text-center">{{i.prize | number: '1.2-2'}}</td>-->
      </tr>
      </tbody>
    </table>
  </div>

</div>
