import {API, APIFunctionTypes, LadAccountModel, LadAPIUtils, LadDate, LadRelatedDocument, LadTime, SystemDocument} from 'ladrov-commons';
import {BlackCoordinator} from './black-coordinator';
import {Draw, initMaxBetObj, Raffle, RaffleSettings} from './utils/number-helper';
import {BlackStatus, BLACK_SYSTEM_NAME} from './utils/black-utils';
import {BlackUserBase} from './black-user-base';
import {isMobileBrowser} from 'handsontable/helpers';
import {BlackAgent} from './black-agent';

export class BlackArea extends SystemDocument implements BlackUserBase {
  public static MODEL_NAME = 'BlackArea';

  status: BlackStatus = BlackStatus.enabled
  name: string;

  draws = [Draw.d1, Draw.d1, Draw.d3];
  raffles = [Raffle.l2, Raffle.s3, Raffle.s3r, Raffle.d4];

  enablePrizes: boolean;
  unitPrizes: RaffleSettings[] = [];

  enableCommissions: boolean;
  commissions: RaffleSettings[] = [];

  enableCutOffTime: boolean;
  cutoffTime: { [p: string]: LadTime } = {};

  enableMaxBet: boolean;
  maxBet: { [key: string]: { [key: string]: RaffleSettings[]; }; } = initMaxBetObj();

  coordinatorRel: LadRelatedDocument<BlackCoordinator>

  constructor(coordinatorId: string) {
    super(BlackArea.MODEL_NAME, BLACK_SYSTEM_NAME);
    this.coordinatorRel = {
      relatedDocumentType: BlackCoordinator.MODEL_NAME,
      relatedDocumentId: coordinatorId
    }
  }

  @API(APIFunctionTypes.PRE_SAVE)
  public static async preSave(area: BlackArea, util: LadAPIUtils) {
    return area;
  }

  @API(APIFunctionTypes.POST_SAVE)
  public static async postSave(area: BlackArea, util: LadAPIUtils) {
  }

  @API(APIFunctionTypes.POST_DELETE)
  public static async postDelete(area: BlackArea, util: LadAPIUtils) {
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getById(args, util?: LadAPIUtils): Promise<BlackArea> {
    let id = args.id;
    if (!id){
      id = args;
    }
    return await util.documentService.findOne(BlackArea.MODEL_NAME, {documentId: id});
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getByCoordinatorId(args, util?: LadAPIUtils): Promise<BlackArea[] | any> {
    let id = args.id;
    const filter = {'coordinatorRel.relatedDocumentId': id};
    const result = await util.documentService.find(BlackArea.MODEL_NAME, filter);
    return result;
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getAgentsByArea(args, util?: LadAPIUtils): Promise<BlackAgent[]> {
    let areaId = args.id;
    if (!areaId){
      areaId = args;
    }
    return util.documentService.find(BlackAgent.MODEL_NAME, { 'areaRel.relatedDocumentId' : areaId}, {sort: {name: 1}});
  }

}
