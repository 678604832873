import {API, APIFunctionTypes, LadAPIUtils, LadDate, SystemDocument} from 'ladrov-commons';
import {combinationFormat, Draw, num, Raffle} from './utils/number-helper';
import {BLACK_SYSTEM_NAME} from './utils/black-utils';
import {BlackCoordinator} from './black-coordinator';
import {BlackAgent} from './black-agent';

export interface BlackNumberSummaryAgentItem {
  total: number;
  agentSummaryId: string;
  details: {[key: string] : number}; // key is transactionId
}

export interface BlackNumberSummaryAreaItem {
  total: number;
  details: {[key: string]:  BlackNumberSummaryAgentItem} // key is agentId
}

export interface BlackNumberSummaryCoorItem {
  total: number;
  details: {[key: string]:  BlackNumberSummaryAreaItem} // key is agentId
}

export class BlackNumberSummary extends SystemDocument {
  public static MODEL_NAME = 'BlackNumberSummary';

  total: number;
  details: { [key: string]: BlackNumberSummaryCoorItem } = {}

  constructor(public date: LadDate, public draw: Draw, public raffle: Raffle, public combination: number) {
    super(BlackNumberSummary.MODEL_NAME, BLACK_SYSTEM_NAME);
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getSummary(args: {date: LadDate, draw: Draw, raffle: Raffle, combination: number}, util: LadAPIUtils): Promise<BlackNumberSummary> {
    let {date, draw, raffle, combination} = args;
    combination = Number(combination)
    const filter = {
      'date.year':  date.year,
      'date.month': date.month,
      'date.day':   date.day,
      draw,
      raffle,
      combination
    };
    let summary: BlackNumberSummary = await util.documentService.findOne(BlackNumberSummary.MODEL_NAME, filter);
    if (!summary) {
      summary = new BlackNumberSummary(date, draw, raffle, combination);
      await util.documentService.upsert(summary, util.currentUserId);
    }
    return summary;
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getGameSheetItems(args: {startDate: Date, endDate: Date, draw: Draw, raffle: Raffle, coordinator: string, agent: string}, util?: LadAPIUtils): Promise<GameSheetItem[]> {
    if (!args.raffle) {
      throw new Error('No raffle specified.');
    }
    if (args.agent && !args.coordinator) {
      throw new Error('No coordinator provided');
    }

    const filter: any = {
      raffle: args.raffle,
      $and: [
        {'date.date': {$gte: new Date(args.startDate)}},
        {'date.date': {$lte: new Date(args.endDate)}}
      ]
    }
    args.draw = args.draw ? args.draw : Draw.ALL;
    if (args.draw === Draw.ALL) {
      filter.$or = [
        {draw: Draw.d1},
        {draw: Draw.d2},
        {draw: Draw.d3},
      ]
    } else {
      filter.draw = args.draw;
    }

    const itemBuffer: {[key: string]: GameSheetItem} = {};
    const numberSummaries: BlackNumberSummary[] = await util.documentService.find(BlackNumberSummary.MODEL_NAME, filter);
    for (const summary of numberSummaries) {
      const combination = Number(summary.combination);
      if (!itemBuffer[combination]) {
        itemBuffer[combination] = {
          raffle: args.raffle,
          label: combinationFormat(summary.combination, args.raffle),
          value: 0,
          combination,
          details: []
        };
      }

      const item: GameSheetItem = itemBuffer[combination];

      let total = 0;
      let rawAgentDetails: GameSheetItemDetail[] = [];
      if (args.agent){
        total = summary.details[args.coordinator]?.details[args.agent]?.total
        rawAgentDetails.push({agentId: args.agent, total, agentName: ''})
      } else if (args.coordinator) {
        total = summary.details[args.coordinator]?.total
        if (total) {
          const details = summary.details[args.coordinator].details;
          for (const agentId in details) {
            const detailItem = details[agentId];
            rawAgentDetails.push({agentId, total: detailItem.total})
          }
        }
      } else {
        total = summary.total;
        if (total) {
          // todo combine with above
          for (const coorItem of Object.values(summary.details)) {
            const details = coorItem.details;
            for (const agentId in details) {
              const detailItem = details[agentId];
              rawAgentDetails.push({agentId, total: detailItem.total})
            }
          }
        }
      }
      item.value += num(total);

      // sort out details
      const buffer = {};
      for (const r of rawAgentDetails) {
        if (!buffer[r.agentId]) {
          const agent = await BlackAgent.getAgentById({id: r.agentId}, util);
          if (agent) {
            r.agentName = agent.name;
          } else {
            r.agentName = 'n/a';
          }
          buffer[r.agentId] = r;
          continue
        }
        const detail: GameSheetItemDetail = buffer[r.agentId];
        detail.total += r.total
      }
      let unSorted: GameSheetItemDetail[] = Object.values(buffer);
      item.details = unSorted.sort((a, b) => b.total - a.total)

    }

    const result = Object.values(itemBuffer);
    result.sort((a, b) => b.value - a.value);
    return result;
  }
}

export interface GameSheetItemDetail {
  agentId: string,
  agentName?: string,
  total: number
}

export interface GameSheetItem {
  raffle: Raffle,
  label: string,
  value: number,
  combination: number,
  details: GameSheetItemDetail[]
}
