import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {QtProductModel} from '../../models/qt-product.model';
import {DynamicFormService} from '../../../../app/dynamic-form/dynamic-form.service';
import {APIService} from '../../../../app/shared/backend/api.service';
import { QtModelNames } from '../../models/qt-model.names';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-product-masterlist',
  templateUrl: './product-masterlist.component.html',
  styleUrls: ['./product-masterlist.component.scss']
})
export class ProductMasterlistComponent implements OnInit {
  productsArr: QtProductModel[];  

  constructor(
    private cdr: ChangeDetectorRef,
    private dfs: DynamicFormService,
    private api: APIService,
    public modalService: NgbModal
    ) {   
    QtProductModel.search({searchKey: null}).then(e => {
      this.productsArr = e;
      cdr.detectChanges();
    });

  }

  ngOnInit(): void {
  }

  refresh(){
    QtProductModel.search({searchKey: null}).then(e => {
      this.productsArr = e;
      this.cdr.detectChanges();
    });
  }

  async delete(productId: QtProductModel){ 
    const response = await this.dfs.fireSwalConfirm('Confirm Product deletion? This will no longer be available in orders', 'warning', 'Delete');
    if(!response.isConfirmed){
        return
    }
    await this.api.deleteDocument(productId.documentId,QtModelNames.PRODUCT).toPromise();
    this.cdr.markForCheck();    
    this.refresh();
  }


}
