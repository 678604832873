<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
  [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
           (click)="toggleSidebar()" data-toggle="collapse">
        <i class="ft-menu font-large-1 ml-1"></i>
      </div>
      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <!--        <li class="nav-item d-none d-lg-block">-->
        <!--          <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:" appToggleFullscreen-->
        <!--             (click)="ToggleClass()"><i class=" {{toggleClass}} font-large-1"></i></a>-->
        <!--        </li>-->
        <li class="nav-item nav-search">
          <a (click)="toggleSearchOpenClass(true)" class="nav-link nav-link-search" href="javascript:"
             id="navbar-search" style="padding-right: 8px; padding-left: 8px;">
            <i class="ft-search font-large-1"></i>
          </a>
          <div class="search-input {{searchOpenClass}}">
            <div class="search-input-icon">
              <i class="ft-search font-medium-3"></i>
            </div>
            <input #search
                   (keydown.esc)="onEscEvent()" (keyup)="triggerKeyUp($event)" (keyup.enter)="onEnter()" [formControl]="control" autofocus
                   class="input"
                   data-search="template-search"
                   placeholder="Enter keyword..."
                   [appAutocomplete]="autocomplete"
                   tabindex="0"
                   type="text"/>
            <div class="search-input-close" (click)="toggleSearchOpenClass(false)">
              <i class="ft-x font-medium-3"></i>
            </div>

            <app-autocomplete #autocomplete="appAutocomplete">
              <ng-template appAutocompleteContent>
                <ng-container *ngIf="(listItems) as result">
                  <app-option #searchResults (click)="option.onClick()" (mouseenter)="removeActiveClass()" *ngFor="let option of result"
                              [url]="option.url" [value]="option.name">
                    <div class="d-flex align-items-center justify-content-between w-100">
                      <div class="d-flex align-items-center justify-content-start">
                        <i class="{{option.icon}} mr-2"></i>
                        <span>{{ option.name }}</span>
                      </div>
                    </div>
                  </app-option>
                  <app-option *ngIf="searchOpenClass === 'open' && listItems?.length === 0" class="no-result">No results
                    found.
                  </app-option>
                </ng-container>
              </ng-template>
            </app-autocomplete>
          </div>
        </li>

      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo">
                <a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                  <div *ngIf="logoUrl" class="logo-img">
                    <img class="logo-img" alt="App Logo" [src]="logoUrl">
                  </div>
                  <span class="text">{{navbarTitle}}</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a (click)="goBackPage()" class="nav-link" href="javascript:" id="navbar-back"><i
              class=" ft-arrow-left font-large-1"></i></a>
          </li>
          <li class="dropdown nav-item mr-1" display="static" ngbDropdown placement="bottom-left">
            <a *ngIf="avatarInfo" class="nav-link dropdown-toggle user-dropdown d-flex align-items-end"
               id="dropdownBasic2"
               href="javascript:" data-toggle="dropdown" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right">{{avatarInfo.name}}</span><span
                class="text-right text-muted font-small-3">{{avatarInfo.role}}</span>
              </div>
              <img (error)="avatarInfo.avatarUrl='assets/img/portrait/default_account.png'" [src]="avatarInfo.avatarUrl"
                   alt="avatar" class="avatar" height="35" width="35"/>
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
              ngbDropdownMenu>
              <!--
              <a class="dropdown-item" routerLink="/chat">
                <div class="d-flex align-items-center">
                  <i class="ft-message-square mr-2"></i><span>Chat</span>
                </div>
              </a>
              <a class="dropdown-item" routerLink="/pages/profile">
                <div class="d-flex align-items-center">
                  <i class="ft-edit mr-2"></i><span>Edit Profile</span>
                </div>
              </a>
              <a class="dropdown-item" routerLink="/inbox">
                <div class="d-flex align-items-center">
                  <i class="ft-mail mr-2"></i><span>My Inbox</span>
                </div>
              </a>
              -->
              <a class="dropdown-item" routerLink="/pages/my-account">
                <div class="d-flex align-items-center">
                  <i class="ft-edit mr-2"></i><span>My Account</span>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" routerLink="/pages/login" (click)="doLogout()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Logout</span>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

</nav>
