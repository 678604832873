<div *ngIf="formField" [formGroup]="formGroup" [ngSwitch]="formField.inputType" class="form-group">
  <label *ngIf="formField.label" [class]="formField.labelClass">
    {{formField.label}}
    <ng-container *ngIf="!formField?.formControl?.valid" class="text-warning">
      <a class="ft-info font-small-3 blinking" [ngbTooltip]="getErrorMessages(formField.formControl.errors)"></a>

    </ng-container>
  </label>

  <ng-container *ngSwitchCase="'date'">
    <div class="input-group">
      <input
        [formControlName]="formField.key"
        [id]="formField.key"
        [name]="formField.key"
        [ngClass]="formField.inputClass"
        [placeholder]="formField.placeholder"
        ngbDatepicker
        #d2="ngbDatepicker" class="form-control">
      <div class="input-group-append">
        <div class="input-group-text" (click)="d2.toggle()">
          <i class="fa fa-calendar" style="cursor: pointer;"></i>
        </div>
      </div>
    </div>
  </ng-container>

  <ngb-timepicker
    *ngSwitchCase="'time'"
    [(ngModel)]="formField.value"
    [ngModelOptions]="{standalone: true}"
    #timepicker
    [id]="formField.key"
    [meridian]="true"
    [spinners]="formField.withTimeSpinners">
  </ngb-timepicker>

  <input
    *ngSwitchCase="'number'"
    [formControlName]="formField.key"
    [id]="formField.key"
    [name]="formField.key"
    [ngClass]="formField.inputClass"
    [placeholder]="formField.placeholder"
    [type]="formField.inputType"
    class="form-control">

  <ng-container *ngSwitchDefault>
    <input
      *ngIf="_typeAheadProcessor == null"
      [id]="formField.key"
      [ngClass]="formField.inputClass"
      [placeholder]="formField.placeholder"
      [type]="formField.inputType"
      [formControlName]="formField.key"
      [maxlength]="formField.maxLength"
      [minlength]="formField.minLength"
      class="form-control">
    <input
      *ngIf="_typeAheadProcessor != null"
      [id]="formField.key"
      [ngClass]="formField.inputClass"
      [ngbTypeahead]="_typeAheadProcessor"
      [placeholder]="formField.placeholder"
      [type]="formField.inputType"
      [value]="_value"
      [formControlName]="formField.key"
      class="form-control dis">
  </ng-container>

  <a *ngIf="formField.otp" href="javascript:" class="font-weight-bold text-warning" (click)="onOTPRequest()">Request OTP</a>
  <a *ngIf="formField.uuid" href="javascript:" class="text-warning" (click)="regenerateUUID()">Renew</a>
</div>

