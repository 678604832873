import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {QtProductModel} from '../../models/qt-product.model';
import {QtOrderItem, QtOrderModel} from '../../models/qt-order.model';
import {DynamicFormService} from '../../../../app/dynamic-form/dynamic-form.service';
import {APIService} from '../../../../app/shared/backend/api.service';
import {animate, group, query, style, transition, trigger} from '@angular/animations';
import {NavbarSearchResultItem, NavbarSearchService} from '../../../../app/shared/navbar/navbar-search.service';
import {GPayAndroidService} from '../../../../app/android/gpay.android';
import {QtBusinessModel} from '../../models/qt-business.model';

@Component({
  selector: 'app-menu',
  templateUrl: './pos-menu.component.html',
  styleUrls: ['./pos-menu.component.scss'],
  animations: [
    trigger('animImageSlider', [
      transition(':increment', [
        query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
        group([
          query(':enter', [
            style({ transform: 'translateX(100%)' }),
            animate('.3s ease-out', style({ transform: 'translateX(0%)' }))], {
            optional: true,
          }),
          query(':leave', [
            style({ transform: 'translateX(0%)' }),
            animate('.3s ease-out', style({ transform: 'translateX(-100%)' }))], {
            optional: true,
          }),
        ]),
      ]),
      transition(':decrement', [
        query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
        group([
          query(':enter', [
            style({ transform: 'translateX(-100%)' }),
            animate('.3s ease-out',style({ transform: 'translateX(0%)' }))], {
            optional: true,
          }),
          query(':leave', [
            style({transform: 'translateX(0%)'}),
            animate('.3s ease-out', style({transform: 'translateX(100%)'}))], {
            optional: true,
          }),
        ]),
      ]),
    ]),
  ]
})
export class PosMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  currentTemplate: number = 0;
  menus: QtProductModel[] = [];
  selectedItem: string;
  selectedItems: QtOrderItem[] = [];
  itemSelectedQty: number;
  isItemClicked: boolean;
  itemClickedDetail: QtProductModel;
  isClickedCDP: boolean;
  defaultColor = '#1ab7ea';
  confirmOrder: boolean;
  cashAmount: string;
  changeAmount: string;
  @ViewChild('cashInput') cashInput!: ElementRef;
  total: number = 0;
  orderId: string;

  initialValues = {
    selectedItems: [],

  };
  private currentBusinessRef: QtBusinessModel;

  constructor(
    private cd: ChangeDetectorRef,
    private api: APIService,
    private dfs: DynamicFormService,
    private navbarSearch: NavbarSearchService,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
  }

  ngOnInit(): void {
    this.resetVariables();
    QtProductModel.search({searchKey: null}).then(result => {
      for (const e of result) {
        e.shortDocumentId = e.documentId.split('-')[0];
      }
      this.menus = result;
      this.cd.detectChanges();
    });
    // QtOrderModel.search({id: null}).then((e: any) => {
    //   this.itemsSelected = e.items;
    //   this.cd.detectChanges();
    // });
  }

  resetVariables(){
    this.selectedItems = [];
    this.itemSelectedQty = 0;
    this.isItemClicked = false;
    this.confirmOrder = false
  }

  ngOnDestroy(): void {
    this.navbarSearch.removeSearchClient(this.onNavbarSearch);
  }

  getQuantity(id){
    const res = this.selectedItems.find(x => x.product.relatedDocumentId === id);
    return res?.quantity;
  }

  getDetails(id, property){
    const item = this.menus.find(x => x.documentId === id);
    return item[property];
  }

  checkSelectedItem(id){
    const item = this.selectedItems.find(x => x.product.relatedDocumentId === id);
    if(item){
      return true;
    }
  }

  async addItem(product: QtProductModel){
    const id = product.documentId;
    this.selectedItem = id;
    let item = this.selectedItems.find(x => x.product.relatedDocumentId === id);
    if(!item){
      if (!this.selectedItems) {
        this.selectedItems = [];
      }
      item = new QtOrderItem(product);
      this.selectedItems.push(item);
      this.itemSelectedQty = 1;
    } else {
      item.quantity = parseFloat((item.quantity + 1).toFixed(1));
      this.itemSelectedQty = item.quantity;
    }
    this.itemClickedDetail = this.menus.find(x => x.documentId === id);
    this.isClickedCDP = false;
    this.cashAmount = this.totalAmount().toFixed(1);
    this.changeAmount = '0';
  }

  async menuQty(id){
    try{
      let item = this.selectedItems.find(x => x.product.relatedDocumentId === id);
      if(item.quantity > 0) {
        item.quantity = parseFloat((item.quantity - 1).toFixed(1));
        if(item.quantity <= 0){
          this.cancelItem(id);
        }
      }
      this.itemSelectedQty = item.quantity;
      this.cashAmount = this.totalAmount().toFixed(1);
      this.changeAmount = '0';
      this.cd.detectChanges();
    } catch (e) {
      console.error(e);
    }
  }

  async cancelItem(id){
    this.selectedItems.forEach((u, i) => {
      if (u.product.relatedDocumentId === id) {
        this.selectedItems.splice(i, 1);
      }
    });
    this.isItemClicked = false;
    this.isClickedCDP = !this.isClickedCDP;
    this.cashAmount = this.totalAmount().toFixed(1);
    this.changeAmount = '0';
  }

  async qtyChange(id, type){
    let item = this.selectedItems.find(x => x.product.relatedDocumentId === id);
    if(type === 'minus' && item.quantity > 1){
      item.quantity = parseFloat((item.quantity - 1).toFixed(1));
    } else if(type === 'add'){
      item.quantity = parseFloat((item.quantity + 1).toFixed(1));
    }
    this.itemSelectedQty = item.quantity;
    this.cd.detectChanges();
    this.cashAmount = this.totalAmount().toFixed(1);
    this.changeAmount = '0';
  }

  async customQtyChange(event: any){
    const qty = Number(event.target.value);
    let item = this.selectedItems.find(x => x.product.relatedDocumentId === this.selectedItem);
    item.quantity = qty;
    this.itemSelectedQty = qty;
    this.cashAmount = this.totalAmount().toFixed(1);
    this.changeAmount = '0';
    this.cd.detectChanges();
  }

  async blurQtyChange(){
    if(this.itemSelectedQty <= 0){
      this.cancelItem(this.selectedItem);
    }
  }

  totalAmount(){
    let amount: number = 0;
    this.selectedItems?.forEach( (item:QtOrderItem) => {
      const price = this.menus?.find(x => x.documentId === item.product.relatedDocumentId);
      amount += (price?.unitPrice * item.quantity);
    });
    amount = parseFloat(amount.toFixed(1));
    return amount;
  }

  cashChange(type, value){
    const numericValue: number = parseFloat(value);
    const money = [0, 1, 5, 10, 20, 50, 100, 200, 500, 1000];
    let thousands = Math.floor(numericValue / 1000) * 1000;
    // let hundreds = numericValue % 1000;
    // let hundreds = numericValue % 1000;
    // let tens = numericValue % 100;
    // let ones = numericValue % 10;
    // const hundredsTens = Math.floor((numericValue % 10000) / 10);
    let hundreds = Math.floor((numericValue % 1000) / 100) * 100;
    let tens = Math.floor((numericValue % 100) / 10) * 10;
    let hundredsTens = hundreds + tens;
    let ones = numericValue % 10;
    let nearest: number = 0;
    if(type === 'minus'){
      if (!isNaN(numericValue)) {
        for (let i = 0; i < money.length; i++) {
          if (money[i] >= hundreds) {
            nearest = money[i - 1];
            break;
          }
        }
        if (hundreds === 0 && thousands >= 1000) {
          thousands -= 1000;
          nearest = money[8];
        }
        nearest = thousands + nearest;
      }
    } else if(type === 'add'){
      if (!isNaN(numericValue)) {
        for (let i = 0; i < money.length; i++) {


          // if (money[i] > tens) {
          //   nearest = getNext(numericValue, money[i]);
          //   break;
          // }
          // if (money[i] > ones) {
          //   // nearest = getNext(numericValue, money[i]);
          //   ones = money[i];
          //   break;
          // }
          if (money[i] > ones) {
            // nearest = getNext(numericValue, money[i]);

            ones = getNext(ones, money[i]);
            tens = getNext((tens + ones), money[i]);
            nearest = getNext((hundreds + tens), money[i]);


            // nearest = money[i] + ones;
            // nearest = money[i];
            break;
          }
        }
        console.log('----------');
        console.log(ones);
        console.log(tens);
        console.log(hundreds);

        // nearest = (thousands? thousands : 0) + (hundreds? hundreds : 0) + (ones? ones : 0);
        nearest = (thousands? thousands : 0) + nearest;
        console.log(nearest);
        console.log('----------');

        function getNext(amount, index) {
          const remainder = amount % index;
          return remainder === 0 ? amount : amount + (index - remainder);
          // const mod = amount % deno;
          // const diff = amount - mod;
          // return diff + deno;
        }


        // let num;
        // if(ones === 0){
        //   num = 1;
        // } else if(ones > 0 && ones < 5){
        //   num = 5;
        // } else if(ones > 4 && ones < 10){
        //   num = 10;
        // }
        // console.log(num);
        // console.log(nearest);


        // const mod = numericValue % nearest;
        // const diff = numericValue - mod;
        // nearest =  diff + nearest;

      }
    }
    const change = nearest - parseFloat(this.totalAmount().toFixed(1));
    this.cashAmount = nearest.toFixed(1);
    this.changeAmount = change.toFixed(1);
    this.cashInput.nativeElement.value = nearest.toFixed(1);
    this.cd.detectChanges();
  }

  cashInputValue(event: any) {
    if (!isNaN(parseFloat(event.target.value))) {
      this.changeAmount = (parseFloat(event.target.value) - this.totalAmount()).toFixed(1);
    } else {
      this.cashAmount = '0';
      this.changeAmount = '0';
    }
  }

  async blurCashInput(event: any){
    if(event.target.value === ''){
      event.target.value = '0.0';
    } else {
      const value = parseFloat(event.target.value).toFixed(1);
      this.cashAmount = value;
      event.target.value = value;
    }
  }

  decimalInputValue(event: any) { // one decimal point only
    const regex = new RegExp(/^\d*\.?\d{0,1}$/g);
    const current: string = event.target.value;
    if (current && !String(current).match(regex)) {
      event.target.value = current.slice(0, -1);
    }
  }

  async confirmAndSaveOrder() {
    const confirm = await this.dfs.fireSwalConfirm('Warning: admin permission is required to cancel orders.', null, 'Confirm Order?');
    if (!confirm.isConfirmed) {
      return;
    }
    try {
      const order = new QtOrderModel(this.selectedItems);
      debugger;
      const result = await QtOrderModel.saveOrder(order);
      this.orderId = result.documentId;
      this.confirmOrder = !this.confirmOrder;
      this.cd.detectChanges();
    } catch (e) {
      console.error(e);
      this.dfs.oops('Save order failed.  Try again later.',);
    }
  }

  getOrderDetails(){
    const length = this.selectedItems.length - 1;
    let ordersString = ''
    for (let i = 0 ; i < this.selectedItems.length; i++) {
      const item = this.selectedItems[i];
      ordersString += `[L]${item?.name} [R]${(item.total).toFixed(1)}\n`
                    + `[L] ${item?.quantity} x ${Number(item?.unitPrice).toFixed(1)}${ i < length? '\n' : ''}`
    }
    return ordersString;
  }

  async printOrder(order: QtOrderModel){

    const layout = `
${this.currentBusinessRef.orderSlipHeader}
[L]ORDER#: ${this.orderId?.toUpperCase()}
[L]TERMINAL: M1
[C]--------------------------------
[L]ORDER DETAILS
[C]--------------------------------
${this.getOrderDetails()}
[C]--------------------------------
[L]TOTAL [R]₱${order.gross}
[L]CASH [R]${this.cashAmount}
[L]CHANGE [R]${this.changeAmount}
[C]<barcode type='128' height='10'>${paddy('1000', 7)}</barcode>
${this.currentBusinessRef.orderSlipFooter}
`
    try {
      console.log(layout);
      GPayAndroidService.sendToBluetoothPrinter(layout);
    } catch (e) {
      console.error(e);
    }
  }

  async reset(){
    const confirm = await this.dfs.fireSwalConfirm('Please note that the order have already been saved.', null, 'Exit Order?');
    if (!confirm.isConfirmed) {
      return;
    } else {
      this.resetVariables();
      this.cd.detectChanges();
      this.currentTemplate = 0;
    }
  }

  async onNavbarSearch(key): Promise<NavbarSearchResultItem[]> {
    key = key?.toLowerCase();
    if (!key) {
      return [];
    }
    return this.menus.filter(e => {
      return e.name?.toLowerCase().includes(key) || e.code?.toLowerCase().includes(key) || `${e.unitPrice}`.includes(key)
    }).map(e => {
      return {
        'name': `${e.code}: ${e.name} (${e.unitPrice ? Number(e.unitPrice).toFixed(2) : '0.00'})`.toUpperCase(),
        'icon': 'ft-tag',
        onClick: async () => {
          const container = this.elementRef.nativeElement.querySelector('#menuItemsContainer');
          const element = this.elementRef.nativeElement.querySelector(`#m${e.shortDocumentId}`);
          console.log(element.offsetTop);
          if (container && element) {
            this.renderer.setProperty(container, 'scrollTop', element.offsetTop - element.offsetHeight);
          }
          this.addItem(e);
          this.isItemClicked = true;
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.navbarSearch.setSearchClient((key) => {
      return this.onNavbarSearch(key);
    });

    QtBusinessModel.getCurrentUserBusiness().then(result => {
      this.currentBusinessRef = result;
    })
  }

}

export function paddy(toPadStr, padLength?, padchar?) {
  try{
    const pad_char = typeof padchar !== 'undefined' ? padchar : '0';
    const pad = new Array(1 + padLength).join(pad_char);
    return (pad + toPadStr).slice(-pad.length); // returns a string
  }catch(err){
    return null;
  }
}
