import {Draw, getWeight, Raffle, RaffleSettings} from './utils/number-helper';
import {API, APIFunctionTypes, LadAPIUtils, LadDate, SystemDocument} from 'ladrov-commons';
import {BLACK_SYSTEM_NAME} from './utils/black-utils';
import {BlackAgent} from './black-agent';
import {BlackCoordinator} from './black-coordinator';
import {BlackOwnerSettings} from './black-owner-settings';
import {BlackNumberSummary, BlackNumberSummaryCoorItem} from './black-number-summary';
import {BlackAgentSummary, BlackAgentSummaryWinDetailItem} from './black-agent-summary';

export class BlackWinningNumber extends SystemDocument {
  public static MODEL_NAME = 'BlackWinningNumber';

  constructor(public date: LadDate, public draw: Draw, public raffle: Raffle, public combination: number) {
    super(BlackWinningNumber.MODEL_NAME, BLACK_SYSTEM_NAME);
  }

  @API(APIFunctionTypes.POST_SAVE)
  public static async postSave(win: BlackWinningNumber, util: LadAPIUtils) {
    win.combination = Number(win.combination);
    // buffer for fetching coor ref
    const coordinatorBuffer: {[key: string]: BlackCoordinator} = {};
    //
    const summary = await BlackNumberSummary.getSummary(win, util);
    // for each agent that won, update winning number and net
    const updateInfos = [];
    for (const coorId of Object.keys(summary.details)) {
      const coorItem: BlackNumberSummaryCoorItem = summary.details[coorId];
      // look into coordinator details
      for (const areaId of Object.keys(coorItem.details)) {
        const areaItem = coorItem.details[areaId];
        // look into area details
        for (const agentId of Object.keys(areaItem.details)) {
          const agentItem = areaItem.details[agentId];
          const totalBetAmount = agentItem.total;
          // todo: calculate weight
          const {weight} = getWeight(win.raffle, win.combination, totalBetAmount);
          const totalWin = await getUnitPrize(agentId, win) * weight;
          // update wins on agent summary
          const updateInfo = {
            documentId: agentItem.agentSummaryId,
            $set: {},
            systemHeader: {
              type: BlackAgentSummary.MODEL_NAME
            }
          };
          const $set = { totalWin: -1 }; // indicates that totalWin needs to be recalibrated
          const winItem: BlackAgentSummaryWinDetailItem = {totalWin, details: agentItem.details};
          $set[`winDetails.${win.raffle}`] = winItem;
          updateInfo.$set = $set;
          updateInfos.push(updateInfo);
        }
      }
    }
    await util.documentService.upsert(updateInfos, util.currentUserId);

    async function getUnitPrize(agentId: string, win: BlackWinningNumber) {
      // get agent win
      const agentRef = await BlackAgent.getAgentById({id: agentId}, util);
      const agentUnitPrizes = agentRef.unitPrizes ? agentRef.unitPrizes : [];
      // get coordinator win
      const coordinatorId = agentRef.coordinatorRel.relatedDocumentId;
      if (!coordinatorBuffer[coordinatorId]) {
        if (agentRef.coordinatorRel.objectRef) {
          coordinatorBuffer[coordinatorId] = agentRef.coordinatorRel.objectRef;
        } else {
          coordinatorBuffer[coordinatorId] = await BlackCoordinator.getById({id: coordinatorId}, util);
        }
      }
      const coorRef = coordinatorBuffer[coordinatorId];
      const coorUnitPrizes = coorRef.unitPrizes;

      const ownerRef: BlackOwnerSettings = await BlackOwnerSettings.getOwnerSettings(null, util);
      const ownerUnitPrizes = ownerRef.unitPrizes;

      const combined = [...ownerUnitPrizes, ...coorUnitPrizes, ...agentUnitPrizes];
      let selectedSettings: RaffleSettings;
      for (const i of combined) {
        if (win.raffle === i.raffleId) {
          // set from - to if not set already
          i.to = i.to ? Number(i.to) : i.to === 0 ? 0 : NaN;
          i.from = i.from ? Number(i.from) : i.from === 0 ? 0 : NaN;
          if (!isNaN(i.from) && isNaN(i.to)) {
            i.to = i.from;
          }
          if (!isNaN(i.to) && isNaN(i.from)) {
            i.from = i.to;
          }
          if (!isNaN(i.from)) {
            // make sure range is correct
            const range = [i.from, i.to];
            let from: number = Math.min(...range);
            let to: number = Math.max(...range);
            if (win.combination >= from && win.combination <= to) {
              selectedSettings = i;
            }
          } else {
            selectedSettings = i;
          }

        }
      }
      if (!selectedSettings) {
        return 0;
      }
      return selectedSettings.value;
    }

  }

  @API(APIFunctionTypes.PRIVATE)
  public static async saveWinningNumbers(args: {changes: BlackWinningNumber[] }, util?: LadAPIUtils) {
    await util.documentService.upsert(args.changes, util.currentUserId);
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getWinningNumbers(args: { date: LadDate; draw: Draw }, util?: LadAPIUtils) {
    const filter: any = {
      'date.year': args.date.year,
      'date.month': args.date.month,
      'date.day': args.date.day
    }
    if (args.draw) {
      if (args.draw === Draw.ALL) {
        filter.$or = [
          {draw: Draw.d1},
          {draw: Draw.d2},
          {draw: Draw.d3},
        ]
      } else {
        filter.draw = args.draw;
      }
    }
    return await util.documentService.find(BlackWinningNumber.MODEL_NAME, filter);
  }
}
