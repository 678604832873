import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';

import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';

import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ToastrModule} from 'ngx-toastr';
import {AgmCoreModule} from '@agm/core';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {StoreModule} from '@ngrx/store';
import {DragulaService} from 'ng2-dragula';
import {NgxSpinnerModule} from 'ngx-spinner';

import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';

import {AppRoutingModule} from './app-routing.module';
import {SharedModule} from './shared/shared.module';
import * as fromApp from './store/app.reducer';
import {AppComponent} from './app.component';
import {ContentLayoutComponent} from './layouts/content/content-layout.component';
import {FullLayoutComponent} from './layouts/full/full-layout.component';

import {AuthService} from './shared/auth/auth.service';
import {AuthGuard} from './shared/auth/auth-guard.service';
import {WINDOW_PROVIDERS} from './shared/services/window.service';
import {Title} from '@angular/platform-browser';
import {DynamicFormSaveService} from './dynamic-form/dynamic-form-save.service';
import {APIFunctionEntry, DocumentModuleEntry, setRegistrar} from 'ladrov-commons';
import {APIService} from './shared/backend/api.service';
import {TitleService} from './shared/services/title.service';

// APP modules
// import {JpeAppModule} from '../projects/jpe-app/jpe-app.module';
import {PosModule} from '../projects/pos/pos.module';
// import {GiiModule} from '../projects/gii/gii.module';
// import {LopModule} from '../projects/lop/lop.module';
import {BlackAppModule} from '../projects/black/black-app.module';
import {CustomDateFormatterService} from "../projects/black/pages/home/CustomDateFormatterService";

const CURRENT_PROJECT: any = 'black'

let toInstallAppModules: any = [
  // JpeAppModule,
  // PosModule,
  // GiiModule,
  // LopModule,
  BlackAppModule
];

switch (CURRENT_PROJECT) {
  // case 'jpe':
  //   toInstallAppModules = [JpeAppModule];
  //   break;
  case 'qt':
    toInstallAppModules = [PosModule];
    break;
  // case 'gii':
  //   toInstallAppModules = [GiiModule];
  //   break;
  // case 'lop':
  //   toInstallAppModules = [LopModule];
  //   break;
  case 'black':
    toInstallAppModules = [BlackAppModule];
    break;
}

const firebaseConfig = {
  apiKey: 'YOUR_API_KEY', // YOUR_API_KEY
  authDomain: 'YOUR_AUTH_DOMAIN', // YOUR_AUTH_DOMAIN
  databaseURL: 'YOUR_DATABASE_URL', // YOUR_DATABASE_URL
  projectId: 'YOUR_PROJECT_ID', // YOUR_PROJECT_ID
  storageBucket: 'YOUR_STORAGE_BUCKET', // YOUR_STORAGE_BUCKET
  messagingSenderId: 'YOUR_MESSAGING_SENDER_ID', // YOUR_MESSAGING_SENDER_ID
  appId: 'YOUR_APP_ID', // YOUR_APP_ID
  measurementId: 'YOUR_MEASUREMENT_ID' // YOUR_MEASUREMENT_ID
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initializeTitle(titleService: TitleService) {
  return async () => await titleService.setTitle();
}

@NgModule({
  declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent],
  imports: [
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js'),
    StoreModule.forRoot(fromApp.appReducer),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    ToastrModule.forRoot(),
    NgbModule,
    NgxSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAP_API_KEY'
    }),
    PerfectScrollbarModule,
      ...toInstallAppModules
  ],
  providers: [
    AuthService,
    AuthGuard,
    DragulaService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
    WINDOW_PROVIDERS,
    Title,
    TitleService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeTitle,
      deps: [TitleService],
      multi: true
    },
    DynamicFormSaveService,
    { provide: NgbDateParserFormatter, useClass: CustomDateFormatterService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(api: APIService) {
    // this will allow direct calls to functions like QtProductModel.search(args) in client
    setRegistrar((m: DocumentModuleEntry, apiFn: APIFunctionEntry) => {
      let modelName =  m.model.MODEL_NAME;
      if (!modelName) {
        modelName = new m.model().systemHeader.type;
      }
      m.model[apiFn.name] = (args, utils, options) => {
        return api.executeAPI(apiFn.name, modelName, args).toPromise();
      }
    });

  }
}
