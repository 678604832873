
<h2 class="card-title">
  <i class="ft-grid"></i>
  &nbsp;Transaction History
</h2>

<div class="input-group col-md-3">
  <input
    [(ngModel)]="selectedDate" [ngModelOptions]="{standalone: true}"
    (ngModelChange)="doSearch()"
    ngbDatepicker
    #d2="ngbDatepicker" class="form-control">
  <div class="input-group-append">
    <div class="input-group-text" (click)="d2.toggle()">
      <i class="fa fa-calendar" style="cursor: pointer;"></i>
    </div>
  </div>
</div>

<section id="responsive-table">
  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div style="padding: 0px !important;">
              <fieldset class="col-md-6" style="padding: 0px !important; max-height: 30px;">
                <form (ngSubmit)="doSearch()">
                  <div class="input-group  mb-2">
                    <input type="text" class="form-control" placeholder="Enter search key" [(ngModel)]="searchKey" [ngModelOptions]="{standalone: true}">
                    <div class="input-group-append">
                      <button class="btn btn-primary" type="submit">Search</button>
                    </div>
                  </div>
                </form>
              </fieldset>
              <div class="col-md-6 mb-3">
              </div>
            </div>

            <div>
              Total: {{totalAmount}} ({{transactionsArr?.length}} items)
            </div>

            <table class="table table-responsive-md mt-2 col-md-12" >
              <thead>
              <tr>
                <th>#</th>
                <th>Draw</th>
                <th scope="col" >Bet ID</th>
                <th scope="col" style="text-align: right;">TIME</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let i of transactionsArr; let idx = index">
                <td>
                  #{{idx + 1}}
                </td>
                <td>
                  {{i.draw | uppercase }}
                </td>
                <td>
                  Ref#
                  <a (click)="openTransaction(i)" class="info p-0" data-placement="top" data-toggle="tooltip" title="View transaction">
                    <span class="" [innerHTML]="i.transactionId "></span>
                    <br/>
                    P<span [innerHTML]="i.total | number: '1.2-2'"></span>
                  </a>
                </td>
                <td style="text-align: right;">
                  {{shortTime(i.date)}}
                </td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  </div>
</section>
