import {API, APIFunctionTypes, LadAPIUtils, LadDate, LadRelatedDocument, SystemDocument} from 'ladrov-commons';
import {BlackAgent} from './black-agent';
import {BlackCoordinator} from './black-coordinator';
import {Draw, num, Raffle} from './utils/number-helper';
import {BLACK_SYSTEM_NAME} from './utils/black-utils';
import {timeAgo} from '../../../app/android/date-helper';

export interface BlackAgentSummaryWinDetailItem {
  totalWin: number,
  details: { [key: string]: number } // transactionId = winAmount (final win amount)
}

// per draw
export class BlackAgentSummary extends SystemDocument {
  public static MODEL_NAME = 'BlackAgentSummary';

  gross: number;
  totalWin: number;
  winDetails: {[key: string]: BlackAgentSummaryWinDetailItem} = { }; // key is raffle
  commission: number;
  net: number;

  latestTransactionId: string;

  public agentRel: LadRelatedDocument<BlackAgent>;
  public coordinatorRel: LadRelatedDocument<BlackCoordinator>;

  constructor(public date: LadDate, public draw: Draw, agent: BlackAgent) {
    super(BlackAgentSummary.MODEL_NAME, BLACK_SYSTEM_NAME);
    this.agentRel = {
      relatedDocumentType: BlackAgent.MODEL_NAME,
      relatedDocumentId: agent.documentId
    };
    this.coordinatorRel = agent.coordinatorRel;
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getBlackAgentSummary(args: {date: LadDate, draw: Draw, agentId: string}, util?: LadAPIUtils) {
    const {date, draw, agentId} = args;
    const filter = {
      'date.year':  date.year,
      'date.month': date.month,
      'date.day':   date.day,
      draw,
      'agentRel.relatedDocumentId': agentId
    }
    let summary: BlackAgentSummary = await util.documentService.findOne(BlackAgentSummary.MODEL_NAME, filter);
    if (!summary) {
      const agentObj = await util.documentService.findOne(BlackAgent.MODEL_NAME, {documentId: agentId});
      summary = new BlackAgentSummary(date, draw, agentObj);
      await util.documentService.upsert(summary, util.currentUserId);
    }
    return summary;
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getBlackAgentSummaries(args: GetSummariesArgs, util?: LadAPIUtils): Promise<AgentSummaryItem[]> {
    const filter: any = {
      $and: [
        {'date.date': {$gte: new Date(args.startDate)}},
        {'date.date': {$lte: new Date(args.endDate)}}
      ]
    }
    if (args.draw) {
      if (args.draw === Draw.ALL) {
        filter.$or = [
          {draw: Draw.d1},
          {draw: Draw.d2},
          {draw: Draw.d3},
        ]
      } else {
        filter.draw = args.draw;
      }
    }
    if (args.agent){
      filter['agentRel.relatedDocumentId'] = args.agent;
    }
    if (args.coordinator){
      filter['coordinatorRel.relatedDocumentId'] = args.coordinator
    }
    // icombine into one summary item ang multi ALL draw
    const toFetchRelatedDocuments = ['agentRel']
    const result: BlackAgentSummary[] = await util.documentService.find(BlackAgentSummary.MODEL_NAME, filter, {toFetchRelatedDocuments});

    const buffer: {[key: string]: AgentSummaryItem} = {};
    // calc net
    for (const r of result) {
      const agentId = r.agentRel.relatedDocumentId;
      if (!buffer[agentId]) {
        buffer[agentId] = {
          commissions: 0, gross: 0, wins: 0,
          agentName: r.agentRel.objectRef?.name?.toUpperCase(),
          areas: r.agentRel.objectRef?.areaRel?.objectRef?.name,
          agentLastActive: timeAgo(r.agentRel.objectRef?.lastActive),
          winDetails: {}
        }
      }
      const bufItem = buffer[agentId];
      bufItem.commissions += num(r.commission);
      bufItem.gross += num(r.gross);

      let itemWin = 0;
      if (r.winDetails) {
        const raffles = Object.keys(r.winDetails);
        for (const raffle of raffles) {
          const winItem = r.winDetails[raffle];
          itemWin += winItem.totalWin;

          if (!bufItem.winDetails[raffle]) {
            bufItem.winDetails[raffle] = {totalWin: 0, details: {}}
          }
          bufItem.winDetails[raffle].totalWin += winItem.totalWin;

          for (const trans of Object.keys(winItem.details)) {
            bufItem.winDetails[raffle].details[trans] = winItem.details[trans];
          }
        }
      }

      bufItem.wins += num(itemWin);
    }

    const all = Object.values(buffer);
    for (const a of all) {
      a.net = a.gross - a.commissions - a.wins;
    }

    return all;

  }


}

export interface GetSummariesArgs {
  startDate: Date, endDate: Date, draw: Draw, raffle: Raffle, coordinator: BlackCoordinator, agent: BlackAgent
}

export class AgentSummaryItem {
  agentLastActive: string = 'n/a';
  agentName = 'n/a';
  areas = '';
  gross = 0;
  commissions = 0;
  wins = 0;
  winDetails = {};
  net? = 0;
}
