import {API, APIFunctionTypes, LadAPIUtils, LadTime, SystemDocument} from 'ladrov-commons';
import {Draw, Raffle, RaffleSettings} from './utils/number-helper';
import {BlackCoordinator} from './black-coordinator';
import {BLACK_SYSTEM_NAME} from './utils/black-utils';
import {BlackUserBase} from './black-user-base';

export class BlackOwnerSettings extends SystemDocument implements BlackUserBase{

  public static MODEL_NAME = 'BlackOwnerSettings';

  draws = [Draw.d1, Draw.d1, Draw.d3];
  raffles = [Raffle.l2, Raffle.l3, Raffle.s3, Raffle.s3r, Raffle.s2, Raffle.p3, Raffle.d4];

  cutoffTime: { [p: string]: LadTime } = {};
  unitPrizes: RaffleSettings[] = [];
  commissions: RaffleSettings[] = [];
  maxBet: {[key: string]: {[key: string]: RaffleSettings[]}} = {};

  constructor() {
    super(BlackOwnerSettings.MODEL_NAME, BLACK_SYSTEM_NAME);
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getOwnerSettings(args?, util?: LadAPIUtils): Promise<BlackOwnerSettings> {
    let owner = await util.documentService.findOne(BlackOwnerSettings.MODEL_NAME, {});
    if (!owner) {
      owner = new BlackOwnerSettings();
      await util.documentService.upsert(owner, util.currentUserId);
    }
    return owner;
  }

  static getLimitAmount(raffle: any, combination: any, ownerObj: BlackOwnerSettings) {
    return NaN;
  }


}
