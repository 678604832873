<div [formGroup]="formGroup" class="form-group">
  <label *ngIf="formField.label" [attr.for]="formField.key" [class]="formField.labelClass">
    {{formField.label}}
    <a *ngIf="!isValid" class="ft-info font-small-3 blinking"></a>
  </label>
  <ng-select
    [addTag]="formField.addTag"
    bindLabel="label"
    bindValue="value"
    [closeOnSelect]="formField.closeOnSelect"
    [formControlName]="formField.key"
    [formGroup]="formGroup"
    [items]="formField.items"
    [multiple]="formField.multiple"
    [placeholder]="formField.placeholder"
    [virtualScroll]="formField.virtualScroll"
    [compareWith]="formField.compareFn"
    (ngModelChange)="formField.ngModelChangeHandler($event)">
    <ng-template *ngIf="formField.customLabelHtmlFn" let-item="item" ng-label-tmp>
      <div [innerHTML]="formField.customLabelHtmlFn(item) | safeHtml"></div>
    </ng-template>
    <ng-template let-item="item" ng-option-tmp>
      <div *ngIf="item?.customItemDisplayHtmlFn" [innerHTML]="item?.customItemDisplayHtmlFn(item) | safeHtml"></div>
      <div *ngIf="item?.customItemDisplayHtml" [innerHTML]="item?.customItemDisplayHtml | safeHtml"></div>
      <div *ngIf="!item?.customItemDisplayHtml && !item?.customItemDisplayHtmlFn"
           [innerHTML]="item?.label | safeHtml"></div>
    </ng-template>
  </ng-select>
  <code *ngIf="!isValid" class="font-small-1 mr-1">*Invalid!</code>
</div>
