import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';

import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/finally';

import {LadAccountModel, LadClientSettings, SystemDocument} from 'ladrov-commons';

// build origin api route address
const origin = `${window.location.origin}`;

@Injectable({
  providedIn: 'root'
})
export class APIService {

  clientSettings: LadClientSettings;

  // WARNING: DO NOT PUT ANY GUI RELATED CODE HERE (loading, warnings, sweetalert etc).
  // They should be done at the component definition of the page.
  constructor(
    private router: Router,
    private http: HttpClient
  ) {
  }

  getFileUrl(fileName) {
    if (!fileName) {
      return null;
    }

    if (fileName.startsWith('asset')) {
      return fileName;
    }
    return `${origin}/api/file/${fileName}`;
  }

  getUploadUrl() {
    return `${origin}/api/upload`;
  }

  getDocument(id: string, type: string, toFetchRelatedDocuments?: string[]) {
    return this.http.get(`${origin}/api/document/${type}/${id}${toFetchRelatedDocuments ? '?toFetchRelatedDocuments=' + JSON.stringify(toFetchRelatedDocuments) : ''}`);
  }

  deleteDocument(id: string, type: string) {
    return this.http.delete(`${origin}/api/document/${type}/${id}`);
  }

  saveDocument(updatedDocument: SystemDocument, saveUrl?: string) {
    saveUrl = saveUrl ? saveUrl : '/api/document'
    const copy = JSON.parse(JSON.stringify(updatedDocument));
    removeObjectRef(copy);
    return this.http.post<any>(`${origin}${saveUrl}`, copy);
    // removes objectRef from LadRelatedDocument
    function removeObjectRef(d: SystemDocument) {
      // prevent objectRef from BBRelatedDocument from being saved
      for (const prop of Object.getOwnPropertyNames(d)) {
        const relDocType = d[prop]?.relatedDocumentType;
        const relDocId = d[prop]?.relatedDocumentId;
        if (relDocType && relDocId) {
          delete d[prop].objectRef; // remove objectRef from BBRelatedDocument types
        }
      }
    }
  }

  updateAccount(updatedAccount: LadAccountModel) {
    return this.http.post<any>(`${origin}/auth/updateAccount`, updatedAccount);
  }

  sendSMS(contactNo, message) {
    return this.http.post<any>(`${origin}/api/sendSMS`, {contactNo, message});
  }

  customFunction(args: CustomFunctionParams) {
    const {name, type, queryParams, body, requestType} = args;
    if (requestType === 'GET') {
      return this.http.get(`${origin}/api/${type}/${name}`, {params: queryParams});
    }
    if (requestType === 'POST') {
      return this.http.post(`${origin}/api/${type}/${name}`, body);
    }
    throw new Error('Invalid custom function call');
  }

  executeAPI(name, type, body = {}) {
    return this.http.post(`${origin}/api/${type}/${name}`, body);
  }

  // auth
  signup(username, mobileNo, password, otp) {
    return this.http.post(`${origin}/auth/signup`, {origin, username, mobileNo, password, otp});
  }

  login(email, password) {
    return this.http.post(`${origin}/auth/login`, {
      origin,
      authId: email,
      authKey: password
    });
  }

  signin3rdPartyUser(userObj) {
    return this.http.post(`${origin}/auth/signin3rdPartyUser`, {
      origin,
      userObj
    });
  }

  logout() {
    return this.http.get(`${origin}/auth/logout`);
  }

  loggedIn() {
    return this.http.get(`${origin}/auth/loggedIn`);
  }

  // face microservice api
  matchFaces(ref: string, query: string) { // documentIds of uploaded images
    return this.http.get(`${origin}/ms/face/matchFaces/${ref}/${query}`);
  }

  detectExpression(img) { // documentIds of uploaded images
    return this.http.get(`${origin}/ms/face/detectExpression/${img}`);
  }

  duplicateCheck(images: string[], excludeImages: string[]) { // documentIds of uploaded images
    return this.http.post<any[]>(`${origin}/ms/face/duplicateCheck`, {images, excludeImages});
  }

  requestOTP(mobileNo: string) { // if no mobileNo, then send to current user
    return this.http.post(`${origin}/auth/requestOTP`, {mobileNo});
  }

  consumeOTP(mobileNo: string, otp: string) {
    return this.http.post(`${origin}/auth/consumeOTP`, {mobileNo, otp});
  }

  getAssetFile(relativeLocation: string) {
    return this.http.get(`${origin}/${relativeLocation}`);
  }

  async getClientSettings(): Promise<LadClientSettings> {
    if (!this.clientSettings) {
      const req = this.http.get(`${origin}/api/clientSettings`).toPromise();
      this.clientSettings = (await req) as LadClientSettings;
    }
    return this.clientSettings;
  }

  getClientTopbarAvatar() {
    return this.http.get(`${origin}/api/getClientTopbarAvatar`);
  }

  // todo: rename to getClientMenu
  getClientSidebarMenu() {
    return this.http.get(`${origin}/api/getClientSidebarMenu`);
  }

  validateCaptcha(clientResponse) {
    return this.http.post(`${origin}/auth/validateCaptcha`, {clientResponse});
  }

}

export class CustomFunctionParams {
  name: string;
  type: string;
  queryParams?: any;
  body?: any;
  requestType: string;
}
