import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {BlackCoordinator} from '../../models/black-coordinator';
import {ActivatedRoute} from '@angular/router';
import {BlackAgent} from '../../models/black-agent';
import {BlackStatus} from '../../models/utils/black-utils';
import {DynamicFormService} from '../../../../app/dynamic-form/dynamic-form.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BlackArea} from '../../models/black-area';
import {AreaEditorComponent} from '../area-editor/area-editor.component';
import {APIService} from '../../../../app/shared/backend/api.service';
import {AgentEditorComponent} from '../agent-editor/agent-editor.component';

@Component({
  selector: 'app-agent-masterlist',
  templateUrl: './agent-masterlist.component.html',
  styleUrls: ['./agent-masterlist.component.scss']
})
export class AgentMasterlistComponent implements OnInit, OnDestroy{

  @ViewChild('divElement') divElement: ElementRef;

  public searchKey: string;
  public areas: BlackArea[]  = [];

  public coordinatorId = '';
  coordinatorDisabled = true;

  items1 = [{valuex: 1, displayx: 'Javascript'}, {valuex: 2, displayx: 'ts'}];
  private showItems: boolean;
  showCustomersOnly = false;

  constructor(
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private dfs: DynamicFormService,
    private modalService: NgbModal,
    private api: APIService,
    private renderer: Renderer2
  ) {
  }

  async ngOnInit() {
    const id = this.route.snapshot.queryParamMap.get('id');
    BlackCoordinator.getById({id}).then(coor => {
      if (!coor) {
        return;
      }
      this.coordinatorId = coor.documentId;
      this.coordinatorDisabled = coor.status === BlackStatus.disabled;
      this.doSearch();
      this.dfs.navigateToSamePageWithQuery({id: this.coordinatorId});
    });
  }

  async doSearch() {
    this.showItems = false;

    this.areas.splice(0, this.areas.length);
    if (!this.coordinatorId) {
      return;
    }

    let foundAreas = await BlackArea.getByCoordinatorId({id: this.coordinatorId});
    if (!foundAreas) {
      return;
    }

    for (const a of foundAreas) {
      a.systemHeader.__ = {agentArr : []};
    }

    const foundAgents = await BlackAgent.search({searchKey: this.searchKey, coordinatorId: this.coordinatorId});

    // add to agentArr
    for (const agent of foundAgents) {
      for (const area of foundAreas) {
        if (area.documentId === agent.areaRel?.relatedDocumentId) {
          area.systemHeader.__.agentArr.push(agent);
        }
      }
    }
    if (this.searchKey) { // do not display empty areas if there is a search for an agent
      foundAreas = foundAreas.filter( a => a.systemHeader.__.agentArr.length > 0);
    }

    this.areas = foundAreas;
    this.showItems = true;
    this.cd.detectChanges();

    setTimeout( () => {
      // Select the first div element with the class 'css-class'
      const element = document.querySelector('div.ng2-tags-container');
      // Check if the element exists
      if (element) {
        // @ts-ignore
        element.click();
      } else {
        console.log("Element not found");
      }
    }, 50);

  }

  delete(i: any) {
  }

  ngOnDestroy(): void {
  }

  async newArea() {
    const newArea = new BlackArea(this.coordinatorId);
    const modalRef = this.modalService.open(AreaEditorComponent, {size: 'lg'});
    const instance: AreaEditorComponent = modalRef.componentInstance;
    instance.isNew = true;
    instance.targetDocument = newArea;
    try {
      const result = await modalRef.result;
      if (result === 'cancel') {
        return;
      }
      await this.api.saveDocument(newArea).toPromise();
      await this.doSearch();
    } catch (e) {
      console.log(e);
    }
  }

  async onAgentSelect(a: BlackAgent) {
    const modalRef = this.modalService.open(AgentEditorComponent, {size: 'lg'});
    const instance: AgentEditorComponent = modalRef.componentInstance;
    instance.isNew = false;
    instance.targetDocument = a;
    try {
      const result = await modalRef.result;
      if (result === 'delete') {
        await this.api.deleteDocument(a.documentId, BlackAgent.MODEL_NAME).toPromise();
        await this.doSearch();
        return;
      }
      await this.api.saveDocument(a).toPromise();
    } catch (e) {
      console.log(e);
    }
  }

  async onAgentAdd($event, area: BlackArea) {
    const agentArr = area.systemHeader.__.agentArr;
    const index = agentArr.indexOf($event);

    const a = new BlackAgent(this.coordinatorId, area.documentId);
    a.name = $event.name;

    const modalRef = this.modalService.open(AgentEditorComponent, {size: 'lg'});
    const instance: AgentEditorComponent = modalRef.componentInstance;
    instance.isNew = true;
    instance.targetDocument = a;
    try {
      const result = await modalRef.result;
      if (result === 'cancel') {
        agentArr.splice(index, 1);
        this.cd.markForCheck();
        return;
      }
      await this.api.saveDocument(a).toPromise();
    } catch (e) {
      console.log(e);
    }

    agentArr[index] = a;

    await this.api.saveDocument(a).toPromise();
    this.cd.markForCheck();
  }

  async onAreaSelect(a: BlackArea) {
    const modalRef = this.modalService.open(AreaEditorComponent, {size: 'lg'});
    const instance: AreaEditorComponent = modalRef.componentInstance;
    instance.isNew = false;
    instance.targetDocument = a;
    try {
      const result = await modalRef.result;
      if (result === 'delete') {
        await this.api.deleteDocument(a.documentId, BlackArea.MODEL_NAME).toPromise();
        await this.doSearch();
        return;
      }
      await this.api.saveDocument(a).toPromise();
    } catch (e) {
      console.log(e);
    }
  }

  onShowCustomerOnlyChange() {
    console.log(this.showCustomersOnly);
  }
}
