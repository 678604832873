import {Component, Input, OnInit} from '@angular/core';
import {LadComponentBase} from '../../models/lad-component-base';
import {LadField, LadFieldOptions} from '../../models/lad-field';

@Component({
  selector: 'app-lad-combo',
  templateUrl: './lad-combo.component.html',
  styleUrls: ['./lad-combo.component.scss']
})
export class LadComboComponent extends LadComponentBase implements OnInit {

  @Input()
  formField: LadCombo;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onSelectedItemChanged($event: any) {
    this.formField.value = $event.target.value;
    this.formField.formControl.patchValue($event.target.value, {emitViewToModelChange: true, onlySelf: true});
  }
}

export interface LadComboItems {
  value: any,
  label: string,
  visible?: boolean
}

export interface LadComboOptions extends LadFieldOptions<any> {
  items: LadComboItems[];
}

export class LadCombo extends LadField<any> {

  items: LadComboItems[];

  constructor(options: LadComboOptions) {
    super(options);
    this.__componentType = LadComboComponent;
  }

}

