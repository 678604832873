import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {APIService} from '../backend/api.service';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

  constructor(private titleService: Title, private api: APIService) {
  }

  public async setTitle() {
    const settings = await this.api.getClientSettings();
    this.titleService.setTitle(settings.appTitle);
  }

}
