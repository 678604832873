
<h2 class="card-title">
  <i class="ft-grid"></i>
  &nbsp;Game Sheets
</h2>
<div class="d-sm-flex mb-3 justify-content-between">
  <div>
    <a class="ml-1 btn btn-primary" type="button" routerLink="/pages/black/game-sheet-editor">New</a>
  </div>
</div>
<table class="table table-responsive table-striped table-bordered col-md-6 px-0">
  <thead>
  <tr>
    <th style="width: 150px">#</th>
    <th scope="col" style="width: 30%; min-width: 250px; max-width: 500px">NAME</th>
    <th scope="col">AMOUNT CUT-OFF</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let i of gameSheetArr; let idx = index">
    <td class="text-truncate">
      {{'#' + (idx + 1) }}
    </td>
    <td>
      <a class="info p-0 " data-placement="top" data-toggle="tooltip"
         [queryParams]="{id: i.documentId}"
         routerLink="/pages/black/game-sheet-editor" title="Edit Game Sheet">
        <i class="ft-edit-2 font-medium-2 mr-1"></i>
        <span [innerHTML]="i.name?.toUpperCase()"></span>
      </a>
    </td>
    <td class="text-uppercase" style="min-width: 300px">
      <div *ngFor="let l of i.limits" class="badge badge-pill bg-light-primary mr-1"> {{l.raffleId}}={{l.value | number}} </div>
    </td>
  </tr>
  </tbody>
</table>
