import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PosRoutingModule} from './pos-routing.module';
import {DynamicFormModule} from '../../app/dynamic-form/dynamic-form.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PosMenuComponent} from './pages/pos-menu/pos-menu.component';
import {ProductMasterlistComponent} from './pages/product-masterlist/product-masterlist.component';
import {OrderSummaryComponent} from './pages/order-summary/order-summary.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgbDatepickerModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {NgApexchartsModule} from 'ng-apexcharts';
import {ProductMastlistAddComponent} from './pages/product-mastlist-add/product-mastlist-add.component';
import {InvoiceTemplateComponent} from './pages/invoice-template/invoice-template.component';
import {StoreComponent} from './pages/store/store.component';
import {StoreEditorComponent} from './pages/store-editor/store-editor.component';

@NgModule({
  declarations: [
    PosMenuComponent,
    ProductMasterlistComponent,
    OrderSummaryComponent,
    ProductMastlistAddComponent,
    InvoiceTemplateComponent,
    StoreComponent,
    StoreEditorComponent
  ],
  imports: [
    CommonModule,
    PosRoutingModule,
    DynamicFormModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    NgApexchartsModule,
    NgbPaginationModule
  ]
})
export class PosModule { }
