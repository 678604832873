import {API, LadDocumentService, SystemDocument} from 'ladrov-commons';

export class DFSampleDocument extends SystemDocument {

  public uploads1 = [
    {
      fileName: 'assets/img/photos/12.jpg',
      mimeType: 'jpg',
      originalName: 'Photos-12.jpg',
      size: 0,
      uploadedTimestamp: Date.now()
    },
    {
      fileName: 'assets/img/photos/13.jpg',
      mimeType: 'jpg',
      originalName: 'Photos-13.jpg',
      size: 0,
      uploadedTimestamp: Date.now()
    },
  ];

    // @API('GET')
  public static async search(req, res) {
    const docService: LadDocumentService = req.documentService;
    const all = await docService.find(DFSampleDocument, {});
    res.status(200).send(all);
  }
  constructor() {
    super(`DFSampleDocument`);
  }

}
