import {API, APIFunctionTypes, LadAPIUtils, LadDate, LadDocumentService, LadRelatedDocument, SystemDocument} from 'ladrov-commons';
import {JPEModelNames} from './jpe-model.names';
import {JPEPropertyContract} from './jpe-property-contract.model';
import {JPEAccount} from './jpe-account.model';

export class InvoiceItem {
  constructor(public details = '', public amount = 0) {
  }
}

export interface InvoiceSummary {
  totalPaid: number;
  totalPaidAmount: number;
  dueInvoices: number;
  totalAmountDue: number;
}

export class JPEInvoice extends SystemDocument {

  propertyContract: LadRelatedDocument<JPEPropertyContract> = {
    relatedDocumentType: JPEModelNames.PROPERTY_CONTRACT,
    relatedDocumentId: null
  }
  // supplied for property contract invoices only
  amortizationInfo: {
    from: LadDate,
    to: LadDate
  };

  checker: LadRelatedDocument<JPEAccount> = {
    relatedDocumentType: JPEModelNames.ACCOUNT,
    relatedDocumentId: null
  }
  billTo: LadRelatedDocument<JPEAccount> = {
    relatedDocumentType: JPEModelNames.ACCOUNT,
    relatedDocumentId: null
  }

  invoiceNumber: string;
  invoiceDate: LadDate = new LadDate();
  dueDate: LadDate = new LadDate();

  items: InvoiceItem[] = [new InvoiceItem()];
  subTotal: number;
  tax: number;
  amountDue: number;

  paymentMethod: 'UNPAID' | 'CASH' | 'CHEQUE' | 'ONLINE-BANKING' = 'UNPAID';
  paymentDate = null;
  receiptNo: string;

  encoderName: string;

  total: number;
  credits: number;
  accountNo: number;

  projectDeleted?: boolean;

  //FOR NOW
  amountPaid: any;
  orDateNoDATE: any;
  orDateNoNO: any;
  balance1: any;
  balance2: any;

  isDownPayment: false;

  @API(APIFunctionTypes.PRIVATE)
  public static async search(args, utils: LadAPIUtils): Promise<JPEInvoice[]> {
    const docService: LadDocumentService = utils.documentService;
    return await docService.find(JPEModelNames.INVOICE, {});
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async fetchInvoices(args, utils: LadAPIUtils): Promise<JPEInvoice[]> {
    const { contractId } = args;
    let { searchKey } = args;
    searchKey = searchKey?.trim().toLowerCase();
    const docService: LadDocumentService = utils.documentService;
    let filter: any = { 'propertyContract.relatedDocumentId': contractId };
    if (searchKey && searchKey !== 'due') {
      filter.$or = [
        { invoiceNumber : new RegExp(searchKey, 'i') },
      ]
    }
    else if (searchKey === 'due') {
      const now = new LadDate();
      filter = {
        ... filter,
        'propertyContract.relatedDocumentId': args.contractId,
        'paymentMethod': 'UNPAID',
        'dueDate.year': {$lte: now.year},
        'dueDate.month': {$lte: now.month},
        'dueDate.day': {$lte: now.day},
      };
    }
    const options = {
      sort: {
        'dueDate.year' : -1 ,
        'dueDate.month': -1,
        'dueDate.day': -1,
      }
    }
    return await docService.find(JPEModelNames.INVOICE, filter, options);
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async fetchInvoicesWithSummary(args, utils?: LadAPIUtils) {
    let {
      fromDate,
      toDate,
      selectedDateType,
      projectIds
    } = args;
    fromDate = fromDate ? fromDate : new LadDate();
    toDate = toDate ? toDate : new LadDate();
    const docService: LadDocumentService = utils.documentService;
    const from = new Date(`${fromDate.year}-${fromDate.month}-${fromDate.day}`);
    const to = new Date(`${toDate.year}-${toDate.month}-${toDate.day}`);
    let filter: any = {
      projectDeleted: {$ne: true}
    };
    let filterSearch = [];
    if(selectedDateType === 'dueDate'){
      filterSearch = [
        {'dueDate.date': {$gte: from}},
        {'dueDate.date': {$lte: to}}
      ]
    } else {
      filterSearch = [
        {'paymentDate.date': {$gte: from}},
        {'paymentDate.date': {$lte: to}}
      ]
    }
    // Invoice(propertyContract) => ProjectContract(projectId) => Project
    if(projectIds){
      const contract = await JPEPropertyContract.search({ projectIds: projectIds }, utils);
      const contractIds = contract.map(c => c.documentId);
      filterSearch = [...filterSearch, {'propertyContract.relatedDocumentId': {$exists: true, $in: contractIds}}];
    }
    if(filterSearch.length){
      filter.$and = filterSearch;
    }
    const options = {
      sort: {
        'paymentDate.date' : -1
      }
    };
    const invoices: JPEInvoice[] = await docService.find(JPEModelNames.INVOICE, filter, options);
    const summary: InvoiceSummary =  {
      dueInvoices: 0, totalAmountDue: 0, totalPaid: 0, totalPaidAmount: 0
    };
    const now = new LadDate();
    for (const i of invoices) {
      if (i.paymentMethod !== 'UNPAID') {
        summary.totalPaidAmount += Number(i.amountDue);
        summary.totalPaid++;
      } else {
        const idate = new LadDate();
        idate.year = i.dueDate.year;
        idate.month = i.dueDate.month;
        idate.day = i.dueDate.day;
        if (now.after(idate) || now.equals(idate)) {
          summary.dueInvoices++;
          summary.totalAmountDue += Number(i.amountDue);
        }
      }
    }
    return {
      invoices,
      summary
    };
  }

  @API(APIFunctionTypes.PRE_SAVE)
  public static async presave(invoice: JPEInvoice, utils: LadAPIUtils): Promise<JPEInvoice> {
    const currentAccount = await utils.getCurrentAccount();
    if (currentAccount) {
      if (currentAccount.username) {
        invoice.encoderName = currentAccount.username;
      } else if (currentAccount.name) {
        invoice.encoderName = currentAccount.name;
      } else {
        invoice.encoderName = currentAccount.documentId.substring(0, 7);
      }
    } else {
      invoice.encoderName = utils.currentUserId.substring(0, 7);
    }
    return invoice;
  }

  @API(APIFunctionTypes.POST_SAVE)
  public static async updateContract(invoice: JPEInvoice, utils: LadAPIUtils): Promise<JPEInvoice> {
    const contractId = invoice.propertyContract.relatedDocumentId;
    if (!contractId) {
      return;
    }
    // update contract balance
    const contract: JPEPropertyContract = await utils.documentService.findOne(JPEModelNames.PROPERTY_CONTRACT, {documentId: contractId});
    if (!contract) {
      return;
    }
    let amountPaid = 0;
    const filter = { 'propertyContract.relatedDocumentId': contractId,  'paymentMethod' : { $ne: 'UNPAID'}};
    const invoices: JPEInvoice[] = await utils.documentService.find(JPEModelNames.INVOICE, filter);
    for (const i of invoices) {
      amountPaid += i.amountDue;
    }
    const updates = {
      documentId: contractId,
      balance: contract.totalContractAmount - amountPaid,
      percentComplete: ((amountPaid / contract.totalContractAmount) * 100).toFixed(2),
      systemHeader: {
        type: JPEModelNames.PROPERTY_CONTRACT
      }
    }
    await utils.documentService.upsert(updates, utils.currentUserId);
  }

  @API(APIFunctionTypes.POST_DELETE)
  public static async updateContractPostDel(invoice: JPEInvoice, utils: LadAPIUtils): Promise<JPEInvoice> {
    console.log('post delete called');
    return JPEInvoice.updateContract(invoice, utils);
  }

  constructor() {
    super(JPEModelNames.INVOICE);
    this.invoiceNumber = this.documentId.substring(0, 7);
  }
}
