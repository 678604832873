import {API, APIFunctionTypes, LadAccountModel, LadAPIUtils, LadRelatedDocument, LadTime, SystemDocument} from 'ladrov-commons';
import {BLACK_SYSTEM_NAME, BlackStatus} from './utils/black-utils';
import {Draw, initMaxBetObj, Raffle, RaffleSettings} from './utils/number-helper';
import {BlackUserBase} from './black-user-base';
import {BlackAgent} from './black-agent';

export class BlackCoordinator extends SystemDocument implements BlackUserBase {
  public static MODEL_NAME = 'BlackCoordinator';

  fullName: string = '';
  areas: string[] = [];
  accessKey = this.documentId.split('-')[0];
  mobileNumber: string;
  mobileNumbers: string[] = [];
  status: BlackStatus = BlackStatus.enabled

  draws = [Draw.d1, Draw.d1, Draw.d3];
  raffles = [Raffle.l2, Raffle.s3, Raffle.s3r, Raffle.d4];

  enableCutOffTime: boolean;
  cutoffTime: { [p: string]: LadTime };

  enablePrizes: boolean;
  unitPrizes: RaffleSettings[] = [];

  enableCommissions: boolean;
  commissions: RaffleSettings[] = [];

  enableMaxBet: boolean;
  maxBet: { [key: string]: { [key: string]: RaffleSettings[]; }; } = initMaxBetObj();

  accountRel: LadRelatedDocument<LadAccountModel>;
  agentCount: number; // number of agents - will be updated whenever agent post save

  constructor() {
    super(BlackCoordinator.MODEL_NAME, BLACK_SYSTEM_NAME);
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async search(args, util?: LadAPIUtils): Promise<BlackCoordinator[]> {
    const {searchKey} = args;
    let filter:any = {status: 'enabled'};
    if (searchKey != null) {
      const keyRegex = new RegExp(searchKey?.trim().toLowerCase(), 'i');
      filter = {
        // status: 'enabled',
        $or: [
          { fullName: keyRegex },
          { areas: keyRegex },
          { mobileNumber: keyRegex }
        ]
      };
    }
    return await util.documentService.find(BlackCoordinator.MODEL_NAME, filter);
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getById(args: {id: string}, util?: LadAPIUtils): Promise<BlackCoordinator> {
    if (!args.id){
      const coor = await BlackCoordinator.getByAccountId(util.currentUserId, util);
      if (!coor) {
        return null;
      }
      args.id = coor.documentId;
    }
    return await util.documentService.findOne(BlackCoordinator.MODEL_NAME, {documentId: args.id});
  }

  static getLimitAmount(draw: Draw, raffle: any, combination: any, coorObj: BlackCoordinator) {
    return NaN;
  }

  @API(APIFunctionTypes.PRE_SAVE)
  public static async preSave(coordinator: BlackCoordinator, util: LadAPIUtils, opt) {
    if (coordinator.accountRel) {
      return coordinator;
    }

    let registerNewAccount = false;
    if (opt.isInsert) {
      registerNewAccount = true;
    } else {
      const agentCount = await BlackCoordinator.getCoordinatorAgentCount(coordinator.documentId, util);
      coordinator.agentCount = Number(agentCount);
    }

    if (coordinator.mobileNumber) {
      const currentCoordinator: BlackCoordinator = await util.documentService.findOne(BlackCoordinator.MODEL_NAME, {documentId: coordinator.documentId});
      if (currentCoordinator && currentCoordinator.mobileNumber !== coordinator.mobileNumber) {
        registerNewAccount = true;
      }
    }

    if (registerNewAccount) {
      const account = await BlackCoordinator.registerCoordinatorAccount(coordinator.accessKey, coordinator.mobileNumber, util);
      coordinator.accountRel = {
        relatedDocumentId: account.documentId,
        relatedDocumentType: `LadAccountModel`
      }
    }

    return coordinator;
  }

  private static async registerCoordinatorAccount(username, mobileNo, util: LadAPIUtils){
    const account = await util.doSignup({username, mobileNo, password: username} );
    return account;
  }

  public static async getCoordinatorAgentCount(coordinatorId: string, util: LadAPIUtils) {
    const filter = {
      'coordinatorRel.relatedDocumentId': coordinatorId
    };
    const result = await util.documentService.count(BlackAgent.MODEL_NAME, filter);
    return result;
  }

  public static async updateCoordinatorAgentCount(coordinatorId: string, util: LadAPIUtils) {
    const agentCount = await BlackCoordinator.getCoordinatorAgentCount(coordinatorId, util);
    const updateInfo = {
      documentId: coordinatorId,
      agentCount,
      systemHeader: {
        type: BlackCoordinator.MODEL_NAME
      }
    };
    await util.documentService.upsert(updateInfo, util.currentUserId);
  }

  @API(APIFunctionTypes.PRIVATE)
  public static async getByAccountId(userId: string, util?: LadAPIUtils): Promise<BlackCoordinator> {
    return await util.documentService.findOne(BlackCoordinator.MODEL_NAME, {'accountRel.relatedDocumentId': userId});
  }

}
