import {ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {LadComponentBase} from '../../models/lad-component-base';
import {LadField, LadFieldOptions} from '../../models/lad-field';
import {LadUploadedAsset} from 'ladrov-commons';
import {Lightbox} from 'ngx-lightbox';
import {APIService} from '../../../shared/backend/api.service';
import {FileItem, FileUploader, ParsedResponseHeaders} from 'ng2-file-upload';
import {NgbCarousel} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {DynamicFormService} from '../../dynamic-form.service';

interface LightboxItem {
  src: string;
  caption: string;
  thumb: string;
}

@Component({
  selector: 'app-lad-uploads',
  templateUrl: './lad-uploads.component.html',
  styleUrls: ['./lad-uploads.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LadUploadsComponent extends LadComponentBase implements OnInit {

  @Input()
  formField: LadUploads;
  @ViewChild('carousel') carousel: NgbCarousel;

  public _album: LightboxItem[] = [];

  public uploader: FileUploader = new FileUploader({
    url: this.api.getUploadUrl(),
    isHTML5: true,
    autoUpload: true
  });
  message = 'Choose File(s)';

  constructor(
    private lightbox: Lightbox,
    private api: APIService,
    private cd: ChangeDetectorRef,
    public toastr: ToastrService,
    private formService: DynamicFormService
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.prepAlbum(this.formField.value);
    this.uploader.onBeforeUploadItem = (item: FileItem) => {
      this.message = 'Uploading...';
    };
    this.uploader.onCompleteItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {
      if (status === 200) {
        const rArray: LadUploadedAsset[] = JSON.parse(response);
        let currentValue = this.formField.formControl.value;
        if (!currentValue) {
          currentValue = [];
        }
        const newVal = currentValue.concat(rArray);
        this.formField.formControl.patchValue(newVal,{onlySelf: true}); // add to first
        this.prepAlbum(newVal);
        this.cd.markForCheck();
        this.message = 'Done!';
        setTimeout(() => {
        }, 1000);
      } else {
        this.toastr.error(`An error occurred when uploading attachment: ${this.formField.key}`, 'Upload Error');
      }
    };
  }

  prepAlbum(value: LadUploadedAsset[]) {
    if (!value) {
      return;
    }
    value.map(each => {
      const url = this.api.getFileUrl(each.fileName);
      if (itemExists(this._album, url)) {
        return;
      }
      this._album.push({
        src: url,
        caption: each.originalName,
        thumb: url
      });
    });
    this.cd.markForCheck();

    function itemExists(album: LightboxItem[], url) {
      for (const item of album) {
        if (item.thumb === url) {
          return true;
        }
      }
      return false;
    }
  }

  open(index: number): void {
    this.lightbox.open(this._album, index, {disableScrolling: true, centerVertically: true});
  }

  close(): void {
    // close lightbox programmatically
    this.lightbox.close();
  }

  async removeItem() {
    this.carousel.pause(); // stop this carousel from moving
    const activeSlideId = this.carousel.activeId;
    const slides = this.carousel.slides.toArray();
    let index = -1;
    for (let i = 0; i < slides.length; i++) {
      if (slides[i].id === activeSlideId) {
        index = i;
        break;
      }
    }

    if (index === -1) {
      throw new Error('Error in uploads component: Did not find the active slide.');
    }

    const value: LadUploadedAsset[] = this.formField.formControl.value;
    const item = value[index];

    const result = await this.formService.fireSwalConfirm(`Confirm removing ${item.originalName}?`, 'warning', 'Warning');
    if (!result.value) {
      return;
    }

    value.splice(index, 1);
    this.formField.formControl.patchValue(value,{onlySelf: true}); // add to first
    this.prepAlbum(value);
    this.cd.markForCheck();
    this.toastr.info(`Removed uploaded asset: ${item.originalName}.`, 'Item Removed');
  }

}


export class LadUploads extends LadField<LadUploadedAsset[]> {

  constructor(options: LadFieldOptions<LadUploadedAsset[]>) {
    super(options);
    this.__componentType = LadUploadsComponent;
  }
}
